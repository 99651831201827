import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import t from "../../translate";
import apiCall from "../../api";
import { getClone } from "../../utils"

const ChangePassword = (props) => {

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [message, setMessage] = useState('');

  const auth = useSelector(state => state.auth);

  function saveNewPassword(){
    let studentToSave = getClone(props.student);
    studentToSave.password = password;
    studentToSave.password_check = password;

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user:studentToSave
      }
    }).then(resp => {
      setMessage(t("Wachtwoord is gewijzigd"))
      setPassword('')
      setRepeatPassword('')
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }

  return (
    <>
    <div className='set_new_password'>
      <div className="col">
        <label htmlFor="login">{t("Wachtwoord")}</label>
        <input
          type="password"
          className="form-control"
          id="password"
          name="password"
          aria-describedby="password"
          placeholder=""
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
        />
      </div>
      <div className="col">
        <label htmlFor="login">{t("Wachtwoord ter controle")}</label>
        <input
          type="password"
          className="form-control"
          id="password_check"
          name="password_check"
          aria-describedby="password_check"
          placeholder=""
          value={repeatPassword}
          onChange={(e)=>setRepeatPassword(e.target.value)}
        />
        {password != repeatPassword ?
          <div className='error'>
            {t("Wachtwoord ter controle is anders")}
          </div>
          :false}
      </div>
    </div>
    {password != "" && repeatPassword != "" && (password == repeatPassword) ?
      <div className="align_right marge_top">
        <span className="btn btn-primary" onClick={()=>saveNewPassword()}>{t("Opslaan")}</span>
      </div>
    :false}
    {message ?
      <div className="align_right marge_top">
        <span className="btn btn-primary btn-success">
          {message} <i className="fa fa-check"></i>
        </span>
      </div>
      :
      false}
    </>
  )
}
export default ChangePassword;
