import React, { useState, useEffect } from "react"
import { useSelector, useDispatch  } from "react-redux";
import DashBoardLeft from "../../course/content/dashboard_left.js"
import QuestionnaireT0 from "../T0";
import BaselineFeedback from "./feedback";
import Rewards from "../../rewards";
import apiCall from "../../api"; 
import { setQuestionnaire, setAnswersLessons, setActiveSubLesson } from "../../../actions";

const Baseline = () => {

  const dispatch = useDispatch();

  const [badgesActive, setBadgesActive] = useState(false);
  const [t0Id, setT0Id] = useState(0);
  const [t0Finished, setT0Finished] = useState(false)
  const [showSuicideMessage, setShowSuicideMessage] = useState(false)

  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);
  const language_id = useSelector(state => state.activeLanguage);

    useEffect(() => {
      if (auth.user_id > 0 && typeof intervention.settings.gamification !== "undefined" && intervention.settings.gamification.badges) {
         if (typeof intervention.settings.gamification.badgesFromDateActive !== "undefined" && typeof intervention.settings.gamification.badgesFromDate !== "undefined" && intervention.settings.gamification.badgesFromDateActive) {
          if(typeof intervention.settings.gamification.badgesFromDate[0] != "undefined" && intervention.settings.gamification.badgesFromDate[0] != "") {
            var date = intervention.settings.gamification.badgesFromDate[0].split("T")[0].split("-");
            var timestamp_to_check = new Date( date[0], date[1] - 1, date[2]) / 1000 ;
            if(timestamp_to_check < parseInt(auth.rights.interventions[0].date_time_start) &&  parseInt(auth.rights.interventions[0].date_time_start) != ""){
               setBadgesActive(true);
            }
          }
         } else {
            setBadgesActive(true);
         }
      }
    }, [auth, intervention]);

    useEffect(() => {
      get_T0(1);  
    }, [])

    const get_T0 = (part) => {
      //api aanroepen
      apiCall({
        action: "get_t0_answers",
        token: auth.token,
        data: {
          language_id: language_id,
          part
        }
      }).then(resp => {
        /* setPart(part); */
        setT0Id(resp.id);
        dispatch(
          setQuestionnaire(
            resp.id,
            resp.questionnaires,
          )
        )
        dispatch(
          setAnswersLessons(
            resp.intervention_id,
            resp.answers
            )
        )
      })
    }




   return (
      <div>
         <div className="dashboard lessoncontent front">
            <div className="clearfix holder">
               <DashBoardLeft hideMenu="false" t0Finished={t0Finished} />
               <div className="right">
                  {/* <div className={(activePart !== 'lesson' && activePart !== 'optional-lesson') ? 'shapes phone' : 'hidden'}>
                     <div className='shape'></div>
                     <div className='shape'></div>
                     <div className='shape'></div>
                     <div className='shape'></div>
                  </div> */}
                  { 
                     !t0Finished ?
                        <QuestionnaireT0 intervention_id={intervention.id} language_id={language_id} questionnaire_id={t0Id} setT0Finished={setT0Finished} setShowSuicideMessage={setShowSuicideMessage} />
                        :
                        <BaselineFeedback showSuicideMessage={showSuicideMessage} />
                  }
                  {badgesActive ? <Rewards setHideMenu="false" /> :false}
            </div>
            </div>
         </div>
      </div>
   )
}

export default Baseline