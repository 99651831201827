import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import apiCall from "../api";
import t from "../translate";
import LeftMenu from "../leftmenu";
import AdminOptions from "../dashboard/admin/options.js";
import TranslatePlatformPages from "./platform/pages.js";
import TranslatePlatformQuestionnaires from "./platform/questionnaires.js";
import LoadScreen from "../loadScreen";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

let saveTimer = null;

const PlatformTranslations = (props) => {

  const auth = useSelector(state => state.auth);
  const location = useLocation();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [loaded, setLoaded] = useState(-1);
  const [languages, setLanguages] = useState([]);
  const [activeLanguageIndex, setActiveLanguageIndex] = useState(0);
  const [translation_id, setTranslationId] = useState(0);
  const [translations, setTranslations] = useState([]);
  const [activeTranslation, setActiveTranslation] = useState([]);
  const [active, setActive] = useState(1);

  useEffect(() => {
    //voldoende rechten?
    if (typeof auth.rights.platform_access === 'undefined' || !auth.rights.platform_access) {
      history.push('/')
    }
    apiCall({
      action: "get_intervention_translations_platform",
      token: auth.token,
      data: {
      }
    }).then(resp => {
      setTranslations(resp.translations);
      setLanguages(resp.languages);
      setActiveTranslation(resp.translations[0].translation)
      setTranslationId(parseInt(resp.translations[0].id))
      setLoaded(1);
    });
  }, [])

  useEffect(() => {
    if(location.pathname.split("/")[2]){
      setActiveTab(location.pathname.split("/")[2])
    } else {
      setActiveTab('pages')
    }
  }, [location])

  const changeTab = (tab) => {
    history.push("/platform-translations/" + tab + "/");
    setActiveTab(tab)
  }
  const changeActiveLanguageIndex = (index) => {
    setShowLanguageSelector(false)
    setActiveLanguageIndex(index)
    setTranslationId(parseInt(translations[index].id))
    setActiveTranslation(translations[index].translation)
  }

  useEffect(() => {

    if (loaded != -1 && translation_id > 0) //prevent from saving onload maar doet die toch
    {
      clearTimeout(saveTimer);

      saveTimer = setTimeout(() => {
        apiCall({
          action: "update_intervention_translation_platform",
          token: auth.token,
          data: {
            translation_id:parseInt(translation_id),
            translation:activeTranslation,
            active:active
          }
        }).then(resp => {
          //
        });
      }, 3000)
    }

  }, [activeTranslation])


  return (
    <div className="container dashboard admin platform-translations">
      <LeftMenu />
      <AdminOptions activity={true}/>
      <div className="list listHidden intervention_edit">
        {languages.length > 0 ?
          <table className="language_selector">
            <tbody>
            <tr>
            <td>
              <h2 onClick={()=>setShowLanguageSelector(showLanguageSelector?false:true)} data-tip={t("Wijzig taal")}>{languages[activeLanguageIndex].language}</h2>
              <ReactTooltip place="top" effect="solid" delayShow={200}   />
            </td>
            <td>
            {showLanguageSelector ?
              <select onChange={(e)=>changeActiveLanguageIndex(e.target.value)} value={activeLanguageIndex} >
                {languages.map((language, index) => {
                  return(
                    <option value={index}>{language.language}</option>
                  )
                })}
              </select>

              :false
            }
            </td>
            </tr>
            </tbody>
          </table>
          :
          false
        }
        <nav className="navbar navbar-intervention-settings navbar-expand-lg navbar-light">
          <div id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <span id="settings-navbar-general" className={"nav-item nav-link" +  (activeTab == "pages" ? " active":"")} onClick={()=>changeTab('pages')}>{t("Paginas")}</span>
              <span id="settings-navbar-general" className={"nav-item nav-link" +  (activeTab == "questionnaires" ? " active":"")} onClick={()=>changeTab('questionnaires')}>{t("Vragenlijsten")}</span>
            </div>
          </div>
        </nav>
        {activeTab == "pages" ? <TranslatePlatformPages activeTranslation={activeTranslation} setActiveTranslation={setActiveTranslation} />:false}
        {activeTab == "questionnaires" ? <TranslatePlatformQuestionnaires activeTranslation={activeTranslation}  setActiveTranslation={setActiveTranslation}/>:false}
      </div>
    </div>

  )
}
export default PlatformTranslations;
