import React, { useEffect, useState } from "react";


const  Pagination = props => {

    return (
      <div className="pagination">
      <i className="fa-solid fa-backward-fast"></i>
        {props.paginationFrom !== 0 ?
          <>
          <span className="start" onClick={()=>props.setPaginationFrom(0)}>
            <i className="fa fa-chevron-left"></i>
            <i className="fa fa-chevron-left"></i>
          </span>
          <span className="pagination_step_back" onClick={()=>props.setPaginationFrom(props.paginationFrom-props.paginationStep)}><i className="fa fa-chevron-left"></i></span><span className="showing_dots">...</span>
          </>:false}
          |&nbsp;
        {[...Array(Math.ceil(props.nrItems/props.paginationStep))].map((e, i) =>
          <>
          {(props.paginationFrom / props.paginationStep) == i || (props.paginationFrom / props.paginationStep) == i - 1 || (props.paginationFrom / props.paginationStep) == i + 1 ?
            <span className={"pagination_go_to " + (i*props.paginationStep == props.paginationFrom ?'active':'')} onClick={()=>props.setPaginationFrom(i*props.paginationStep)}><span>{i*props.paginationStep} - {(i*props.paginationStep)+props.paginationStep < props.nrItems ?(i*props.paginationStep)+props.paginationStep:props.nrItems}</span> |&nbsp; </span>
            :false }
          </>
        )}
        {props.paginationFrom + props.paginationStep < props.nrItems ?
          <>
          <span className="showing_dots">...</span>
          <span className="pagination_step_forward" onClick={()=>props.setPaginationFrom(props.paginationFrom+props.paginationStep)}><i className="fa fa-chevron-right"></i></span>
          <span className="end" onClick={()=>props.setPaginationFrom(Math.floor(props.nrItems/props.paginationStep)*props.paginationStep)}>
              <i className="fa fa-chevron-right"></i>
              <i className="fa fa-chevron-right"></i>
          </span>
          </>:false}

      </div>
    )
}

export default Pagination;
