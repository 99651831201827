import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import { useHistory } from "react-router-dom";
import t from "../translate";
import { setActivePart, setActivePage } from "../../actions";
import chevronPNG from '../../images/course/standard/chevron.png';
import LinkBox from "../profile/linkBox.js";


const Overview = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const intervention = useSelector(state => state.intervention);

  const changeActivePage = (page_id) => {
    dispatch(setActivePart('page'));
    dispatch(setActivePage(page_id));
    history.push("/course/" + intervention.id + "/page/" + page_id);
  }

  const changeActivePartSettings = () => {
    dispatch(setActivePart('settings'));
    history.push("/course/" + intervention.id + "/settings");
  }

  return(
    <div className="pages">
      <h1>{t('Meer informatie')}</h1>
      <ul>
        {intervention.settings.pages.map((page, key) => (
          <>
            {page.settings.visible != 'hidden' ?
              <li onClick={e=>changeActivePage(page.id)} className="cursor" key={key}>
                <img src={chevronPNG} className='chevron'/> {page.title}
              </li>
              :false}
          </>
        ))}
        <li><br /></li>
      </ul>
      <LinkBox />
    </div>
  )
}

export default Overview;
