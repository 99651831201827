export const registrationTextObject = () => {
   return {
      panel1: {
         introTitle: {
            nl: '',
            eng: '',
         },
         introContent: {
            nl: '',
            eng: '',
         },
         surveyTitle: {
            nl: '',
            eng: '',
         },
         surveyContent: {
            nl: '',
            eng: '',
         },
         programsTitle: {
            nl: '',
            eng: '',
         },
         programsContent: {
            nl: '',
            eng: '',
         },
         unisTitle: {
            nl: '',
            eng: '',
         },
         unisContent: {
            nl: '',
            eng: '',
         },
         thirdpartiesTitle: {
            nl: '',
            eng: '',
         },
         thirdpartiesContent: [],
         popups: []
      },
      panel2: {
         interventions: []
      },
      panel3: {
         informedConsent: {
            nl: '',
            eng: ''
         },
         mailDomains: '',
         question1: {
            nl: '',
            eng: ''
         },
         question2: {
            nl: '',
            eng: ''
         },
         question3: {
            nl: '',
            eng: ''
         },
         question4: {
            nl: '',
            eng: ''
         },         
         suicideQuestion: [], 
         wrongUni: {
            nl: '',
            eng: ''
         }
      },
      panel4: {
         intro: {
            nl: '',
            eng: ''
         },  
         description: {
            nl: '',
            eng: ''
         },  
      },
      panel5: {
         privateEmailTip: {
            nl: '',
            eng: ''
         }
      },
      panel6: {
         interventions: []
      }
   }
}


export const interventionObject = () => {
   return {
      id: 0,
      title: {
         nl: 'Over ',
         eng: 'About ',
      },
      description: {
         nl: '',
         eng: '',
      },
      button: {
         nl: 'Dit programma volgen',
         eng: 'Do this program',
      },
      waitinglist: {
         nl: '',
         eng: '',
      },
      studentsReviewsTitle: {
         nl: 'Wat studenten zeggen',
         eng: 'What students say',
      },
      studentsReviewsContent: [],
      mediaTitle: {
         nl: 'Een inkijk in ',
         eng: 'A glimpse of ',
      },
      youtubeCode: {
         nl: '',
         eng: '',
      },
      mediaContent: []
   }
}
