import React, { useState, useEffect } from "react";
import moment from "moment";
import 'moment/min/moment-with-locales'
import t from "../../translate";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import { getClone } from "../../utils";
import ContentEditable from 'react-contenteditable';
import parse from 'html-react-parser';
import $ from "jquery";
import SortObjectArray from "../../helpers/sortObjectArray.js";
import Typewriter from 'typewriter-effect';
import pencilSVG from '../../../images/course/standard/svg/pencil.svg';


const Journal = () => {

  const [journalItems, setJournalItems] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formTitle, setFormTitle] = useState("Jouw stress dagboek");
  const [btnLabel, setBtnLabel] = useState("");
  const [journalIndex, setJournalIndex] = useState(-1);
  const [journalContent, setJournalContent] = useState("");

  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);
  const language_id = useSelector(state => state.uiTranslation.language_id);

  useEffect(() => {

    if (activeIntervention > 0) {
      apiCall({
        action: "get_journal",
        token: auth.token,
        data: {
          intervention_id: parseInt(activeIntervention)
        }
      }).then(resp => {
        if (resp.error == 0 && resp.content) {

          setJournalItems(resp.content.sort(SortObjectArray("dateTime", "desc")));
        }
      });
    }

  }, []);

  useEffect(() => {
    if(language_id == 1){
      moment.locale('nl',require('moment/locale/nl'))
    } else {
      moment.locale('en')
    }
  }, [language_id]);

  useEffect(() => {
    saveJournal();
  }, [journalItems])

  const showAddItem = () => {
    setJournalIndex(-1);
    setFormTitle("Toevoegen dagboek item");
    setBtnLabel("Toevoegen");
    setShowForm(true);
  }

  const showEditItem = (index) => {
    setJournalIndex(index);
    setFormTitle("Wijzig dagboek item");
    setBtnLabel("Opslaan");
    setJournalContent(journalItems[index].content);
    setShowForm(true);
  }

  const onChange = (e) => {
    setJournalContent(e.target.value == "<br>" ? '':e.target.value);
  }

  const addEditItem = (e) => {
    e.preventDefault();
    if (journalIndex === -1) //toevoegen
    {

      let newJournalItem = {
        dateTime: Date.now(),
        content: journalContent
      }
      setJournalItems([...journalItems, newJournalItem]);
    }
    else //wijzigen
    {
      let editedJournalItems = [...journalItems];
      editedJournalItems[journalIndex].content = journalContent;
      setJournalItems(editedJournalItems);
    }
    setShowForm(false);
    setJournalContent("");
  }

  const hideForm = () => {
    setShowForm(false);
    setFormTitle("Jouw stress dagboek")
  }

  const saveJournal = () => {

    if (journalItems.length > 0)
    {
      apiCall({
        action: "save_journal",
        token: auth.token,
        data: {
          intervention_id: parseInt(activeIntervention),
          content: journalItems
        }
      }).then(resp => {
      });
    }

  }

  return (
    <div className="journal">
      <div className="add">
        <h1>{t(formTitle)}</h1>
        {
          (parseInt(language_id) === 1) ?
            <p>
              Denk na over deze vragen, je coach zal je antwoorden kunnen zien en je kunnen ondersteunen:
              <ul>
                <li>Beschrijf wat er vandaag is gebeurd. Beschrijf de situatie zo gedetailleerd mogelijk.</li>
                <li>Wat zijn je gedachten en emoties over deze situatie?</li>
                <li>Kun je iets identificeren dat bijdraagt aan je stressniveau vandaag?</li>
                <li>Hoe probeerde u met deze situatie om te gaan?</li>
                <li>Wat kunt u de volgende keer doen als u een soortgelijke situatie ervaart? (Hier kunt u ook nadenken over de technieken die u kunt toepassen.)</li>
              </ul>
            </p>
            :
            <p>
              Think about these questions, your coach will be able to see your answers and support you:
              <ul>
                <li>Describe what happened today. Please describe the situation as detailed as you can.</li>
                <li>What are your thoughts and emotions about this situation?</li>
                <li>Can you identify anything contributing to your stress levels today?</li>
                <li>How did you try to cope with this situation? </li>
                <li>What can you do next time when you experience a similar situation? (Here you can also think about the techniques that you can apply.)</li>
              </ul>
            </p>
        }
        <br />
        <button className={(!showForm) ? 'btn btn-primary' : 'hidden'} onClick={showAddItem}>{t("Voeg item toe")}</button>
      </div>

      {
        (showForm) ?
          <>
            <form>
            <div className="openQuestion">
            <div className="icon">
                <img src={pencilSVG} alt="pencil" />
            </div>
            <div className="input">
              <ContentEditable
                  //innerRef={props.focus !== false && typed == false ? focus:false}
                  html={journalContent}
                  placeholder={t("Je dagboek item")}
                  onChange={onChange}
                  className="textarea"
                />
            </div>
            </div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <button className="btn" onClick={hideForm}>{t("Annuleer")}</button>
                    </td>
                    <td>
                      <button className="btn btn-primary" onClick={addEditItem}>{t(btnLabel)}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </>
          :
          <></>
      }
      {journalItems.length > 0 && !showForm ?
        <div className="journal_items">
          <h2>{t("Je dagboek items")}</h2>
          <div className='items'>
            {
              journalItems.map((item, index) => (
                  <div key={index} className="item" onClick={() => showEditItem(index)}>
                    <table>
                      <tbody>
                        <tr className="dateTime">
                          <td>
                            <i className="fas fa-calendar-week"></i>
                            {moment(item.dateTime).format("LL")}
                          </td>
                          <td>
                            <i className="far fa-clock" ></i>
                            {moment(item.dateTime).format("LT")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {parse(item.content)}
                  </div>
              ))
            }
          </div>
        </div>
        :''}
     </div>
  );
};

export default Journal;
