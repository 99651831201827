import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
//import { setIntervention, setSavingStatus } from "../../../actions";
import { setIntervention } from "../../../actions";
import t from "../../translate";
import ContentEditable from 'react-contenteditable'
import Badges from './gamification/badges'
import { getClone } from "../../utils";
import BadgesLibrary from "../../badgeslibrary/badgeslibrary.js";
import { setChosenBadge } from "../../../actions";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import {ChangeFormatDateTime, ChangeFormatDate} from "../../helpers/changeFormatDate";

let saveSettingsTimeout = null;

const InterventionSettingsGamification = props => {

  const [gamificationSettings, setGamificationSettings] = useState(false);
  const [pointsData, setPointsData] = useState(false);
  const [badgesData, setbadgesData] = useState(false);
  const dispatch = useDispatch();
  const intervention = useSelector(state => state.intervention);
  const [badges, setBadges] = useState([0,1,2,3,4,5,6,7,8,9,10])
  const [setBadgesOption, setSetBadgesOption] = useState(false)
  const [lessons, setLessons] = useState([])
  const [optionalLessons, setOptionalLessons] = useState([])
  const [goals, setGoals] = useState([])
  const [questionnaires, setQuestionnaires] = useState([])
  const [otherOptions, setOtherOptions] = useState([])

  const domain = useSelector(state => state.url);

  const [date, setDate] = useState('')

  useEffect(() => {
    /// nog checken of dit nog goed gaat wellicht gaat het hier mis
    /// check of punten optie en dagboek optie aan moeten.
    if (intervention.settings.include_journal == 1) {
      setOtherOptions([{id:"1",title:"Diary"}, {id:"2",title:"Points"}]);
    } else {
      setOtherOptions([{id:"2",title:"Points"}]);
    } 
  }, [gamificationSettings.points, intervention.settings.include_journal]);

  useEffect(() => {
    /// nog checken of dit nog goed gaat wellicht gaat het hier mis
    if (gamificationSettings.badgesFromDate !== "") {
      setDate(gamificationSettings.badgesFromDate);
    }
  }, [gamificationSettings.badgesFromDate]);

  function updateDate(dateChanged){
    setDate(dateChanged)

    let tempGamificationSettings = getClone(gamificationSettings);
    tempGamificationSettings.badgesFromDate = dateChanged;
    setGamificationSettings(tempGamificationSettings);

    let interventionC = getClone(intervention);
    interventionC.settings.gamification = tempGamificationSettings;
    saveGamificationSettings(interventionC.settings);
  }
  function toggleDate(){
    let tempGamificationSettings = getClone(gamificationSettings);
    tempGamificationSettings.badgesFromDateActive = tempGamificationSettings.badgesFromDateActive ? false:true;
    setGamificationSettings(tempGamificationSettings);

    let interventionC = getClone(intervention);
    interventionC.settings.gamification = tempGamificationSettings;
    saveGamificationSettings(interventionC.settings);
  }

  useEffect(() => {
    let tempLessons = []
    for(let i = 0 ; i < intervention.settings.selfhelp.lessons.length ; i++){
      if(intervention.settings.selfhelp.lessons[i].parent_id == 0){
        tempLessons.push(intervention.settings.selfhelp.lessons[i])
      }
    }
    setLessons(tempLessons)

    let tempOptionalLessons = []
    for(let i = 0 ; i < intervention.settings.selfhelp.optionalLessons.length ; i++){
      if(intervention.settings.selfhelp.optionalLessons[i].parent_id == 0){
        tempOptionalLessons.push(intervention.settings.selfhelp.optionalLessons[i])
      }
    }
    setOptionalLessons(tempOptionalLessons)

    let tempGoals = []
    for(let i = 0 ; i < intervention.settings.goals.length ; i++){
      tempGoals.push(intervention.settings.goals[i])
    }
    setGoals(tempGoals)

    let tempQuestionnaires = []
    for(let i = 0 ; i < intervention.settings.questionnaires.length ; i++){
      //alleen zichtbare questionnaires:
      if (typeof intervention.settings.questionnaires[i].settings !== 'undefined' && typeof intervention.settings.questionnaires[i].settings.visible !== "undefined" &&  intervention.settings.questionnaires[i].settings.visible === "hidden") {
        continue;
      }
      tempQuestionnaires.push(intervention.settings.questionnaires[i])
    }
    setQuestionnaires(tempQuestionnaires)

    if(typeof intervention.settings.gamification !== "undefined"){
      setGamificationSettings(intervention.settings.gamification)
      if(typeof intervention.settings.gamification.pointsData !== "undefined"){
        setPointsData(intervention.settings.gamification.pointsData)
      }
      if(typeof intervention.settings.gamification.badgesData !== "undefined"){
        let tempBadgesData = intervention.settings.gamification.badgesData;
        if (typeof tempBadgesData.uncovered_badges === "undefined" || tempBadgesData.uncovered_badges.length < 1) {
          tempBadgesData.uncovered_badges = [{
            image: '',
            option: 'uncovered',
            text: ''
          }]
        }
        setbadgesData(tempBadgesData)
      }
    } else {
      intervention.settings.gamification = [];
      intervention.settings.gamification.pointsData = {};
      intervention.settings.gamification.badgesData = {};
    }
  }, [intervention]);

  function togglePart(part){
    let tempGamificationSettings = getClone(gamificationSettings);
    tempGamificationSettings[part] = tempGamificationSettings[part] ? false:true;
    setGamificationSettings(tempGamificationSettings);

    let interventionC = getClone(intervention);
    interventionC.settings.gamification = tempGamificationSettings;
    saveGamificationSettings(interventionC.settings);
  }

  function toggleView(part, show){
    let tempGamificationSettings = getClone(gamificationSettings);
    if(typeof tempGamificationSettings.view == "undefined"){
      tempGamificationSettings.view = {};
      tempGamificationSettings.view["uncovered"] = false;
      tempGamificationSettings.view["start"] = false;
      tempGamificationSettings.view["lesson"] = false;
      tempGamificationSettings.view["optionalLesson"] = false;
      tempGamificationSettings.view["goals"] = false;
      tempGamificationSettings.view["other"] = false;
      tempGamificationSettings.view["questionnaires"] = false;
    }
    tempGamificationSettings.view[part] = show;
    setGamificationSettings(tempGamificationSettings);

    let interventionC = getClone(intervention);
    interventionC.settings.gamification = tempGamificationSettings;
    saveGamificationSettings(interventionC.settings);
  }

  function updatePoints(type, value){
    let tempPoints = getClone(pointsData);
    tempPoints[type] = value;
    setPointsData(tempPoints)

    let interventionC = getClone(intervention);
    interventionC.settings.gamification.pointsData = tempPoints;
    saveGamificationSettings(interventionC.settings);
  }

  function setTimes(type, id, action, i = false, times = false){
    let tempBadges = getClone(badgesData);

    if(typeof tempBadges[type + "_badges"] == "undefined"){
      tempBadges[type + "_badges"] = [];
    }

    let this_badge_obj = tempBadges[type + "_badges"].filter(function (badge) {
      return badge.id === id
    });

    if(this_badge_obj.length == 0){
      tempBadges[type + "_badges"].push({id:id, options:[], active:true});
    } else {
      let index = tempBadges[type + "_badges"].indexOf(this_badge_obj[0]);

      if(action == "active"){
        tempBadges[type + "_badges"][index].active = (tempBadges[type + "_badges"][index].active ? false:true);
      }
      if(action == "add"){
        tempBadges[type + "_badges"][index].options.push({image:0, option:tempBadges[type + "_badges"][index].options.length, times:1});
      }
      if(action == "delete"){
        tempBadges[type + "_badges"][index].options.splice(i, 1);
      }

      if(action == "updateTimes"){
        tempBadges[type + "_badges"][index].options[i].times = times;
      }
    }


    /*
    if(action == "min"){
      if(tempBadges[type + "_badges"][index].options[i].times > 1){
        tempBadges[type + "_badges"][index].options[i].times = tempBadges[type + "_badges"][index].options[i].times - 1;
      }
    }
    if(action == "plus"){
      tempBadges[type + "_badges"][index].options[i].times = tempBadges[type + "_badges"][index].options[i].times + 1;
    }
    */

    setbadgesData(tempBadges)

    let interventionC = getClone(intervention);
    interventionC.settings.gamification.badgesData = tempBadges;
    saveGamificationSettings(interventionC.settings);
  }

  function updateBadges(type, value){
    let tempBadges = getClone(badgesData);
    tempBadges[type] = value;
    setbadgesData(tempBadges)

    let interventionC = getClone(intervention);
    interventionC.settings.gamification.badgesData = tempBadges;
    saveGamificationSettings(interventionC.settings);
  }

  const saveGamificationSettings = (newSettings) => {

    props.setErrorMessage("");
    clearTimeout(saveSettingsTimeout);

    //dispatch(setSavingStatus("not_saved"));

    saveSettingsTimeout = setTimeout(() => {
      props.saveSettings(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      );
    }, 1500);
  }

  const [badgesLibraryVisible, setBadgesLibraryVisible] = useState("hide");
  const [filterTypes, setFilterTypes] = useState([]);

  //////////////////////
  ///show BadgesLibrary
  function showBadgesLibrary(index, type_id) {
    setBadgesLibraryVisible("show");
    document.body.style.overflow = "hidden";

    /// empty store + set index
    dispatch(setChosenBadge("", type_id));
  }

  const badgelibrary = useSelector(state => state.badgeLibrary);

  //////////////////////
  ///Save if chosen image uit bieb is not empty alleen bij overeenkomstige id
  if (badgelibrary.chosen_image !== "") {
    /// empty chosen image status
    dispatch(setChosenBadge(""));
    imageAction(badgelibrary.chosen_image, badgelibrary.index);
  }



  function imageAction(chosenImage, index){
    let image_url = "";

    if(chosenImage !== ""){
      image_url = domain + "/uploads/intervention/" + chosenImage;
    }

    let tempBadges = getClone(badgesData);
    /// 0 type
    /// 1 id
    /// 2 index
    let temp_setBadgesOption = index.split("_");
    let type = temp_setBadgesOption[0];
    let id = temp_setBadgesOption[1];

    if(typeof tempBadges[type + "_badges"] == "undefined"){
      tempBadges[type + "_badges"] = [];
    }

    let this_badge_obj = tempBadges[type + "_badges"].filter(function (badge) {
      return badge.option === id
    });

    if(type == "goals" || type == "other"){
      let index_option = temp_setBadgesOption[2];

      /// {id, options, active}
      let this_time_obj = tempBadges[type + "_badges"].filter(function (times_obj) {
        return times_obj.id === id
      });

      if(this_time_obj.length !== 0){
        tempBadges[type + "_badges"][tempBadges[type + "_badges"].indexOf(this_time_obj[0])].options[index_option].image = image_url;
      }
    } else {
      if(this_badge_obj.length !== 0){
        tempBadges[type + "_badges"][tempBadges[type + "_badges"].indexOf(this_badge_obj[0])].image = image_url;
      } else {
        tempBadges[type + "_badges"].push({image:image_url, option:id})
      }
    }

    setbadgesData(tempBadges)
    setSetBadgesOption(false)

    let interventionC = getClone(intervention);
    interventionC.settings.gamification.badgesData = tempBadges;
    saveGamificationSettings(interventionC.settings);
  }

  function updateRewardText(value, type, id, index){
    let tempBadges = getClone(badgesData);

    let this_badge_obj = tempBadges[type + "_badges"].filter(function (badge) {
      return badge.option === id
    });

    if(type == "goals" || type == "other"){
      /// {id, options, active}
      let this_text_obj = tempBadges[type + "_badges"].filter(function (text_obj) {
        return text_obj.id === id
      });

      if(this_text_obj.length !== 0){
        tempBadges[type + "_badges"][tempBadges[type + "_badges"].indexOf(this_text_obj[0])].options[index].text = value;
      }
    } else {
      if(this_badge_obj.length !== 0){
        tempBadges[type + "_badges"][tempBadges[type + "_badges"].indexOf(this_badge_obj[0])].text = value;
      } else {
        tempBadges[type + "_badges"].push({image:"", option:id, text:value})
      }
    }

    let interventionC = getClone(intervention);
    interventionC.settings.gamification.badgesData = tempBadges;
    saveGamificationSettings(interventionC.settings);
  }

  return (
    <>
      <div className="part">
        <table>
          <tbody>
            <tr>
              <td>
                <h5>
                  {t("Badges")}
                </h5>
              </td>
              <td>
                <label className="switch">
                  <input type="checkbox" checked={gamificationSettings !== false && gamificationSettings.badges ? true:false} onChange={(e) => togglePart("badges")}/>
                  <span className="slider_switch round" ></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        {gamificationSettings !== false && gamificationSettings.badges ?
          <div className="settings badges">

          <Badges
            title={t("Niet gehaalde badge")}
            updateBadges={updateBadges}
            type="uncovered"
            value={[{title:"uncovered", id:"uncovered"}]}
            badgesData={badgesData["uncovered_badges"]}
            setSetBadgesOption={setSetBadgesOption}
            showBadgesLibrary={showBadgesLibrary}
            toggleView={toggleView}
            imageAction={imageAction}
            updateRewardText={updateRewardText}
            visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["uncovered"]:false}
            />

            <Badges
              title={t("Start")}
              updateBadges={updateBadges}
              type="start"
              value={[{title:"start", id:"start"}]}
              badgesData={badgesData["start_badges"]}
              setSetBadgesOption={setSetBadgesOption}
              showBadgesLibrary={showBadgesLibrary}
              toggleView={toggleView}
              imageAction={imageAction}
              updateRewardText={updateRewardText}
              visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["start"]:false}
              />
            <Badges
              title={t("Lessen")}
              updateBadges={updateBadges}
              type="lesson"
              value={lessons}
              badgesData={badgesData["lesson_badges"]}
              setSetBadgesOption={setSetBadgesOption}
              showBadgesLibrary={showBadgesLibrary}
              imageAction={imageAction}
              updateRewardText={updateRewardText}
              toggleView={toggleView}
              visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["lesson"]:false}
              />
            {optionalLessons.length > 0 ?
              <>
                <Badges
                  title={t("Optionele lessen")}
                  updateBadges={updateBadges}
                  type="optionalLesson"
                  value={optionalLessons}
                  badgesData={badgesData["optionalLesson_badges"]}
                  setSetBadgesOption={setSetBadgesOption}
                  showBadgesLibrary={showBadgesLibrary}
                  imageAction={imageAction}
                  updateRewardText={updateRewardText}
                  toggleView={toggleView}
                  visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["optionalLesson"]:false}
                  />
              </>
              :
              <></>
            }
            {goals.length > 0 ?
              <>
                <Badges
                  title={t("Doelen")}
                  updateBadges={updateBadges}
                  type="goals"
                  value={goals}
                  badgesData={badgesData["goals_badges"]}
                  setSetBadgesOption={setSetBadgesOption}
                  setTimes={setTimes}
                  showBadgesLibrary={showBadgesLibrary}
                  toggleView={toggleView}
                  updateRewardText={updateRewardText}
                  visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["goals"]:false}
                  />
              </>
              :
              <></>
            }
            {questionnaires.length > 0 ?
              <>
                <Badges
                  title={t("Vragenlijsten")}
                  updateBadges={updateBadges}
                  type="questionnaires"
                  value={questionnaires}
                  badgesData={badgesData["questionnaires_badges"]}
                  setSetBadgesOption={setSetBadgesOption}
                  setTimes={setTimes}
                  imageAction={imageAction}
                  showBadgesLibrary={showBadgesLibrary}
                  toggleView={toggleView}
                  updateRewardText={updateRewardText}
                  visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["questionnaires"]:false}
                  />
              </>
              :
              <></>
            }
            {otherOptions.length > 0 ?
              <>
                <Badges
                  title={t("Overige")}
                  updateBadges={updateBadges}
                  type="other"
                  value={otherOptions}
                  badgesData={badgesData["other_badges"]}
                  setSetBadgesOption={setSetBadgesOption}
                  setTimes={setTimes}
                  showBadgesLibrary={showBadgesLibrary}
                  toggleView={toggleView}
                  updateRewardText={updateRewardText}
                  updatePoints={updatePoints}
                  pointsData={pointsData}
                  visible={typeof gamificationSettings.view !== "undefined" ? gamificationSettings.view["other"]:false}
                  />
              </>
              :
              <></>
            }
              <BadgesLibrary
                BadgesLibraryVisible={badgesLibraryVisible}
                setBadgesLibraryVisible={setBadgesLibraryVisible}
                filterTypes={filterTypes}
              />

            <div className="vanaf">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {t("Aanmeldingen vanaf krijgen badges")}
                    </td>
                    <td>
                      <label className="switch">
                        <input type="checkbox" checked={gamificationSettings.badgesFromDateActive == true ? true:false} onChange={(e) => toggleDate()}/>
                        <span className="slider_switch round" ></span>
                      </label>
                    </td>
                    <td>
                    <div className={(gamificationSettings.badgesFromDateActive == false ? "hide ":"") + "date"}>
                      <Flatpickr
                        options={{ locale: Dutch, dateFormat: "d.m.Y" }}
                        value={date}
                        onChange={dateChanged => {
                          updateDate(dateChanged)
                        }}
                      />
                    </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          :<></>}
      </div>
    </>
  );
}

export default InterventionSettingsGamification
