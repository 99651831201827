import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../api";
import $ from "jquery";
import "popper.js/dist/popper"; //als package geinstalleerd
import "bootstrap/dist/js/bootstrap"; //als package geinstalleerd
import { getClone } from "../utils";
import SortObjectArray from "../helpers/sortObjectArray.js";
import AdminOptions from "../dashboard/admin/options.js";
import LeftMenu from "../leftmenu";
import CoachDetails from "./details/indexadmin.js";
import Modal from "../modal";
import Editcoach from "./edituseradmin.js";
import LoadScreen from "../loadScreen";
import t from "../translate";
import { setIntervention } from "../../actions";
import Pagination from "../helpers/pagination.js";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Coaches = (props) => {

  const history = useHistory();

  let { intervention_id } = useParams();

  const [interventions, setInterventions] = useState([])
  const [coachesLoaded, setCoachesLoaded] = useState(false)
  const [filterOnIntervention, setFilterOnIntervention] = useState('');
  const paginationStep = 50;
  const [paginationFrom, setPaginationFrom] = useState(0)
  const [nrCoaches, setnrCoaches] = useState(0)
  const [openSearch, setOpenSearch] = useState(false)
  const [searchFor, setSearchFor] = useState("")
  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const [showDetails, setShowDetails] = useState([]);

  const [searchedCoaches, setSearchedCoaches] = useState([])
  const [coachesToShow, setCoachesToShow] = useState([])

  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const uiTranslation = useSelector(state => state.uiTranslation);

  const [superVisors, setSupervisors] = useState("");

  //deze functie wordt als property doorgegeven aan modal en vandaar naar editUser
  //en aangeroepen na een geslaagde saveUser
  const closeModal = (msg, intervention_id) => {
    $("#user_edit").modal("toggle");
    setMessage(msg);
    getUsers();
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };


  const [state, setState] = useState({
    users: [],
    modalState: {
      name: "user_edit",
      label: "user_edit_label",
      title: "",
      component: Editcoach,
      btnValue: t("Opslaan"),
      componentData: {
        coaches: [],
        interventions: [],
        user: {
          id: 0,
          firstname: "",
          insertion: "",
          lastname: "",
          email: "",
          phone: "",
          organisation_id: 0,
          type: "",
          login: "",
          password: "",
          password_check: "",
          hasStudents: false,
          hasCoaches: false,
          profile_pic: false,
          rights: {
            max_students: 0,
            bio: '',
            bioTranslations: [],
            languages: [],
            interventions: []
          },
          preferences: {},
          removeUser: false
        },
        closeModal: closeModal
      }
    }
  });

  useEffect(
    () => {
      let coaches = state.users

      let tempCearchedCoaches = [];
      let searchForLowerCase = searchFor.toLowerCase()
      let push = true;
      let match = false;
      for (let i = 0; i < coaches.length; i++) {
        push = true
        let name = coaches[i].firstname + " " + coaches[i].insertion +  " " + coaches[i].lastname ;
        if (!name.toLowerCase().includes(searchForLowerCase) && !coaches[i].login.toLowerCase().includes(searchForLowerCase) && !coaches[i].email.toLowerCase().includes(searchForLowerCase) && !coaches[i].id.toLowerCase().includes(searchForLowerCase)) {
          push = false
        }
        if(filterOnIntervention != ""){
          match = false;
          for(let ii = 0 ; ii < coaches[i].rights.interventions.length ; ii++){

            if(parseInt(coaches[i].rights.interventions[ii].id) == parseInt(filterOnIntervention)){
              match = true;
            }
          }
          if(!match){
            push = false;
          }
        }
        if(push){
          tempCearchedCoaches.push(coaches[i])
        }
      }
      setSearchedCoaches(tempCearchedCoaches)
      setnrCoaches(tempCearchedCoaches.length)
      if(tempCearchedCoaches.length < paginationFrom){
        setPaginationFrom(0)
      }
      setCoachesToShow(tempCearchedCoaches.slice(paginationFrom, (paginationStep + paginationFrom)));

    },
    [searchFor, filterOnIntervention, state, paginationFrom]
  )

  useEffect(
    () => {
      if(sort != ""){
        let tempSearchedCoaches = [...searchedCoaches];
        tempSearchedCoaches.sort(SortObjectArray(sort, sortDirection));
        setSearchedCoaches(tempSearchedCoaches)
        setCoachesToShow(tempSearchedCoaches.slice(paginationFrom, (paginationStep + paginationFrom)));
      }
    },
    [sort, sortDirection]
  )

  const show = (c) => {
    //e.stopPropagation();
    if(!showDetails.includes(c.id))
    {
      let tempShowDetails = [...showDetails];
      tempShowDetails.push(c.id)
      setShowDetails(tempShowDetails)
    } else {
      hide(c.id)
    }
  }

  const hide = (id) =>{
    let tempShowDetails = [...showDetails];
    const index = tempShowDetails.indexOf(id);
    if (index > -1) { // only splice array when item is found
      tempShowDetails.splice(index, 1); // 2nd parameter means remove one item only
      setShowDetails(tempShowDetails)
    }
  }

  const getUsers = () => {
    //api aanroepen
    apiCall({
      action: "get_coaches",
      token: auth.token,
      data: {}
    }).then(resp => {
      const newState = getClone(state);
      newState.users = resp.users;
      newState.modalState.componentData.coaches = resp.users;
      setState(newState);
      setCoachesLoaded(true);
      setnrCoaches(resp.users.length)
      setSupervisors(newState.modalState.componentData.supervisors)
    });
  };

  useEffect(() => {
    apiCall({
      action: "get_interventions",
      token: auth.token,
      data: {}
    }).then(resp => {
      setInterventions(resp.interventions)
      const newState = getClone(state);
      newState.modalState.componentData.interventions = resp.interventions;
      setState(newState);
      getUsers();
    })

  }, [props]);

  const getInterventionSettings = (intervention_id) => {
    apiCall({
      action: "get_intervention_settings",
      token: auth.token,
      data: {
        intervention_id,
        language_id: uiTranslation.language_id
      }
    })
    .then(resp => {
      dispatch(
        setIntervention(
          resp.intervention_id,
          resp.organisation_id,
          resp.title,
          resp.settings
        )
      );
    })
    .then(() => {
      getUsers(intervention_id);
    });
  }

  //deze functie wordt aangeroepen bij aanklikken van een user in de list (ListItems)
  const setStateCallback = (user, title) => {
    let newState = getClone(state);
    newState.modalState.componentData.user = user;
    newState.modalState.title = title;
    newState.modalState.componentData.user.hasCoaches = false;
    if (user.isSupervisor) {
      //hangen er coaches onder deze supervisor?
      state.users.map(coach => {
        if (coach.rights.interventions !== undefined) {
          coach.rights.interventions.map(int => {
            if (int.id === intervention.id && int.hasSupervisor === user.id) {
              newState.modalState.componentData.user.hasCoaches = true;
            }
          });
        }
      });
    }
    setState(newState);
  };

  const addUser = () => {
    setStateCallback(
      {
        id: 0,
        firstname: "",
        insertion: "",
        lastname: "",
        email: "",
        phone: "",
        organisation_id: auth.organisation_id,
        type: "coach",
        login: "",
        password: "",
        password_check: "",
        rights: {
          max_students: 0,
          bio: '',
          bioTranslations: [],
          languages: [],
          interventions: []
        },
        preferences: {},
      },
      t("Toevoegen coach")
    );
    $("#user_edit").modal("toggle");
    $("#firstname").focus();
  };

  const searchView = () => {
    setOpenSearch(true)
  }

  function changeSort(type){
    setSortDirection(sortDirection == "asc" ? "desc":"asc")
    setSort(type)
  }

  //// dit zou natuurlijk ook hoger kunnen in de componenten tree opdat je slechts enkel 1 keer een aanroep hoeft te doen maar dat geldt voor alle api calls
  function changeIntervention(int_id){
    let this_intervention_obj = interventions.filter(function (intervention) {
      return intervention.id === int_id
    });
    if(this_intervention_obj.length != 0){

      history.push("/intervention/coaches/" + int_id)
      getInterventionSettings(int_id)
    }

  }

  function clearSearched(){
    setSearchFor("")
    setSearchFor("")
  }

  const getInterventionTitle = (index) => {
    var this_intervention = interventions.find(int => parseInt(int.id) === parseInt(index));
    if(this_intervention){
      return this_intervention.title;
    }
  };

  return (
      <div className="container dashboard admin coachInterface">
      {/*<nav className="navbar navbar-expand-lg navbar-light">
        <h2 className="noPadding">
          {t("Coaches")}
        </h2>
      </nav>*/}
      <LeftMenu />
      <AdminOptions activity={true}/>
      <div className={"overview_students coachInterface" + (openSearch ? ' openSearch':'')}>

        <table className="theIntervention">
          <tbody>
            <tr>
              <td>
                {/*<h2>{t("Coaches")}</h2>*/}
                {!openSearch && (searchFor != "" || filterOnIntervention != '') ?
                  <div className="holder_searched_for">
                    {searchFor != ""  ?
                      <span className="btn btn-secondary filter" onClick={()=>clearSearched()}>
                        {searchFor} <i className="fas fa-times"></i>
                      </span>
                    :''}
                    {filterOnIntervention != '' ?
                      <span className="btn btn-secondary filter" onClick={()=>setFilterOnIntervention('')}>
                        {getInterventionTitle(filterOnIntervention)} <i className="fas fa-times"></i>
                      </span>
                    :''}
                  </div>
                :false}
              </td>
              <td className="options">
                <button className="btn btn-primary btn-sm btn-trans" onClick={addUser}>
                  <i className="fas fa-plus"></i>
                </button>
                <i className={"fas fa-search pointer" + (openSearch ? ' hide':' ')} onClick={()=>searchView()}></i>
                <div className="search">
                  <select onChange={(e)=>setFilterOnIntervention(e.target.value)} value={filterOnIntervention}>
                    <option value="">{t("Alle interventies")}</option>
                    {interventions.map((intervention, index) => {
                      return (
                        <option key={index} value={intervention.id}>{intervention.title}</option>
                      )
                    })}
                  </select>
                  <input type="text" value={searchFor} placeholder={t("Zoek coach")} onChange={(e) => setSearchFor(e.target.value)}/>
                  <i className="far fa-times-circle pointer" onClick={()=>setOpenSearch(false)}></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="holder">
          {coachesLoaded ?
            <div className="total">
              {t("Totaal gevonden")}: {nrCoaches}
            </div>
            :false}
          <div className='users'>
            <table className="overview_students">

              {
                coachesLoaded ?
                  <>
                  <thead>
                    <tr>
                      <th className="pointer" onClick={() => changeSort("id")}>#</th>
                      <th className="pointer" onClick={() => changeSort("firstname")}>{t("Naam")}</th>
                      <th>{t("Programs")}</th>
                      <th className="pointer" onClick={() => changeSort("email")}>{t("Email")}</th>
                      <th className="pointer" onClick={() => changeSort("phone")}>{t("Telefoon")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coachesToShow.map((coach, index) => {
                      let superVisorName = "";
                      let superVisorObject = state.users.find(u => {
                        return u.id === coach.hasSupervisor;
                      });
                      if (superVisorObject) {
                        superVisorName =
                          superVisorObject.firstname +
                          " " +
                          superVisorObject.insertion +
                          " " +
                          superVisorObject.lastname;
                      }
                      return (
                        <>
                        <tr
                          key={index}
                          className={"pointer rowHover" + (coach.hide == 'true' ? ' hide':'') + (showDetails.includes(coach.id) ? ' active':'')}
                          onClick={() => show(coach)}
                        >
                          <td>{coach.id}</td>
                          <td>{coach.firstname} {coach.insertion} {coach.lastname}</td>
                          <td>
                            {
                              (coach.rights.interventions !== undefined) ?
                                <>
                                  {
                                    coach.rights.interventions.map((int, index) => {
                                      try {
                                        let program = interventions.find(intervention => parseInt(intervention.id) === parseInt(int.id)).title + ' '
                                        if (int.isSupervisor) {
                                          program += '(S) '
                                        }
                                        return <span key={index} dangerouslySetInnerHTML={{__html: program }}></span>
                                      } catch(e) {}
                                      /* if (int.id === intervention.id && int.hasSupervisor === user.id) {
                                        newState.modalState.componentData.user.hasCoaches = true;
                                      } */
                                    })
                                  }
                                </> : <></>
                            }
                          </td>
                          <td>{coach.email}</td>
                          <td>{coach.phone}</td>
                        </tr>
                        {showDetails.includes(coach.id) ?
                          <tr className={"details" + (coach.hide == 'true' ? ' hide':'')}>
                            <td colSpan="9">
                              <CoachDetails
                                coach={coach}
                                setState={setState}
                                state={state}
                                hide={hide}
                                supervisors={superVisors}
                                interventions={interventions} />
                            </td>
                          </tr>
                          :false}
                        </>
                      );
                    })}
                  </tbody>
                  </>
                  :
                  <tbody>
                    <tr>
                      <td colSpan="5">
                      <LoadScreen />
                      </td>
                    </tr>
                  </tbody>
              }
            </table>
            {nrCoaches > 0 ?
              <Pagination
                  nrItems={nrCoaches}
                  paginationFrom={paginationFrom}
                  setPaginationFrom={setPaginationFrom}
                  paginationStep={paginationStep}
              />
              :<><br/><br/></>}
          </div>
        </div>
      </div>

      <div
        className={message.length < 1 ? "hidden" : "alert alert-success"}
        role="alert"
      >
        {message}
      </div>
      <div
        className={errorMessage.length < 1 ? "hidden" : "alert alert-danger"}
        role="alert"
      >
        {errorMessage}
      </div>

      <Modal {...state.modalState} />
    </div>
  );
};

export default Coaches;
