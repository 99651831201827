import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import GetPart from '../../../content/front/get_part.js';

const SleepDiaryInfo = (props) => {

  const intervention = useSelector(state => state.intervention);

  const [page, setPage] = useState({
    title: "",
    parts: []
  });

  useEffect(() => {

    ///hard gezet niet helemaal ideaal....
    let activePage = 47;
    if (intervention.id == 47) {
      activePage = 68;
    }
    if (intervention.id == 46) {
        activePage = 60;
    }

    intervention.settings.pages.forEach((page, key) => {
      if (parseInt(page.id) === parseInt(activePage))
      {
        setPage({
          title: page.title,
          parts: page.settings.parts
        })
      }
    });

  }, [intervention]);

  return(
    <div className="info">
      <h1>{parse(page.title)}</h1>
      <div className="lessoncontent front">
        <div className="right">
          {
            page.parts.map((part, index) => {
              return (
              <div key={index} className="component_holder">
                <GetPart part={part} />
              </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
export default SleepDiaryInfo
