import React, { useState, useEffect } from "react";
import HighlightRound from "./highlight_round.js";
import Pie from "../../content/front/parts/chart/pie.js";
import ChartBar from "../../content/front/parts/chart/bar.js";
import t from "../../translate";

const Registrations = (props) => {

  const [showDetails, setShowDetails] = useState(true);

  const toggleDetails = () => {
    if(showDetails){
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  };

  return(
    <>
      {props.data ?
        <>
        <h3>{t(props.header)}</h3>
        <HighlightRound icon="fa fa-language" label="Registraties" content={props.data.total} />
        <HighlightRound icon="fa fa-language" label="CU Survey" content={props.data.via_qualtrix + (props.data.total !== 0 ? " ("+Math.round((props.data.via_qualtrix/props.data.total) * 100)+"%)":" (0%)")} />
        <HighlightRound icon="fa fa-language" label="Direct" content={props.data.via_direct + (props.data.via_direct !== 0 ? " ("+Math.round((props.data.via_direct/props.data.total) * 100)+"%)":" (0%)")} />
        <br/>
        <HighlightRound icon="" label="Geincludeerd" content={props.data.included + (props.data.total !== 0 ? " ("+Math.round(( props.data.included/props.data.total) * 100)+"%)":" (0%)" )} color="orange"/>
        <HighlightRound icon="fa fa-language" label="Account aangemaakt" content={props.data.account_created + (props.data.total !== 0 ? " ("+Math.round(( props.data.account_created/props.data.total) * 100)+"%)":" (0%)" ) } color="yellow" />
        <HighlightRound icon="fa fa-language" label="Account geactiveerd" content={props.data.account_activated + (props.data.total !== 0 ? " ("+Math.round(( props.data.account_activated/props.data.total) * 100)+"%)":" (0%)" ) } color="yellow"/>
        <br/>

        <HighlightRound icon="" label="T0 gestart" content={props.data.t0_gestart + ((props.data.total) !== 0 ? " ("+Math.round(( props.data.t0_gestart/props.data.total) * 100)+"%)":" (0%)" )} color="orange"/>
        <HighlightRound icon="" label="T0 afgerond" content={props.data.t0_afgerond + (props.data.total !== 0 ? " ("+Math.round(( props.data.t0_afgerond/props.data.total) * 100)+"%)":" (0%)" )} color="orange"/>

        
        {
          /*
          <div className="show_details highlight_round" onClick={()=>toggleDetails()}>
            <div className="content">
              <i className="fas fa-chevron-down"></i>
              <div className="label">
                {t("Details")}
              </div>
            </div>
          </div>
          */
        }
        {showDetails && props.data.total > 0 ?
          <div className="details">

            <div className="inline_block">
              {/* <h4>{t("Universiteit")}</h4>
              <div className="content">
              <table className="standard">
              <tbody>
                {Object.keys(props.data.via_direct_uni).map(function(key, index) {
                  return(
                      <tr>
                      <td>{key}</td> <td> {props.data.via_direct_uni[key]}</td>
                      </tr>
                  )
                })}
                </tbody>
                </table>
              </div> */}
              {
              props.data.university.length > 0 ?
                <>
                  <h4>{t("Universiteit")}</h4>
                  <div className="content">
                  <table className="standard">
                  <tbody>
                    {Object.keys(props.data.university).map(function(key, index) {
                      return(
                        <tr>
                        <td>
                          {key}</td><td>{props.data.university[key]} ({props.data.total === 0 ?
                            0 :
                            Math.round(( props.data.university[key]/props.data.total) * 100)
                          }%)
                          </td>
                          </tr>
                      )
                    })}
                    </tbody>
                    </table>
                    </div>
                  </> : <></>
              }

              <h4>{t("Programma")}</h4>
              <div className="content">
              <table className="standard">
              <tbody>
                {Object.keys(props.data.program).map(function(key, index) {
                  return(
                    <tr>
                    <td>
                      {key}</td> <td>{props.data.program[key]} ({props.data.total === 0 ?
                        0 :
                        Math.round(( props.data.program[key]/props.data.total) * 100)
                      }%)
                      </td>
                      </tr>
                  )
                })}
                </tbody>
                </table>
              </div>

              <h4>{t("Nationaliteit")}</h4>
              <div className="content">
              <table className="standard">
              <tbody>
                {Object.keys(props.data.nationality).map(function(key, index) {
                  return(
                      <tr>
                      <td>
                      {key}</td><td>{props.data.nationality[key]} ({props.data.total === 0 ?
                        0 :
                        Math.round(( props.data.nationality[key]/props.data.total) * 100)
                      }%)
                      </td>
                      </tr>
                  )
                })}
                </tbody>
                </table>
              </div>

            </div>
            <div className="inline_block">
              <h4>{t("Geslacht")}</h4>
              <div className="content">
                <div className="small_pie">
                  <Pie labels={[t("Vrouw"), t("Man"), t("Anders")]} values={[props.data.vrouw, props.data.man, props.data.other]} colors={["#fabc5a", "#4fbdf4", "#ff8d7a"]}/>
                </div>
                <table className="in_row">
                <tbody>
                <tr>
                  <td><i className="fa fa-female"></i><br/><b>{t("Vrouw")}</b><br/> {props.data.vrouw} ({Math.round(( props.data.vrouw/props.data.total) * 100)}%)</td>
                  <td><i className="fa fa-male"></i><br/><b>{t("Man")}</b><br/> {props.data.man} ({Math.round(( props.data.man/props.data.total) * 100)}%)</td>
                  <td><i className="fa fa-genderless"></i><br/><b>{t("Anders")}</b><br/> {props.data.other} ({Math.round(( props.data.other/props.data.total) * 100)}%)</td>
                </tr>
                </tbody>
                </table>

              </div>
              <h4>{t("Taal")}</h4>
              <div className="content">
                <div className="small_pie">
                  <Pie labels={[t("Nederlands"), t("Engels")]} values={[props.data.taal.nl, props.data.taal.eng]} colors={["#9ebac7", "#ef5ea3"]}/>
                </div>
                <table className="in_row">
                <tbody>
                <tr>
                  <td>
                  <b>{t("Nederlands")}</b><br/> {props.data.taal.nl} ({Math.round(( props.data.taal.nl/props.data.total) * 100)}%)
                  </td>
                  <td>
                  <b>{t("Engels")}</b> <br/>{props.data.taal.eng} ({Math.round(( props.data.taal.eng/props.data.total) * 100)}%}
                  </td>
                </tr>
                </tbody>
                </table>
              </div>
              <h4>{t("Followup")}</h4>
              <div className="content">
                <div className="bigger">
                  {props.data.followup} ({Math.round(( props.data.followup/props.data.total) * 100)}%)
                </div>
                {t("hebben toestemming gegeven voor followup research")}
              </div>
              <h4>{t("Anoniem")}</h4>
              <div className="content">
                <div className="bigger">
                  {props.data.anoniem} ({Math.round(( props.data.anoniem/props.data.total) * 100)}%)
                </div>
                {t("hebben aangegeven anoniem te willen deelnemen")}
              </div>
              <h4>{t("Domein")}</h4>
              <div className="content">
              <table className="standard">
              <tbody>
                {Object.keys(props.data.domains).map(function(key, index) {
                  return(
                    <tr>
                    <td>
                      {key}</td><td>{props.data.domains[key]} ({props.data.total === 0 ?
                        0 :
                        Math.round(( props.data.domains[key]/props.data.total) * 100)
                      }%)
                      </td>
                      </tr>
                  )
                })}
                </tbody>
                </table>
              </div>
            </div>
            <h4>{t("Leeftijd")}</h4>
            <div className="bar_chart">
            <div className="inline_block">
              <div className="content">
                <ChartBar
                colors="#fabc5a"
                part={{label_value:t("Studenten"),label_items:t("Leeftijd")}}
                labels={Object.keys(props.data.age)
                  .map(function(key) {
                      return [key];
                  })}
                values={Object.keys(props.data.age)
                  .map(function(key) {
                      return props.data.age[key];
                  })} />
                  <div className="age">
                  {Object.keys(props.data.age).map(function(key, index) {
                    return(<table className="standard">
                    <tbody>
                        <tr>
                        <td>{key} {t("jaar")}</td><td> {props.data.age[key]} ({props.data.total === 0 ?
                          0 :
                          Math.round(( props.data.age[key]/props.data.total) * 100)
                        }%)</td>
                        </tr>
                        </tbody>
                        </table>
                    )
                  })}

                  </div>
                  </div>

                </div>
            </div>
          </div>
          :false}
        </>
        :false}
    </>
  )
}
export default Registrations
