import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Login from "./components/login";
import Router from "./components/router";
import Registration from "./components/registration";
import Confirmation from "./components/registration/confirmation.js";
import Questionnaire from "./components/questionnaires/questionnaire.js";
import ResetPassword from "./components/login";
import isInterventionRegistrationRedirect from "./components/helpers/interventionRedirects";
import "./App.scss";
import "./App.css";

const App = () => {

  const auth = useSelector(state => state.auth);
  
  const [registration, setRegistration] = useState(false);
  const [landingPage, setLandingPage] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(false);
  const [resetpassword, setResetPassword] = useState(false);

  useEffect(() => {
    let querystring = window.location.href.split("/");
    if (querystring[3] === 'registration')
    {
      setLandingPage(querystring[4]);
      setRegistration(true);
    }
    else if (querystring[3] === 'confirmation')
    {
      setConfirmation(true);
    }
    else if (querystring[3] === 'questionnaire')
    {
      setQuestionnaire(true);
    }
    else if (querystring[3] === 'resetpassword')
    {
      setResetPassword(true);
    }
    else if (isInterventionRegistrationRedirect(querystring[3])) {
      setLandingPage(querystring[3])
      setRegistration(true);
    }
  }, [])

  return (
    <div id="wrapper">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      {
        registration === true ? <Registration landingPage={landingPage} setLandingPage={setLandingPage}  /> :
          <>
            {
              confirmation === true ? <Confirmation setConfirmation={setConfirmation} /> :
                <>
                  {
                    questionnaire === true ? <Questionnaire /> :
                      <>
                        {
                          resetpassword === true ? <ResetPassword /> :
                            <>
                              {
                                auth.status === true ? <Router /> : <Login />
                              }
                            </>
                        }
                      </>
                  }
                </>
            }
          </>
      }
      <div className="overlay"></div>
    </div>
  );
}

export default App;
