import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import t from '../../../../translate';

const Routing = props => {

   const [routableParts, setRoutableParts] = useState([]);
   const [routingAvailable, setRoutingAvailable] = useState(false);

   const intervention = useSelector(state => state.intervention);

   useEffect(() => {
     if(props.parts !== "")
     {
        getRoutableParts();
     }
  }, [props]); /// deze vullen als je na state change use effect wil aanroepen

   function getRoutableParts(){
      /// get next page
      let routablePartsUpdate = [];
      let pageCounter = 0;
      let currentPage = false;
      let routingAvailableTemp = false;

      for(let i = 0 ; i< props.parts.length; i++ )
      {
          /// get current page index of item
          if(i === parseInt(props.index)) {
             currentPage = pageCounter;
          }
          if(props.parts[i].subtype === "einde pagina")
          {
             pageCounter++;
          } else {
            let checkAgainst = currentPage
            if(typeof props.skipNext !== "undefined" && props.skipNext === 'true'){
              checkAgainst = currentPage + 1

            }

             if(pageCounter > checkAgainst && currentPage !== false)
             {
               /// get parts next page
               routablePartsUpdate.push(props.parts[i]);
               routingAvailableTemp = true;

             }
          }
      }

      //aanvullen met opvolgende lessen met dezelfde parent_id:
      let lessonFound = false;
      let parent_id = -1;
      let lesson_id = -1;
      if (typeof intervention.settings.selfhelp.lessons !== "undefined") {
            for(let i = 0 ; i< intervention.settings.selfhelp.lessons.length; i++ )
            {
                if(parseInt(intervention.settings.selfhelp.lessons[i].id) === parseInt(props.lessonId))
                {
                    lessonFound = true;
                    parent_id = intervention.settings.selfhelp.lessons[i].parent_id;
                    lesson_id = intervention.settings.selfhelp.lessons[i].id;
                }
                if (lessonFound && parseInt(lesson_id) !== parseInt(intervention.settings.selfhelp.lessons[i].id) && (parseInt(parent_id) === parseInt(intervention.settings.selfhelp.lessons[i].parent_id) || parseInt(lesson_id) === parseInt(intervention.settings.selfhelp.lessons[i].parent_id)))
                {
                    routablePartsUpdate.push({type: "lesson", id: intervention.settings.selfhelp.lessons[i].id});
                    routingAvailableTemp = true;
                }
            }

      }
      if (!lessonFound) {
        //zoeken in optionalLessons
        if (typeof intervention.settings.selfhelp.optionalLessons !== "undefined") {
            for(let i = 0 ; i< intervention.settings.selfhelp.optionalLessons.length; i++ )
            {
                if(parseInt(intervention.settings.selfhelp.optionalLessons[i].id) === parseInt(props.lessonId))
                {
                    lessonFound = true;
                    parent_id = intervention.settings.selfhelp.optionalLessons[i].parent_id;
                    lesson_id = intervention.settings.selfhelp.optionalLessons[i].id;
                }
                if (lessonFound && parseInt(lesson_id) !== parseInt(intervention.settings.selfhelp.optionalLessons[i].id) && (parseInt(parent_id) === parseInt(intervention.settings.selfhelp.optionalLessons[i].parent_id) || parseInt(lesson_id) === parseInt(intervention.settings.selfhelp.optionalLessons[i].parent_id)))
                {
                    routablePartsUpdate.push({type: "lesson", id: intervention.settings.selfhelp.optionalLessons[i].id, question: t('Les')+': '+intervention.settings.selfhelp.optionalLessons[i].title});
                    routingAvailableTemp = true;
                }
            }   
        }
      }
      setRoutingAvailable(routingAvailableTemp)
      props.setRoutingAvailable(routingAvailableTemp)
      setRoutableParts(routablePartsUpdate);
   }

   function getQuestionReference(part){
      if(part.question === "" && typeof part.content !== "undefined")
      {
         return part.content.substring(0,20).replace(/<[^>]*>?/gm, '');
      } else {
         return part.question.replace(/<[^>]*>?/gm, '')
      }
   }
   
   return(
     <div className={"setRouting" + (routingAvailable && props.routingOn ? '':' hide')}>
      {routingAvailable ?
         <select onChange={(e)=>props.updateRouting(e.target.value, props.item_id)} value={props.routing}>
            <option value="">Geen routing</option>
            {routableParts.map((part, index) =>
               <option key={index} value={part.id}>
                  {getQuestionReference(part)}
               </option>
            )}
            <option value="finishQuestionnaire">{t("Einde vragenlijst/les")}</option>
         </select>
         :''}
     </div>
   )
}

export default Routing;
