import React from "react";
import {GetTime, GetDate} from "./convertTimestamp";

export const minutesToHours= (min) => {
  var hours = (min / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + ":" + (rminutes == 0 ? "00" : rminutes);
}

export const minutesToHoursNumberFormat= (min) => {
  var hours = (min / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + (minutes/60);
}

export const minutesToHoursNumberFormatConvertBack= (time) => {
  var time_split = String(time).split(".");

  var minutes_of_hour = (time_split.length > 1 ? Math.round((time - parseInt(time_split[0])) * 60) / 100 :false);
  var minutes = "00";
  if(minutes_of_hour){
    minutes = String(minutes_of_hour).split(".")[1].length == 1 ? String(minutes_of_hour).split(".")[1] + "0":String(minutes_of_hour).split(".")[1];
  }

  return time_split[0] + ":" + minutes;
}

export const getTimeStamp= (date) => {
  let timestamp = Date.parse(date);

  return timestamp/1000;
}

export const ChangeFormatDateTime= (date) => {
  let timestamp = Date.parse(date);

  ///zonder deze tussen stap is de tijd 2 uur off
  timestamp = timestamp/1000;

  return GetTime(timestamp);
}
export const ChangeFormatDate= (date) => {
  let timestamp = Date.parse(date);

  ///zonder deze tussen stap is de tijd 2 uur off
  timestamp = timestamp/1000;

  return GetDate(timestamp);
}
