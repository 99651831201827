import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import { getClone } from "../../../../utils";
import SleepDiaryFillScheme from "./fill";

const SleepDiaryWizzardStep2 = (props) => {

  const [sleepDiary, setSleepDiary] = useState([]);


  useEffect(() => {
    let tempContent = getClone(props.content);
    let tempSleepDiary = [];

    if(props.content.sleepdiary.length == 0){
      for(let i = 7 ; i < 24 ; i++){
        tempSleepDiary.push(
          {
            timestamp:props.toTimestamp(props.dateSleep, i),
            hour:i,
            quarters:[
              {quarter:0,status:'',timestamp:props.toTimestamp(props.dateSleep, i)},
              {quarter:1,status:'',timestamp:props.toTimestamp(props.dateSleep, i,15)},
              {quarter:2,status:'',timestamp:props.toTimestamp(props.dateSleep, i,30)},
              {quarter:3,status:'',timestamp:props.toTimestamp(props.dateSleep, i,45)},
            ]
          })
      }
      for(let i = 0 ; i < 20 ; i++){
        tempSleepDiary.push({
          timestamp:props.toTimestamp(props.wakeUpDay, i),
          hour:i,
          quarters:[
            {quarter:0,status:'',timestamp:props.toTimestamp(props.wakeUpDay, i)},
            {quarter:1,status:'',timestamp:props.toTimestamp(props.wakeUpDay, i,15)},
            {quarter:2,status:'',timestamp:props.toTimestamp(props.wakeUpDay, i,30)},
            {quarter:3,status:'',timestamp:props.toTimestamp(props.wakeUpDay, i,45)},
          ]
        })
      }
    } else {
      tempSleepDiary = JSON.parse(JSON.stringify(props.content.sleepdiary));
    }

    let indexBedTimeHour = props.getIndexTime(tempSleepDiary, props.content.timestamp_to_bed);
    /// TODO misschien nog fallback voor als index niet bestaat!!!
    if (typeof tempSleepDiary[indexBedTimeHour] !== "undefined" && typeof tempSleepDiary[indexBedTimeHour].quarters[props.bedTimeQuarter] !== "undefined") {
      tempSleepDiary[indexBedTimeHour].quarters[props.bedTimeQuarter].status = 'sleep';
    }

    ///zet slaap tot aan wake up time
    let indexWakeupTimeHour = props.getIndexTime(tempSleepDiary, props.content.timestamp_wake_up);

    for(let i = 0 ; i < tempSleepDiary.length ; i++){
      for(let ii = 0 ; ii < 4 ; ii++){
        let overwrite = true;
        let type = tempSleepDiary[i].quarters[ii].type;

        if(!['nap', 'light'].includes(type)){
          if(i < indexBedTimeHour){
            overwrite = false
          } else if(i == indexBedTimeHour && ii < props.bedTimeQuarter ){
            overwrite = false
          } else if(i == indexWakeupTimeHour && ii >= props.wakeupTimeQuarter){
            overwrite = false
          } else if(i > indexWakeupTimeHour){
            overwrite = false
          }

          if(!overwrite){
            tempSleepDiary[i].quarters[ii].status = '';
            tempSleepDiary[i].quarters[ii].type = '';
          }

        } else {

          if(i > indexBedTimeHour){
            overwrite = true
          } else if(i == indexBedTimeHour && ii >= props.bedTimeQuarter ){
            overwrite = true
          } else if(i == indexWakeupTimeHour && ii <= props.wakeupTimeQuarter){
            overwrite = true
          } else {
            overwrite = false;
          }
        }

        if(overwrite){
          tempSleepDiary[i].quarters[ii].type = 'night';
          if(tempSleepDiary[i].quarters[ii].status != "awake" && tempSleepDiary[i].quarters[ii].status != "out_of_bed"){
            tempSleepDiary[i].quarters[ii].status = 'sleep';
          }
        }

      }
    }

    tempContent.sleepdiary = tempSleepDiary;

    props.setContent(tempContent)

  }, []);

  const changeStatus = (index, quarterIndex) => {
    let tempSleepDiary = JSON.parse(JSON.stringify(props.content.sleepdiary));

    if(tempSleepDiary[index].quarters[quarterIndex].status == 'sleep'){
      tempSleepDiary[index].quarters[quarterIndex].status = 'awake';
    } else if(tempSleepDiary[index].quarters[quarterIndex].status == 'awake'){
      tempSleepDiary[index].quarters[quarterIndex].status = 'out_of_bed';
      tempSleepDiary[index].quarters[quarterIndex].type = '';
    } else if(tempSleepDiary[index].quarters[quarterIndex].status == 'out_of_bed'){
      tempSleepDiary[index].quarters[quarterIndex].status = 'sleep';
      tempSleepDiary[index].quarters[quarterIndex].type = '';
    }

    let tempContent = getClone(props.content);
    tempContent.sleepdiary = tempSleepDiary;
    props.setContent(tempContent)
  }

  return (
    <SleepDiaryFillScheme
      content={props.content}
      changeStatus={changeStatus}
      type="sleep"
      getIndexTime={props.getIndexTime}
      />
  );
};

export default SleepDiaryWizzardStep2;
