import React, {useEffect, useState} from "react";
//import StudentDetailsItems from "./items";
import Content from "./items/content.js";
import { checkNestedProperties } from "../../utils";
import { useSelector } from "react-redux";
import InviteT2StudentBegeleiding from "./inviteT2StudentBegeleiding";
import apiCall from "../../api";

const  StudentDetailsQuestionnaires = ({studentId, intervention, user}) => {

  const auth = useSelector(state => state.auth);
  const [items, setItems] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [itemshown, setItemShown] = useState(0);
  const [loading, setLoading] = useState(true);

  const typesWeNeed = ["matrix", "question_open", "question_checkboxes", "question_radio", "select", "slider", "custom"];

  useEffect(() => {
    setLoading(true);
      if (checkNestedProperties(intervention, 'settings', 'questionnaires')) 
      {
          let questionnaires = intervention.settings.questionnaires;
          //aantekenen welke lessen invul oefeninen bevatten
          questionnaires.map((item, index) => {
              questionnaires[index]["hasFillinParts"] = false;
              questionnaires[index]["fillInOnce"] = true;
              questionnaires[index].settings.parts.map(part => {

                  if (typesWeNeed.indexOf(part.type) > -1)
                  {
                      questionnaires[index]["hasFillinParts"] = true;
                  }
              })
          })

          //setItems(questionnaires);
          apiCall({
              action: "get_all_questionnaire_answers",
              token: auth.token,
              data: {
                  /* id: questionnaires[0].id, */
                  intervention_id: intervention.intervention_id,
                  student_id: studentId,
              }
              }).then(resp => {
         
                setAnswers(resp.allAnswers);

                let questionnaires_filtered = [];
                resp.allAnswers.map(a => {
                    let q = questionnaires.map(qs => {
                        if (parseInt(qs.id) === parseInt(a.the_id))
                        {
                            questionnaires_filtered.push(qs);
                        }
                    })
                })
                setItems(questionnaires_filtered);
                setLoading(false);
              })

      }
  }, [studentId])

  const setitem = (e) => {
    e.preventDefault();
    setItemShown(e.target.value);
    }

  return(
    loading ? <i className="fas fa-spinner fa-spin"></i> :
    <div>
        <div className="select-switch-holder">
        {
        user.specialAccountType == 'B' ?
            <InviteT2StudentBegeleiding user={user} intervention_id={intervention.intervention_id} /> : <></>
        }
        <div className="anotherHolder">
            <select className="custom-select" onChange={(e) => setitem(e)} value={parseInt(itemshown)}>
                {
                    answers.map((item, index) => {
 
                            return (
                                <option key={index} value={index}>
                                    {item.questionnaire_title} {item.ended}
                                </option>
                            )
                    })
                }
            </select>
        </div>
        </div>
        <br /><br />
        <Content itemKey={itemshown} items={items} answers={answers} type="questionnaire" />
    </div>
  )
}

export default StudentDetailsQuestionnaires
