import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortObjectArray from "../../helpers/sortObjectArray.js";
import apiCall from "../../api";
import moment from "moment";
import Pagination from "../../helpers/pagination.js";
import t from "../../translate";
import ReactTooltip from 'react-tooltip';

const  StudentDetailsLog = props => {

    const auth = useSelector(state => state.auth);
    const intervention = useSelector(state => state.intervention);

    const [logLoaded, setLogLoaded] = useState(false);
    const [log, setLog] = useState([]);
    const [logToShow, setLogToShow] = useState([]);

    const [sort, setSort] = useState("")
    const [sortDirection, setSortDirection] = useState("")
    const [paginationFrom, setPaginationFrom] = useState(0)
    const [paginationStep, setPaginationStep] = useState(50)

    useEffect(() => {

     setLogToShow(log.slice(paginationFrom, (paginationStep + paginationFrom)))

     }, [paginationFrom, log])


    function changeSort(type){
      setSortDirection(sortDirection == "asc" ? "desc":"asc")

      if(type != ""){
        let tempLog = [...log]
        tempLog.sort(SortObjectArray(type, sortDirection));
        setLog(tempLog)
      }

    }

    useEffect(() => {
        if(props.studentInterventionID > 0){
            //student info ophalen
            apiCall({
                action: "get_student_log",
                token: auth.token,
                data: {
                  user_id: props.studentId,
                  intervention_id: props.studentInterventionID
                }
              }).then(resp => {
                setLog(resp.log);
                setLogLoaded(true)
              });
        }

    }, [props.studentId]);

    function downloadLog(){
        var csvContent = 'id;date_time_create;action;description\n';
    var dataString = '';
    let status = '';

    log.forEach(function(r, index) {
      if (r.date_time_create > 0)
      {
        r.date_time_create = moment.unix(r.date_time_create).format("DD-MM-YYYY HH:mm:ss", { trim: false });
      }

      dataString += r.id +";"+ r.date_time_create+";"+r.action+";"+r.object+"\n";
      //csvContent += index < registrations.length ? dataString + '\n' : dataString;

    });
    csvContent += dataString;

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    var download = function(content, fileName, mimeType) {
      var a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';

      if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
          type: mimeType
        }), fileName);
      } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
          type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        /// hier loopt die vast maar is misschien ook niet nodig
        //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
      }
    }

    download(csvContent, 'log_user_'+props.studentId + "_" + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');
    }

    return (
        <div className="log">
        {logLoaded ?
            <>
            {log.length > 0 ?
                <>
                <table className="table table-striped">
                <thead>
                    <tr>
                        <th className="pointer" onClick={() => changeSort("id")}>#id</th>
                        <th className="pointer" onClick={() => changeSort("date_time_create")}>date/time</th>
                        <th className="pointer" onClick={() => changeSort("action")}>action</th>
                        <th className="pointer" onClick={() => changeSort("object")}>description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    logToShow.map((l, index) => (
                        <>
                        <tr key={index}>
                            <td>{l.id}</td>
                            <td>{l.date_time_create}</td>
                            <td>{l.action}</td>
                            <td>{l.object}</td>
                        </tr>
                        <tr><td colSpan="4"></td></tr>
                        </>
                    ))
                    }
                </tbody>
                </table>
                {log.length > paginationStep ?
                    <Pagination
                        nrItems={log.length}
                        paginationFrom={paginationFrom}
                        setPaginationFrom={setPaginationFrom}
                        paginationStep={paginationStep}
                    />
                    :false}
                </>
                :t("Nog geen activiteiten gelogd")}
                <i className="fas fa-file-download" data-tip={t("Download log")} onClick={()=>downloadLog()}></i>
                <ReactTooltip place="top" effect="solid" delayShow={200}   />
            </>
            :<i className="fas fa-spinner fa-spin"></i>}
        </div>
    )
}

export default StudentDetailsLog;
