import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import LoadScreen from "../../loadScreen"
import apiCall from "../../api"
import t from "../../translate"

const PlatformPages = props => {

   const history = useHistory()

   const [pages, setPages] = useState([])
   const [newPageTitle, setNewPageTitle] = useState("")
   const [editable, setEditable] = useState(false)

   const auth = useSelector(state => state.auth)

   useEffect(() => {
      //voldoende rechten?
      if (typeof auth.rights.platform_access === 'undefined' || !auth.rights.platform_access) {
         history.push('/')
      }
      apiCall({
         action: 'get_platform_pages',
         token: auth.token,
         data: {}
      }).then(resp => {
         setPages(resp.pages)
         setEditable(true)
      })
   }, [])

   function updateTitle(key, page_id, title) {

      let newPages = [...pages]
      newPages[key].settings.title = title
      newPages[key].settings.newPart = false
      newPages[key].settings.removePart = false

      setPages(newPages)

      apiCall({
         action: 'save_platform_page',
         token: auth.token,
         data: {
            id: page_id,
            settings: newPages[key].settings,
         }
      })
   }

   function addPage(e) {
      if (e.key === "Enter") {
         apiCall({
            action: 'save_platform_page',
            token: auth.token,
            data: {
               id: 0,
               title: newPageTitle,
            }
         }).then(resp => {
            setPages(resp.pages)
            setNewPageTitle("")
         })
      }
    }

   function copyPage(page_id) {
      apiCall({
         action: 'copy_platform_page',
         token: auth.token,
         data: {
            page_id
         }
      }).then(resp => {
         setPages(resp.pages)
      })
   }

   function deletePageConfirm(page_id) {
      if (window.confirm(t("Weet je zeker dat je deze pagina wilt verwijderen?"))) {
         apiCall({
            action: 'delete_platform_page',
            token: auth.token,
            data: {
               page_id
            }
         }).then(resp => {
            setPages(resp.pages)
         })
      }
   }

   return (
   <div className="intervention_edit coachInterface platform">
      <div className="settings_container">
         <div id="settings-pages">
            {/*<h1>{t("Platform pagina's")}</h1>*/}
            {
               editable ?
               <>
                  <div className="items pages">
                  {
                     pages.map((page, key) => {
                        return (
                           <div key={key} className="item">
                              <div className="form-group item">
                              <table>
                                 <tbody>
                                    <tr>
                                      <td className="title">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={"page_" + page.id}
                                          name={"page_" + page.id}
                                          placeholder={t("Titel")}
                                          aria-describedby={"page_" + page.id}
                                          value={page.settings.title}
                                          onChange={e => updateTitle(key, page.id, e.target.value)}
                                        />
                                      </td>
                                      <td>
                                         <div className="lesson-controls">
                                             <span className="btn edit disabled" data-tip={t("Wijzig pagina")} onClick={() => { history.push('/platformpages/edit/' + page.id)}}>
                                               <i className="fas fa-pen"></i>
                                             </span>

                                             <span className="otherActionsToggle btn">
                                               <i className="fas fa-ellipsis-h"></i>
                                               <div className="otherActions">
                                                 <table onClick={() => { copyPage(page.id) }}>
                                                   <tbody>
                                                     <tr>
                                                       <td>{t("Kopieer")}</td>
                                                       <td>
                                                         <i className="fa fa-copy"></i>
                                                       </td>
                                                     </tr>
                                                   </tbody>
                                                 </table>
                                                 <table onClick={() => { deletePageConfirm(page.id) }}>
                                                   <tbody>
                                                     <tr>
                                                       <td>{t("Verwijder")}</td>
                                                       <td>
                                                         <i className="fas fa-minus"></i>
                                                       </td>
                                                     </tr>
                                                   </tbody>
                                                 </table>
                                               </div>
                                             </span>
                                         </div>
                                      </td>
                                    </tr>
                                 </tbody>
                              </table>
                             </div>
                           </div>
                        )
                     })
                  }
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="new"
                      placeholder={t("Voeg nieuwe pagina toe")}
                      value={newPageTitle}
                      onChange={e => setNewPageTitle(e.target.value)}
                      onKeyPress={addPage}
                    />
                  </div>
               </>
               :
                  <LoadScreen />
            }

         </div>
      </div>
</div>
   )
}

export default PlatformPages
