import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo_moodlift.png";
import { useSelector } from "react-redux";
import t from "../translate";

const NavBar = () => {

  const auth = useSelector(state => state.auth);
  /*
  <span className={(auth.user_id !== 0) ? 'loggedInAs' : 'hidden'} >
    {t("Ingelogd als: ")} {auth.name} ({auth.userType})
  </span>
  */
  return (
    <div>
      {auth.userType != "student" ?
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link to="/">
          <img src={logo} className="logo"/>
        </Link>
      </nav>
      :'' }
    </div>
  );
};

export default NavBar;
