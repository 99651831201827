import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import Select from 'react-select'

const SleepDiaryWizzardStep1 = (props) => {

  const bedTimeHours = [{value:17,label:17},{value:18,label:18},{value:19,label:19},{value:20,label:20},{value:21,label:21},{value:22,label:22},{value:23,label:23},{value:0,label:"00"},{value:1,label:"01"},{value:2,label:"02"},{value:3,label:"03"},{value:4,label:"04"},{value:5,label:"05"},{value:6,label:"06"},{value:7,label:"07"},{value:8,label:"08"}]
  const wakeUpTimeHours = [{value:21,label:21},{value:22,label:22},{value:23,label:23},{value:0,label:"00"},{value:1,label:"01"},{value:2,label:"02"},{value:3,label:"03"},{value:4,label:"04"},{value:5,label:"05"},{value:6,label:"06"},{value:7,label:"07"},{value:8,label:"08"},{value:9,label:"09"},{value:10,label:10},{value:11,label:11},{value:12,label:12},{value:13,label:13},{value:14,label:14},{value:15,label:15},{value:16,label:16},{value:17,label:18},{value:19,label:19}]
  const quarters = [{value:0,label:"00"},{value:1,label:15},{value:2,label:30},{value:3,label:45}]

  return (
    <div className="step_1">
      <div className="question box">
        <h4>{t("Ik ben gaan slapen om")}...</h4>
        <table>
        <tbody>
          <tr>
            <td>
              <Select
                className="time_select"
                options={bedTimeHours}
                onChange={(option)=>props.setBedTimeHour(option.value)}
                onBlur= { event => event.preventDefault()}
                value = {
                   bedTimeHours.filter(option =>
                      option.value === props.bedTimeHour)
                }
                />
            </td>
            <td>
              :
            </td>
            <td>
              <Select
                className="time_select"
                options={quarters}
                onChange={(option)=>props.setBedTimeQuarter(option.value)}
                onBlur= { event => event.preventDefault()}
                value = {
                   quarters.filter(option =>
                      option.value === props.bedTimeQuarter)
                }
                />
            </td>
          </tr>
        </tbody>
        </table>
        <div className="instruction">
          <b>{t("Uitleg")}:</b> {t("Geef hier aan hoe laat je bent gaan slapen. Hiermee bedoelen we de tijd dat je het licht uitdeed in bed.")}<br/><br/>
          {t("Voorbeeld 1: je ging om 23.00 uur naar bed en deed meteen het licht uit. In dit geval noteer je 23.00 uur.")}<br/><br/>
          {t("Voorbeeld 2: je ging om 23.00 uur naar bed maar ging een half uur lezen en deed om 23.30 uur het licht uit. In dit geval noteer je 23.30 uur.")}<br/><br/>
          {t("Voorbeeld 3: je ging om 23.00 uur naar bed en deed het licht uit maar viel uiteindelijk pas om 01.00 uur in slaap. In dit geval noteer je 23.00 uur. De twee uur wakker liggen kun je op de volgende pagina aangeven.")}
        </div>
      </div>
      <div className="question box">
        <h4>{t("Ik ben opgestaan om")}...</h4>
        <table>
        <tbody>
          <tr>
            <td>
              <Select
                className="time_select"
                options={wakeUpTimeHours}
                onChange={(option)=>props.setWakeupTimeHour(option.value)}
                value = {
                   wakeUpTimeHours.filter(option =>
                      option.value === props.wakeupTimeHour)
                }
                />
            </td>
            <td>
              :
            </td>
            <td>
              <Select
                className="time_select"
                options={quarters}
                onChange={(option)=>props.setWakeupTimeQuarter(option.value)}
                value = {
                   quarters.filter(option =>
                      option.value === props.wakeupTimeQuarter)
                }
                />
            </td>
          </tr>
        </tbody>
        </table>
        <div className="instruction">
          <b>{t("Uitleg")}:</b> {t("Geef hier aan hoe laat je uit bed bent gegaan. Dit kan een andere tijd zijn dan de tijd dat je wakker bent geworden.")}
          <br/><br/>
          {t("Misschien ben je om 7.30 uur wakker geworden maar om 8.00 uur opgestaan. In dit geval kies je hier 8.00 uur en ga je verder op de volgende pagina.")}
        </div>
      </div>
    </div>
  )
}
export default SleepDiaryWizzardStep1
