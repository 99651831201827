import React from "react";
import LogOut from "./logout";

const MenuResearcher = props => {

  return (
    <div>
      <LogOut />
    </div>
  );

};

export default MenuResearcher;
