import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Login from '../login';
import logo from '../../images/caring_universities.png';
import apiCall from '../api';
import { setUiTranslation } from '../../actions';
import t from '../translate';
import logoMoodlift from '../../images/logo_moodlift.png';
import arrowRight from '../../images/svg/noun-arrow-3134209.svg';
import vector1 from '../../images/home/vector1.svg';
import vector2 from '../../images/home/vector2.svg';
import vector3 from '../../images/home/vector3.svg';
import vector4 from '../../images/home/vector4.svg';
import vector5 from '../../images/home/vector5.svg';
import vector6 from '../../images/home/vector6.svg';

const Confirmation = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [language, setLanguage] = useState(1); //dutch
  const language_id = useSelector(state => state.uiTranslation.language_id);
  const [showError, setShowError] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [allreadyConfirmed, setShowAllreadyConfirmed] = useState(false);
  /*
  apiCall({
        action: "get_registration_data",
        data: {
          token: cookies.token
        }
      }).then(resp => {
        setRegistrationData(resp.registrationData);
      });
      */

  useEffect(() => {
    let querystring = window.location.href.split("/");

    if (typeof querystring[4] !== "undefined" && querystring[4].substr(0,1) === '?')
    {
      let par = querystring[4].substr(1).split("&");
      if (par.length > 1)
      {
        let ac = par[0].split("=")[1];
        let dtc = par[1].split("=")[1];
        apiCall({
          action: "update_registration",
          data: {
            step: 7,
            ac,
            dtc
          }
        }).then(resp => {
          changeLanguage(parseInt(resp.language_id));
          if (parseInt(resp.accountConfirmed) === 1)
          {
            setShowConfirmed(true);
            //history.push('/')
          }
          else if (parseInt(resp.accountConfirmed) === 2)
          {
            setShowAllreadyConfirmed(true);
          }
          else
          {
            setShowError(true);
          }
        });

      }
      else
      {
        setShowError(true);
      }
    }
    else
    {
      setShowError(true);
    }
    
   
  }, [window.location.href])

  useEffect(() => {

  }, [language_id]);

  const changeLanguage = (language_id) => {
    if (language !== language_id)
    {
      if (language_id !== 1 && language_id !== 2) {
        language_id = 2;
      }
      setLanguage(language_id);
      if (parseInt(language_id) === 1)
      {
        dispatch(setUiTranslation(1,[]));
      }
      else
      {
        //getTranslation
        apiCall({
          action: "get_ui_translation_strings_by_language",
          data: {
            language_id: language_id
          }
        }).then(resp => {
          dispatch(setUiTranslation(language_id, resp.translation));
        });
      }
    }
  }

  
  return (
    <>
    {
      showConfirmed ?
      <Login setConfirmation={props.setConfirmation} heading="Je account is geactiveerd. Je kunt meteen aan de slag!" /> 
      
      :

      allreadyConfirmed ?

      <Login setConfirmation={props.setConfirmation} heading="Jouw account was al op een eerder moment geactiveerd..." /> 

      :
      
    <div className="loginScreen">
        <span className="homeScreenVectors">
            <img src={vector1} className="vector1" />
            <img src={vector2} className="vector2" />
            <br />
            <img src={vector3} className="vector3" />
            <img src={vector4} className="vector4" />
            <br />
            <img src={vector5} className="vector5" />
            <img src={vector6} className="vector6" />
        </span>
        <div style={{position: 'absolute', margin: 'auto', width: '100%', top: '0px', textAlign: 'center', paddingTop: '60px'}}>
            <img src={logoMoodlift} style={{width: '300px', display: 'block', margin: 'auto', marginBottom: '20px'}} />

            <h1>{t("Accountactivation")}</h1>

          <div className={showError ? '' : 'hidden'}>
            <br /><br />
            {t("Account Activation Failed: The link you used does not seem valid...")}
          </div>

          <div className={allreadyConfirmed ? '' : 'hidden'}>
            {t("Your account was already activated at an earlier time...")}
            <br /><br />
            <a href="/">{t("Click here to go to the login screen.")}</a>
          </div>

          <div className={(!showError && !showConfirmed && !allreadyConfirmed) ? '' : 'hidden'}>
            {t("One moment please, your account is being activated.")}
          </div>
        </div>
    </div>
    }
    </>
    
  )
}

export default Confirmation;
