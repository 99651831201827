import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import t from "../../../translate";
import { stripTags } from "../../../utils";

const Select = (props) => {

  function updateSelected(item_id){
    let chosenAnswersUpdated = []
    chosenAnswersUpdated.push(item_id)

    props.updateAnswer(props.part.id, {"chosenAnswers":chosenAnswersUpdated})
  }

  return(
    <div className={"list " + (props.part.type == "list" ? props.part.subtype:"") +(props.part.must ? ' must':'')}>
      <div className="content center">
        <div className="question">
          {parse(props.part.question)}
        </div>
        <select onChange={(e) => updateSelected(e.target.value)} value={typeof props.answer.chosenAnswers != "undefined"? props.answer.chosenAnswers[0]:''} disabled={(props.hasOwnProperty("disabled") && props.disabled === "true") ? true : false}>
          <option value=''>{t("selecteer een optie")}</option>
          {props.part.items.map((item, index) =>
            <option key={item.id} value={parse(item.id)}>{stripTags(item.content.replace(/&gt;/g, ">").replace(/&st;/g, "<"))}</option>
          )}
        </select>
      </div>

    </div>
  )
}

export default Select
