import React from "react";
import InterventionUsers from "../students/researcher.js";

const Researcher = () => {
   return (
      <>
         <InterventionUsers />
      </>
   );
}

export default Researcher;