import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";

const Carousel = (props) => {

  //////////////////////
  ///Carousel
  function PrevButton({ onClick }) {
    return <i className="fas fa-chevron-left" onClick={onClick}></i>;
  }
  function NextButton({ onClick }) {
    return <i className="fas fa-chevron-right" onClick={onClick}></i>;
  }
  const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
    };

  const [widthSlide, setWidthSlide] = useState($(".right .content").width())

  useEffect(() => {
    window.addEventListener('resize', setWidthSlide($(".right .content").width()))
  }, []);

  return(
    <div>
      {(props.images.length > 0) && props.part.subtype == "carousel" ?
          <Slider {...sliderSettings}>
            {props.images.map((image, index) =>
              <div key={index} className="imageHolder" style={{width:widthSlide}}>
                {props.buildImage(image.url)}
              </div>
            )}
          </Slider>
      :''}
    </div>
  )
}

export default Carousel;
