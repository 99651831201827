import React from "react"
import t from "../translate"

const Panel5 = ({ registrationTexts, getTextInput, getContentEditable, getEditor }) => {

   function addTableRow(cell1, cell2, cell3) {
      return (
         <tr><td>{cell1}</td><td>{cell2}</td><td>{cell3}</td></tr>
      )
   }

   return (
      <div>
         <h2>{t("Scherm 5: Laatste stap, over jou")}</h2>
         <table className="table table-striped">
            <thead>
               <tr>
                  <th width="20%"></th>
                  <th width="40%">{t("Nederlands")}</th>
                  <th width="40%">{t("Engels")}</th>
               </tr>
            </thead>
            <tbody>
               {
                  addTableRow(
                     t("Tooltip Private email"), 
                     getContentEditable('panel5_privateEmailTip_nl', registrationTexts.panel5.privateEmailTip.nl, 'Tootltip naast label privé email'),
                     getContentEditable('panel5_privateEmailTip_eng', registrationTexts.panel5.privateEmailTip.eng, 'Tootltip next to label private email'),
                  )
               }
            </tbody>
         </table>
      </div>
   )
} 

export default Panel5
