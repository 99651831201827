import React, { useState, useEffect } from "react";
import Lessons from "./lessons.js";

const StudentDetails = (props) => {

  return (
    <div className="content details">
      <Lessons studentId={props.student.id} interventionId={props.student.intervention_id } />  
    </div>
  )

}
export default StudentDetails;
