import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import t from "../../translate";

const FreeEmail = (props) => {

  const auth = useSelector(state => state.auth);
  const [message, setMessage] = useState('');

  const freeRegistrationEmail = (id) => {
    //alle studenten ophalen
    apiCall({
      action: "free_registration_email",
      token: auth.token,
      data: {
        user_id:id
      }
    }).then(resp => {
      if(props.setRegistrationsToShow){
        const registrationToShowN = [...props.registrationsToShow];

        for(let i = 0 ; i < registrationToShowN.length ; i++){
          if (registrationToShowN[i].id === props.registration.id){
            registrationToShowN[i].email = resp.freeEmail;
            break;
          }
        }
        props.setRegistrationsToShow(registrationToShowN)
      }

      setMessage(t("Emailadres is vrijgegeven"))
      /*
      setTimeout(() => {
        setMessage("");
      }, 3000);
      */
    });
  }

  return(
    <>
      <b>{t("Emailadres vrijgeven")}</b><br/><br/>
      {message == '' ?
        <span className="btn btn-primary" onClick={()=>freeRegistrationEmail(props.registration.id)}>{t("Geef emailadres vrij")}</span>
      :
        <span className="btn btn-primary btn-success">
          {message} <i className="fa fa-check"></i>
        </span>
      }
    </>
  )

}

export default FreeEmail
