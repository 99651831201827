import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClone, checkNestedProperties } from "../../utils";
import t from "../../translate";
import $ from "jquery";
import apiCall from "../../api";
import defaultUncoveredBadge from '../../../images/badges/badge_uncovered.png';
import { setNewBadges } from "../../../actions"

const Badges = () => {

  const [totalBadges, setTotalBadges] = useState(0);
  const [collectedBadges, setCollectedBadges] = useState(0);
  const [badges, setBadges] = useState([]);
  const [pointsBadges, setPointsBadges] = useState([]);
  const [rewards, setRewards] = useState([]);

  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  const [uncoveredBadge, setUncoveredBadge] = useState(defaultUncoveredBadge);

  /////////////////
  ////Badges page is shown
  useEffect(() => {
    apiCall({
      action: "badges_pages_is_shown",
      token: auth.token,
      data: {
        intervention_id: parseInt(intervention.id)
      }
    }).then(resp => {
      dispatch(setNewBadges(intervention.id, []));
    });
    if (checkNestedProperties(intervention, 'settings', 'gamification', 'badgesData', 'uncovered_badges') && intervention.settings.gamification.badgesData.uncovered_badges.length > 0 && typeof intervention.settings.gamification.badgesData.uncovered_badges[0].image !== "undefined" && intervention.settings.gamification.badgesData.uncovered_badges[0].image !== "") {
      setUncoveredBadge(intervention.settings.gamification.badgesData.uncovered_badges[0].image);
    }
  }, [intervention]);

  /////////////////
  ////Set reward local
  useEffect(() => {
    let this_rewards_obj = auth.rewards.filter(function (reward) {
      return parseInt(reward.id) === intervention.id
    });
    if(this_rewards_obj.length !== 0){
      setRewards(this_rewards_obj[0]);
    }
  }, [auth, intervention]);

  /////////////////
  ////Get badges that are set and check if collected
  useEffect(() => {
    let tempBadges = [];
    let tempPointsBadges = [];
    let tempCollectedBadges = 0;
    let tempTotalBadges = 0;
    if(typeof intervention.settings.gamification !== "undefined"){
      let clonedInterventionsGamification = getClone(intervention.settings.gamification.badgesData);

      //start
      if (typeof clonedInterventionsGamification.start_badges !== "undefined") {
        for(let i = 0 ; i < clonedInterventionsGamification.start_badges.length ; i++){
          clonedInterventionsGamification.start_badges[i].type = "start";
          //clonedInterventionsGamification.start_badges[i].title = clonedInterventionsGamification.start_badges[i].text !== "" ? clonedInterventionsGamification.start_badges[i].text:t("Gestart met programma");
          clonedInterventionsGamification.start_badges[i].finished = true;
          clonedInterventionsGamification.lesson_badges[i].priority = 0;
          tempBadges.push(clonedInterventionsGamification.start_badges[i])
          tempTotalBadges++;
          tempCollectedBadges++;
        }
      }

      //lesson
      if (typeof intervention.settings.gamification.badgesData.lesson_badges !== "undefined") {
        for(let i = 0 ; i < clonedInterventionsGamification.lesson_badges.length ; i++){
          clonedInterventionsGamification.lesson_badges[i].type = "lesson";

          if(clonedInterventionsGamification.lesson_badges[i].image !== ""){
            let this_lesson_obj = intervention.settings.selfhelp.lessons.filter(function (lesson) {
              return lesson.id === clonedInterventionsGamification.lesson_badges[i].option
            });

            if(this_lesson_obj.length !== 0){
              let counter_module = 0;
              for(let il = 0 ; il < intervention.settings.selfhelp.lessons.length ; il++){
                if(intervention.settings.selfhelp.lessons[il].parent_id == 0){
                  counter_module++;
                  if(intervention.settings.selfhelp.lessons.indexOf(this_lesson_obj[0]) == il){
                    break;
                  }
                }
              }

              ///check if lesson is finished
              if(typeof rewards.lessons !== "undefined" && rewards.lessons.finished.indexOf(clonedInterventionsGamification.lesson_badges[i].option) !== -1){
                clonedInterventionsGamification.lesson_badges[i].finished = true;
                tempCollectedBadges++;
              }

              clonedInterventionsGamification.lesson_badges[i].priority = counter_module;
              //clonedInterventionsGamification.lesson_badges[i].title = t("Module ") + counter_module + " " + t("afgerond");
              tempTotalBadges++;
            }

            tempBadges.push(clonedInterventionsGamification.lesson_badges[i])
          }
        }
      }

      //optionele lesson
      if (typeof clonedInterventionsGamification.optional_lesson_badges !== 'undefined') {
        for(let i = 0 ; i < clonedInterventionsGamification.optionalLesson_badges.length ; i++){
          clonedInterventionsGamification.optionalLesson_badges[i].type = "optionalLessons";

          if(clonedInterventionsGamification.optionalLesson_badges[i].image !== ""){
            let this_optionalLesson_obj = intervention.settings.selfhelp.optionalLessons.filter(function (lesson) {
              return lesson.id === clonedInterventionsGamification.optionalLesson_badges[i].option
            });

            if(this_optionalLesson_obj.length !== 0){
              let counter_module = 0;
              for(let il = 0 ; il < intervention.settings.selfhelp.optionalLessons.length ; il++){
                if(intervention.settings.selfhelp.optionalLessons[il].parent_id == 0){
                  counter_module++;
                  if(intervention.settings.selfhelp.optionalLessons.indexOf(this_optionalLesson_obj[0]) == il){
                    break;
                  }
                }
              }

              ///check if optional lesson is finished
              if(typeof rewards.optionalLessons !== "undefined" && rewards.optionalLessons.finished.indexOf(clonedInterventionsGamification.optionalLesson_badges[i].option) !== -1){
                clonedInterventionsGamification.optionalLesson_badges[i].finished = true;
                tempCollectedBadges++;
              }

              clonedInterventionsGamification.optionalLesson_badges[i].priority = counter_module + 999999999999; /// sort pakt lessen en optionele => om de sort correct te laten verlopen 999999999999 erbij
              //clonedInterventionsGamification.optionalLesson_badges[i].title = t("Optionele module") + " " + counter_module + t("afgerond");
              tempTotalBadges++;
            }
            tempBadges.push(clonedInterventionsGamification.optionalLesson_badges[i])
          }
        }
      }

      ///sort on priority
      tempBadges.sort(dynamicSort("priority"));


      //Goals
      if (typeof intervention.settings.gamification.badgesData.goal_badges !== "undefined") {
        for(let i = 0 ; i < clonedInterventionsGamification.goals_badges.length ; i++){
          if(  clonedInterventionsGamification.goals_badges[i].active){
            clonedInterventionsGamification.goals_badges[i].type = "goal";

            let this_goal_obj = intervention.settings.goals.filter(function (goal) {
              return goal.id === clonedInterventionsGamification.goals_badges[i].id
            });

            if(this_goal_obj.length !== 0){
              //clonedInterventionsGamification.goals_badges[i].pretext = t("Doe");
              //clonedInterventionsGamification.goals_badges[i].title = this_goal_obj[0].title;
            }

            for(let io = 0 ; io < clonedInterventionsGamification.goals_badges[i].options.length ; io++){
              if(checkIfFinished(clonedInterventionsGamification.goals_badges[i], clonedInterventionsGamification.goals_badges[i].options[io])){
                tempCollectedBadges++;
              }
              tempTotalBadges++;
            }

            tempBadges.push(clonedInterventionsGamification.goals_badges[i])
          }
        }
      }


      //Vragenlijsten
      if (typeof intervention.settings.gamification.badgesData.questionnaires_badges !== "undefined") {
        for(let i = 0 ; i < clonedInterventionsGamification.questionnaires_badges.length ; i++){
          clonedInterventionsGamification.questionnaires_badges[i].type = "questionnaire";

          if(clonedInterventionsGamification.questionnaires_badges[i].image !== ""){
            let this_questionnaire_obj = intervention.settings.questionnaires.filter(function (questionnaire) {
              return questionnaire.id === clonedInterventionsGamification.questionnaires_badges[i].option
            });

            if(this_questionnaire_obj.length !== 0){
              let counter_module = 0;
              for(let il = 0 ; il < intervention.settings.questionnaires.length ; il++){
                if(intervention.settings.questionnaires[il].parent_id == 0){
                  counter_module++;
                  if(intervention.settings.questionnaires.indexOf(this_questionnaire_obj[0]) == il){
                    break;
                  }
                }
              }

              ///check if lesson is finished
              if(typeof rewards.questionnaires !== "undefined" && rewards.questionnaires.finished.indexOf(clonedInterventionsGamification.questionnaires_badges[i].option) !== -1){
                clonedInterventionsGamification.questionnaires_badges[i].finished = true;
                tempCollectedBadges++;
              }

              //clonedInterventionsGamification.questionnaires_badges[i].title = t("Vragenlijst") + " " + this_questionnaire_obj[0].title + " " + t("afgerond");
              tempTotalBadges++;
            }

            tempBadges.push(clonedInterventionsGamification.questionnaires_badges[i])
          }
        }
      }
      //Other ///check of dagboek wel aanstaat
      if (typeof clonedInterventionsGamification.other_badges !== "undefined") {
        for(let i = 0 ; i < clonedInterventionsGamification.other_badges.length ; i++){
          if(clonedInterventionsGamification.other_badges[i].id == 1 && typeof intervention.settings !== "undefined" && typeof intervention.settings.include_journal !== "undefined" &&  intervention.settings.include_journal === 1){
            if(clonedInterventionsGamification.other_badges[i].active)
            {
              clonedInterventionsGamification.other_badges[i].type = "diary";
              clonedInterventionsGamification.other_badges[i].pretext = t("Schrijf");
              //clonedInterventionsGamification.other_badges[i].title = t("in dagboek");

              for(let io = 0 ; io < clonedInterventionsGamification.other_badges[i].options.length ; io++){
                if(checkIfFinished(clonedInterventionsGamification.other_badges[i], clonedInterventionsGamification.other_badges[i].options[io])){
                  tempCollectedBadges++;
                }
                tempTotalBadges++;
              }

              tempBadges.push(clonedInterventionsGamification.other_badges[i])
            }

          }
          if(clonedInterventionsGamification.other_badges[i].id == 2 && clonedInterventionsGamification.other_badges[i].active){
            clonedInterventionsGamification.other_badges[i].type = "points";
            clonedInterventionsGamification.other_badges[i].pretext = t("");
            //clonedInterventionsGamification.other_badges[i].title = t("punten behaald");
            tempPointsBadges.push(clonedInterventionsGamification.other_badges[i])
          }
        }
      }
    }

    setCollectedBadges(tempCollectedBadges)
    setTotalBadges(tempTotalBadges)
    setBadges(tempBadges)
    setPointsBadges(tempPointsBadges)

  }, [intervention, rewards]);

  /////////////////
  ////Check if collected only for parts with multiple options per part
  function checkIfFinished(badge, option){
    if(rewards.length == 0){return false}
    if(badge.type == "goal"){
      let this_rewards_obj = rewards.goals.filter(function (reward) {
        return parseInt(reward.id) === parseInt(badge.id)
      });

      if(this_rewards_obj.length !== 0){
        if(option.times <= this_rewards_obj[0].times){
          return true;
        }
      }
    }
    if(badge.type == "points"){
      if(rewards.points >= parseInt(option.times)){
        return true;
      }
    }
    if(badge.type == "diary"){
      let this_rewards_obj = rewards.other.filter(function (reward) {
        return reward.type === "diary"
      });
      if(this_rewards_obj.length !== 0){
        if(option.times <= this_rewards_obj[0].times){
          return true;
        }
      }

    }

    return false;
  }

  /////////////////
  ////Sort function to sort lessons
  function dynamicSort(property) {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }

  return(
    <div className="badges">
      <h1>{t("Jouw badges")}</h1>
      <div className="intro">
        <div className="b_l">
          {t("Dit zijn alle badges die je met dit programma kunt verdienen.")}
        </div>
        <div className="b_r">
          <div className="b_t">
            <span className="collectedBadges">
              {collectedBadges}
            </span>
            &nbsp;{t("van de")+" "}&nbsp;
            <span>
              {totalBadges}
            </span>
          </div>
          {t("behaald")}
        </div>
      </div>
      <div className="items">
        {badges.map((badge, i) => (
          <>
          {badge.type == "start" || badge.type == "lesson" || badge.type == "optionalLessons" || badge.type == "questionnaire" ?
            <span className={"badge" + (typeof badge.finished !== "undefined" && badge.finished == true ? " finished":"")} key={i}>
              {typeof badge.finished !== "undefined" && badge.finished == true ?
                <img src={badge.image} />
                :
                <img src={uncoveredBadge} />
              }
              <span className="title" title={badge.text?badge.text:false}>
                {badge.text?badge.text:false}
              </span>
            </span>
            :
            <>
            {badge.type !== "points" ?
              <>
                {badge.options.map((option, ii) => (
                  <span className={"badge" + (checkIfFinished(badge, option) ? " finished":"")} key={i+ "_"+ ii}>
                    {checkIfFinished(badge, option) ?
                      <img src={option.image} />
                      :
                      <img src={uncoveredBadge} />
                    }
                    <span className="title" title={option.text ? option.text:false}>
                      {option.text ? option.text:false}
                    </span>
                  </span>
                ))}
              </>
              :
              <></>
            }
            </>
          }
          </>
        ))}
      </div>
      {pointsBadges.length > 0 ?
        <div className="points">
          <h1>{t("Jouw punten")}</h1>
          <div className="intro">
            <div className="b_l">
              {t("Totaal aantal punten dat je met dit programma hebt behaald.")}
            </div>
            <div className="b_r">
              <div className="b_t">
                <span className="collectedBadges">
                  {rewards.points}
                </span>
              </div>
            </div>
          </div>
          <div className="items">
            {pointsBadges.map((badge, i) => (
              <>
                {badge.type == "points" ?
                  <>
                    {badge.options.map((option, ii) => (
                      <span className={"badge" + (checkIfFinished(badge, option) ? " finished":"")} key={i+ "_"+ ii}>
                        {checkIfFinished(badge, option) ?
                          <img src={option.image} />
                          :
                          <img src={uncoveredBadge} />
                        }
                        <span className="title" title={option.text ? option.text:false}>
                          {option.text ?option.text:false}
                        </span>
                      </span>
                    ))}
                  </>
                :
                <></>
              }
              </>
            ))}
          </div>
        </div>
        :
        <></>
      }
    </div>
  );
}

export default Badges
