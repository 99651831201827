import React from 'react';
import StressMeter from '../stressMeter.js';

const CustomContent = (props) => {
  var dynamicContent = "";

  switch(props.part.custom_id) {
    case "1":
      dynamicContent = <StressMeter part={props.part} updateAnswer={props.updateAnswer} answer={props.answer} />;
      break;
  }

  return (
    dynamicContent
  )
}
export default CustomContent
