import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import Pagination from "../../helpers/pagination.js";
import t from "../../translate";
import parse from 'html-react-parser';
import ReactTooltip from 'react-tooltip';

const EmailContent = props => {

  const [email, setEmail] = useState(false);
  const [message, setMessage] = useState('');

  const auth = useSelector(state => state.auth);

  useEffect(() => {
     if(props.email){
       setEmail(props.email)
     }
   }, [props])

  const resendMail = (email_id) => {
    apiCall({
      action: "resend_mail",
      token: auth.token,
      data: {
        email_id:email_id
      }
    }).then(resp => {
      setMessage(t('Email is opnieuw verzonden'))
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }

  return(
    <>
      {email ?
        <>
          {parse(email.body)}
          <div className={(message != "" ? ' resend':'')}>
            <i className="fas fa-paper-plane resendMail pointer" data-tip={t('Verstuur e-mail opnieuw')} onClick={()=>resendMail(email.id)}></i> {message}
            <ReactTooltip place="top" effect="solid" delayShow={200}   />
          </div>
          <br/>
        </>
        :false}

    </>
  )

}

export default EmailContent;
