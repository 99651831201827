import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import LeftMenu from "../../leftmenu"
import ContentEdit from "../../content/edit";
import LoadScreen from "../../loadScreen";
import apiCall from "../../api"
import t from "../../translate"

const PlatformPagesEdit = props => {

   const params = useParams()

   const options = ["quote", "list", "wysiwyg", "video", "audio", "image"]

   const [contentIndex, setContentIndex] = useState(0)
   const [content, setContent] = useState("")
   const [editable, setEditable] = useState(false)

   const auth = useSelector(state => state.auth)


   useEffect(() => {
      apiCall({
         action: 'get_platform_page',
         token: auth.token,
         data: {
            page_id: params.page_id
         }
      }).then(resp => {   
         resp.settings.newPart = false
         resp.settings.removePart = false
         setContent(resp.settings)
         setEditable(true)
      })
   }, [params.page_id])

   return (
      <>
         <LeftMenu />
         {
            editable ? 
               <ContentEdit
                  id={params.page_id}
                  options={options}
                  content={content}
                  contentIndex={contentIndex}
                  type="page"
                  linkName={t("Platform pagina's")}
                  name={content.title}
                  saveAction="save_platform_page"
                  action={false}
                  url={"platformpages"}
               />
               :
               <LoadScreen />
         }
      </>
   )
}

export default PlatformPagesEdit