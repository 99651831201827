import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "../navBar";
import Dashboard from "../dashboard/admin";
import InterventionEdit from "../intervention/edit.js";
import TranslationEdit from "../translations/edit.js";
import InterventionStats from "../stats/";
import Registrations from "../registrations/";
import InterventionUsers from "../students/overview.js";
import Coaches from "../coaches/coachesadmin.js";
import CoachesOverview from "../coaches/overview.js";
import Emails from "../emails/";
import InterventionWaitinglist from "../waitinglist/";
import InterventionData from "../data/";
import Monitor from "../monitor/";
//import Questionnaires from "../questionnaires";
import QuestionnaireEdit from "../questionnaires/edit.js";
//import Lessons from "../lessons";
import LessonEdit from "../lessons/edit.js";
import GoalEdit from "../goal/edit.js";
import PageEdit from "../pages/edit.js";
import LessonJson from "../lessons/json.js";
import Platform from "../platform/index.js";
import PlatformTranslations from "../translations/platform.js";
import PlatformPagesEdit from "../pages/platform/edit.js";
import PlatformQuestionnairesEdit from "../questionnaires/platform/edit.js";
import RegistrationTexts from "../registrationtexts";
// import AsAdmin from "../dashboard/superuser/asadmin";
// import Users from "../users";
// import Organisations from "../organisations";

/// TODO om het interventieobject gelijk te krijgen in de verschillende edit bestanden moet die hier gezet worden anders kun je niet hard reloaden <= todo na 1 mei

const RouterAdmin = () => {

  const auth = useSelector(state => state.auth);

  return (
    <Router>
      <NavBar />
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route path="/intervention/edit/">
          {
            (typeof auth.rights.config_access !== 'undefined' && auth.rights.config_access) ? <InterventionEdit /> : <Dashboard />
          }
        </Route>
        <Route path="/coaches/">
          {
            (typeof auth.rights.config_access !== 'undefined' && auth.rights.coaches_access) ? <Coaches /> : <Dashboard />
          }
        </Route>
        <Route path="/coachesoverview/">
          {
            (typeof auth.rights.config_access !== 'undefined' && auth.rights.coaches_access) ? <CoachesOverview /> : <Dashboard />
          }
        </Route>
        <Route path="/emails/">
          {
            (typeof auth.rights.data_access !== 'undefined' && auth.rights.data_access) ? <Emails /> : <Dashboard />
          }
        </Route>
        <Route path="/intervention/waitinglist/">
          <InterventionWaitinglist />
        </Route>
        <Route path="/intervention/data/">
          {
            (typeof auth.rights.config_access !== 'undefined' && auth.rights.data_access) ? <InterventionData /> : <Dashboard />
          }
        </Route>
        <Route path="/intervention/stats-all/">
          <Dashboard />
        </Route>
        <Route path="/intervention/stats/:intervention_id">
          <InterventionStats />
        </Route>
        <Route path="/intervention/users/">
          <InterventionUsers />
        </Route>
        <Route path="/intervention/monitor/">
          <Monitor />
        </Route>
        {/*
          <Route path="/questionnaires/">
            <Questionnaires />
          </Route>
          <Route path="/lessons/">
            <Lessons />
          </Route>
        */}
        <Route path="/questionnaire/edit/">
          <QuestionnaireEdit action="edit" />
        </Route>
        <Route path="/questionnaire/edit-scores/">
          <QuestionnaireEdit action="editScores"/>
        </Route>

        <Route path="/goal/edit">
          <GoalEdit />
        </Route>

        <Route path="/page/edit">
          <PageEdit />
        </Route>

        <Route path="/lesson/edit">
          <LessonEdit />
        </Route>
        <Route path="/lesson/json">
          <LessonJson />
        </Route>

        <Route path="/translation/edit">
          <TranslationEdit />
        </Route>

        <Route path="/unfinished-registrations/">
          <Registrations />
        </Route>
        <Route path="/platformpages/edit/:page_id?"> {/* wijzigen programma overstijgende pagina */}
          <PlatformPagesEdit />
        </Route>
        <Route path="/platformquestionnaires/edit/:questionnaire_id?"> {/* wijzigen programma overstijgende vragenlijst */}
          <PlatformQuestionnairesEdit />
        </Route>
        <Route path="/platform-translations/">
          <PlatformTranslations />
        </Route>
        <Route path="/platform/">
          <Platform />
        </Route>
        <Route path="/registration-texts">
          <RegistrationTexts />
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>

        {/* <Route path="/asAdmin" exact>
          <AsAdmin />
        </Route>
        <Route path="/users" exact>
          <Users />
        </Route>
        <Route path="/organisations" exact>
          <Organisations />
        </Route> */}
      </Switch>
    </Router>
  );
};

export default RouterAdmin;
