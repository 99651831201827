import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../registration/panels/header';
import Footer from '../registration/panels/footer';
import QuestionnaireContent from './questionnaire_content';
import apiCall from '../api';
import { setQuestionnaire, setAnswersLessons, setUiTranslation, setIntervention } from "../../actions";
import t from '../translate';
import BadgeOverlay from "../rewards/badge.js";
import ShowPoints from "../rewards/points.js";
import thumbsup from '../../images/thumbsup.png';

const Confirmation = () => {

  const dispatch = useDispatch();
  const location = useLocation();


  const [ic, setIc] = useState('');
  const [dtc, setDtc] = useState('');
  const [weeknr, setWeeknr] = useState('');
  const [t1, setT1] = useState(false);
  const [language_id, setLanguageId] = useState(1); //default dutch
  const [intervention_id, setInterventionId] = useState(0);
  const [questionnaire_id, setQuestionnaireId] = useState(0);


  const [showLoading, setShowLoading] = useState(true);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [showWrongParams, setShowWrongParams] = useState(false);
  const [allreadyFinished, setShowAllreadyFinished] = useState(false);
  const [showFinished, setShowFinished] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [showReward, setShowReward] = useState([]);///rewards to show
  const [showPoints, setShowPoints] = useState(false);
  const [showData, setShowData] = useState(false);///data to show if not false badge will be shown
  const [studentBegeleiding, setStudentBegeleiding] = useState(false);
  //const [dateTimeStartedIntervention, setDateTimeStartedIntervention] = useState(false);///data to show if not false badge will be shown
  const [dateUserAccountCreated, setDateUserAccountCreated] = useState('0000-00-00')

  function closeShownPoints(){
    apiCall({
      action: "update_points_are_shown_questionnaire",
      data:{
        ic: ic,
        dtc: dtc
      }
    }).then(resp => {
      setRewards(resp.rewards)
      setShowPoints(false)
    });
  }
  function closeRewards(){
    apiCall({
      action: "update_reward_is_shown_questionnaire",
      data:{
        ic: ic,
        dtc: dtc
      }
    }).then(resp => {
      setRewards(resp.rewards)
      setShowOverlay(false)
    });
  }

  useEffect(() => {

    if (location.search.substr(0,1) === '?')
    {
      let par = location.search.substr(1).split("&");
      if (par.length > 1)
      {
        let invite_code = par[0].split("=")[1];
        let date_time_create = par[1].split("=")[1];

        setIc(invite_code);
        setDtc(date_time_create);

        apiCall({
          action: "get_questionnaire_on_invite",
          data: {
            ic: invite_code,
            dtc: date_time_create
          }
        }).then(resp => {
          if (parseInt(resp.questionnaireFound) === 0)
          {
            setShowLoading(false);
            setShowWrongParams(true);
          }
          else if (parseInt(resp.questionnaireFinished) === 1)
          {
            if (parseInt(resp.language_id) !== 1) {
              dispatch(
                setUiTranslation(
                  resp.language_id,
                  resp.ui_translation)
              )
            }
            setShowLoading(false);
            setShowAllreadyFinished(true);
          }
          else
          {
            //questionnaire laden...
            setInterventionId(resp.intervention_id);
            setLanguageId(resp.language_id);
            setQuestionnaireId(resp.questionnaire_id);
            setWeeknr(resp.weeknr);
            setStudentBegeleiding(resp.studentBegeleiding)
            setT1(resp.t1)

            if (parseInt(resp.language_id) !== 1)
            {
              dispatch(
                setUiTranslation(
                  resp.language_id,
                  resp.ui_translation)
              )
            }

            dispatch(
              setQuestionnaire(
                resp.id,
                resp.questionnaires,
              )
            )

            dispatch(
              setAnswersLessons(
                resp.intervention_id,
                resp.answers
                )
            )

            dispatch(
              setIntervention(
                resp.intervention_id,
                resp.organisation_id,
                resp.title,
                resp.settings
                )
            )

            setRewards(resp.rewards);

            setShowLoading(false);
            setShowQuestionnaire(true);
          }

          setDateUserAccountCreated(resp.dateUserAccountCreated);
        });
      }
      else
      {
        setShowLoading(false);
        setShowWrongParams(true);
      }
    }
    else
    {
      setShowLoading(false);
      setShowWrongParams(true);
    }

  }, [location])

  const setFinished = () => {
    setShowQuestionnaire(false);
    setShowFinished(true);
  }

  /// check of badges aan staan en of de datum van start indien gezet later is
  const [badgesActive, setBadgesActive] = useState(false);
  const intervention = useSelector(state => state.intervention);

  useEffect(() => {
    //checken of gamification actief is voor programma en deze specifieke gebruiker
    if(typeof intervention.settings.gamification !== "undefined" && intervention.settings.gamification.badges) {
      if(typeof intervention.settings.gamification.badgesFromDateActive !== "undefined" && typeof intervention.settings.gamification.badgesFromDate !== "undefined" && intervention.settings.gamification.badgesFromDateActive) {
        if(typeof intervention.settings.gamification.badgesFromDate[0] !== "undefined" && intervention.settings.gamification.badgesFromDate[0] !== "") {
          //er is een datum vanaf ingesteld voor de gamification, alleen accounts die na deze datum zijn aangemaakt
          //krijgen deze optie te zien.
          if (dateUserAccountCreated > intervention.settings.gamification.badgesFromDate[0].substring(0,10)) {
            setBadgesActive(true);
          }
        }
      } else {
        setBadgesActive(true);
      }
    }
  }, [intervention, dateUserAccountCreated]);

  return (

    <div className="questionnaireContainer">

      <Header loginbutton={false} />

      <div className="container lessoncontent front">

        <div className={showLoading ? '' : 'hidden'}>
          {t("One moment, the questionnaire is being loaded...")}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>

        <div className={showWrongParams ? '' : 'hidden'}>
          {t("Unfortunately, the questionnaire could not be retrieved.")}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>

        <div className={allreadyFinished ? '' : 'hidden'}>
          {t("Je hebt deze vragenlijst al eerder ingevuld en afgerond.")}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>

        <div className={showQuestionnaire ? '' : 'hidden'}>
          {
            showQuestionnaire ?
              <QuestionnaireContent  intervention_id={intervention_id} language_id={language_id} questionnaire_id={questionnaire_id} weeknr={weeknr} ic={ic} dtc={dtc} setFinished={setFinished} setRewards={setRewards} />
              :
              <></>
          }
        </div>

        <div className={showFinished ? '' : 'hidden'} style={{textAlign: 'center'}}>
          <img src={thumbsup} style={{display: 'inline-block', width: '300px', maxWidth: '90%', marginBottom: '20px'}} />
          <div>
            <strong>{t("Bedankt voor het invullen van de vragenlijst!")}<br /></strong>
            <br />
            {
              studentBegeleiding ? 
                <>{" " + t("Je ontvangt binnen enkele minuten een email waarmee je in kunt loggen om het programma te volgen.")}</>
                : 
                t1 ?
                <>{t("Weten hoe het is met je mentale welzijn?")} <a href="/">{t("Log dan in op het platform")}</a> {t("en klik op 'Jouw scores' in het menu om de terugkoppeling te lezen.")}</> : <></>
            }
          </div>
          <br /><br />
        </div>


      </div>

      <Footer language={0} />

      {
        badgesActive  ?
          <>
            <ShowPoints rewards={rewards} showData={false} closeShownPoints={closeShownPoints} showPoints={showPoints} setShowPoints={setShowPoints}/>
            {
              !showQuestionnaire && showOverlay ?
                <>
                  <BadgeOverlay rewards={rewards} showReward={showReward} showData={showData} setShowData={setShowData} closeRewards={closeRewards} setShowReward={setShowReward}/>
                </>
              :
                <>
                </>
            }
          </>
        :
          <></>
      }

    </div>
  )
}

export default Confirmation;