import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_user_preferences, setFinishedCourse } from "../../../actions";
import apiCall from "../../api";
import t from "../../translate";
import $ from "jquery";
import { checkNestedProperties } from "../../utils";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
 
const SsiModuleSelector = forwardRef((props, ref) => {

   const dispatch = useDispatch();
   
   const [errorMessage, setErrorMessage] = useState("");
   const auth = useSelector(state => state.auth);
   const intervention = useSelector(state => state.intervention);
   const [preferences, setPreferences] = useState([]);
   const [ssiCategory, setSsiCategory] = useState(false);
   const [ssiLesson, setSsiLesson] = useState(false);
   const [ssiHasCategories, setSsiHasCategories] = useState(false);
   //tbv van loop
   let lastCategory = 0;

   useEffect(() => {
     //bevat het programma uberhaupt categorieen?
     let hasCategories = false;
     props.ssiLessonList.forEach((lesson, index) => {
         if (lesson.title.length > 8 && lesson.title.substr(0, 9).toLowerCase() === '[heading]') {
            setSsiHasCategories(true);
         }
      });
   }, [props.ssiLessonList])

   useEffect(() => {
      let newModalState = {...props.modalState};
      if (ssiCategory) { //means that cat is set allready
         newModalState.cancellable = true;
         newModalState.componentData.closeModal = () => {
            setSsiCategory(false);
            setSsiLesson(false);
            let newModalState = {...props.modalState};
            //newModalState.cancellable = false;
            newModalState.submitable = false;
            props.setModalState(newModalState);
         } 
      } else {
         //ssi subject
         if (props.ssiActiveLessons.length > 0) {
            newModalState.cancellable = true;
            newModalState.componentData.closeModal = () => {
            let newModalState = {...props.modalState};
            newModalState.ssiShowSelector = 'part2';
            newModalState.cancellable = false;
            props.setModalState(newModalState);
            }
         } else {
            newModalState.cancellable = false;
         }
      }
      props.setModalState(newModalState);
   }, [ssiCategory])

   useEffect(() => {
      let newModalState = {...props.modalState};
      if (ssiLesson) {
         newModalState.submitable = true;
      } else {
         newModalState.submitable = false;
         if (props.ssiActiveLessons.length < 1) {
            newModalState.cancellable = false;
         }
      }

      props.setModalState(newModalState);
   }, [ssiLesson])
   
   //deze functie wordt aangeroepen door button in component Modal -> ref functie koppelt
   //componenten aan elkaar
   useImperativeHandle(ref, () => ({
     submitHandler() {

      let newPreferences = [...auth.preferences];
      let foundSsiPreference = false;
      newPreferences.forEach((pref, index) => {
         if (pref.option === 'ssi') {
            foundSsiPreference = true;
            newPreferences[index].value.push(ssiLesson);
         }
      })
       if (!foundSsiPreference) {
         newPreferences.push({
           option: 'ssi',
           value: [ssiLesson]
         })
       }
      saveUserPreferences(newPreferences);
     }
   }));

   const finishProgram = (finish) => {
      if (finish) {
         let newPreferences = [...auth.preferences];
         if (!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) {
            newPreferences.forEach((pref, index) => {
               if (pref.option === 'ssi') {
                  newPreferences[index].value.push('ssi_'+props.intervention_id+'_programfinished');
               }
            })
         } else {
            //flipcard menu: de preference ssi bestaat nog niet, dus aanmaken
            newPreferences.push({
               option: 'ssi',
               value: ['ssi_'+props.intervention_id+'_programfinished']
            })
         }
         saveUserPreferences(newPreferences);
         //modal uitzetten
         props.closeModal('');
         //confettie aanzetten
         dispatch(setFinishedCourse(true));
         //action ivm eventueel verzenden mail programma afgerond
         apiCall({
            action: "send_mail_program_finished",
            token: auth.token,
            data: {
              intervention_id: props.intervention_id,
            }
          })
      } else {
         //part 1 tonen indien geen flipmenu
         if (!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) {
            let newModalState = {...props.modalState};
            newModalState.ssiShowSelector = 'part1';
            if (props.ssiActiveLessons.length > 0) {
               //indien keuze voor tweede les, dan mogelijkheid om terug te gaan naar part 2
               newModalState.cancellable = true;
               newModalState.componentData.closeModal = () => {
                  setSsiCategory(false);
                  setSsiLesson(false);
                  let newModalState = {...props.modalState};
                  newModalState.ssiShowSelector = 'part2';
                  newModalState.cancellable = false;
                  props.setModalState(newModalState);
               }
            }
            props.setModalState(newModalState);
         } else {
            $("#ssi_module_selector").modal("toggle");
            props.setSsiShowModuleSelector(false);
         }
      }
   }

   const saveUserPreferences = (newPreferences) => {
      apiCall({
         action: "save_user_preferences",
         token: auth.token,
         data: {
           preferences: newPreferences
         }
       }).then(resp => {
         if (resp.save_user_preferences) {
           //props.closeModal(resp.msg);
           //prefs vastleggen in redux: auth
           dispatch(set_user_preferences(newPreferences));
           //modal sluiten
           $("#ssi_module_selector").modal("toggle");
           props.setSsiShowModuleSelector(false);
         } else {
           setErrorMessage(resp.msg);
         }
       });
   }

   return (
     <div>
       <div
         className={errorMessage.length < 1 ? "hidden" : "alert alert-danger"}
         role="alert"
       >
         <i className="fas fa-exclamation-circle"></i> &nbsp;
         <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
       </div>
       <div>
         
         {
           props.modalState.ssiShowSelector === 'part1' ?
            <div className="ssi-profile">
               <span dangerouslySetInnerHTML={{__html: intervention.settings.selfhelp.ssi_texts.intro_modal}}></span><br />
               {  
                  ssiCategory ?
                     <strong><span dangerouslySetInnerHTML={{__html: intervention.settings.selfhelp.ssi_texts.heading_lesson}}></span><br /></strong>
                  :
                     <strong><span dangerouslySetInnerHTML={{__html: intervention.settings.selfhelp.ssi_texts.heading_category}}></span><br /></strong>
               }
               {
                 props.ssiLessonList.map((lesson, index) => {
                   //categorieen tonen?
                   if (lesson.title.length > 8 && lesson.title.substr(0, 9).toLowerCase() === '[heading]') {
                     lastCategory = lesson.id;
                     if (!ssiCategory) {
                        return (
                           <div key={index} className="ssi-profile-lesson">
                              <input className="form-check-input" type="radio" name={'ssi_'+props.intervention_id} id={'ssi_'+props.intervention_id+'_'+lesson.id} onClick={e => setSsiCategory(lesson.id)} /> <label className="form-check-label ssi-profile-label" htmlFor={'ssi_'+props.intervention_id+'_'+lesson.id}> <span dangerouslySetInnerHTML={{__html: lesson.title.substr(9)}} /></label><br />
                          </div>
                        ) } else {
                           return null;
                        }
                   } else {
                     if (((ssiCategory && lastCategory === ssiCategory) || !ssiHasCategories) && !props.ssiActiveLessons.includes(lesson.id)) {
                     return (
                       <div key={index} className="ssi-profile-lesson">
                         <input className="form-check-input" type="radio" name={'ssi_'+props.intervention_id} id={'ssi_'+props.intervention_id+'_'+lesson.id} onClick={e => setSsiLesson('ssi_'+props.intervention_id+'_'+lesson.id)}  /> <label className="form-check-label ssi-profile-label" htmlFor={'ssi_'+props.intervention_id+'_'+lesson.id}> <span dangerouslySetInnerHTML={{__html: lesson.title + ((checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcardtexts', lesson.id) && intervention.settings.selfhelp.alternative_menu_flipcardtexts[lesson.id].length > 0) ? ': ' + intervention.settings.selfhelp.alternative_menu_flipcardtexts[lesson.id] : '')}} /> </label> <br />
                       </div>
                     )
                   } else {
                     return null;
                     }
                  }
                 })
               }
            </div> 
            :
            <div className="ssi-profile">
               <span dangerouslySetInnerHTML={{__html: intervention.settings.selfhelp.ssi_texts.finish_program}}></span>
               <br />
               <center>
                  <button type="button" className="btn btn-ssi-select" onClick={() => finishProgram(true)}>{t("Rond programma af")}</button>
                  &nbsp;
                  <button type="button" className="btn btn-ssi-select" onClick={() => finishProgram(false)}>{t("Kies andere module")}</button>
               </center>
            </div>
         }

      </div>
     </div>
   );
 });
 
 export default SsiModuleSelector;