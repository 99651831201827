import React from "react";
import t from "../translate";
import "./loadScreen.css"

const LoadScreen = () => {
  return(
    <div className="loadScreen">
      {/* <img src="https://media2.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"/> */}
      

      <div className="spinner-container">
        <div className="smt-spinner-circle">
         <div className="smt-spinner"></div>
        </div>
      </div>
    </div>
  )
}

export default LoadScreen;
