const text_14 = {
  intervention:14,
  description_short:"Ga gevoelens van lusteloosheid, verdriet of depressie tegen en verbeter je stemming.",
  step_2_title:"Dus je wilt je stemming verbeteren? Je bent aan het juiste adres!",
  description_part1:"Moodpep is een zelf-hulpprogramma waarin je leert je stemming te verbeteren. Dit programma is gebaseerd op Cognitieve Gedragstherapie en bestaat uit leesmateriaal en interactieve oefeningen. De onderwerpen die aan bod komen zijn: het starten van nieuwe activiteiten, het leren om je effectiever te ontspannen, het veranderen van negatieve gedachten en het werken aan je eigen, specifieke doelen. Tijdens het programma krijg je ondersteuning van een online coach (geheel gratis) die tekstuele feedback geeft op de interactieve opdrachten en je algehele voortgang.",
  description_part2:"Het programma bestaat uit 7 modules die elk tussen 30 en 60 minuten duren. We raden aan 1 module per week te doen gedurende 7 weken.",
  description_part3:"Als je dit programma wilt volgen, lees de onderstaande informatie dan zorgvuldig door:",
}

export {text_14};
