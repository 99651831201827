import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../api";
//import Dashboard from "../dashboard/student";
import Course from "../course";
import Courses from "../courses";
import QuestionnaireFillOut from "../questionnaires/fillOut.js";
import { setActiveIntervention, setIntervention, setAnswersLessons, setPageHistory, setUiTranslation, setActiveLanguage } from "../../actions";
import theme2CSS from '../../styles/themes/2/index.scss';
import baseThemeCSS from '../../styles/themes/base/index.scss';
import LoadScreen from "../loadScreen";
import Baseline from "../questionnaires/baseline"

const RouterStudent = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [themeId, setThemeId] = useState(false)

  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);
  const intervention = useSelector(state => state.intervention);
  const [showLoadScreen, setShowLoadScreen] = useState(true)
  //const answers = useSelector(state => state.answersLessons);
  //let location = useLocation(); //current url

  const checkRightsIntervention = () => {
    //nog niet bekend welke cursus geladen moet worden?
    if (activeIntervention === 0)
    {
      if (auth.rights.interventions.length === 1)
      {
        //activeIntervention vastleggen in redux store
        dispatch(
          setActiveIntervention(
            auth.rights.interventions[0].id
          )
        );
        history.push("/course/"+auth.rights.interventions[0].id+"/lessons")
      }
      else
      {
        //cursist heeft rechten voor meerdere cursussen, naar keuzescherm
        return <Redirect to="/courses/" />;
      }
    }
  }

  useEffect(() => {
    /// zet het theme pas als als de interventie geladen is <= voorkomt een eerste glimp van het basis thema
    if(intervention.id > 0){
      let themeId = 1; /// als niks gezet dan het basis thema
      if(intervention.settings.themeId){
        themeId = intervention.settings.themeId;
      }
      setThemeId(themeId)
    }
    //reload van een pagina? Dan o.b.v. url de intervention bepalen
    let querystring = window.location.href.split("/");
    if (querystring.length > 4 && querystring[4].length > 0)
    {
      if (!isNaN(querystring[4]))
      {
        if (activeIntervention !== parseInt(querystring[4]))
        {
          let hasRights = auth.rights.interventions.find((interv) => {
            return parseInt(interv.id) === parseInt(querystring[4])
          });
          if (hasRights)
          {
            dispatch(
              setActiveIntervention(
                parseInt(querystring[4])
              )
            );
          }
        }
      }
      else
      {
        //ongeldige intervention_id in URL
        checkRightsIntervention();
      }
    }
    else
    {
      checkRightsIntervention();
    }
  }, [window.location.href, intervention])

  useEffect(() => {

    //bij laden language preference ophalen
    if (parseInt(activeIntervention) !== 0 && auth.user_id !== 0)
    {
      let language_id_pref = auth.preferences.find(pref => {
        return pref.option === 'language_id'
      })
      if (typeof language_id_pref === 'undefined')
      {
        language_id_pref = 1; //dutch
      }
      dispatch(setActiveLanguage(parseInt(language_id_pref.value)));

      //settings van de interventie ophalen en in de store plaatsen
      //plus antwoorden van de student ophalen voor deze interventie
      if (intervention.id !== activeIntervention)
      {
        //interventie settings ophalen en in redux store plaatsen
        apiCall({
          action: "get_intervention_settings",
          token: auth.token,
          data: {
            intervention_id: activeIntervention,
            include_forms: true, //tijdelijk...
            language_id: language_id_pref.value
          }
        }).then(resp => {
          dispatch(setUiTranslation(language_id_pref.value, resp.settings.ui_translation));
          dispatch(
            setIntervention(
              activeIntervention,
              resp.organisation_id,
              resp.title,
              resp.settings
            )
          );
        });
        //antwoorden ophalen en in redux store plaatsen
        apiCall({
          action: "get_course_answers",
          token: auth.token,
          data: {
            id: activeIntervention
          }
        }).then(resp => {
          dispatch(
            setAnswersLessons(
              activeIntervention,
              resp.answers
              )
          )
          dispatch(
            setPageHistory(
              activeIntervention,
              resp.pageHistory
              )
          )
          setShowLoadScreen(false)
        });
      }
    }
  }, [activeIntervention, auth]);


  function getTheme(){
    /// alleen iets zetten als duidelijk is welk thema geladen moet worden
    if(themeId){
      if(themeId > 1){
        return(<link rel="stylesheet" type="text/css" href={theme2CSS} />)
      } else {
        return(<link rel="stylesheet" type="text/css" href={baseThemeCSS} />)
      }
    }
  }


  return (
    <>
    {
      showLoadScreen ? <LoadScreen /> :
        <div className={"base_theme" + ' theme_'+themeId}>
          {getTheme()}
          <Router>
            <Switch>
              <Route path="/" exact>
                <Course />
              </Route>
              <Route path="/courses">
                <Courses />
              </Route>
              <Route path="/course">
                <Course />
              </Route>
              <Route path="/questionnaire">
                <QuestionnaireFillOut />
              </Route>
              <Route path="/baseline/">
                <Baseline />
              </Route>
              <Route path="*" exact>
              <Redirect to="/" />
            </Route>
            </Switch>
          </Router>
        </div>
    }
    </>
  )
}

export default RouterStudent;
