import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import t from "../../translate";
import apiCall from "../../api";
import parse from 'html-react-parser';
import { getClone } from "../../utils"

const Interventions = (props) => {

  const auth = useSelector(state => state.auth);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [coach, setCoach] = useState(false);
  const [interventions, setInterventions] = useState(false);

  useEffect(() => {
    if(props.coach){
      setCoach(props.coach)
    }
    if(props.interventions){
      setInterventions(props.interventions)
    }
  },[props])

  const onChange = e => {

    const newCoach = {...coach};
    if (e.target.name.substr(0, 7) === 'isCoach') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));

      if (typeof newCoach.rights.interventions !== 'undefined') {
        let intervention_key = -1;
        for(let i=0;i < newCoach.rights.interventions.length;i++) {
          if (parseInt(newCoach.rights.interventions[i].id) === intervention_id) {
            intervention_key = i;
            break;
          }
        }
        if (intervention_key > -1) { //remove
          //zijn er studenten gekoppeld, dan kan de coaching niet uitgezet worden
          if (newCoach.rights.interventions[intervention_key].hasStudents) {
            setErrorMessage(t("Er zijn voor dit programma nog studenten ("+newCoach.rights.interventions[intervention_key].hasStudents+") gekoppeld aan deze coach, u kunt deze daarom niet verwijderen."))
            return false;
          } else {
            //is deze coach soms supervisor, en is het de enige of hangen er no coaches onder?
            if (newCoach.rights.interventions[intervention_key].isSupervisor) {
              //hasCoaches???
              let hasCoaches = false;
              let otherSupervisors = false;
              props.state.users.map(coach => {
                if (typeof coach.rights !== 'undefined' && typeof coach.rights.interventions !== 'undefined') {
                  coach.rights.interventions.map(interv => {
                    if (parseInt(interv.id) === parseInt(intervention_id)) {
                      if (interv.isSupervisor) {
                        otherSupervisors = true;
                      } else {
                        if (parseInt(interv.hasSupervisor) === parseInt(newCoach.id)) {
                          hasCoaches = true
                        }
                      }
                    }
                  })
                }
              })
              if (hasCoaches) {
                setErrorMessage(t("Er zijn nog coaches gekoppeld aan deze supervisor, u kunt deze daarom niet verwijderen."))
                return false;
              } else if (!otherSupervisors) {
                setErrorMessage(t("Deze coach is de enige supervisor voor dit programma, u kunt deze daarom niet verwijderen."))
                return false;
              } else {
                //wel supervisor, maar er hangen geen coaches onder en is ook niet de enige supervisor
                newCoach.rights.interventions.splice(intervention_key, 1)
              }
            } else {
              //geen supervisor en geen gekoppelde studenten...
              newCoach.rights.interventions.splice(intervention_key, 1)
            }
          }
        } else { //add
          newCoach.rights.interventions.push({
            id: intervention_id,
            isSupervisor: false,
            hasSupervisor: 0,
            /* max_students: 0,
            bio: '',
            bioTranslations: [],
            languages: [], */
            hasStudents: false
          })
        }
      } else {
        newCoach.rights.interventions = [{
          id: intervention_id,
          isSupervisor: false,
          hasSupervisor: 0,
          /* max_students: 0,
          bio: '',
          bioTranslations: [],
          languages: [], */
          hasStudents: false
        }]
      }
    } else  if (e.target.name.substr(0, 12) === 'isSupervisor') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      for(let i=0;i < newCoach.rights.interventions.length;i++) {
        if (parseInt(newCoach.rights.interventions[i].id) === intervention_id) {
          if (newCoach.rights.interventions[i].isSupervisor) {
            //hasCoaches???
            let hasCoaches = false;
            let otherSupervisors = false;
            props.state.users.map(coach => {
              if (typeof coach.rights !== 'undefined' && typeof coach.rights.interventions !== 'undefined') {
                coach.rights.interventions.map(interv => {
                  if (parseInt(interv.id) === parseInt(intervention_id)) {
                    if (interv.isSupervisor) {
                      otherSupervisors = true;
                    } else {
                      if (parseInt(interv.hasSupervisor) === parseInt(newCoach.id)) {
                        hasCoaches = true
                      }
                    }
                  }
                })
              }
            })
            if (hasCoaches) {
              setErrorMessage(t("Er zijn nog coaches gekoppeld aan deze supervisor, u kunt deze daarom niet verwijderen."))
              return false;
            } else if (!otherSupervisors) {
              setErrorMessage(t("Deze coach is de enige supervisor voor dit programma, u kunt deze daarom niet verwijderen."))
              return false;
            } else {
              //wel supervisor, maar er hangen geen coaches onder en is ook niet de enige supervisor
              newCoach.rights.interventions[i].isSupervisor = false;
              newCoach.rights.interventions[i].hasSupervisor = 0;
            }
          } else {
            newCoach.rights.interventions[i].isSupervisor = true;
            newCoach.rights.interventions[i].hasSupervisor = 0;
          }
          break;
        }
      }
    } else if (e.target.name.substr(0,13) === 'hasSupervisor') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      for(let i=0;i < newCoach.rights.interventions.length;i++) {
        if (parseInt(newCoach.rights.interventions[i].id) === intervention_id) {
          newCoach.rights.interventions[i].hasSupervisor = e.target.value
          break;
        }
      }
    } /* else if (e.target.name.substr(0, 11) === 'maxStudents') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      for(let i=0;i < newCoach.rights.interventions.length;i++) {
        if (parseInt(newCoach.rights.interventions[i].id) === intervention_id) {
          newCoach.rights.interventions[i].max_students = e.target.value
          break;
        }
      }
    } */ else if (e.target.name.substr(0, 4) === 'lang') {
      /* let intervention_id = parseInt(e.target.getAttribute('int_id')); */
      let language_code = e.target.getAttribute('lang_code');
      /* for(let i=0;i < newCoach.rights.interventions.length;i++) {
        if (parseInt(newCoach.rights.interventions[i].id) === intervention_id) { */
          if(newCoach.rights.languages.includes(language_code)) {
            let indexOfLanguage = newCoach.rights.languages.indexOf(language_code);
            newCoach.rights.languages.splice(indexOfLanguage, 1);
          } else {
            newCoach.rights.languages.push(language_code);
          }
          /* break;
        }
      }  */
    } else if(e.target.name.substr(0, 3) === 'bio') {
      let language_code = e.target.getAttribute('lang_code');
      if (typeof newCoach.rights.bioTranslations.find(bio => bio.code === language_code) !== 'undefined') {
        newCoach.rights.bioTranslations.find(bio => bio.code === language_code).content = e.target.value
      } else {
        newCoach.rights.bioTranslations.push({
          code: language_code,
          content: e.target.value
        })
      }
    } else if(e.target.name === 'max_students') {
      newCoach.rights.max_students = e.target.value;
    } else if(e.target.name === 'bio') {
      newCoach.rights.bio = e.target.value;
    } else {
      newCoach[e.target.name] = e.target.value;
    }
    setCoach(newCoach);
    setErrorMessage("");
  };

  function saveChanges(){

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: coach
      }
    }).then(resp => {

      if(props.setState){
        const stateN = getClone(props.state);
        for(let i = 0 ; i < stateN.users.length ; i++){
          if (stateN.users[i].id === coach.id){
            stateN.users[i] = coach;
            break;
          }
        }
        props.setState(stateN) /// dit nog oplossen
      }

      setMessage(t("Gegevens zijn gewijzigd"))
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }

  return (
    <div className="interventions_selection">
    {
      errorMessage.length > 0 ?
        <div className="error">
          <i className="fas fa-exclamation-circle"></i> &nbsp;
        <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </div> : <></>
    }
    { coach ?
      <>
      <table classname={message != "" ? 'saved':''}>
      <thead>
      <tr>
      <th>
        {t('Interventie')}
      </th>
      <th>
        {t('Supervisor')}
      </th>
      <th>
        {t('Valt onder')}
      </th>
      </tr>
      </thead>
      <tbody>
        {interventions.map(int => {

          let isCoach = coach.rights.interventions.find(interv => parseInt(interv.id) === parseInt(int.id));
          let isSupervisor = false;
          let hasSupervisor = 0;
          /* let maxStudents = 0;
          let bio = '';
          let bioTranslations = [];
          let languages = []; */
          if (typeof isCoach !== 'undefined') {
            isSupervisor = isCoach.isSupervisor;
            hasSupervisor = isCoach.hasSupervisor;
            /* maxStudents = isCoach.max_students;
            bio = isCoach.bio;
            bioTranslations = isCoach.bioTranslations;
            languages = isCoach.languages;
            if (typeof languages === 'undefined') {
              languages = []
            } */
          }


          return (
            <tr key={'int'+int.id}>
              <td>
                <input className="form-check-input" type="checkbox" name={'isCoach'+int.id + '_' + coach.id} id={'isCoach'+int.id + '_' + coach.id} int_id={int.id} onChange={e => onChange(e)} checked={(typeof isCoach !== 'undefined') ? true : false} />
                <label className="form-check-label" htmlFor={'isCoach'+int.id + '_' + coach.id} dangerouslySetInnerHTML={{__html: parse(int.title)}}></label>
                {isCoach ?
                  <>
                  ({(isCoach.hasStudents > 0) ? isCoach.hasStudents:0}/{(coach.rights.max_students) != '' ? coach.rights.max_students:0})
                  </>
                  :false}
              </td>
              <td>
              {
              typeof isCoach !== 'undefined' ?
                <>
                <input className="form-check-input" type="checkbox" name={'isSupervisor'+int.id + '_' + coach.id} id={'isSupervisor'+int.id + '_' + coach.id} int_id={int.id} onChange={e => onChange(e)} checked={isSupervisor} /> <label className="form-check-label" htmlFor={'isSupervisor'+int.id + '_' + coach.id}> </label>
                </>
                 : false
              }
              </td>
              <td>
              {
              typeof isCoach !== 'undefined' ?
                <>
                  {
                    !isSupervisor ?
                      <>
                        <select
                          id={'hasSupervisor'+int.id}
                          name={'hasSupervisor'+int.id}
                          int_id={int.id}
                          value={hasSupervisor}
                          onChange={onChange}
                          className="form-control"
                        >
                          <option value="0"> --- {t("Selecteer een supervisor")} --- </option>
                          {typeof props.state.users != "undefined" && props.state.users.map(coach => {
                            //bepalen of deze coach supervisor is voor dit programma
                            let coachIsSupervisor = false
                            coach.rights.interventions.map(interv => {
                              if (parseInt(interv.id) === parseInt(int.id) && interv.isSupervisor) {
                                coachIsSupervisor = true;
                              }
                            })
                            if (coachIsSupervisor) {
                              return (
                                <option value={coach.id} key={'int'+int.id+'coach'+coach.id}>
                                  {coach.firstname} {coach.insertion}{" "}
                                  {coach.lastname}
                                </option>
                              )
                            }
                          })}
                        </select>
                      </> : <></>
                  }
                </> : false
              }
              </td>
            </tr>
          )
        })
      }
      </tbody>
      </table>
      <br/>
      <label htmlFor={"max_students_" + +coach.id}><b>{t("Begeleidt max. aantal deelnemers")}</b></label>
      <input type="number" min="0" max="999" step="1" className="form-control" name="max_students" id={"max_students_" + +coach.id} value={coach.rights.max_students} onChange={e => onChange(e)} />
      <br/>
      </>
      :false}

    <div className="align_right">
      {message ?
        <span className="btn btn-primary btn-success">
          {message} <i class="fa fa-check"></i>
        </span>
        :
        <span className="btn btn-primary" onClick={()=>saveChanges()}>{t("Opslaan")}</span>
      }
    </div>
    </div>
  )
}
export default Interventions;
