import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import t from "../../translate";
import { getClone } from "../../utils"
import { ConvertTimestamp } from "../../helpers/convertTimestamp.js"
import SortObjectArray from "../../helpers/sortObjectArray.js";
import moment from "moment";
import parse from 'html-react-parser';
import apiCall from "../../api";
import Pagination from "../../helpers/pagination.js";
import ReactTooltip from 'react-tooltip';

const StudentsAll = (props) => {

  const [studentsOnPage, setStudentsOnPage] = useState([])
  const [students, setStudents] = useState([])
  const [studentsAll, setStudentsAll] = useState([])
  const [loaded, setLoaded] = useState(false)
  const paginationStep = 20;
  const [paginationFrom, setPaginationFrom] = useState(-1)
  const [indexToShow, setIndexToShow] = useState(false)

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  useEffect(() => {
    if(props.coach.rights.interventions.length > 0){
      setIndexToShow(0);
    }
  },[props.coach])
  useEffect(() => {
    if(indexToShow !== false){
      getUsers(props.coach.rights.interventions[indexToShow].id)
    }
  },[indexToShow])


  useEffect(() => {
    if(props.coach){
      let tempStudents = [];
      for(let i = 0; i < studentsAll.length ; i++){
        if(studentsAll[i].rights.interventions){
          for(let ii = 0 ; ii < studentsAll[i].rights.interventions.length ; ii++){
              if(studentsAll[i].rights.interventions[ii].id == props.coach.rights.interventions[indexToShow].id){
                if(parseInt(studentsAll[i].rights.interventions[ii].hasCoach) == parseInt(props.coach.id)){
                  studentsAll[i].paid = (parseInt(studentsAll[i].rights.interventions[ii].paid) ? parseInt(studentsAll[i].rights.interventions[ii].paid):0)/// zet paid status op hoofdniveau opdat je er op kan sorteren
                  tempStudents.push(studentsAll[i])
                }
                //break;
              }
          }
        }

      }
      setPaginationFrom(0)
      setStudents(tempStudents)

    }
  },[studentsAll, props.coach])

  useEffect(() => {
    setStudentsOnPage(students.slice(paginationFrom, (paginationStep + paginationFrom)))
  },[paginationFrom, students])

  const getUsers = (intervention_id) => {
    setLoaded(false)
    //api aanroepen
    //mooier misschien enkel die van coach
    apiCall({
      //action: "get_students",
      action: "get_all_students_with_details",
      token: auth.token,
      data: {
        //intervention_id: intervention_id
      }
    }).then(resp => {
      setStudentsAll(resp.students)
      setLoaded(true)
    });
  };

  function getStatus(student){
    let status = 'niet betaald';
    for(let ii = 0 ; ii < student.rights.interventions.length ; ii++){
        if(student.rights.interventions[ii].id == props.coach.rights.interventions[indexToShow].id){
          if(typeof student.rights.interventions[ii].paid !== "undefined" && student.rights.interventions[ii].paid == 1){
            status = 'betaald';
          }
          break;
        }
    }
    return status;
  }

  function changeStatus(student, index){
    let studentsN = [...students]

    let status = 1;
    for(let ii = 0 ; ii < student.rights.interventions.length ; ii++){
        if(student.rights.interventions[ii].id == props.coach.rights.interventions[indexToShow].id){
          if(typeof student.rights.interventions[ii].paid !== "undefined" && student.rights.interventions[ii].paid == 1){
            status = 0;
          }
          student.paid = status /// zet paid status op hoofdniveau opdat je er op kan sorteren
          student.rights.interventions[ii].paid = status;
          break;
        }
    }
    studentsN[index] = student;
    ///student moet ook nog gesaved worden ivm paid status
    setStudents(studentsN)
  }

  function dowloadStudentsCSV(){
    var csvContent = 'id;date_time_create;firstname;insertion;lastname;email\n';
    if(props.paid){
      csvContent = 'id;date_time_create;firstname;insertion;lastname;email;paid\n';
    }
    var dataString = '';
    let status = '';

    students.forEach(function(r, index) {
      if (r.date_time_create > 0)
      {
        r.date_time_create = moment.unix(r.date_time_create).format("DD-MM-YYYY HH:mm:ss", { trim: false });
      }

      if(props.paid){
        ///is betaald?
        status = 'niet betaald';
        for(let ii = 0 ; ii < r.rights.interventions.length ; ii++){
            if(r.rights.interventions[ii].id == props.coach.rights.interventions[indexToShow].id){
              if(typeof r.rights.interventions[ii].paid !== "undefined" && r.rights.interventions[ii].paid == 1){
                status = 'betaald';
              }
              break;
            }
        }

        dataString += r.id +";"+ r.date_time_create+";"+r.firstname+";"+r.insertion+";"+r.lastname+";"+r.email+";"+status+";"+"\n";
      } else {
        dataString += r.id +";"+ r.date_time_create+";"+r.firstname+";"+r.insertion+";"+r.lastname+";"+r.email+";"+"\n";
      }


      //csvContent += index < registrations.length ? dataString + '\n' : dataString;

    });
    csvContent += dataString;

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    var download = function(content, fileName, mimeType) {
      var a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';

      if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
          type: mimeType
        }), fileName);
      } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
          type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        /// hier loopt die vast maar is misschien ook niet nodig
        //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
      }
    }

    download(csvContent, intervention.title+'_studenten_van_coach_'+props.coach.firstname + (props.coach.insertion != "" ? " " + props.coach.insertion + "":"") + props.coach.lastname + "_(#"+props.coach.id+") "  + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');
  }

  const [sortDirection, setSortDirection] = useState("")

  function changeSort(type){
    setSortDirection(sortDirection == "asc" ? "desc":"asc")

    if(type != ""){
      let tempStudents = [...students]
      tempStudents.sort(SortObjectArray(type, sortDirection));
      setStudents(tempStudents)
    }
  }

  const getInterventionTitle = (index) => {
    var this_intervention = props.interventions.find(int => parseInt(int.id) === parseInt(index));
    if(this_intervention){
      return parse(this_intervention.title);
    }
  };

  return (
    <div className="students_holder">
    {
        props.coach.rights.interventions.length > 1 ?
          <>
          <select value={indexToShow} onChange={(e)=>setIndexToShow(e.target.value)} className={"interventions" + (indexToShow !== false ? ' active':'')}>
          {props.coach.rights.interventions.map((id, index) => {
            return (
            <option value={index}>{getInterventionTitle(props.coach.rights.interventions[index].id)} ({props.coach.rights.interventions[index].hasStudents > 0 ? props.coach.rights.interventions[index].hasStudents:0})</option>
            )
            })}
          </select>
          <br/>
          </>
          :
          false
    }
    {indexToShow !== false ?
      <>
      {loaded ?
        <>
        {
          students.length == 0  ?
          <>
            Geen studenten gevonden
          </>
          :
          <>
          <table className="students">
            <thead>
              <tr>
                <th onClick={() => changeSort("id")}>
                  #
                </th>
                <th onClick={() => changeSort("date_time_create")}>
                  {t("Aangemeld op")}
                </th>
                <th onClick={() => changeSort("firstname")}>
                  {t("Naam")}
                </th>
                <th onClick={() => changeSort("login")}>
                  {t("Login")}
                </th>
                {props.paid ?
                  <th onClick={() => changeSort("paid")}>
                    {t("Status")}
                  </th>
                  :false}
              </tr>
            </thead>
            <tbody>
              {studentsOnPage.map((student, index) => (
                <tr key={index}>
                  <td>
                    {student.id}
                  </td>
                  <td>
                    {moment.unix(student.date_time_create).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                  <td>
                    {student.firstname} {student.insertion} {student.lastname}
                  </td>
                  <td>
                    {student.login}
                  </td>
                  {props.paid ?
                    <td>
                      <span className={getStatus(student)} onClick={()=>changeStatus(student, index)}>{t(getStatus(student))}</span>
                    </td>
                    :false}

                </tr>
              ))}
            </tbody>
          </table>
          {students.length > paginationStep ?
            <Pagination
                nrItems={students.length}
                paginationFrom={paginationFrom}
                setPaginationFrom={setPaginationFrom}
                paginationStep={paginationStep}
            />
            :<><br/></>}
            <i className="fas fa-file-download" data-tip={t("Exporteer status deelnemers")} onClick={()=>dowloadStudentsCSV()}></i>
            <ReactTooltip place="top" effect="solid" delayShow={200}   />


          </>
        }
        </>
        :
        <i className="fas fa-spinner fa-spin"></i>
      }
      </>
      :false}

    </div>
  )
}

export default StudentsAll
