import React from "react"
import t from "../translate"

const Panel1 = ({ registrationTexts, setRegistrationTexts, getTextInput, getContentEditable, getEditor, addThirdPartyProgram, removeThirdPartyProgram, interventions }) => {

   function addTableRow(cell1, cell2, cell3) {
      return (
         <tr><td>{cell1}</td><td>{cell2}</td><td>{cell3}</td></tr>
      )
   }

   function getPopupChecked(intervention_id) {
      let index = registrationTexts.panel1.popups.findIndex(item => item.intervention_id === intervention_id)
      if (index > -1) {
         return registrationTexts.panel1.popups[index].show
      } else {
         return false
      }
   }

   function getPopupContent(intervention_id, language_code) {
     let index = registrationTexts.panel1.popups.findIndex(item => item.intervention_id === intervention_id)
     if (index > -1) {
      return registrationTexts.panel1.popups[index].content[language_code]
     } else {
      return ''
     }
   }

   function togglePopups(intervention_id) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let index = registrationTexts.panel1.popups.findIndex(item => item.intervention_id === intervention_id)
      if (index > -1) {
         registrationTextsTemp.panel1.popups[index].show = !registrationTextsTemp.panel1.popups[index].show
      } else {
         registrationTextsTemp.panel1.popups.push({
            intervention_id, 
            show: true,
            content: {
               nl: '',
               eng: ''
            }
         })
      }
      setRegistrationTexts(registrationTextsTemp)
   }

   return (
      <div>
         <h2>{t("Scherm 1: Overzicht programma's")}</h2>
         <table className="table table-striped">
            <thead>
               <tr>
                  <th width="20%"></th>
                  <th width="40%">{t("Nederlands")}</th>
                  <th width="40%">{t("Engels")}</th>
               </tr>
            </thead>
            <tbody>
               {
                  addTableRow(
                     t("Titel intro"), 
                     getTextInput('panel1_introTitle_nl', registrationTexts.panel1.introTitle.nl, 'Titel scherm 1 NL'),
                     getTextInput('panel1_introTitle_eng', registrationTexts.panel1.introTitle.eng, 'Titel scherm 1 ENG')
                  ) 
               }
               {
                  addTableRow(
                     t("Inhoud intro"), 
                     getEditor('panel1_introContent_nl', registrationTexts.panel1.introContent.nl),
                     getEditor('panel1_introContent_eng', registrationTexts.panel1.introContent.eng)
                  )
               }
               {
                  addTableRow(
                     t("Titel survey"), 
                     getTextInput('panel1_surveyTitle_nl', registrationTexts.panel1.surveyTitle.nl, 'Titel survey NL'),
                     getTextInput('panel1_surveyTitle_eng', registrationTexts.panel1.surveyTitle.eng, 'Titel survey ENG')
                  ) 
               }
               {
                  addTableRow(
                     t("Inhoud survey"), 
                     getEditor('panel1_surveyContent_nl', registrationTexts.panel1.surveyContent.nl),
                     getEditor('panel1_surveyContent_eng', registrationTexts.panel1.surveyContent.eng)
                  )
               }
               {
                  addTableRow(
                     t("Titel programma's"), 
                     getTextInput('panel1_programsTitle_nl', registrationTexts.panel1.programsTitle.nl, 'Titel programma\'s NL'),
                     getTextInput('panel1_programsTitle_eng', registrationTexts.panel1.programsTitle.eng, 'Titel programma\'s ENG')
                  ) 
               }
               {
                  addTableRow(
                     t("Inhoud programma's"), 
                     getEditor('panel1_programsContent_nl', registrationTexts.panel1.programsContent.nl),
                     getEditor('panel1_programsContent_eng', registrationTexts.panel1.programsContent.eng)
                  )
               }
               {
                  addTableRow(
                     t("Titel universiteiten"), 
                     getTextInput('panel1_unisTitle_nl', registrationTexts.panel1.unisTitle.nl, 'Titel Universiteiten NL'),
                     getTextInput('panel1_unisTitle_eng', registrationTexts.panel1.unisTitle.eng, 'Titel Universiteiten ENG')
                  ) 
               }
               {
                  addTableRow(
                     t("Inhoud unis"), 
                     getEditor('panel1_unisContent_nl', registrationTexts.panel1.unisContent.nl),
                     getEditor('panel1_unisContent_eng', registrationTexts.panel1.unisContent.eng)
                  )
               }
               {
                  addTableRow(
                     t("Titel third party programma's"), 
                     getTextInput('panel1_thirdpartiesTitle_nl', registrationTexts.panel1.thirdpartiesTitle.nl, 'Titel third party programma\'s NL'),
                     getTextInput('panel1_thirdpartiesTitle_eng', registrationTexts.panel1.thirdpartiesTitle.eng, 'Titel third party programma\'s ENG')
                  ) 
               }
               <tr>
                  <td>{t("Popup tonen")}</td>
                  <td colSpan="2">
                     {
                        interventions.map(int => {
                           return (
                              <div key={int.id}>
                                 <input type="checkbox" id={'popup_'+int.id} checked={getPopupChecked(int.id)} onChange={() => togglePopups(int.id)} /> <label htmlFor={'popup_'+int.id} dangerouslySetInnerHTML={{__html: int.title}} />
                                 {
                                    registrationTexts.panel1.popups.find(item => item.intervention_id === int.id) && registrationTexts.panel1.popups.find(item => item.intervention_id === int.id).show ?
                                       <div>
                                          <table>
                                             <tr>
                                                <td>
                                                   <label htmlFor={'popup_'+int.id} dangerouslySetInnerHTML={{__html: t("Inhoud popup") + ' ' + int.title + ' NL'}} />
                                                   <div className="panel2-popup-cel">
                                                      {  getEditor('panel1_popup_' + int.id + '_nl', getPopupContent(int.id, 'nl'))}
                                                   </div>
                                                </td>
                                                <td>
                                                <label htmlFor={'popup_'+int.id} dangerouslySetInnerHTML={{__html: t("Content popup") + ' ' + int.title + ' ENG'}} />
                                                <div className="panel2-popup-cel">
                                                   {  getEditor('panel1_popup_' + int.id + '_eng', getPopupContent(int.id, 'eng'))}
                                                </div>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                    : <></>
                                 }
                              </div>
                           )
                        })
                     }
                  </td>
               </tr>
               <tr>
                  <td>{t("Third party programma's")}</td>
                  <td colSpan="2">
                     <button className="btn btn-primary btn-sm btn-trans float-right" onClick={addThirdPartyProgram}>
                        + {t("Toevoegen")}
                     </button>
                  </td>
               </tr>

               {
                  registrationTexts.panel1.thirdpartiesContent.map((item, key) => {
                     return(
                        <tr key={key}>
                           <td>
                              <button className="btn btn-danger btn-sm btn-trans float-right" onClick={() => removeThirdPartyProgram(key)}>-&nbsp;{t("Verwijderen")}</button>
                           </td>
                           <td>
                              <span>{t("Titel NL")}:</span><br />
                              {getTextInput('panel1_thirdpartiesContent_'+key+'_title_nl', item.title.nl, 'Titel third party programma NL')}
                              {t("Korte omschrijving NL")}:<br />
                              {getContentEditable('panel1_thirdpartiesContent_'+key+'_shortdescription_nl', item.shortdescription.nl, 'Korte omschrijving third party programma NL')}
                              {t("Lange omschrijving NL")}:<br />
                              {getContentEditable('panel1_thirdpartiesContent_'+key+'_longdescription_nl', item.longdescription.nl, 'Lange omschrijving party programma NL')}
                              {t("URL NL")}:<br />
                              {getTextInput('panel1_thirdpartiesContent_'+key+'_url_nl', item.url.nl, 'URL third party programma NL')}
                           </td>
                           <td>
                              {t("Titel ENG")}:<br />
                              {getTextInput('panel1_thirdpartiesContent_'+key+'_title_eng', item.title.eng, 'Titel third party programma ENG')}
                              {t("Korte omschrijving ENG")}:<br />
                              {getContentEditable('panel1_thirdpartiesContent_'+key+'_shortdescription_eng', item.shortdescription.eng, 'Korte omschrijving third party programma ENG')}
                              {t("Lange omschrijving ENG")}:<br />
                              {getContentEditable('panel1_thirdpartiesContent_'+key+'_longdescription_eng', item.longdescription.eng, 'Lange omschrijving party programma ENG')}
                              {t("URL ENG")}:<br />
                              {getTextInput('panel1_thirdpartiesContent_'+key+'_url_eng', item.url.eng, 'URL third party programma ENG')}
                           </td>
                        </tr>
                     )
                  })
               }

            </tbody>
         </table>

      </div>
   )
} 

export default Panel1
