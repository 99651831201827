import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import { getClone } from "../../../../utils";
import Slider from '../../../../content/front/parts/slider.js'

const SleepDiaryWizzardStep5 = (props) => {

  const [screenTimeBeforeBed, setScreenTimeBeforeBed] = useState(false)
  const [radioSet, setRadioSet] = useState(false)

  useEffect(() => {
    if(props.content){
      setScreenTimeBeforeBed(props.content.screenTimeBeforeBed)
      setRadioSet(true)
    }
  }, []);

  useEffect(() => {
    let tempContent = getClone(props.content);
    tempContent.screenTimeBeforeBed = screenTimeBeforeBed;
    props.setContent(tempContent)
  }, [screenTimeBeforeBed]);

  function updateAnswer(value){
    setScreenTimeBeforeBed(value)
  }

  return (
    <>
      {
        radioSet ?
          <div className="question step_5 box">
            <b>{t("Uitleg")}:</b> {t('Heb je een uur voor het inslapen een beeldscherm gebruikt (denk aan mobiele telefoon, tablet, e-reader, laptop, of TV)? Geef alleen "Ja" aan als je minstens 5 minuten op het scherm hebt gekeken. Geef "Nee" aan als je alleen een wekker hebt ingesteld.')}

            <div>
            <input type="radio" name="screen_time_before_bed" checked={screenTimeBeforeBed ? true:false}/>
            <label for="screen_time_before" onClick={()=>updateAnswer(true)} >{t("Ja")}</label>
            <input type="radio" name="screen_time_before_bed" checked={!screenTimeBeforeBed? true:false}/>
            <label for="screen_time_before" onClick={()=>updateAnswer(false)}>{t("Nee")}</label>
          </div>
        </div>
        : false
      }
    </>
  )
}
export default SleepDiaryWizzardStep5
