import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import { useSelector } from "react-redux";
import apiCall from "../api";
import { getClone } from "../utils";
import t from "../translate";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import ConfirmBox from "../alert/confirm";

const Edituser = forwardRef((props, ref) => {

  const [interventionId, setInterventionId] = useState(0);
  const [state, setState] = useState({
    user: {
      id: 0,
      firstname: "",
      insertion: "",
      lastname: "",
      email: "",
      phone: "",
      gender: "",
      education: "",
      organisation_id: 0,
      type: "",
      login: "",
      password: "",
      password_check: "",
      rights: {},
      preferences: [
        {option: 'language_id', value: '0'}
      ],
      removeUser: false,
      specialAccountType: '',
      language_id: 0
    },
    showPassword: false
  });



  const [errorMessage, setErrorMessage] = useState("");

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  //laden state met data uit props
  useEffect(() => {
    const newState = {...state};
    newState.user = props.user;
    newState.user.password = "";
    newState.user.password_check = "";
    newState.types = ["student"];
    if (newState.user.id == 0) {
      newState.showPassword = true;
    } else {
      newState.showPassword = false;
    }
    newState.user.removeUser = false;
    if (typeof newState.user.preferences !== "undefined") {
      for (var pref of newState.user.preferences) {
        if (pref.option === 'language_id') {
          newState.user.language_id = pref.value;
        }
      }
    }
    setState(newState);
    setErrorMessage("");
  }, [props.user]);

  useEffect(() => {
    if (state.user.specialAccountType === 'B' && state.showPassword === true) {
      toggleShowPassword();
    } else if (state.user.specialAccountType === 'T' && state.showPassword === false) {
      toggleShowPassword();
    }
  }, [state.user.specialAccountType])

  useEffect(() => {
    let intervention = {
        id: interventionId, 
        hasCoach: auth.user_id
    }
    let newState = {...state};
    newState.user.rights.interventions = [intervention];
    setState(newState);
  }, [interventionId])

  const onChange = e => {
    const newState = getClone(state);
    newState.user[e.target.name] = e.target.value;
    if (e.target.name === 'language_id') {
      for (var i=0;i<newState.user.preferences.length;i++) {
        if (newState.user.preferences[i].option === 'language_id')
          newState.user.preferences[i].value = e.target.value;
      }
    }
    setState(newState);
    setErrorMessage("");
  };

  const toggleShowPassword = e => {
    const newState = getClone(state);
    if (newState.showPassword) {
      newState.showPassword = false;
    } else {
      newState.showPassword = true;
    }
    setState(newState);
  };

  const toggleRemoveUser = e => {
    const newState = getClone(state);
    if (newState.user.removeUser) {
      newState.user.removeUser = false;
    } else {
      newState.user.removeUser = true;
    }
    setState(newState);
  };

  const [confirmOptions, setConfirmOptions] = useState({});
  const [toDeleteIndex, setToDeleteIndex] = useState(-1); /// doet niks maar is nodig in ingeladen custom confirm
  const [deleteUser, setDeleteUser] = useState(false);

  function deleteConfirm() {
    let confirmOptionsToSet = {
      show: "true",
      text: "<h4>"+t("Weet u zeker dat u deze deelnemer wilt verwijderen?")+"</h4>",
      cancelText: t("Annuleer"),
      confirmText: t("Verwijder"),
      confirmAction: () => doDeleteUser()
    };
    //setToDeleteIndex(-1);
    setConfirmOptions(confirmOptionsToSet);
  }

  function doDeleteUser(){

    //indien verwijderen, is dit de enige interventie waaraan de student is gekoppeld?
    if (state.user.removeUser && state.user.rights.interventions.length > 1) {
      //zo ja, dan alleen deelnemer loskoppelen van deze interventie
      state.user.rights.interventions = state.user.rights.interventions.filter(
        function(value, index, arr) {
          return value != intervention.id;
        }
      );
      state.user.removeUser = false;
    }

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: state.user
      }
    }).then(resp => {
      if (resp.save_user) {
        state.selectedCoach = 0;
        props.closeModal(resp.msg, intervention.id);
      } else {
        setErrorMessage(resp.msg);
      }
    });
  }


  //deze functie wordt aangeroepen door button in component Modal -> ref functie koppelt
  //componenten aan elkaar
  useImperativeHandle(ref, () => ({
    submitHandler() {
      //nee
      if (state.user.removeUser) {
        if (deleteConfirm() != 'true'
          /*!window.confirm(
            t("Weet u zeker dat u deze coach wilt verwijderen?")
          )*/
        ) {
          //props.closeModal("");
          return;
        }
      }
      /*
      //indien verwijderen, is dit de enige interventie waaraan de student is gekoppeld?
      if (state.user.removeUser && state.user.rights.interventions.length > 1) {
        //zo ja, dan alleen deelnemer loskoppelen van deze interventie
        state.user.rights.interventions = state.user.rights.interventions.filter(
          function(value, index, arr) {
            return value != intervention.id;
          }
        );
        state.user.removeUser = false;
      }
      */

      //tijdelijk om hasCoach aan rechten toe te voegen:
      if (state.user.rights.interventions.length > 0) {
        for (var key in state.user.rights.interventions) {
          if (!state.user.rights.interventions[key].hasOwnProperty("hasCoach")) {
            state.user.rights.interventions[key].hasCoach = auth.user_id;
          }
        }
      }

      apiCall({
        action: "save_user",
        token: auth.token,
        data: {
          user: state.user
        }
      }).then(resp => {
        if (resp.save_user) {
          props.closeModal(resp.msg, intervention.id);
        } else {
          setErrorMessage(resp.msg);
        }
      });
    }
  }));

  //.log("firstname: "+state.user.firstname);
  return (
    <div>
      {
        errorMessage.length > 0 ?
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-circle"></i> &nbsp;
            <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </div> : <></>
      }
      <form>
        {
          parseInt(state.user.id) === 0 ?
            <>
                <div className="col">
                    <label htmlFor="intervention_id">{t("Programma")}</label>
                    <select id="intervention_id" name="intervention_id" className="form-control" value={interventionId} onChange={(e) => setInterventionId(e.target.value)}>
                        <option value=""></option>
                        {
                            props.registrationableInterventions.map(intervention => (
                                <option value={intervention.intervention_id} key={intervention.intervention_id}>{intervention.title}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="form-row align-items-center bigLabel">
                  <div className="col">
                    <br />
                    <input
                      type="radio"
                      className="form-control"
                      id="specialAccountType_t"
                      name="specialAccountType"
                      aria-describedby="specialAccountType"
                      value="T"
                      onChange={onChange}
                      checked={state.user.specialAccountType === 'T'}
                    /> <label htmlFor="specialAccountType_t">{t("Testaccount")}</label>
                    <input
                      type="radio"
                      className="form-control"
                      id="specialAccountType_b"
                      name="specialAccountType"
                      aria-describedby="specialAccountType"
                      value="B"
                      onChange={onChange}
                      checked={state.user.specialAccountType === 'B'}
                    /> <label htmlFor="specialAccountType_b">{t("Begeleid account")}</label>
                    <br /><br />
                  </div>
                </div>
            </> : <></>
        }
        <div className="form-row align-items-center">
          <div className="col-auto">
            <label htmlFor="firstname">{t("Voornaam")}</label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              name="firstname"
              aria-describedby="firstname"
              placeholder=""
              value={state.user.firstname || ""}
              onChange={onChange}
            />
          </div>
          <div className="col-auto col-sm">
            <label htmlFor="insertion">{t("Tussenvoegsel")}</label>
            <input
              type="text"
              className="form-control"
              id="insertion"
              name="insertion"
              aria-describedby="insertion"
              placeholder=""
              value={state.user.insertion || ""}
              onChange={onChange}
            />
          </div>
          <div className="col-auto">
            <label htmlFor="lastname">{t("Achternaam")}</label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              aria-describedby="lastname"
              placeholder=""
              value={state.user.lastname || ""}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="form-row align-items-center">
          <div className="col">
            <br />
            <label htmlFor="gender">{t("Gender")}</label><br />
              <input
                type="radio"
                className="form-control"
                id="gender_f"
                name="gender"
                aria-describedby="gender"
                value="F"
                onChange={onChange}
                checked={state.user.gender === 'F'}
              /> <label htmlFor="gender_f">{t("Vrouw")}</label>
              <input
                type="radio"
                className="form-control"
                id="gender_m"
                name="gender"
                aria-describedby="gender"
                value="M"
                onChange={onChange}
                checked={state.user.gender === 'M'}
                /> <label htmlFor="gender_m">{t("Man")}</label>
                <input
                type="radio"
                className="form-control"
                id="gender_x"
                name="gender"
                aria-describedby="gender"
                value="X"
                onChange={onChange}
                checked={state.user.gender === 'X'}
                /> <label htmlFor="gender_x">{t("Anders")}</label>
                <br /><br />
            </div>
        </div>

        <div className="form-row align-items-center">
          <div className="col">
            <label htmlFor="email">{t("E-mail")}</label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              aria-describedby="email"
              placeholder=""
              value={state.user.email || ""}
              onChange={onChange}
            />
          </div>
          <div className="col">
            <label htmlFor="phone">{t("Telefoonnummer")}</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              aria-describedby="phone"
              placeholder=""
              value={state.user.phone || ""}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="login">{t("Gebruikersnaam")}</label>
          <input
            type="text"
            className="form-control"
            id="login"
            name="login"
            aria-describedby="login"
            placeholder=""
            value={state.user.login || ""}
            onChange={onChange}
          />
        </div>
        <div className={state.user.id != 0 ? "form-check" : "hidden"}>
          <input
            className="form-check-input"
            type="checkbox"
            id="showPassword"
            onChange={toggleShowPassword}
            checked={state.showPassword}
          />
          <label className="form-check-label" htmlFor="showPassword">
            {t("Wachtwoord wijzigen")}
          </label>
          <br />
          <br />
        </div>
        <div
          className={
            state.showPassword ? "form-row align-items-center" : "hidden"
          }
        >
          <div className="col">
            <label htmlFor="login">{t("Wachtwoord")}</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              aria-describedby="password"
              placeholder=""
              value={state.user.password || ""}
              onChange={onChange}
            />
          </div>
          <div className="col">
            <label htmlFor="login">{t("Wachtwoord ter controle")}</label>
            <input
              type="password"
              className="form-control"
              id="password_check"
              name="password_check"
              aria-describedby="password_check"
              placeholder=""
              value={state.user.password_check || ""}
              onChange={onChange}
            />
          </div>
        </div>
        <div
          className={
            auth.user_id === props.user.id || props.user.id === 0
              ? "hidden"
              : "form-check"
          }
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="remove"
            name="remove"
            onChange={toggleRemoveUser}
            checked={state.user.removeUser}
          />
          <label className="form-check-label" htmlFor="remove">
            {t("Verwijder gebruiker")}
          </label>
        </div>

        <div className="form-row align-items-center bigLabel">
              <div className="col">
                <br />
                <input
                  type="radio"
                  className="form-control"
                  id="language_id_1"
                  name="language_id"
                  aria-describedby="language_id"
                  value="1"
                  onChange={onChange}
                  checked={parseInt(state.user.language_id) === 1}
                /> <label htmlFor="language_id_1">{t("Nederlandstalig")}</label>
                <input
                  type="radio"
                  className="form-control"
                  id="language_id_2"
                  name="language_id"
                  aria-describedby="language_id"
                  value="2"
                  onChange={onChange}
                  checked={parseInt(state.user.language_id) === 2}
                /> <label htmlFor="language_id_2">{t("Engelstalig")}</label>
                <br /><br />
              </div>
            </div>
      </form>

      <ConfirmBox confirmOptions={confirmOptions} setConfirmOptions={setConfirmOptions} setToDeleteIndex={setToDeleteIndex}/>
    </div>
  );
});

export default Edituser;
