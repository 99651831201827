import React from "react"
import Panel1 from "./panel1"
import Panel2 from "./panel2"
import Panel3 from "./panel3"
import Panel4 from "./panel4"
import Panel5 from "./panel5"
import Panel6 from "./panel6"

const Panels = ({ activeTab, registrationTexts, setRegistrationTexts, getTextInput, getContentEditable, getEditor, addThirdPartyProgram, removeThirdPartyProgram, interventions, addStudentsReview, removestudentsReview, addMediaContent, removeMediaContent, setMediaContentImage }) => {
   switch (activeTab) {
      case "panel1":
         return <Panel1 registrationTexts={registrationTexts} setRegistrationTexts={setRegistrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} addThirdPartyProgram={addThirdPartyProgram} removeThirdPartyProgram={removeThirdPartyProgram} interventions={interventions} />
      case "panel2":
         return <Panel2 registrationTexts={registrationTexts} setRegistrationTexts={setRegistrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} interventions={interventions} addStudentsReview={addStudentsReview} removestudentsReview={removestudentsReview} addMediaContent={addMediaContent} removeMediaContent={removeMediaContent} setMediaContentImage={setMediaContentImage} />
      case "panel3":
         return <Panel3 registrationTexts={registrationTexts} setRegistrationTexts={setRegistrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} interventions={interventions} />
      case "panel4":
         return <Panel4 registrationTexts={registrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} />
      case "panel5":
         return <Panel5 registrationTexts={registrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} />
      case "panel6":
         return <Panel6 registrationTexts={registrationTexts} setRegistrationTexts={setRegistrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} interventions={interventions} />
   }
} 

export default Panels
