import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import StudentDetailsItems from "../studentDetails/items";
import { checkNestedProperties } from "../../utils";
import apiCall from "../../api";
import { setIntervention } from "../../../actions";

const  StudentDetailsLessons = props => {

   const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const [items, setItems] = useState([]);
  const [answers, setAnswers] = useState([]);

  const typesWeNeed = ["matrix", "question_open", "question_checkboxes", "question_radio", "select", "slider", "custom", "feedback_questionnaire"];

  useEffect(() => {
    //eerst de interventie ophalen
    apiCall({
      action: "get_intervention_settings",
      token: auth.token,
      data: {
        intervention_id: props.interventionId,
        language_id: 1
      }
    })
    .then(resp => {
      dispatch(
        setIntervention(
          resp.intervention_id,
          resp.organisation_id,
          resp.title,
          resp.settings
        )
      );
    })
   }, [props.interventionId])

   useEffect(() => {
    if (intervention.id > 0 && checkNestedProperties(intervention, 'settings', 'selfhelp', 'lessons')) 
    {
        let lessons = intervention.settings.selfhelp.lessons;

        //aantekenen welke lessen invul oefeninen bevatten
        lessons.map((item, index) => {
            lessons[index]["hasFillinParts"] = false;
            lessons[index]["optional"] = false;
            lessons[index].settings.parts.map(part => {

                if (typesWeNeed.indexOf(part.type) > -1)
                {
                    lessons[index]["hasFillinParts"] = true;
                }
            })
        })

        let optionalLessons = intervention.settings.selfhelp.optionalLessons;
        //aantekenen welke lessen invul oefeninen bevatten
        optionalLessons.map((item, index) => {
            optionalLessons[index]["hasFillinParts"] = false;
            optionalLessons[index]["optional"] = true;
            optionalLessons[index].settings.parts.map(part => {

                if (typesWeNeed.indexOf(part.type) > -1)
                {
                    optionalLessons[index]["hasFillinParts"] = true;
                }
            })
        })

        setItems(lessons.concat(optionalLessons));

        apiCall({
            action: "get_lesson_answers",
            token: auth.token,
            data: {
                id: lessons[0].id,
                student_id: props.studentId,
            }
            }).then(resp => {
                setAnswers(resp.allAnswers);
            })

            //unfixen van select met class select-switch-holder
       setTimeout(() => {
         let selects = document.querySelectorAll('.select-switch-holder');
         selects.forEach(select => {
           select.classList.remove('select-switch-holder');
         })
       }, 1000)

    }

    
  }, [props.studentId, intervention])

  return(
    <StudentDetailsItems type="lesson" items={items} answers={answers} studentId={props.studentId} interventionID={intervention.id} />
  )
}

export default StudentDetailsLessons
