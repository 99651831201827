import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import t from "../translate";
import LogOut from "./logout";
import LanguageSwitch from "./languageswitch";
import $ from "jquery";

const MenuAdmin = props => {
  let history = useHistory();

  const handleClick = (event, redirectTo) => {
    $("#menu_left").animate(
      {
        marginRight: "-405px"
      },
      600
    );
    $('body').removeClass('menuIsVisible');
    $("#nav-icon-wrapper, #menu_left").removeClass("open");
    $(".overlay").fadeOut();
    history.push(redirectTo);
    window.scrollTo(0, 0);
  };

  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  return (
    <div>
      {
        (typeof auth.rights.config_access !== 'undefined' && auth.rights.config_access) ?
          <div className="pointer" onClick={event => handleClick(event, "/")}>
            <div className="icon">
              <i className="fas fa-home"></i>
            </div>
            <div className="menu-left-link">{t("Dashboard")}</div>
          </div>
          : <></>
      }
      <div
        className={
          typeof intervention.id !== undefined && intervention.id > 0
            ? ""
            : "hidden"
        }
      >
        <div
          className={auth.rights.config_access ? 'pointer' : 'hidden'}
          onClick={event =>
            handleClick(
              event,
              "/intervention/edit/" + intervention.id + "/general/"
            )
          }
        >
          <div className="icon">
            <i className="fas fa-cog color_logo_orange"></i>
          </div>
          <div className="menu-left-link">{t("Instellingen")}</div>
        </div>


        {
          (typeof auth.rights.data_access !== 'undefined' && auth.rights.data_access) ?
            <div
              className={auth.rights.data_access ? 'pointer' : 'hidden'}
              onClick={event =>
                handleClick(event, "/intervention/data/" + intervention.id)
              }
            >
              <div className="icon">
                <i className="fas fa-table color_logo_blue"></i>
              </div>
              <div className="menu-left-link">{t("Data")}</div>
            </div> : <></>
        }
        {
          /*
        <div
          className="pointer"
          onClick={event =>
            handleClick(event, "/intervention/monitor/")
          }
        >
          <i className="fas fa-chart-area color_logo_blue"></i>
          <div className="menu-left-link">{t("Monitor")}</div>
        </div>
        */}

      </div>
      {intervention.id == 0 ?
        <>
          {
            (typeof auth.rights.stats_access !== 'undefined' && auth.rights.stats_access && typeof auth.rights.stats_access_universities !== 'undefined' && auth.rights.stats_access_universities.length > 0) ?
              <div
                  className="pointer"
                  onClick={event =>
                    handleClick(event, "/intervention/stats-all/")
                  }
                >
                  <div className="icon">
                    <i className="fas fa-chart-bar color_logo_blue"></i>
                  </div>
                  <div className="menu-left-link">{t("Statistieken")}</div>
              </div> : <></>
          }
        <div
            className={auth.rights.coaches_access ? 'pointer' : 'hidden'}
            onClick={event =>
              handleClick(event, "/coaches/")
            }
          >
            <div className="icon">
              <i className="fas fa-hands-helping color_logo_orange"></i>
            </div>
            <div className="menu-left-link">{t("Coaches")}</div>
          </div>
        <div
            className={auth.rights.coaches_access ? 'pointer' : 'hidden'}
            onClick={event =>
              handleClick(event, "/coachesoverview/")
            }
          >
            <div className="icon">
              <i className="fas fa-users color_logo_blue"></i>
            </div>
            <div className="menu-left-link">{t("Coaches overzicht")}</div>
        </div>
        {
          (typeof auth.rights.students_access !== 'undefined' && auth.rights.students_access) ?
            <div
                className="pointer"
                onClick={event =>
                  handleClick(event, "/intervention/users/")
                }
              >
                <div className="icon">
                  <i className="fas fa-users color_logo_orange"></i>
                </div>
                <div className="menu-left-link">{t("Studenten")}</div>
            </div> : <></>
        }
        {
          (typeof auth.rights.unfinished_registrations_access !== 'undefined' && auth.rights.unfinished_registrations_access) ?
            <div
                className="pointer"
                onClick={event =>
                  handleClick(event, "/unfinished-registrations/")
                }
              >
                <div className="icon">
                  <i className="fas fa-registered color_logo_blue"></i>
                </div>
                <div className="menu-left-link">{t("Onafgeronde registraties")}</div>
            </div> : <></>
        }
        {/*<div
            className={auth.rights.data_access ? 'pointer' : 'hidden'}
            onClick={event =>
              handleClick(event, "/emails/")
            }
          >
            <i className="fas fa-envelope color_logo_orange"></i>
            <div className="menu-left-link">{t("E-mails")}</div>
        </div>*/}
        {
          typeof auth.rights.platform_access !== "undefined" && auth.rights.platform_access ?
            <>
              <div className="pointer" onClick={ event => handleClick(event, "/platform/") } >
                <div className="icon">
                  <i className="fas fa-file color_logo_orange"></i>
                </div>
                <div className="menu-left-link">{t("Platform pagina's en vragenlijsten")}</div>
              </div>
              <div className="pointer" onClick={ event => handleClick(event, "/platform-translations/") } >
                <div className="icon">
                  <i className="fas fa-file-alt color_logo_blue"></i>
                </div>
                <div className="menu-left-link">{t("Platform vertalingen")}</div>
              </div>
            </> : <></>
         }
         {
          typeof auth.rights.registration_texts_access !== "undefined" && auth.rights.registration_texts_access ?
            <>
              <div className="pointer" onClick={ event => handleClick(event, "/registration-texts/") } >
                <div className="icon">
                  <i className="fas fa-id-card color_logo_orange"></i>
                </div>
                <div className="menu-left-link">{t("Registratie teksten")}</div>
              </div>
            </> : <></>
        }
        </>
        :false}
      <LogOut />
      <LanguageSwitch />
    </div>
  );
};

export default MenuAdmin;
