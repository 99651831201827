import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HighlightRound from "./highlight_round.js";
import t from "../../translate";
import apiCall from "../../api";
import moment from "moment";
import ReactTooltip from 'react-tooltip';

const InterventionData = (props) => {

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    if(showDetails){
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  };

  useEffect(() => {
    if(props.showDetails){
      setShowDetails(true);
    }
  }, [props]);

  const [languages, setLanguages] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [totals, setTotals] = useState([]);
  const [actieve_users, setActieveUsers] = useState(0);

  const auth = useSelector(state => state.auth);

  useEffect(() => {

    //actieve gebruikers bepalen
    let nr_afgerond = 0
    if (typeof props.activity?.total?.nr_opened_lessons !== 'undefined') {
      Object.keys(props.activity?.total?.nr_opened_lessons).map(function(item, index)  {
        if (item === props.okey) {
          Object.keys(props.activity?.total?.nr_opened_lessons[item]).map(function(key_, index_)  {
            if (key_ > 0) {
              nr_afgerond += parseInt(props.activity?.total?.nr_opened_lessons[item][key_])
            }
          })
        }
      })
    }
    setActieveUsers(nr_afgerond)

    //alle talen ophalen
    apiCall({
      action: "get_languages",
      token: auth.token,
      data: {}
    }).then(resp => {
      setLanguages(resp.languages);
    });

    //alle coaches ophalen
    apiCall({
      action: "get_all_coaches",
      token: auth.token,
      data: {}
    }).then(resp => {
      let tempTotals = [];
      let tempCoaches = [];

      for(let i = 0 ; i < resp.coaches.length ; i++){
        let coachInt = resp.coaches[i].interventions.find(i => {
          return parseInt(i.id) === parseInt(props.okey);
        });
        if (coachInt !== undefined) {
          let room = coachInt.max_students - coachInt.is_coaching;
          if (room < 0) {
            room = 0;
          }

          let talen = '';
          coachInt.languages.forEach(lang => {
            if (tempTotals[lang] === undefined) {
              tempTotals[lang] = 0;
            }
            tempTotals[lang] += room;
            talen += lang+' ';
          })

          if (tempTotals.total === undefined) {
            tempTotals.total = 0;
          }
          tempTotals.total += room;

          tempCoaches.push({
            id:resp.coaches[i].id,
            login:resp.coaches[i].login,
            is_coaching:coachInt.is_coaching,
            max_students:coachInt.max_students,
            talen:talen.slice(0, -1)
          })
      }}

      setTotals(tempTotals);
      setCoaches(tempCoaches);
    });

  }, [props.okey])

  function downloadCoaches(){

    var csvContent = 'id;login;coaching;max;languages\n';
    var dataString = '';
    let status = '';

    coaches.forEach(function(c, index) {

      dataString += c.id +";"+ c.login+";"+c.is_coaching+";"+c.max_students+";"+c.talen+"\n";
      //csvContent += index < registrations.length ? dataString + '\n' : dataString;

    });
    csvContent += dataString;

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    var download = function(content, fileName, mimeType) {
      var a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';

      if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
          type: mimeType
        }), fileName);
      } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
          type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        /// hier loopt die vast maar is misschien ook niet nodig
        //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
      }
    }

    download(csvContent, 'coaches_'+props.getInterventionTitle(props.okey) + "_" + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');

  }

  return(
    <>
    {props.okey ?
      <div className="intervention">
        <div className="title">
          {props.getInterventionTitle(props.okey)}
        </div>
        <div className="image" style={{ backgroundImage: "url('"+props.getInterventionCoverPhoto(props.okey)+"')" }}></div>

        <HighlightRound icon="fa fa-users" label="Gebruikers" content={props.activity.total.intervention[props.okey]} color="light_blue"/>
        <HighlightRound icon="fas fa-book" label="Lessen gestart" content={props.activity.total.opened_lessons[props.okey]}  color="orange"/>
        <HighlightRound icon="fa fa-check" label="Actief" content={actieve_users}  color="yellow"/>
        <HighlightRound icon="fas fa-mail-bulk" label="Vragenlijsten verstuurd" content={props.activity.total.questionnaire_invites[props.okey]}  color="dark_bleu"/>
        <br/>

        {showDetails  ?
          <>
          <div className="details">
          {(typeof props.activity.total.questionnaire_invites_spec[props.okey] != "undefined" && Object.keys(props.activity.total.questionnaire_invites_spec[props.okey]).length > 0) || (typeof props.activity.total.questionnaire_invites_spec[props.okey] != "undefined" && Object.keys(props.activity.total.questionnaire_invites_spec[props.okey]).length > 0) ?
            <table className={"two_td" + (typeof props.activity.total.questionnaire_invites_spec[props.okey] != "undefined" && Object.keys(props.activity.total.questionnaire_invites_spec[props.okey]).length > 0 ? '':' one_td' )}>
            <tbody>
              <tr>
                <td>
                  {typeof props.activity.total.nr_opened_lessons[parseInt(props.okey)] != "undefined" && Object.keys(props.activity.total.questionnaire_invites_spec[parseInt(props.okey)]).length > 0 ?
                    <>
                    <h4>{t("Aantal lessen gestart ")}  ({t("van de")} {props.mainlessons[props.okey].length} + {props.optionallessons[props.okey].length})</h4>
                    <table className="standard">
                    <tbody>
                    {Object.keys(props.activity.total.nr_opened_lessons[parseInt(props.okey)]).map(function(key_, index_) {
                      return(
                        <>
                        <tr>
                          <td>
                          {props.activity.total.nr_opened_lessons[parseInt(props.okey)][key_]} {t("studenten")}
                          </td>
                          <td>
                          {key_} {t("lessen")}
                          </td>
                        </tr>
                        </>
                      )
                    })}
                    </tbody>
                    </table>
                    </>
                  :false}
                </td>
                <td>
                  {typeof props.activity.total.questionnaire_invites_spec[props.okey] != "undefined" && Object.keys(props.activity.total.questionnaire_invites_spec[props.okey]).length > 0 ?
                    <>
                    <h4>{t("Vragenlijsten")}</h4>
                    <table className="standard">
                    <tbody>
                    {Object.keys(props.activity.total.questionnaire_invites_spec[props.okey]).map(function(kkey, index) {
                      return (
                           <tr>
                              <td>{kkey}</td><td> {props.activity.total.questionnaire_invites_spec[props.okey][kkey]}x</td>
                           </tr>
                      )
                    })}
                    </tbody>
                    </table>
                    <br/>
                    </>
                    :false
                  }
                </td>
              </tr>
            </tbody>
            </table>
          :false}
            {
              /*
              <div className="asterix">
                {t("* afgerond wil zeggen de laatste normale les gemaakt => voor oplossingSHop en Lifehack (controle) zegt dit dus niets.")}
              </div>
              */

            }

            {
              /*
              <div className="asterix">
                {t("* Uitnodigingen T3 voor GetStarted (controle) worden direct na randomisatie klaargezet, de uitnodigingsmail met de link wordt 4 weken later verzonden.")}
              </div>
              */

            }

            {coaches.length > 0 ?
              <>
              <h4>{t("Coaches")}</h4>
              <table className="coaches">
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Naam
                  </th>
                  <th>
                    Studenten/Max (taal)
                  </th>
                </tr>
              </thead>
              <tbody>
              {
                coaches.map((coach, index) => {
                   return (
                    <tr key={index}>
                      <td>
                         #{coach.id}
                      </td>
                      <td>
                        {coach.login}
                      </td>
                      <td>
                        {coach.is_coaching}/{coach.max_students} {coach.talen != "" ?"("+coach.talen+")":false}
                      </td>
                    </tr>
                  )
                })
                }
                {
                  languages.map((lang, index) => {
                    return (
                      <tr key={index}>
                        <td><b>{t("Total ") + lang.code}</b></td>
                        <td></td>
                        <td>{(totals[lang.code] !== undefined && totals[lang.code] !== undefined) ?
                        totals[lang.code] : ''}</td>
                      </tr>
                    )
                  })
                }
                <tr>
                  <td><b>Totaal</b></td>
                  <td></td>
                  <td>{totals.total}</td>
                </tr>
                </tbody>
                </table>
                <div className="align_right">
                  <i className="fas fa-file-download" data-tip={t("Download coaches")} onClick={()=>downloadCoaches()}></i>
                  <ReactTooltip place="top" effect="solid" delayShow={200}   />
                </div>
              </>
              :false}
          </div>
          {!props.showDetails ?
            <div className="btn btn-primary hide_details_btn" onClick={()=>toggleDetails()}>
              {t("Verberg details")}
            </div>
            :false}
          </>
          :
          <div className="btn btn-primary show_details_btn" onClick={()=>toggleDetails()}>
            {t("Toon details")}
          </div>
        }
      </div>
      :false}
    </>
  )
}
export default InterventionData;
