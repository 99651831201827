import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import t from "../translate";
import { getClone } from "../utils";

const BadgeOverlay = (props) =>{

  const [gamificationSettings, setGamificationSettings] = useState(false);///gamification settings

  const intervention = useSelector(state => state.intervention);

  /////////////////
  ////Set gamification settings
  useEffect(() => {
    if(typeof intervention.settings.gamification !== "undefined"){
      setGamificationSettings(intervention.settings.gamification.badgesData);
    }
  }, [intervention])

  /////////////////
  ////Check if reward data contains to show object
  useEffect(() => {
    if(typeof props !== "undefined" && typeof props.rewards !== "undefined" && props.rewards !== null && props.rewards.length > 0) {
      let this_rewards_obj = props.rewards.filter(function (reward) {
        return parseInt(reward.id) === parseInt(intervention.id)
      });
      if(this_rewards_obj.length !== 0) {
        if(this_rewards_obj[0].to_show.length > 0){
          ///if there are rewards to show show first
          props.setShowReward(this_rewards_obj[0].to_show)
        } else {
          ///no rewards to show hide and empty
          props.setShowData(false);
          props.setShowReward([]);
        }
      }
    }
  }, [props.rewards, intervention]);

  /////////////////
  ////Set data to show if there is a reward to show
  useEffect(() => {
    if(props.showReward.length > 0 && gamificationSettings){

      let type = props.showReward[0].type;

      let this_badge_obj = [];

      ///Get badge object for reward type
      if(type == "lessons" && typeof gamificationSettings[type + "_badges"] !== "undefined"){
        type = "lesson";

        this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
          return parseInt(badge.option) === parseInt(props.showReward[0].value)
        });
      } else if(type == "optionalLessons" && typeof gamificationSettings[type + "_badges"] !== "undefined"){
        type = "optionalLesson";
        this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
          return parseInt(badge.option) === parseInt(props.showReward[0].value)
        });
      } else if(type == "goals" && typeof gamificationSettings[type + "_badges"] !== "undefined") {
        this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
          return parseInt(badge.id) === parseInt(props.showReward[0].key_value)
        });
      } else if(type == "other" && typeof gamificationSettings[type + "_badges"] !== "undefined") {
        if(props.showReward[0].key_value == "diary"){
          this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
            return badge.id === "1";
          });
        }
        if(props.showReward[0].key_value == "points" && typeof gamificationSettings[type + "_badges"] !== "undefined"){
          this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
            return badge.id === "2";
          });
        }
      } else if(type == "start"){
        if(props.showReward[0].key_value == "start" && typeof gamificationSettings[type + "_badges"] !== "undefined"){
          this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
            return badge.option === "start";
          });
        }
      } else if(type == "questionnaires" && typeof gamificationSettings[type + "_badges"] !== "undefined"){
        this_badge_obj = gamificationSettings[type + "_badges"].filter(function (badge) {
          return parseInt(badge.option) === parseInt(props.showReward[0].value)
        });
      }

      /// Set reward to show data based on badge and type of reward
      if(this_badge_obj.length != 0){
        let text = '';
        if(type == "lesson"){
          let counter_module = 0;
          for(let il = 0 ; il < intervention.settings.selfhelp.lessons.length ; il++){
            if(intervention.settings.selfhelp.lessons[il].parent_id == 0){
              counter_module++;
              if(intervention.settings.selfhelp.lessons[il].id == props.showReward[0].value){
                break;
              }
            }
          }
          if(typeof this_badge_obj[0].text != "undefined" && this_badge_obj[0].text != ""){
            text = this_badge_obj[0].text;
          } else {
            //text = t("Module") + " " + counter_module + " " + t("finished");
          }

          props.setShowData({"text":text, "badge":this_badge_obj[0].image});
          if (typeof props.setHideMenu !== "undefined") {
            props.setHideMenu(true)
          }
        }
        if(type == "optionalLesson"){
          let counter_module = 0;
          for(let il = 0 ; il < intervention.settings.selfhelp.optionalLessons.length ; il++){
            if(intervention.settings.selfhelp.optionalLessons[il].parent_id == 0){
              counter_module++;
              if(intervention.settings.selfhelp.optionalLessons[il].id == props.showReward[0].value){
                break;
              }
            }
          }
          if(typeof this_badge_obj[0].text != "undefined" && this_badge_obj[0].text != ""){
            text = this_badge_obj[0].text;
          } else {
            //text = t("Optional module ") + " " + counter_module + " " + t("finished");
          }

          props.setShowData({"text":text, "badge":this_badge_obj[0].image});
          if (typeof props.setHideMenu !== "undefined") {
            props.setHideMenu(true)
          }
        }
        if(type == "questionnaires"){
          let counter_module = 0;
          let questionnaire_title = "";
          for(let il = 0 ; il < intervention.settings.questionnaires.length ; il++){
            if(intervention.settings.questionnaires[il].id == props.showReward[0].value){

              questionnaire_title = intervention.settings.questionnaires[il].title
              break;
            }
          }
          if(typeof this_badge_obj[0].text != "undefined" && this_badge_obj[0].text != ""){
            text = this_badge_obj[0].text;
          } else {
            //text = t("Vragenlijst ") + " " + questionnaire_title + " " + t("finished");
          }

          props.setShowData({"text":text, "badge":this_badge_obj[0].image});
          if (typeof props.setHideMenu !== "undefined") {
            props.setHideMenu(true)
          }
        }
        if(type == "goals"){
          ///match met badge?
          let this_times_obj = this_badge_obj[0].options.filter(function (times) {
            return parseInt(times.times) === parseInt(props.showReward[0].value)
          });

          /// als er een match met een badge is show
          if(this_times_obj.length != 0){
            let this_goal_obj = intervention.settings.goals.filter(function (goal) {
              return goal.id === props.showReward[0].key_value
            });

            if(this_goal_obj.length != 0){
              if(typeof this_times_obj[0].text != "undefined" && this_times_obj[0].text != ""){
                text = this_times_obj[0].text;
              } else {
                //text = t("Wow!") + " " + this_goal_obj[0].title + " " + this_times_obj[0].times + " " + t("keer") + "." ;
              }
            }
            props.setShowData({"text":text, "badge":this_times_obj[0].image});
            if (typeof props.setHideMenu !== "undefined") {
              props.setHideMenu(true)
            }
          } else {
            /// niet relevante show verwijder. Latere aanpassingen in badges resulteert hier niet alsnog in een show
            props.closeRewards();
          }
        }
        if(type == "other"){

          /// dagboek
          /// als er een match met een badge is show
          if(this_badge_obj[0].id == "1"){
            ///match met badge?
            let this_times_obj = this_badge_obj[0].options.filter(function (times) {
              return parseInt(times.times) === parseInt(props.showReward[0].value)
            });

            if(this_times_obj.length != 0){
              if(typeof this_times_obj[0].text != "undefined" && this_times_obj[0].text != ""){
                text = this_times_obj[0].text;
              } else {
                if(this_badge_obj[0].id == "1"){
                  //text = t("Wow!") + " " + t("Dagboek") + " " + this_times_obj[0].times + " " + t("keer") + ".";
                }
              }
              props.setShowData({text:text, "badge":this_times_obj[0].image});
              if (typeof props.setHideMenu !== "undefined") {
                props.setHideMenu(true)
              }
            } else {
              /// niet relevante show verwijder. Latere aanpassingen in badges resulteert hier niet alsnog in een show
              props.closeRewards();
            }
          }

          /// punten
          if(this_badge_obj[0].id == "2"){
            if(typeof this_badge_obj[0].options[props.showReward[0].offset_option] != "undefined"){
              if(typeof this_badge_obj[0].options[props.showReward[0].offset_option].text != "undefined" && this_badge_obj[0].options[props.showReward[0].offset_option].text != ""){
                text = this_badge_obj[0].options[props.showReward[0].offset_option].text;
              } else {
                //text = t("Wow!") + " " + this_badge_obj[0].options[props.showReward[0].offset_option].times + " " + t("punten gehaald") + ".";
              }
              props.setShowData({text:text, "badge":this_badge_obj[0].options[props.showReward[0].offset_option].image});
              if (typeof props.setHideMenu !== "undefined") {
                props.setHideMenu(true)
              }
            } else {
              /// niet relevante show verwijder. Latere aanpassingen in badges resulteert hier niet alsnog in een show
              props.closeRewards();
            }
          }
        }
        if(type == "start"){
          if(typeof this_badge_obj[0].text != "undefined" && this_badge_obj[0].text != ""){
            text = this_badge_obj[0].text;
          } else {
            //text = t("Interventie gestart");
          }

          props.setShowData({"text":text, "badge":this_badge_obj[0].image});
          if (typeof props.setHideMenu !== "undefined") {
            props.setHideMenu(true)
          }
        }
      }
    }
  }, [props.showReward, gamificationSettings]);

  return (
    <>
      {props.showData ?
        <div className="reward fadeIn">
          <div className="content">
            <div className="image">
              <img src={props.showData.badge}/>
            </div>
            <div className="title">
              {props.showData.text}
            </div>
            <span className="btn btn-primary" onClick={()=>props.closeRewards()}>{t("Sluit")}</span>
          </div>
        </div>
        :
        <></>
      }
    </>
  )
}

export default BadgeOverlay;
