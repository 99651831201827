import React from "react";
import { useSelector } from "react-redux";
import RouterSuperUser from "./superUser";
import RouterAdmin from "./admin";
import RouterCoach from "./coach";
import RouterStudent from "./student";
import Researcher from "../researcher";

const selectRoute = userType => {
  switch (userType) {
    case "superuser":
      return <RouterSuperUser />;
    case "admin":
      return <RouterAdmin />;
    case "coach":
      return <RouterCoach />;
    case "student":
      return <RouterStudent />;
    case "researcher":
      return <Researcher />;
    default:
      return <div>Routing error...</div>;
  }
};

const Router = () => {
  const auth = useSelector(state => state.auth);

  return (
    <div className={auth.userType}>
      {selectRoute(auth.userType)}
    </div>
  );
};

export default Router;
