import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Confetti from "react-confetti";
import apiCall from "../../api";
import t from "../../translate";
import previewEsteemCert from "../../../images/certificate/EsteemUpPreview.png";
//import $ from "jquery";

let animateTimeout = false;
let unloadTimeout = false;

const ConfettiFullScreen = (props) =>{
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showConfetti, setShowConfetti] = useState(false);
  const [load, setLoad] = useState(true);

  const [activeLessonIndex, setActiveLessonIndex] = useState(0);
  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  const [showCerticateLink, setShowCerticateLink] = useState(false);
  
  function resize(){
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  const [animated, setAnimated]= useState(false)

  if(!animated){
    clearTimeout(animateTimeout);
    animateTimeout = setTimeout(() => {
      setAnimated(true)
    }, 2000)
  }

  useEffect(() => {
    if (intervention.id == 41) {
      apiCall({
        action: "get_esteemup_certificate",
        token: auth.token,
        data: {}
      }).then(resp => {
        if (resp.get_esteemup_certificate){
          setShowCerticateLink(true);
        }
      })
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", resize);

    setShowConfetti('true')
    //$("body").addClass('shake')
    document.body.classList.add('shake')
  }, [props]);

  function turnOff(){
    //$("body").removeClass('shake')
    document.body.classList.remove('shake')
    setShowConfetti(false)

    clearTimeout(unloadTimeout);
    unloadTimeout = setTimeout(() => {
      setLoad(false)
      props.setEndCourse(false)
    }, 500)
  }

  return(
    <div>
      {load ?
        <div className={"confettiHolder" + (showConfetti ? ' show':' fadeOut')}>
          <Confetti
              width={width}
              height={height}
              recycle={showConfetti}
            />
          <div className={"confettiOverlay" + (animated ? '':' animate')}></div>
          <div className={'popup' + (animated ? '':' animate')}>
            <h1>{t('Geweldig')}</h1>
            <div>
              {t('Je hebt het programma afgerond - dat mag wel even gevierd worden!')}
            </div>
            <span className='btn btn-primary' onClick={()=>turnOff()}>
              {t('Confetti sluiten')}
            </span>
            {
              showCerticateLink ?
                <>
                  <br />
                  <br />
                  <a href={"/certificate?t=" + auth.token} target="_blank">{t("Download hier je persoonlijke certificaat")}</a> <br />
                  {t("Je hebt deze ook per mail ontvangen.")}
                  <center>
                    <br />
                    <img src={previewEsteemCert} style={{width: "200px"}} />
                    {/* <object data={"/certificate?t=" + auth.token} type="application/pdf" width="200px" ></object>  */}
                    {/* <iframe id="certprev" src="/api/certificate/previewEsteemUpCertificate.pdf" width="200px" height="140px" frameBorder="0" style={{border: "#4fbdf4 4px solid;"}}></iframe> */}
                  </center>
                </>
                  : <></>
            }
          </div>
        </div>
      :
        ''
      }
    </div>
  )
}

export default ConfettiFullScreen;
