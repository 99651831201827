import React, {useState,Fragment} from "react";
import t from "../../../../translate";
import { useSelector } from "react-redux";
import { GetDate, GetNight, GetDay, GetTommorow } from "../../../../helpers/convertTimestamp.js";
import { getTimeStamp, minutesToHours } from "../../../../helpers/changeFormatDate.js";
import { capitalize } from "../../../../helpers/text.js";
import { GetTime } from "../../../../helpers/convertTimestamp.js";
import ReactTooltip from 'react-tooltip'

const NightDetails = (props) => {

  const [showDetails, setShowDetails] = useState(false)

  const intervention = useSelector(state => state.intervention);

  const getDate= (date) => {
    let date_split = date.split("-")
    return date_split[2] + "-" + date_split[1] + "-" + date_split[0];
  }
  const getDayAfter= (date) => {
    var tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() +1);

    return tomorrow.getDate() + '-' + (tomorrow.getMonth() + 1) + "-" + tomorrow.getFullYear();
  }

  const getGradeClass= (value) => {
    if(value > 4 && value < 8){
      return 'middle'
    } else if (value >= 8){
      return 'high'
    } else {
      return 'low'
    }
  }

  const getStatusTitle = (status) =>{
    if(status == "awake"){
      return t("Wakker in bed")
    } else if (status == "sleep") {
      return t("Slapen")
    } else if (status == "light"){
      return t("Buiten")
    } else if (status == "nap"){
      return t("Dutje")
    } else if (status == "out_of_bed"){
      return t("Wakker uit bed")
    }
  }

  return (
    <div key={props.index} className={"item " + props.class + (typeof props.entrie.empty != "undefined" ? ' empty':'') + (showDetails  ? ' open':'')}>
      <div className="night">
        {capitalize(GetDay(getTimeStamp(props.entrie.date_sleep)))} <span>({getDate(props.entrie.date_sleep)})</span> {t(("op"))} {GetTommorow(getTimeStamp(props.entrie.date_sleep))} <span>({getDayAfter(props.entrie.date_sleep)})</span>

        {typeof props.entrie.empty == "undefined" ?
          <i className={"fas fa-chevron-up"} onClick={()=>setShowDetails(showDetails ? false:true)}></i>
          :false}

      </div>
      {typeof props.entrie.empty == "undefined" ?
        <>
        <div className="scheme_holder">
          <table>
          <tbody>
            <tr>
              <td>
                {GetTime(props.entrie.content.sleepdiary[0].timestamp)}
              </td>
              <td>
                {GetTime(props.entrie.content.sleepdiary[9].timestamp)}
              </td>
              <td>
                {GetTime(props.entrie.content.sleepdiary[Math.floor((props.entrie.content.sleepdiary.length)/2)].timestamp)}
              </td>
              <td>
                {GetTime(props.entrie.content.sleepdiary[27].timestamp)}
              </td>
              <td>
                {GetTime(props.entrie.content.sleepdiary[props.entrie.content.sleepdiary.length-1].timestamp)}
              </td>
            </tr>
          </tbody>
          </table>
          <div className="scheme">
            {props.entrie.content.sleepdiary.map((item, index) =>
              <Fragment key={index}>
                <div className={"quarter " + (item.quarters[0].status)} data-tip={getStatusTitle(t(item.quarters[0].status))}></div>
                <div className={"quarter " + (item.quarters[1].status)} data-tip={getStatusTitle(t(item.quarters[1].status))}></div>
                <div className={"quarter " + (item.quarters[2].status)} data-tip={getStatusTitle(t(item.quarters[2].status))}></div>
                <div className={"quarter " + (item.quarters[3].status)} data-tip={getStatusTitle(t(item.quarters[3].status))}></div>
                <ReactTooltip place="top" effect="solid" delayShow={200} className="menu_tooltip"  />
              </Fragment>
            )}
          </div>
        </div>
        {showDetails ?
          <div className="details">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="prop">
                      {t("Tijd naar bed")} : {GetTime(props.entrie.content.timestamp_to_bed)} {t("uur")}
                    </div>
                    <div className="prop">
                      {t("Tijd uit bed")} : {GetTime(props.entrie.content.timestamp_wake_up)} {t("uur")}
                    </div>
                    <br/>
                    <div className="prop">
                      {t("Slapen")} : {minutesToHours((props.entrie.content.sleeping_quarters * 15))} {t("uur")}
                    </div>
                    <div className="prop">
                      {t("Wakker")} : {minutesToHours((props.entrie.content.awake_quarters * 15) + (props.entrie.content.out_of_bed_quarters * 15))} {t("uur")}
                    </div>
                    <div className="prop">
                      {t("Dutje")} : {minutesToHours((props.entrie.content.nap_quarters * 15))} {t("uur")}
                    </div>
                    {intervention.settings.include_sleepdiary_light == 1 ?
                      <>
                      <div className="prop">
                        {t("Buiten")} : {minutesToHours((props.entrie.content.light_quarters * 15))} {t("uur")}
                      </div>
                      <br/>
                      <div className="prop">
                        {t("Schermgebruik voor het slapen")} : {(props.entrie.content.screenTimeBeforeBed ? t("Ja"):t("Nee"))}
                      </div>
                      </>
                      :false}
                  </td>
                  <td>

                    <div className={"prop score " + props.getEfficiencyClass(Math.round(props.entrie.content.sleep_efficiency))} data-tip={t("Slaapefficiëntie")}>
                      {Math.round(props.entrie.content.sleep_efficiency)} %
                    </div>
                    <div className={"prop score " + getGradeClass(props.entrie.content.grade_sleep)} data-tip={t("Slaapkwaliteit")}>
                      <i className="fas fa-bed"></i>{props.entrie.content.grade_sleep}
                    </div>
                    <div className={"prop score " + getGradeClass(props.entrie.content.feel)} data-tip={t("Uitgerust")}>
                      <i className="fas fa-heart"></i>{props.entrie.content.feel}
                    </div>
                    <ReactTooltip place="top" effect="solid" delayShow={200} className="menu_tooltip"  />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          :false}
        </>
        :
        <div className="empty_night">
          {t("Geen gegevens opgegeven")}
        </div>
      }
    </div>
  )
}

export default NightDetails
