import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setIntervention } from "../../../actions";
import t from "../../translate";
import { getClone } from "../../utils";

let saveSettingsTimeout = null;

const InterventionSettingsType = props => {
  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);

  const onChangeType = e => {
    let type = e.target.value;
    //set settings in store:
    let interventionC = getClone(intervention);
    interventionC.settings.intervention_type = type;
    saveThemSettings(interventionC.settings);
  };

  const onChangeCheck = e => {
    let interventionC = getClone(intervention);
    if (e.target.checked)
    {
      interventionC.settings.selfhelp[e.target.id] = 1;
    }
    else
    {
      interventionC.settings.selfhelp[e.target.id] = 0;
    }
    saveThemSettings(interventionC.settings);
  }

  const saveThemSettings = (newSettings) => {
    
    dispatch(
      setIntervention(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      )
    );

    props.setErrorMessage("");
    clearTimeout(saveSettingsTimeout);
    saveSettingsTimeout = setTimeout(() => {
      props.saveSettings(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      );
    }, 1500);
  }

  return (
    <div className="form-group">
      <h5>{t("Type interventie")}</h5>
      <div className="question">
        <input
          type="radio"
          name="intervention_type"
          id="intervention_type_selfhelp"
          value="selfhelp"
          checked={typeof intervention.settings.intervention_type != "undefined" && intervention.settings.intervention_type === "selfhelp"}
          onChange={onChangeType}
        />
        <label
          htmlFor="intervention_type_selfhelp"
        >
          {t("Zelfhulp")}
        </label>
      </div>
      <div className="question">
        <input
          type="radio"
          name="intervention_type"
          id="intervention_type_guided_selfhelp"
          value="guided_selfhelp"
          checked={
            intervention.settings.intervention_type === "guided_selfhelp"
          }
          onChange={onChangeType}
        />
        <label
          className="question"
          htmlFor="intervention_type_guided_selfhelp"
        >
          {t("Begeleide zelfhulp")}
        </label>
      </div>
      <div className={(intervention.settings.intervention_type === 'guided_selfhelp') ? 'marginLeft30' : 'hidden'}>
        <input
            type="checkbox"
            name="guided_selfhelp_view_lessons"
            id="guided_selfhelp_view_lessons"
            value="1"
            checked={
              intervention.settings.selfhelp.guided_selfhelp_view_lessons === 1
            }
            onChange={onChangeCheck}
          />
        <label
            className="question"
            htmlFor="guided_selfhelp_view_lessons"
          >
          {t("begeleider mag antwoorden lessen inzien")}
        </label>
        <br />
        <input
            type="checkbox"
            name="guided_selfhelp_view_goals"
            id="guided_selfhelp_view_goals"
            value="1"
            checked={
              intervention.settings.selfhelp.guided_selfhelp_view_goals === 1
            }
            onChange={onChangeCheck}
          />
        <label
            className="question"
            htmlFor="guided_selfhelp_view_goals"
          >
          {t("begeleider mag doelen inzien")}
        </label>
        <br />
        <input
            type="checkbox"
            name="guided_selfhelp_view_questionnaires"
            id="guided_selfhelp_view_questionnaires"
            value="1"
            checked={
              intervention.settings.selfhelp.guided_selfhelp_view_questionnaires === 1
            }
            onChange={onChangeCheck}
          />
        <label
            className="question"
            htmlFor="guided_selfhelp_view_questionnaires"
          >
          {t("begeleider mag ingevulde vragenlijsten inzien")}
        </label>
        <br />
        <input
            type="checkbox"
            name="guided_selfhelp_view_log"
            id="guided_selfhelp_view_log"
            value="1"
            checked={
              intervention.settings.selfhelp.guided_selfhelp_view_log === 1
            }
            onChange={onChangeCheck}
          />
        <label
            className="question"
            htmlFor="guided_selfhelp_view_log"
          >
          {t("begeleider mag log inzien")}
        </label>
        <br />
        <input
            type="checkbox"
            name="guided_selfhelp_chat_contact"
            id="guided_selfhelp_chat_contact"
            value="1"
            checked={
              intervention.settings.selfhelp.guided_selfhelp_chat_contact === 1
            }
            onChange={onChangeCheck}
          />
        <label
            className="question"
            htmlFor="guided_selfhelp_chat_contact"
          >
          {t("contact tussen cursist en coach d.m.v. 'chat' berichten")}
        </label>
        
      </div>
      <div className="question hidden">
        <input
          className="question-input"
          type="radio"
          name="intervention_type"
          id="intervention_type_chatcourse"
          value="chatcourse"
          checked={intervention.settings.intervention_type === "chatcourse"}
          onChange={onChangeType}
        />
        <label
          htmlFor="intervention_type_chatcourse"
        >
          {t("Chatcursus")}
        </label>
      </div>
    </div>
  );
};

export default InterventionSettingsType;
