import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import NavBar from "../navBar";
import Dashboard from "../dashboard/coach";
import Students from "../students";
import InterventionCoaches from "../coaches/";
import apiCall from "../api";
import { useSelector } from "react-redux";

const RouterCoach = () => {

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const [supervisorFor, setSupervisorFor] = useState([]);
  const [interventions, setInterventions] = useState([])

  useEffect(() => {
    //api aanroepen
    /* apiCall({
      action: "get_interventions",
      token: auth.token,
      data: {}
    }).then(resp => {
      setInterventions(resp.interventions)
    }); */

    let localSupervisorFor = [];
    auth.rights.interventions.forEach(function (interv, index) {
      if (interv.isSupervisor)
      {
        localSupervisorFor.push(interv.id);
      }
    });
    setSupervisorFor(localSupervisorFor);
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" exact >
          {/* <NavBar />
          <Dashboard interventions={interventions}/> */}
          <Students />
        </Route>
        {/* <Route path="/students">
          <Students interventions={interventions}/>
        </Route> */}
        {supervisorFor.length > 0 ?
          <Route path={"/intervention/coaches/"} >
            <InterventionCoaches interventions={interventions}/>
          </Route>
        : <></>}

        <Route path="*" exact>
          <Redirect to="/" />
        </Route>

      </Switch>
    </Router>
  )
}

export default RouterCoach;
