import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';

const Slider = props => {

  const [min, setMin] = useState("0");
  const [minLabel, setMinLabel] = useState(false);
  const [max, setMax] = useState("10");
  const [maxLabel, setMaxLabel] = useState(false);
  /* const [rangeMin, setRangeMin] = useState("0");
  const [rangeMax, setRangeMax] = useState("10"); */
  const [chosen, setChosen] = useState(0)
  const [wasEmpty, setWasEmpty] = useState(true)
  const [empty, setEmpty] = useState('')

  useEffect(() => {
    setWasEmpty(true)
  }, [props.part.id])

  //////////////////////
  ///Get content
  //////////////////////
  useEffect(() => {

    if(typeof props.part.min !== "undefined" && props.part.min != "")
    {
      setMin(props.part.min);
    }
    if(typeof props.part.max !== "undefined" && props.part.max !== "")
    {
      setMax(props.part.max);
    }
    if(typeof props.part.minLabel !== "undefined" && props.part.minLabel != "")
    {
      setMinLabel(props.part.minLabel);
    }
    if(typeof props.part.maxLabel !== "undefined" &&props.part.maxLabel != "")
    {
      setMaxLabel(props.part.maxLabel);
    }
    /* if(typeof props.part.rangeMin !== "undefined" && props.part.rangeMin !== "")
    {
      setRangeMin(props.part.rangeMin);
    }
    if(typeof props.part.rangeMax !== "undefined" && props.part.rangeMax !== "")
    { */
      //setRangeMax(props.part.rangeMax);
      let initialValue = props.answer;
      if(initialValue === "") {
        initialValue = Math.floor((parseInt(props.part.rangeMax) + parseInt(props.part.rangeMin)) / 2)
      }

      setChosen(initialValue)
      positionValueBullet(initialValue)
    //}
    if(props.answer){
      setWasEmpty(false)
    }
    if(props.resetSetWasEmpty && props.answer == ""){
      setWasEmpty(true)
    }

    window.addEventListener('resize', positionValueBulletChosen)
  }, [props]);

  function positionValueBulletChosen(){
    /// check is nodig ander loopt die vast aan de kant van de coach
    if(document.getElementById("range_" + props.index)){
        positionValueBullet(document.getElementById("range_" + props.index).value)
    }

  }

  const [offsetLeft, setOffsetLeft] = useState(0)

  function positionValueBullet(value) {
    let tempOffsetLeft = (((value - props.part.rangeMin)/(props.part.rangeMax - props.part.rangeMin)) * (document.getElementById("range_" + props.index).offsetWidth - 22)) + 'px';
    setOffsetLeft(tempOffsetLeft)
  }

  function updateSliderAnswer(value){
    props.updateAnswer(props.part.id, value)
    setWasEmpty(false)
    setChosen(value)
    setEmpty('')
    positionValueBullet(value)
  }

  return(
    <div className={"slider" + (props.part.must ? ' must':'')}>
      <div className="center">
        <div className="question">
          {parse(props.part.question)}
        </div>
        <span className="rs-label" style={{'left':offsetLeft}}>{wasEmpty ? '':chosen}</span>
        <input id={'range_' + props.index} type="range" min={props.part.rangeMin} max={props.part.rangeMax} onChange={(e) => updateSliderAnswer(e.target.value)} value={chosen}  disabled={(props.hasOwnProperty("disabled") && props.disabled === "true") ? true : false}/>
        <table className="min_max">
          <tbody>
            <tr>
              <td>
                <span>{parse(min)}</span>
                {typeof minLabel === 'string' ?
                  parse(minLabel)
                  :
                  minLabel
                }
              </td>
              <td>
                <span>{parse(max)}</span>
                  {typeof maxLabel === 'string' ?
                    parse(maxLabel)
                    :
                    maxLabel
                  }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Slider
