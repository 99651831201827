import React, { useState, useEffect } from "react";
import t from "../../../translate";
import apiCall from "../../../api";
import { useSelector } from "react-redux";
import { GetDate, GetNight, GetDay, GetTommorow } from "../../../helpers/convertTimestamp.js";
import { getTimeStamp } from "../../../helpers/changeFormatDate.js";
import BarChartThisWeek from "./this_week/bar_chart.js";
import NightsThisWeek from "./this_week/nights.js";
import { capitalize } from "../../../helpers/text.js";

const SleepDiaryThisWeek = (props) => {

  const today = new Date();
  const [lastWeekDates, setLastWeekDates] = useState([today.setDate(today.getDate() - 1), today.setDate(today.getDate() - 1), today.setDate(today.getDate() - 1), today.setDate(today.getDate() - 1), today.setDate(today.getDate() - 1), today.setDate(today.getDate() - 1), today.setDate(today.getDate() - 1)]);
  const weekAgoTimestamp = new Date(lastWeekDates[6]);
  const weekAgoDate = weekAgoTimestamp.toISOString().split('T')[0];

  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);

  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [entries, setEntries] = useState([]);
  const [entriesThisWeek, setEntriesThisWeek] = useState([]);
  const [sleepefficiencyWeek, setSleepefficiencyWeek] = useState(false);
  const [topEfficiencyWeek, setTopEfficiencyWeek] = useState(false);
  const [startDate, setStartDate] = useState(false);

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    /// get chosen values from server and set in chosenvalues en dates
    if (activeIntervention > 0 || props.intervention_id > 0) {
      let data = {
        intervention_id: parseInt(activeIntervention)
      };
      if(props.student_id && props.coach && props.intervention_id){
        data = {
          intervention_id: props.intervention_id,
          student_id: parseInt(props.student_id)
        }
      }

      apiCall({
        action: "get_sleep_diary",
        token: auth.token,
        data: data
      }).then(resp => {
        if (resp.content) {
          setStartDate(resp.start_intervention)
          setEntries(resp.content)

          let tempLabels = [];
          let tempValues = [];
          let dateToCompare;

          for(let i = 0 ; i < lastWeekDates.length ; i++){
            dateToCompare = new Date(lastWeekDates[i])
            dateToCompare = dateToCompare.getFullYear() + "-"+ (dateToCompare.getMonth() < 9 ? "0":'') + (dateToCompare.getMonth()+1) +"-"+ (dateToCompare.getDate() < 10 ? "0":'') + dateToCompare.getDate()

            let this_night_obj = resp.content.filter(function (entrie) {
              return entrie.date_sleep === dateToCompare
            });

            if(this_night_obj.length != 0){

              tempLabels.push(GetDay(getTimeStamp(resp.content[resp.content.indexOf(this_night_obj[0])].date_sleep)))
              tempValues.push(Math.round(resp.content[resp.content.indexOf(this_night_obj[0])].content.sleep_efficiency))
            } else {
              tempLabels.push(GetDay(getTimeStamp(dateToCompare)))
              tempValues.push(0)
            }
          }

          for(let i = 0 ; i <= resp.content.length - 1 ; i++){
            if(Date.parse(resp.content[i].date_sleep) >= Date.parse(weekAgoDate)){

            } else {
              break;
            }
          }

          setValues(tempValues.reverse());
          setLabels(tempLabels.reverse());

        }
        setLoaded(true)
      });
    }
  }, [activeIntervention, props.intervention_id]);

  useEffect(() => {
    if(entries.length > 0){

      let sleepefficiencyTotal = 0;
      let sleepefficiencyDays = 0;
      let tempEntriesThisWeek = [];
      let tempTopEfficiency = 0;
      ///get last week items
      for(let i = 0 ; i < entries.length ; i++){
        if(Date.parse(entries[i].date_sleep) >= Date.parse(weekAgoDate)){
          sleepefficiencyTotal = entries[i].content.sleep_efficiency + sleepefficiencyTotal;
          sleepefficiencyDays++;
          tempEntriesThisWeek.push(entries[i])

          if(entries[i].content.sleep_efficiency >= tempTopEfficiency){
            tempTopEfficiency = entries[i].content.sleep_efficiency
            setTopEfficiencyWeek(entries[i])
          }
        } else {
          break;
        }
      }

      setSleepefficiencyWeek(Math.round(sleepefficiencyTotal/sleepefficiencyDays))
      setEntriesThisWeek(tempEntriesThisWeek)
    }
  }, [entries]);


  return (
    <div className="this_week">
      {!props.coach ?
        <>
          <h1>{t("Deze week")}</h1>
          <div className="intro">
            {t("Hier zie je een overzicht van je afgelopen 7 nachten.")}
          </div>
        </>
        :false}
      {entriesThisWeek.length == 0 && loaded ?
        <div className="no_nights">
          {t("Nog geen nachten opgegeven")}
        </div>
        :
        <>
        {loaded ?
          <>
          {/*
            {sleepefficiencyWeek >= 0 ?
              <div className="in_short box">
                <h2>{t("Gemiddelde Slaapefficiëntie")}</h2>
                <span className={props.getEfficiencyClass(sleepefficiencyWeek)}>
                  {sleepefficiencyWeek} %
                </span>
              </div>
              :false}
            {topEfficiencyWeek ?
              <div className="in_short box best_night">
                <h2>{t("Mijn beste nacht")} </h2>
                <div className="night">
                  {capitalize(GetDay(getTimeStamp(topEfficiencyWeek.date_sleep)))} op {GetTommorow(getTimeStamp(topEfficiencyWeek.date_sleep))}
                </div>
                <span className={props.getEfficiencyClass(Math.round(topEfficiencyWeek.content.sleep_efficiency))}>
                  {Math.round(topEfficiencyWeek.content.sleep_efficiency)} %
                </span>
              </div>
              :false}
            */}
          <BarChartThisWeek labels={labels} values={values} />
          <NightsThisWeek entriesThisWeek={entriesThisWeek} getEfficiencyClass={props.getEfficiencyClass} lastWeekDates={lastWeekDates} startDate={startDate}/>
          </>
          :false}
        </>
      }
    </div>
  );
};

export default SleepDiaryThisWeek;
