import React, { useState, useEffect } from "react";
import t from "../../../translate";
import { useSelector, useDispatch } from "react-redux";
import SleepDiaryWizzardStep1 from "./wizzard/step_1.js";
import SleepDiaryWizzardStep2 from "./wizzard/step_2.js";
import SleepDiaryWizzardStep3 from "./wizzard/step_3.js";
import SleepDiaryWizzardStep4 from "./wizzard/step_4.js";
import SleepDiaryWizzardStep5 from "./wizzard/step_5.js";
import SleepDiaryWizzardStep6 from "./wizzard/step_6.js";
import { getClone } from "../../../utils";
import Title from "./wizzard/title.js";
import apiCall from "../../../api";
import { setSleepDiary } from "../../../../actions";
import { GetHour, GetMinutes } from "../../../helpers/convertTimestamp.js";
import NotificationBox from "../../../alert/notification";
import moment from 'moment';

let saveTimeout = false;

const SleepDiaryWizard = (props) => {

  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);
  const intervention = useSelector(state => state.intervention);
  const sleepDiary = useSelector(state => state.sleepDiary);

  const dispatch = useDispatch();

  const emptyContent = {sleepdiary:[],feel:'',grade_sleep:'',screenTimeBeforeBed:'false', };

  const [bedTimeHour, setBedTimeHour] = useState(22);
  const [bedTimeQuarter, setBedTimeQuarter] = useState(0);
  const [wakeupTimeHour, setWakeupTimeHour] = useState(7);
  const [wakeupTimeQuarter, setWakeupTimeQuarter] = useState(0);
  const quarters = [0,15,30,45];

  useEffect(() => {
    updateSleepTimestamps(new Date(dateSleep), wakeUpDay)
  }, [bedTimeHour,bedTimeQuarter,wakeupTimeHour,wakeupTimeQuarter]);

  const [activeStep, setActiveStep] = useState(0);

  const setContent = (newContent) => {
    let tempDatesToFill =JSON.parse(JSON.stringify(props.datesToFill))
    tempDatesToFill[props.activeDateIndex].content = newContent

    props.setDatesToFill(tempDatesToFill)
    save(newContent)
  }

  const next = () => {
    let next = activeStep + 1;
    if(intervention.settings.include_sleepdiary_light != 1 && next == 3){
      next = 5;
    }
    let tempContent = JSON.parse(JSON.stringify(props.datesToFill[props.activeDateIndex].content));
    tempContent.activeStep = next
    setContent(tempContent)
    setActiveStep(next)
    window.scrollTo(0, 0);
  }
  const back = () => {
    let back = activeStep - 1;
    if(intervention.settings.include_sleepdiary_light != 1 && back == 4){
      back = 2;
    }
    let tempContent = JSON.parse(JSON.stringify(props.datesToFill[props.activeDateIndex].content));
    tempContent.activeStep = back
    setContent(tempContent)
    setActiveStep(back)
    window.scrollTo(0, 0);
  }

  const [dateSleep, setDateSleep] = useState(false)

  useEffect(() => {
    if(props.datesToFill.length > 0){
      if(props.datesToFill[props.activeDateIndex].content){
        ///alleen update als er wat verandert is anders kom je in een loopie
        let updateContent = false;
        if(GetHour(props.datesToFill[props.activeDateIndex].content.timestamp_to_bed) != bedTimeHour){
          setBedTimeHour(GetHour(props.datesToFill[props.activeDateIndex].content.timestamp_to_bed))
          updateContent = true;
        }
        if(quarters.indexOf(GetMinutes(props.datesToFill[props.activeDateIndex].content.timestamp_to_bed)) != bedTimeQuarter){
          setBedTimeQuarter(quarters.indexOf(GetMinutes(props.datesToFill[props.activeDateIndex].content.timestamp_to_bed)))
          updateContent = true;
        }
        if(GetHour(props.datesToFill[props.activeDateIndex].content.timestamp_wake_up) != wakeupTimeHour){
          setWakeupTimeHour(GetHour(props.datesToFill[props.activeDateIndex].content.timestamp_wake_up))
          updateContent = true;
        }
        if(quarters.indexOf(GetMinutes(props.datesToFill[props.activeDateIndex].content.timestamp_wake_up)) != wakeupTimeQuarter){
          setWakeupTimeQuarter(quarters.indexOf(GetMinutes(props.datesToFill[props.activeDateIndex].content.timestamp_wake_up)))
          updateContent = true;
        }
        if(props.datesToFill[props.activeDateIndex].date_sleep != dateSleep){
          setDateSleep(props.datesToFill[props.activeDateIndex].date_sleep)
        }
        if(updateContent){
          setContent(props.datesToFill[props.activeDateIndex].content)
        }
        ///setActivestep
        if(props.datesToFill[props.activeDateIndex].content.activeStep && props.datesToFill[props.activeDateIndex].content.activeStep != activeStep){
          setActiveStep(props.datesToFill[props.activeDateIndex].content.activeStep)
        }
      } else {
        setDateSleep(props.datesToFill[props.activeDateIndex].date_sleep)
        setBedTimeHour(22);
        setBedTimeQuarter(0);
        setWakeupTimeHour(7);
        setWakeupTimeQuarter(0);
        setContent(emptyContent)
      }

    }
  }, [props.datesToFill, props.activeDateIndex]);

  const [notificationOptions, setNotificationOptions] = useState('');

  const checkIfMustAnswersAreSet = (tempDatesToFill, finish) => {
    if(activeStep == 5 && (tempDatesToFill.feel == "" || tempDatesToFill.grade_sleep == "") && finish == 1){
      if(tempDatesToFill.feel == ""){
        document.getElementById("cph_feel").classList.add("empty_must");
      }
      if(tempDatesToFill.grade_sleep == ""){
        document.getElementById("cph_grade_sleep").classList.add("empty_must");
      }
      setNotificationOptions({
        show: "true",
        text: t("Je hebt niet alle verplichte velden ingevuld"),
        confirmText: t("Ok")
      });
      return false;
    } else {
      return true;
    }
  }

  const save = (tempDatesToFill, finish = 0) => {
    if(dateSleep && checkIfMustAnswersAreSet(tempDatesToFill, finish)){
      clearTimeout(saveTimeout);
      saveTimeout = setTimeout(() => {
        apiCall({
          action: "save_sleep_diary",
          token: auth.token,
          data: {
            intervention_id: parseInt(activeIntervention),
            date_sleep: dateSleep,
            content: tempDatesToFill,
            finish: finish
          }
        }).then(resp => {
          if(resp.finished == 1){
            props.setPart("this-week")
            props.setShowFinishNotification(true)

            let tempDatesToFill = JSON.parse(JSON.stringify(props.datesToFill));
            tempDatesToFill.splice(props.activeDateIndex, 1);
            props.setDatesToFill(tempDatesToFill)

            if(tempDatesToFill.length == 0){
              props.setWizardAvailable(false)
            }
          }

        });
      }, 200); // als je snel switched dan wordt er niks gesaved bij een les switch...

    }
  }

  const [wakeUpDay, setWakeUpDay] = useState(false)

  useEffect(() => {
    let tempWakeUpDay = new Date(dateSleep);

    tempWakeUpDay.setDate(tempWakeUpDay.getDate() + 1)
    setWakeUpDay(tempWakeUpDay)
    updateSleepTimestamps(new Date(dateSleep), tempWakeUpDay)
  }, [dateSleep]);

  const toTimestamp = (date, hour, minutes = '00') => {
    if(minutes == 0){
      minutes = '00'
    }

    ///opdat 0 erbij zitten
    date = new Date(date)

    ///moment mooier eigenlijk
    //date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    date = moment(date).format().split('T')[0];

    ///voeg het gezette uur en de gezette minuten toe en maand - 1 !!!!
    date = new Date(date.split("-")[0], date.split("-")[1] - 1, date.split("-")[2], hour, minutes)

    return Date.parse(date) / 1000;
  }

  const updateSleepTimestamps = (to_bed_date, wake_up_date) => {

    ///2022-3-31 niet updaten voordat beiden gezet zijn
    if(!to_bed_date || !wake_up_date){
      return false;
    }

    let tempContent = emptyContent;
    if(props.datesToFill[props.activeDateIndex].content){
      tempContent = JSON.parse(JSON.stringify(props.datesToFill[props.activeDateIndex].content));
    }

    ///2022-3-31
    ///convert naar dd-mm-jj (dat was de basis) zelfde format als to_bed_date
    to_bed_date = moment(to_bed_date).format().split('T')[0];
    wake_up_date = moment(wake_up_date).format().split('T')[0];

    // ga ik na twaalven naar bed
    if(bedTimeHour < 10){
      tempContent.timestamp_to_bed = toTimestamp(wake_up_date, bedTimeHour, quarters[bedTimeQuarter]);
    } else {
      tempContent.timestamp_to_bed = toTimestamp(dateSleep, bedTimeHour, quarters[bedTimeQuarter]);
    }

    // ga ik voor twaalven naar bed
    if(wakeupTimeHour > 20){
      tempContent.timestamp_wake_up = toTimestamp(dateSleep, wakeupTimeHour, quarters[wakeupTimeQuarter]);
    } else {
      tempContent.timestamp_wake_up = toTimestamp(wake_up_date, wakeupTimeHour, quarters[wakeupTimeQuarter]);
    }

    ///hij gaat even naar 108000.... ??? is dat nog steeds
    if(tempContent.timestamp_wake_up > 108000 && tempContent.timestamp_wake_up <= tempContent.timestamp_to_bed){
      if(bedTimeQuarter == 3){
        setWakeupTimeHour(bedTimeHour + 1);
        setWakeupTimeQuarter(0);
      } else {
        setWakeupTimeHour(bedTimeHour)
        setWakeupTimeQuarter(bedTimeQuarter + 1);
      }

    } else {
      setContent(tempContent)
    }
  }

  const getIndexTime = (sleepdiary, timestamp) => {
    for(let i = 0 ; i < sleepdiary.length ; i++){
      for(let ii = 0 ; ii < 4 ; ii++){
        if(sleepdiary[i].quarters[ii].timestamp == timestamp)
        {
          return i;
        }
      }
    }
  }

  return (
    <div className="wizard">
      {dateSleep && wakeUpDay ?
        <Title
          activeStep={activeStep}
          dateSleep={new Date(dateSleep)}
          wakeUpDay={wakeUpDay}
          datesToFill={props.datesToFill}
          activeDateIndex={props.activeDateIndex}
          setActiveDateIndex={props.setActiveDateIndex}
          />
        :<></>}

        {activeStep == 0 ?
          <SleepDiaryWizzardStep1
            bedTimeHour={bedTimeHour}
            setBedTimeHour={setBedTimeHour}
            bedTimeQuarter={bedTimeQuarter}
            setBedTimeQuarter={setBedTimeQuarter}
            wakeupTimeHour={wakeupTimeHour}
            setWakeupTimeHour={setWakeupTimeHour}
            wakeupTimeQuarter={wakeupTimeQuarter}
            setWakeupTimeQuarter={setWakeupTimeQuarter}
          />
          :<></>
        }
        {activeStep == 1 ?
          <SleepDiaryWizzardStep2
            bedTimeHour={bedTimeHour}
            bedTimeQuarter={bedTimeQuarter}
            dateSleep={new Date(dateSleep)}
            wakeUpDay={wakeUpDay}
            toTimestamp={toTimestamp}
            wakeupTimeHour={wakeupTimeHour}
            wakeupTimeQuarter={wakeupTimeQuarter}
            setContent={setContent}
            content={props.datesToFill[props.activeDateIndex].content}
            getIndexTime={getIndexTime}
            />
          :<></>
        }
        {activeStep == 2 ?
          <SleepDiaryWizzardStep3
            setContent={setContent}
            content={props.datesToFill[props.activeDateIndex].content}
            getIndexTime={getIndexTime}
            />
          :<></>
        }
        {activeStep == 3 ?
          <SleepDiaryWizzardStep4
            setContent={setContent}
            content={props.datesToFill[props.activeDateIndex].content}
            getIndexTime={getIndexTime}
            />
          :<></>
        }
        {activeStep == 4 ?
          <SleepDiaryWizzardStep5
            setContent={setContent}
            content={props.datesToFill[props.activeDateIndex].content}
            getIndexTime={getIndexTime}
            />
          :<></>
        }
        {activeStep == 5 ?
          <SleepDiaryWizzardStep6
            setContent={setContent}
            content={props.datesToFill[props.activeDateIndex].content}
            />
          :<></>
        }
        <table className="steps">
          <tbody>
            <tr>
              <td>
                {activeStep > 0 ?
                  <span className='btn btn-back' onClick={()=>back()}>{t('Terug')}</span>
                  :<></>}
              </td>
              <td>
                {activeStep != 5 ?
                  <span className='btn btn-primary' onClick={()=>next()}>{t('Verder')}</span>
                  :<></>}
                {activeStep == 5 ?
                  <span className='btn btn-primary' onClick={()=>save(props.datesToFill[props.activeDateIndex].content, 1)}>{t('Opslaan')}</span>
                  :<></>}
              </td>
            </tr>
          </tbody>
        </table>
        <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions} />
    </div>
  );
};

export default SleepDiaryWizard;
