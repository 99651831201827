import React from 'react';

const Video = (props) => {
  return (
    <div className='video center'>
      <div className={"embed " + (props.part.url == "" ? 'emtpy':'')}>
        <iframe src={props.part.url}></iframe>
        <div className="embedCode showOnHover">
          <input
            type="text"
            name={`part_${props.index}`}
            label=""
            placeholder="Embed url e.g. https://www.youtube-nocookie.com/embed/8Bkwp0ZCsdfrdw"
            defaultValue={props.part.url}
            onChange={(e) => props.updatePart(props.index, 'url', e.target.value)}
            />
        </div>
      </div>
    </div>
  );
}

export default Video;
