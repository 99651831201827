import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import apiCall from "../api";
import { useSelector } from "react-redux";
import t from "../translate";
import SortObjectArray from "../helpers/sortObjectArray.js";
import StudentDetails from "./overview/researcher.js";
import ReactTooltip from "react-tooltip";
import LeftMenu from "../leftmenu";
import AdminOptions from "../dashboard/admin/options.js";
import LoadScreen from "../loadScreen";
import Pagination from "../helpers/pagination.js";

const InterventionUsers = props => {

  const history = useHistory();

  const auth = useSelector(state => state.auth);

  const [loaded, setLoaded] = useState(-1);
  const [interventions, setInterventions] = useState([]);
  const [students, setStudents] = useState([]);
  const [nrStudentsToShow, setNrStudentsToShow] = useState(0);
  const [coaches, setCoaches] = useState(false);
  const [filterOnIntervention, setFilterOnIntervention] = useState('');
  const [filterOnCoach, setFilterOnCoach] = useState('');
  const [nrStudentsPagination, setnrStudentsPagination] = useState(false);

  useEffect(() => {
    //voldoende rechten
    if (typeof auth.rights.students_access === 'undefined' || !auth.rights.students_access) {
      history.push("/");
    }
    //alle studenten ophalen
    apiCall({
      action: "get_all_students_with_details",
      token: auth.token,
      data: {}
    }).then(resp => {
      setStudents(resp.students);
      setInterventions(resp.interventions)
      setnrStudentsPagination(resp.students.length)
      setLoaded(true)
    });

    //alle coaches ophalen
    apiCall({
      action: "get_all_coaches",
      token: auth.token,
      data: {}
    }).then(resp => {
      setCoaches(resp.coaches);
    });

  }, [])

  const searchView = () => {
    setOpenSearch(true)
    //setDetailsVisible(false)
  }

  const [studentId, setStudentId] = useState(0);

  const [openSearch, setOpenSearch] = useState(false)
  const [searchFor, setSearchFor] = useState("")
  const [searchForDelayed, setSearchForDelayed] = useState("")
  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")
  const [paginationFrom, setPaginationFrom] = useState(0)
  const [paginationStep, setPaginationStep] = useState(50)

  ////Kleine delay alvorens gezocht gaat worden gezien de lijst met studenten zo lang is
  useEffect(
    () => {
      let timerSearchFor = setTimeout(() => setSearchFor(searchForDelayed), 300);
      return () => {
        clearTimeout(timerSearchFor);
      };
    },
    [searchForDelayed]
  )

  function changeSort(type){
    setSortDirection(sortDirection == "asc" ? "desc":"asc")
    setSort(type)
    setPaginationFrom(0)
  }
  function clearSearched(){
    setSearchForDelayed("")
    setSearchFor("")
  }

  const [showDetailsStudents, setShowDetailsStudents] = useState([]);

  function showDetails(id){
    if(!showDetailsStudents.includes(id))
    {
      let tempShowDetailsStudents = [...showDetailsStudents];
      tempShowDetailsStudents.push(id)
      setShowDetailsStudents(tempShowDetailsStudents)
    } else {
      hideDetails(id)
    }
  }

  function hideDetails(id){
    let tempShowDetailsStudents = [...showDetailsStudents];
    const index = tempShowDetailsStudents.indexOf(id);
    if (index > -1) { // only splice array when item is found
      tempShowDetailsStudents.splice(index, 1); // 2nd parameter means remove one item only
      setShowDetailsStudents(tempShowDetailsStudents)
    }
  }

  const getInterventionTitle = (index) => {
    var this_intervention = interventions.find(int => parseInt(int.id) === parseInt(index));
    if(this_intervention){
      return this_intervention.title;
    }
  };
  const getCoachName = (index) => {
    if(coaches){
      var this_coach = coaches.find(coach => coach.id === index);
      if(this_coach){
        return this_coach.login;
      }
    }

  };

  const [searchedStudents, setSearchedStudents] = useState([])
  const [studentsToShow, setStudentsToShow] = useState([])

  useEffect(
    () => {
      let searchForLowerCase = searchFor.toLowerCase()
      let tempSearchedStudents = [];
      let push = true;
      for (let i = 0; i < students.length; i++) {
        push = true;
        let name = students[i].id + " " + students[i].firstname + " " + students[i].insertion +  " " + students[i].lastname ;
        if (!name.toLowerCase().includes(searchForLowerCase) && searchForLowerCase != "") {
          push = false;
        }
        if(filterOnIntervention != ""){
          if(students[i].intervention_id != filterOnIntervention){
            push = false;
          }
        }
        if(filterOnCoach != ""){
          if(students[i].hasCoach != filterOnCoach){
            push = false;
          }
        }
        if(push){
          tempSearchedStudents.push(students[i])
        }
      }

      setSearchedStudents(tempSearchedStudents)

      setnrStudentsPagination(tempSearchedStudents.length)

      if(tempSearchedStudents.length < paginationFrom){
        setPaginationFrom(0)
      }

      setStudentsToShow(tempSearchedStudents.slice(paginationFrom, (paginationStep + paginationFrom)));
    },
    [searchFor, filterOnIntervention, students, filterOnCoach, paginationFrom]
  )

  useEffect(
    () => {
      if(sort != ""){
        let tempSearchedStudents = [...searchedStudents];
        tempSearchedStudents.sort(SortObjectArray(sort, sortDirection));
        setSearchedStudents(tempSearchedStudents)
        setStudentsToShow(tempSearchedStudents.slice(paginationFrom, (paginationStep + paginationFrom)));
      }
    },
    [sort, sortDirection]
  )

  return (
    <div className="container dashboard admin">
    <LeftMenu />
    <AdminOptions activity={true}/>
    <div className={"overview_students coachInterface" + (openSearch ? ' openSearch':'')}>

      {loaded == -1 ?
        <LoadScreen/>
        :
        false
      }
      <table className="theIntervention">
        <tbody>
          <tr>
            <td>
              {
                /*
                <h2>{t('Deelnemers')}</h2>
                */
              }
              {!openSearch && (searchFor != "" || filterOnIntervention != '' || filterOnCoach != '') ?
                <div className="holder_searched_for">
                  {searchFor != ""  ?
                    <span className="btn btn-secondary filter" onClick={()=>clearSearched()}>
                      {searchFor} <i className="fas fa-times"></i>
                    </span>
                  :''}
                  {filterOnIntervention != '' ?
                    <span className="btn btn-secondary filter" onClick={()=>setFilterOnIntervention('')}>
                      {getInterventionTitle(filterOnIntervention)} <i className="fas fa-times"></i>
                    </span>
                  :''}
                  {filterOnCoach != '' ?
                    <span className="btn btn-secondary filter" onClick={()=>setFilterOnCoach('')}>
                    {!coaches ?
                      <>
                      <i className="fas fa-spinner fa-spin" ></i> <i className="fas fa-times"></i>
                      </>
                      :
                      <>
                      {getCoachName(filterOnCoach)} <i className="fas fa-times"></i>
                      </>
                    }
                    </span>
                  :''}
                </div>
              :false}
            </td>
            <td className="options">

              {
                /*
                <button className="btn btn-primary btn-sm btn-trans" onClick={addUser}>
                  <i className="fas fa-plus"></i>
                </button>
                */
              }
              <i className={"fas fa-search pointer" + (openSearch ? ' hide':' ')} onClick={()=>searchView()}></i>
              <div className="search">
              {coaches ?
                <select onChange={(e)=>setFilterOnCoach(e.target.value)} value={filterOnCoach}>
                  <option value="">{t("Alle coaches")}</option>
                  {coaches.map((coach, index) => {
                    return (
                      <option key={index} value={coach.id}>{coach.login}</option>
                    )
                  })}
                </select>
                :false}
                <select onChange={(e)=>setFilterOnIntervention(e.target.value)} value={filterOnIntervention}>
                  <option value="">{t("Alle interventies")}</option>
                  {interventions.map((intervention, index) => {
                    return (
                      <option key={index} value={intervention.id}>{intervention.title}</option>
                    )
                  })}
                </select>
                <input type="text" value={searchForDelayed} placeholder={t("Zoek deelnemer")} onChange={(e) => setSearchForDelayed(e.target.value)}/>
                <i className="far fa-times-circle pointer" onClick={()=>setOpenSearch(false)}></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="holder">
        <div className="users">
        <div className="total">
          {t("Totaal gevonden")}: {nrStudentsPagination}
        </div>
        {studentsToShow.length > 0 ?
          <table className="overview_students">
            <tbody>
              <tr>
                <th className="pointer" onClick={() => changeSort("id")}>#</th>
                <th className="pointer" onClick={() => changeSort("interventie")}>{t("Intervention")} </th>
                <th className="pointer" onClick={() => changeSort("coach")}>{t("Coach")} </th>
              </tr>
              {studentsToShow.map(user => {
                return (
                  <>
                  <tr
                    key={user.id}
                    id={"user_" + user.id}
                    className={"pointer rowHover" + (showDetailsStudents.includes(user.id) ? ' active':'')}
                    onClick={()=>showDetails(user.id)}
                  >
                    <td>{user.id}</td>
                    <td>{getInterventionTitle(user.intervention_id)}</td>
                    <td>{!coaches ?
                      <i className="fas fa-spinner fa-spin" ></i>
                      :
                      getCoachName(user.hasCoach)}</td>
                  </tr>
                  {showDetailsStudents.includes(user.id) ?
                    <tr className="details">
                      <td colSpan="5">
                        <StudentDetails
                        student={user}
                        interventions={interventions}
                        coaches={coaches}
                        students={students}
                        setStudents={setStudents}
                        hideDetails={hideDetails}/>
                      </td>
                    </tr>
                    :false}
                  </>
                );
              })}
            </tbody>
          </table>
          :false}
          {nrStudentsPagination > 0 ?
            <>
            <Pagination
                nrItems={nrStudentsPagination}
                paginationFrom={paginationFrom}
                setPaginationFrom={setPaginationFrom}
                paginationStep={paginationStep}
            />
            </>
            :
            <>
            {loaded != -1 ?
              <div className="no_results">
                {t("Geen studenten gevonden")}
              </div>
              :
              false
            }
            </>

          }
        </div>
      </div>
    </div>
    </div>
  );
}
export default InterventionUsers;
