import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import t from "../../translate";
import ConfirmBox from "../../alert/confirm";

const DeleteCoach = (props) => {

  const [confirmOptions, setConfirmOptions] = useState({});
  const [toDeleteIndex, setToDeleteIndex] = useState(-1); /// doet niks maar is nodig in ingeladen custom confirm
  const [deleteCoach, setDeleteCoach] = useState(false);

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  function deleteConfirm() {
    let confirmOptionsToSet = {
      show: "true",
      text: "<h4>"+t("Weet u zeker dat u deze deelnemer (#"+props.student.id+") wilt verwijderen?")+"</h4>",
      cancelText: t("Annuleer"),
      confirmText: t("Verwijder"),
      confirmAction: () => doDeleteCoach()
    };
    //setToDeleteIndex(-1);
    setConfirmOptions(confirmOptionsToSet);
  }

  function doDeleteCoach(){
    props.student.removeUser = true;
    if (props.student.removeUser && props.student.rights.interventions.length > 1) {
      let intervention_index = 0;
      //zo ja, dan alleen coach loskoppelen van deze interventie
      props.student.rights.interventions.map((interv, index) => {
        if (intervention.id == interv.id) {
          intervention_index = index;
        }
      });
      props.student.rights.interventions.splice(intervention_index, 1);
      props.student.removeUser = false;
    }

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: props.student
      }
    }).then(resp => {
      if(props.setStudents){
        const studentsN = [...props.students];

        for(let i = 0 ; i < studentsN.length ; i++){
          if (studentsN[i].id === props.student.id){
            studentsN.splice(i, 1);
            break;
          }
        }

        props.setStudents(studentsN)
      }

      //props.deleteUser(props.student);
    });
  }

  return (
    <>
    <span className="btn btn-primary red" onClick={()=>deleteConfirm()}>{t("Verwijder gebruiker")} <i className="fa fa-times"></i></span>
    <ConfirmBox confirmOptions={confirmOptions} setConfirmOptions={setConfirmOptions} setToDeleteIndex={setToDeleteIndex}/>
    </>
  )
}
export default DeleteCoach;
