import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import apiCall from "../../api";
import t from '../../translate';
import standardAvatar from "../../../images/course/standard/avatar.png";
import Footer from './footer'
import LoadScreen from '../../loadScreen';

const Step4 = ({ registrationTexts, changeLanguage, registrationData, setRegistrationData }) => {

  const [coaches, setCoaches] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [coachChosen, setCoachChosen] = useState(0);
  const [languageCode, setLanguageCode] = useState('nl')
  const [noCoachesText, setNoCoachesText] = useState("Een moment...")
  const [showLoadScreen, setShowLoadScreen] = useState(false)
  const [steps, setSteps] = useState('stap 2 van 3');

  const url = useSelector(state => state.url);

  useEffect(() => {
    if (registrationData.language_id === 1) {
      setLanguageCode('nl')
    } else {
      setLanguageCode('eng')
    }
    //registratie RCT LifeHack en i-SLeep hebben een stap meer
    if (registrationData.intervention_id === 45 || registrationData.intervention_id === 47) {
      setSteps('stap 3 van 4');
    }
 }, [registrationData])

 /* useEffect(() => {
  setCoachChosen(dataStep4.coachChosen)
 }, [dataStep4]) */

  useEffect(() => {
    window.scrollTo(0,0)
    setShowLoadScreen(true)

    apiCall({
      action: "get_available_coaches",
      data: {
        language_id: registrationData.language_id,
        intervention_id: registrationData.intervention_id
      }
    }).then(resp => {
      if (resp.coaches.length < 1) {
        setNoCoachesText("Er zijn op dit moment helaas geen coaches vrij. Je kunt daardoor je aanmelding helaas niet afronden. Probeer het op een later tijdstip nogmaals.")
      } else {
        setCoaches(resp.coaches);
      }
      setLanguages(resp.languages)
      setShowLoadScreen(false)
    })
  }, [])

  useEffect(() => {
    if (coachChosen > 0) {
      let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
      registrationDataTemp.coachChosen = coachChosen
      registrationDataTemp.step = 5
      setRegistrationData(registrationDataTemp)
      window.scroll(0,0)
    }
  }, [coachChosen])

  const getCoaches = () => {
    const content = [];
    coaches.map((coach, index) => {
      if(getBio(coach)){
        content.push(
          <div key={index} className={"coach pointer" + (coachChosen == coach.id ? ' active':'')} onClick={() => setCoachChosen(coach.id)}>
            <div className='image' style={{ backgroundImage: "url("+getImage(coach)+")" }}>
            </div>
            <h2>{coach.name}</h2><br />
            <div>{getBio(coach)}</div>
            <div className="btn_holder">
              <span className="btn red">
                {t('Kies')}
              </span>
            </div>
          </div>
        )
      }
    })

    return content
  }
  const getImage = (coach) => {
    if(coach.profile_pic == ""){
      return standardAvatar;
    } else {
      //return url+"/uploads/user/"+ coach.id + "/" + coach.profile_pic
      return url + "/api/?a=get_profile_pic_coach&user_id=" + coach.id + "&" + new Date().getTime()
    }
  }
  const getBio = (coach) => {
    if(languages.length > 0){
      let this_language_obj = languages.filter(function (language) {
        return language.id == registrationData.language_id
      })
      if(coach.languages.includes(this_language_obj[0].code)){
        if(this_language_obj[0].code == "nl"){
          return coach.bio
        } else {
          let this_bioTranslation_obj = coach.bioTranslations.filter(function (translation) {
            return translation.code == this_language_obj[0].code
          });
          if(this_bioTranslation_obj.length > 0){
            return coach.bioTranslations[coach.bioTranslations.indexOf(this_bioTranslation_obj[0])].content
          }
        }
      }
    }
  }

  function setStep(step) {
    let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
    registrationDataTemp.step = step
    setRegistrationData(registrationDataTemp)
    window.scrollTo(0,0)
  }

  return (
    <>
      <div className="step5">
        {
          //Bij aanmeldingen voor RCT iSleep kan men niet terug naar de vragenlijsten, er is al gerandomiseerd immers
          parseInt(registrationData.intervention_id) !== 47 && parseInt(registrationData.intervention_id) !== 45 ?
            <button type="button" className="btn prev" onClick={() => setStep(3)}>{t("Terug")}</button> : <></>
        }
        <div className="container">
          <div className="step">
            <b>{t(steps)}</b> {t("keuze coach")}
          </div>
          <div className="text">
            <div className='intro'>
              <h1>{registrationTexts.panel4.intro[languageCode]}</h1>
              <p dangerouslySetInnerHTML={{__html: registrationTexts.panel4.description[languageCode]}} />
            </div>
            {
            showLoadScreen ?
              <LoadScreen /> : <></>
            }
            <div className="coach_holder clearfix">
              {
                coaches.map((coach, index) => {

                    return(
                      <div key={index} className={"coach pointer" + (coachChosen == coach.id ? ' active':'')} onClick={() => setCoachChosen(coach.id)}>
                        <div className='image' style={{ backgroundImage: "url("+getImage(coach)+")" }} />
                        <div className='info'>
                          <h2>{coach.name}</h2>
                          <div>{getBio(coach)}</div>
                          <div className="btn_holder">
                            <span className="btn red">
                              {t('Kies')}
                            </span>
                          </div>
                        </div>
                      </div>
                    )

                })
              }
            </div>
        </div>
        </div>
      </div>
      {/* <Footer language={registrationData.language_id} changeLanguage={changeLanguage} /> */}
    </>
  )

}

export default Step4;
