import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { interventionObject } from './registrationTextObjects';
import AddImage from "../content/edit/parts/helpers/addImage";
import MediaLibrary from "../medialibrary";
import { setChosenImage, setIntervention, resetIntervention } from "../../actions";
import t from "../translate"

const Panel2 = ({ registrationTexts, setRegistrationTexts, getTextInput, getContentEditable, getEditor, interventions, addStudentsReview, removestudentsReview , addMediaContent, removeMediaContent, setMediaContentImage }) => {


  const dispatch = useDispatch();

   ////////////////////////////////////////////
  /////////Media library functies om door te geven
  ///////////////////////////////////////////
  const [mediaLibraryVisible, setMediaLibraryVisible] = useState("hide");
  const medialibrary = useSelector(state => state.mediaLibrary);
  //////////////////////
  ///Save if chosen image uit bieb is not empty alleen bij overeenkomstige id
  if(medialibrary.chosen_image !== "") {
    // empty chosen image status
    dispatch(setChosenImage(''))
    dispatch(resetIntervention())
    //setContent
    let parts = medialibrary.index.split('_')
    setMediaContentImage(parts[0], parts[1], parts[2], medialibrary.chosen_image)
  }

  function showMediaLibrary(index) {
   let parts = index.split('_')
   let intervention = interventions.find(int => parseInt(int.id) === parseInt(parts[0]))
   if (typeof intervention !== "undefined") {
      dispatch(setIntervention(intervention.id, 0, intervention.title, {}))
      setTimeout(() =>  {
         setMediaLibraryVisible("show")
         document.body.style.overflow = "hidden"
         dispatch(setChosenImage("", index))
      }, 300)
   }    
  }

   useEffect(() => {
      //bevat array registrationTexts.panel2.interventions alle interventies?
      if (Object.entries(registrationTexts).length > 0 && interventions.length > 0) {
         let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
         let update = false;
         interventions.forEach((intervention, index) => {
            let findIt = registrationTexts.panel2.interventions.findIndex(int => {
               return int.id === intervention.id
            })
            if (findIt === -1) {
               let newIntervention = JSON.parse(JSON.stringify(interventionObject()))
               newIntervention.id = intervention.id
               registrationTextsTemp.panel2.interventions.push(newIntervention)
               update = true
            } 
         })
         if (update) {
            setRegistrationTexts(registrationTextsTemp)
         }
      }

   }, [registrationTexts, interventions])

   function addTableRow(cell1, cell2, cell3) {
      return (
         <tr><td>{cell1}</td><td>{cell2}</td><td>{cell3}</td></tr>
      )
   }

   return (
      <div>
         <MediaLibrary mediaLibraryVisible={mediaLibraryVisible} setMediaLibraryVisible={setMediaLibraryVisible} />
         <h2>{t("Scherm 2: Programma informatie")}</h2>
         {
            interventions.map((intervention, key) => {
               let interv = registrationTexts.panel2.interventions.find(int => {
                  return int.id === intervention.id
               })
               if (intervention.title.indexOf('(controle)') === -1) {

                  return (
                     <div key={key}>
                        <h5 dangerouslySetInnerHTML={{__html: intervention.title}}></h5>
                        {
                           typeof interv !== "undefined" ?
                              <div>
                                 <table className="table table-striped">
                                    <thead>
                                       <tr>
                                          <th width="20%"></th>
                                          <th width="40%">{t("Nederlands")}</th>
                                          <th width="40%">{t("Engels")}</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          addTableRow(
                                             t("Titel"), 
                                             getTextInput('panel2_interventions_'+intervention.id+'_title_nl', interv.title.nl, 'Titel NL'),
                                             getTextInput('panel2_interventions_'+intervention.id+'_title_eng', interv.title.eng, 'Titel ENG'),
                                          ) 
                                       }
                                       {
                                          addTableRow(
                                             t("Omschrijving"), 
                                             getEditor('panel2_interventions_'+intervention.id+'_description_nl', interv.description.nl,),
                                             getEditor('panel2_interventions_'+intervention.id+'_description_eng', interv.description.eng,),
                                          )
                                       }
                                       {
                                          addTableRow(
                                             t("Button scherm 3"), 
                                             getTextInput('panel2_interventions_'+intervention.id+'_button_nl', interv.button.nl, 'Button NL'),
                                             getTextInput('panel2_interventions_'+intervention.id+'_button_eng', interv.button.eng, 'Button ENG'),
                                          ) 
                                       }
                                       {
                                          addTableRow(
                                             t("Wachtlijst tekst"), 
                                             getEditor('panel2_interventions_'+intervention.id+'_waitinglist_nl', interv.waitinglist.nl,),
                                             getEditor('panel2_interventions_'+intervention.id+'_waitinglist_eng', interv.waitinglist.eng,),
                                          )
                                       }
                                       {
                                          addTableRow(
                                             t("Titel studenten reviews"), 
                                             getTextInput('panel2_interventions_'+intervention.id+'_studentsReviewsTitle_nl', interv.studentsReviewsTitle.nl, 'Titel NL'),
                                             getTextInput('panel2_interventions_'+intervention.id+'_studentsReviewsTitle_eng', interv.studentsReviewsTitle.eng, 'Titel ENG'),
                                          ) 
                                       }

                                       <tr>
                                          <td>{t("Student reviews")}</td>
                                          <td colSpan="2">
                                             <button className="btn btn-primary btn-sm btn-trans float-right" onClick={() => addStudentsReview(interv.id)}>
                                                + {t("Toevoegen")}
                                             </button>
                                          </td>
                                       </tr>
                                       {
                                          interv.studentsReviewsContent.map((item, key) => {
                                             return(
                                                <tr key={key}>
                                                   <td>
                                                      <button className="btn btn-danger btn-sm btn-trans float-right" onClick={() => removestudentsReview(interv.id, key)}>-&nbsp;{t("Verwijderen")}</button>
                                                   </td>
                                                   <td>
                                                      {getContentEditable('panel2_interventions_'+intervention.id+'_studentsReviewsContent_'+key+'_nl', item.nl, 'Studentreview NL')}
                                                   </td>
                                                   <td>
                                                      {getContentEditable('panel2_interventions_'+intervention.id+'_studentsReviewsContent_'+key+'_eng', item.eng, 'Studentreview ENG')}
                                                   </td>
                                                </tr>
                                             )
                                          })
                                       }

                                       {
                                          addTableRow(
                                             t("Titel media"), 
                                             getTextInput('panel2_interventions_'+intervention.id+'_mediaTitle_nl', interv.mediaTitle.nl, 'Titel NL'),
                                             getTextInput('panel2_interventions_'+intervention.id+'_mediaTitle_eng', interv.mediaTitle.eng, 'Titel ENG'),
                                          ) 
                                       }
                                       {/* {
                                          addTableRow(
                                             t("Youtube code"), 
                                             getTextInput('panel2_interventions_'+intervention.id+'_youtubeCode_nl', interv.youtubeCode.nl, 'Youtube code NL'),
                                             getTextInput('panel2_interventions_'+intervention.id+'_youtubeCode_eng', interv.youtubeCode.eng, 'Youtube code ENG'),
                                          ) 
                                       } */}

                                       <tr>
                                          <td>{t("Content media")}</td>
                                          <td colSpan="2">
                                             <button className="btn btn-primary btn-sm btn-trans float-right" onClick={() => addMediaContent(interv.id, 'youtube')}>
                                                {t("Toevoegen")} <i className="fa fa-play"></i>
                                             </button>
                                             <button className="btn btn-primary btn-sm btn-trans float-right" onClick={() => addMediaContent(interv.id, 'image')} style={{marginRight: '10px'}}>
                                                {t("Toevoegen")} <i className="fa fa-image"></i>
                                             </button>
                                          </td>
                                       </tr>
                                       {
                                          interv.mediaContent.map((item, key) => {
                                             return(
                                                <tr key={key}>
                                                   <td>
                                                      <button className="btn btn-danger btn-sm btn-trans float-right" onClick={() => removeMediaContent(interv.id, key)}>-&nbsp;{t("Verwijderen")}</button>
                                                   </td>
                                                   <td class="RegistrationTextsPanel2Image">
                                                      {
                                                      item.type === 'youtube' ?
                                                         <>{getTextInput('panel2_interventions_'+intervention.id+'_mediaContent_'+key+'_nl', item.nl, 'Youtube code NL')}</>
                                                         :
                                                         <AddImage index={intervention.id+'_'+key+'_nl'} showMediaLibrary={showMediaLibrary} image={item.nl} deleteImage={() => setMediaContentImage(intervention.id, key, 'nl', '')} />
                                                      }
                                                   </td>
                                                   <td class="RegistrationTextsPanel2Image">
                                                      {
                                                      item.type === 'youtube' ?
                                                         <>{getTextInput('panel2_interventions_'+intervention.id+'_mediaContent_'+key+'_eng', item.eng, 'Youtube code ENG')}</>
                                                         :
                                                         <>
                                                         <AddImage index={intervention.id+'_'+key+'_eng'} showMediaLibrary={showMediaLibrary} image={item.eng} deleteImage={() => setMediaContentImage(intervention.id, key, 'eng', '')} />
                                                         </>
                                                      }
                                                   </td>
                                                </tr>
                                             )
                                          })
                                       }

                                    </tbody>
                                 </table>
                                 <br /><br />
                              </div>
                              : <></>
                         }
                     </div>
                  )
               } else {
                  return <></>
               }

            })
         }
      </div>
   )
} 

export default Panel2
