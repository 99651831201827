import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../translate";
import apiCall from "../../api"
import Questionnaire from "./questionnaire.js";

const TranslatePlatformQuestionnaire = (props) => {

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  const [loaded, setLoaded] = useState(-1)
  const [questionnaires, setQuestionnaires] = useState([])
  const [activeQuestionnaire, setActiveQuestionnaire] = useState(0)

  useEffect(() => {
    apiCall({
       action: 'get_platform_questionnaires',
       token: auth.token,
       data: {}
    }).then(resp => {
       setQuestionnaires(resp.questionnaires)
       setLoaded(1)
    })
  }, [])

  useEffect(() => {
    if(questionnaires[0]){
        setActiveQuestionnaire(questionnaires[0]);
    }
  }, [questionnaires])

  const selectQuestionnaire = (e) => {
    setActiveQuestionnaire(questionnaires[e.target.value]);
  }

  return (
    <div>
      {loaded == -1 ? <i className="fas fa-spinner fa-spin" ></i>
      :
      <>
        {questionnaires.length > 0 ?
          <>
          <select className="custom-select" onChange={(e) => selectQuestionnaire(e)}>
          {
            questionnaires.map((questionnaire, index) => {
              return (
                <option key={index} value={index}>
                  {questionnaire.settings.title}
                </option>
              )
            })
          }
          </select>
          <br /><br />
          <Questionnaire activeQuestionnaire={activeQuestionnaire} activeTranslation={props.activeTranslation}  setActiveTranslation={props.setActiveTranslation} />
          </>
          :
          <div className="no_results">
            {t("Er zijn nog vragenlijsten.")}
          </div>
        }
        </>
       }

    </div>
  )

}

export default TranslatePlatformQuestionnaire;
