import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import apiCall from "../api";
import t from "../translate";
import LeftMenu from "../leftmenu";
import moment from "moment";
import SortObjectArray from "../helpers/sortObjectArray.js";
import AdminOptions from "../dashboard/admin/options.js";
import LoadScreen from "../loadScreen";
import Pagination from "../helpers/pagination.js";
import FreeEmail from "./details/free_email.js";

const Registrations = (props) => {

  const history = useHistory();

  const auth = useSelector(state => state.auth);

  const [loaded, setLoaded] = useState(-1);
  const [interventions, setInterventions] = useState([]);
  const [filterOnIntervention, setFilterOnIntervention] = useState('');
  const [registrations, setRegistrations] = useState([])
  const [openSearch, setOpenSearch] = useState(false)
  const [searchFor, setSearchFor] = useState("")
  const [searchForDelayed, setSearchForDelayed] = useState("")
  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")
  const [paginationFrom, setPaginationFrom] = useState(0)
  const [paginationStep, setPaginationStep] = useState(50)
  const [nrRegistrationsPagination, setnrRegistrationsPagination] = useState(false);
  const [deleted, setDeleted] = useState([]);
  const [showDetailsRegistrations, setShowDetailsRegistrations] = useState([]);

  useEffect(
    () => {
      let timerSearchFor = setTimeout(() => setSearchFor(searchForDelayed), 300);
      return () => {
        clearTimeout(timerSearchFor);
      };
    },
    [searchForDelayed]
  )

  function changeSort(type){
    setSortDirection(sortDirection == "asc" ? "desc":"asc")
    setSort(type)
    setPaginationFrom(0)
  }
  function clearSearched(){
    setSearchForDelayed("")
    setSearchFor("")
  }

  useEffect(() => {

    apiCall({
      action: "get_interventions",
      token: auth.token,
      data: {}
    }).then(resp => {
      setInterventions(resp.interventions)
      setLoaded(true)
    })

    //voldoende rechten?
    if (typeof auth.rights.unfinished_registrations_access === 'undefined' || !auth.rights.unfinished_registrations_access) { 
      history.push("/");
    }
    //alle studenten ophalen
    apiCall({
      action: "get_all_registrations",
      token: auth.token,
      data: {}
    }).then(resp => {
      setRegistrations(resp.registrations);

    });

  }, [])

  const [searchedRegistrations, setSearchedRegistrations] = useState([])
  const [registrationsToShow, setRegistrationsToShow] = useState([])

  useEffect(
    () => {
      let searchForLowerCase = searchFor.toLowerCase()
      let tempSearchedRegistrations = [];
      let push = true;
      for (let i = 0; i < registrations.length; i++) {
        push = true;
        let email = registrations[i].id + " " + registrations[i].email ;
        if (!email.toLowerCase().includes(searchForLowerCase) && searchForLowerCase != "") {
          push = false;
        }
        if(filterOnIntervention != ""){
          if(registrations[i].intervention_id != filterOnIntervention){
            push = false;
          }
        }
        if(push){
          tempSearchedRegistrations.push(registrations[i])
        }
      }

      setSearchedRegistrations(tempSearchedRegistrations)

      setnrRegistrationsPagination(tempSearchedRegistrations.length)

      if(tempSearchedRegistrations.length < paginationFrom){
        setPaginationFrom(0)
      }

      setRegistrationsToShow(tempSearchedRegistrations.slice(paginationFrom, (paginationStep + paginationFrom)));
    },
    [searchFor, filterOnIntervention, registrations, paginationFrom]
  )

  useEffect(
    () => {
      if(sort != ""){
        let tempSearchedRegistrations = [...searchedRegistrations];
        tempSearchedRegistrations.sort(SortObjectArray(sort, sortDirection));
        setSearchedRegistrations(tempSearchedRegistrations)
        setRegistrationsToShow(tempSearchedRegistrations.slice(paginationFrom, (paginationStep + paginationFrom)));
      }
    },
    [sort, sortDirection]
  )

  const getInterventionTitle = (index) => {
    var this_intervention = interventions.find(int => parseInt(int.id) === parseInt(index));
    if(this_intervention){
      return this_intervention.title;
    }
  };

  const deleteUser  = (c) => {
    let tempDelete = [...deleted];
    tempDelete.push(c.id)
    setDeleted(tempDelete)
  };

  function showDetails(id){
    if(!showDetailsRegistrations.includes(id))
    {
      let tempShowDetailsRegistrations = [...showDetailsRegistrations];
      tempShowDetailsRegistrations.push(id)
      setShowDetailsRegistrations(tempShowDetailsRegistrations)
    } else {
      hideDetails(id)
    }
  }

  function hideDetails(id){
    let tempShowDetailsRegistrations = [...showDetailsRegistrations];
    const index = tempShowDetailsRegistrations.indexOf(id);
    if (index > -1) { // only splice array when item is found
      tempShowDetailsRegistrations.splice(index, 1); // 2nd parameter means remove one item only
      setShowDetailsRegistrations(tempShowDetailsRegistrations)
    }
  }

  const searchView = () => {
    setOpenSearch(true)
    //setDetailsVisible(false)
  }

  return (
    <div className="container dashboard admin">
    <LeftMenu />
    <AdminOptions activity={true}/>
    <div className={"overview_registrations overview_students coachInterface" + (openSearch ? ' openSearch':'')}>

      {loaded == -1 ?
        <LoadScreen/>
        :
        false
      }
      <table className="theIntervention">
        <tbody>
          <tr>
            <td>
              {
                /*
                <h2>{t('Deelnemers')}</h2>
                */
              }
              {!openSearch && (searchFor != "" || filterOnIntervention != '') ?
                <div className="holder_searched_for">
                  {searchFor != ""  ?
                    <span className="btn btn-secondary filter" onClick={()=>clearSearched()}>
                      {searchFor} <i className="fas fa-times"></i>
                    </span>
                  :''}
                  {filterOnIntervention != '' ?
                    <span className="btn btn-secondary filter" onClick={()=>setFilterOnIntervention('')}>
                      {getInterventionTitle(filterOnIntervention)} <i className="fas fa-times"></i>
                    </span>
                  :''}

                </div>
              :false}
            </td>
            <td className="options">

              {
                /*
                <button className="btn btn-primary btn-sm btn-trans" onClick={addUser}>
                  <i className="fas fa-plus"></i>
                </button>
                */
              }
              <i className={"fas fa-search pointer" + (openSearch ? ' hide':' ')} onClick={()=>searchView()}></i>
              <div className="search">
                <select onChange={(e)=>setFilterOnIntervention(e.target.value)} value={filterOnIntervention}>
                  <option value="">{t("Alle interventies")}</option>
                  {interventions.map((intervention, index) => {
                    return (
                      <option key={index} value={intervention.id}>{intervention.title}</option>
                    )
                  })}
                </select>
                <input type="text" value={searchForDelayed} placeholder={t("Zoek deelnemer")} onChange={(e) => setSearchForDelayed(e.target.value)}/>
                <i className="far fa-times-circle pointer" onClick={()=>setOpenSearch(false)}></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="holder">
        <div className="total">
          {t("Totaal gevonden")}: {nrRegistrationsPagination}
        </div>
        <div className="registrations users">
        {/*<div className="total">{nrRegistrationsPagination}</div>*/}
        {registrationsToShow.length > 0 ?
          <table className="overview_registrations overview_students">
            <tbody>
              <tr>
                <th className="pointer" onClick={() => changeSort("id")}>#</th>
                <th className="pointer" onClick={() => changeSort("date_time_create")}>{t("Gestart op")} </th>
                <th className="pointer" onClick={() => changeSort("email")}>{t("Email")} </th>
                <th className="pointer" onClick={() => changeSort("interventie")}>{t("Intervention")} </th>
                <th className="pointer" onClick={() => changeSort("step")}>{t("Stap")} </th>
              </tr>
              {registrationsToShow.map(registration => {
                if(!deleted.includes(registration.id)){
                return (
                  <>
                  <tr
                    key={registration.id}
                    id={"registration_" + registration.id}
                    className={"pointer rowHover" + (showDetailsRegistrations.includes(registration.id) ? ' active':'')}
                    onClick={()=>showDetails(registration.id)}
                  >
                    <td>{registration.id}</td>
                    <td>{moment.unix(registration.date_time_create).format("DD-MM-YYYY HH:mm:ss")}</td>
                    <td>
                        {registration.email}
                    </td>
                    <td>{getInterventionTitle(registration.intervention_id)}</td>
                    <td>{registration.step}</td>

                  </tr>
                  {showDetailsRegistrations.includes(registration.id) ?
                    <tr className="details">
                      <td colSpan="5">
                        <div className="content">
                          <FreeEmail registration={registration} registrationsToShow={registrationsToShow} setRegistrationsToShow={setRegistrationsToShow}/>
                        </div>
                      </td>
                    </tr>
                    :false}
                  </>
                );
                }
              })}
            </tbody>
          </table>
          :false}
          {nrRegistrationsPagination > 0 ?
            <>
            <Pagination
                nrItems={nrRegistrationsPagination}
                paginationFrom={paginationFrom}
                setPaginationFrom={setPaginationFrom}
                paginationStep={paginationStep}
            />
            </>
            :
            <>
            {loaded != -1 ?
              <div className="no_results">
                {t("Geen registraties gevonden")}
              </div>
              :
              false
            }
            </>

          }
        </div>
      </div>
    </div>
    </div>
  )
}
export default Registrations;
