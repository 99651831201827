import React, { useState, useEffect, useRef } from "react"
import t from "../translate"

const Pagination = ({pagingStart, pagingLength, setPagingStart, setPagingLength, nrResults}) => {

    const [nrButtons, setNrButtons] = useState(0)
    const [activeButton, setActiveButton] = useState(1)

    useEffect(() => { 

        //calculate number of buttons
        const buttons = Math.ceil(nrResults / pagingLength)
        setNrButtons(buttons)

        //set active button
        if (pagingStart === 0) {
            setActiveButton(1)
        } else {
            setActiveButton(Math.ceil(pagingStart / pagingLength) + 1)
        }

    }, [pagingStart, pagingLength, setPagingStart, setPagingLength, nrResults])

    return (
        <div className="pagination">
            <div className="results">{nrResults} {t("resultaten")}</div>
                <nav aria-label="Page navigation example" className="paginatingButtons">
                    <ul className="pagination">
                        <li className={`page-item ${activeButton === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" onClick={() => {
                                if (activeButton > 1) {
                                    setPagingStart((activeButton - 2) * pagingLength)
                                    setActiveButton(activeButton - 1)
                                }
                            }}>&lt;</a>
                        </li>
                        {
                            Array.from({length: nrButtons}, (v, i) => i + 1).map((button, index) => {
                                return (
                                    <li key={index} className={`page-item ${activeButton === button ? 'active' : ''}`}>
                                        <a className="page-link" href="#" onClick={() => {
                                            setPagingStart((button - 1) * pagingLength)
                                            setActiveButton(button)
                                        }}>{button}</a>
                                    </li>
                                )
                            })
                        }
                        <li className={`page-item ${activeButton === nrButtons ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" onClick={() => {
                                if (activeButton < nrButtons) {
                                    setPagingStart(activeButton * pagingLength)
                                    setActiveButton(activeButton + 1)
                                }
                            }}>&gt;</a>
                        </li>
                    </ul>
                </nav>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {t("Toon")} &nbsp;
            <select className="form-control pagingLength" value={pagingLength} onChange={(e) => {setPagingLength(parseInt(e.target.value))}}>

                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                
            </select>
            &nbsp; {t("resultaten per pagina")}
        </div>
    )

}

export default Pagination