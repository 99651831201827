import React, { useState, useEffect } from "react";
import t from "../../translate";
import apiCall from "../../api";
import { useSelector, useDispatch } from "react-redux";
import { getClone } from "../../utils";
import { validateEmail } from "../../utils";
import Flatpickr from "react-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import parse from 'html-react-parser';

const RegistrationData = (props) => {

  const [student, setStudent] = useState({registration_data:[]});
  const [emailValid, setEmailValid] = useState(true);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if(props.student){
      props.student.registration_data = [];
      setStudent(props.student)
    }
  },[])

  function updateRegistrationData(e) {
    const studentN = getClone(student);
    studentN.registration_data[e.target.name] = e.target.value;
    setStudent(studentN)
  }
  function updateRegistrationDateData(value, key) {
    const studentN = getClone(student);
    studentN.registration_data[key] = value;
    setStudent(studentN)
  }
  function updateSetting(e) {
    const studentN = getClone(student);
    if(!(e.target.name == "bioTranslations" || e.target.name == "languages")){
        studentN[e.target.name] = e.target.value;
    } else if(e.target.name == "bioTranslations"){
      let bioTranslations = studentN.bioTranslations.filter(function (translation) {
        return translation.code === e.target.dataset.code
      });
      if(bioTranslations.length > 0){
        studentN.bioTranslations[studentN.bioTranslations.indexOf(bioTranslations[0])]['content'] = e.target.value
      } else {
        studentN.bioTranslations.push({"code":e.target.dataset.code,"content":e.target.value})
      }
    } else if(e.target.name == "languages"){
      if(studentN.languages.includes(e.target.value)){
        let indexOfLanguage = studentN.languages.indexOf(e.target.value);
        studentN.languages.splice(indexOfLanguage, 1);
      } else {
        studentN.languages.push(e.target.value)
      }
    }
    setStudent(studentN)
    if(props.setStudent){
      props.setStudent(studentN)
    }
  }

  function saveChanges(){

    setErrorMessage('');

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: student
      }
    }).then(resp => {

      if(resp.save_user){
        if(props.setStudents){
          const studentsN = [...props.students];

          for(let i = 0 ; i < studentsN.length ; i++){
            if (studentsN[i].id === student.id){
              studentsN[i] = student;
              break;
            }
          }
          props.setStudents(studentsN)
        }

        setMessage(t("Gegevens zijn gewijzigd"))
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else {
        setErrorMessage(parse(resp.msg));
      }

    });
  }

  /*
  const [newRegistrationDataField, setNewRegistrationDataField] = useState('');
  function addRegistrationDataField(){
    if(typeof student.registration_data.newRegistrationDataField == "undefined"){
      const studentN = getClone(student);
      studentN.registration_data[newRegistrationDataField] = '';
      setStudent(studentN)
    }
  }
  */

  function updateDateTimeBirth(){

  }

  return(
    <div className="registration_data">
    {
      /*
      <input type="text" value={newRegistrationDataField} onChange={(e)=>setNewRegistrationDataField(e.target.value)}/>
      <span className="btn add" onClick={()=>addRegistrationDataField()}>+</span>
      */
    }
    <table>
      <tbody>
        <tr className={message != "" ? 'saved':''}>
          <td>
            <label>{t("Gebruikersnaam")}</label>
            <br/>
            <input type="text" value={student.login} name='login' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Voornaam")}</label>
            <br/>
            <input type="text" value={student.firstname} name='firstname' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Tussenvoegsel")}</label>
            <br/>
            <input type="text" value={student.insertion} name='insertion' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Achternaam")}</label>
            <br/>
            <input type="text" value={student.lastname} name='lastname' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("E-mailadres")}</label>
            <br/>
            <input type="email" value={student.email} name='email' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Telefoonnummer")}</label>
            <br/>
            <input type="text" value={student.phone} name='phone' className="form-control" onChange={(e)=>updateSetting(e)}/>
            {Object.keys(student.registration_data).map(function(key) {
              if(['geboortedatum'].includes(key)){
                return <>
                <label>{t(key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " "))}</label>
                <br/>
                <Flatpickr
                  options={{ locale: Dutch, dateFormat: "d.m.Y" }}
                  value={student.registration_data[key] * 1000}
                  name={key}
                  className="form-control white"
                  onChange={date => {
                    updateRegistrationDateData(date[0].getTime()/1000, key)
                  }}
                />
                </>;
              }
            })}
          </td>
          {Object.keys(student.registration_data).length > 0 ?
            <>
            <td>
            {Object.keys(student.registration_data).map(function(key) {
              if(!['intervention_id', 'from', 'achternaam', 'voornaam', 'voorletters', 'gebruikersnaam', 'email', 'geboortedatum'].includes(key)){
                return <div key={key}>
                <label>{t(key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " "))}</label>
                <br/>
                <input type="text" value={student.registration_data[key]} name={key} className="form-control" onChange={(e)=>updateRegistrationData(e)} disabled={(key == "entry_id" ?true:false)}/>
                </div>;
              }
            })}
            </td>
            </>
            : false}

        </tr>
        <tr>
          <td Colspan={Object.keys(student.registration_data).length > 0 ? 2:0} className="align_right" >
            {errorMessage != "" ?
              <div className="error">
                {errorMessage}
              </div>
            :false}
            {message ?
              <span className="btn btn-primary btn-success">
                {message} <i className="fa fa-check"></i>
              </span>
              :
              <span className="btn btn-primary" onClick={()=>saveChanges()}>{t("Opslaan")}</span>
            }
          </td>
        </tr>
      </tbody>
    </table>

    </div>
  )
}

export default RegistrationData;
