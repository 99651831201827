import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ConfettiFullScreen from "./confettiFullscreen";
import OptionalLessons from "./optional_lessons.js";
import { setFinishedCourse, setActivePart, setActiveLesson, setShowLeftMenu } from "../../../actions";
import Typewriter from 'typewriter-effect';
import Rewards from "../../rewards";
//import doneSVG from '../../../images/course/standard/svg/done.svg';
//import lockedSVG from '../../../images/course/standard/svg/locked.svg';
//import activeSVG from '../../../images/course/standard/svg/active.svg';
import activeSVG from '../../../images/course/standard/svg/alt_item_active.svg';
import doneSVG from '../../../images/course/standard/svg/alt_item_finished.svg';
import lockedSVG from '../../../images/course/standard/svg/alt_item_locked.svg';
import personalisedProgramSSI from '../../../images/ssi/personalised_program.png';
import NotificationBox from '../../alert/notification';
import Modal from "../../modal";
import $ from "jquery";
import SsiModuleSelector from './SsiModuleSelector';
import { checkNestedProperties } from "../../utils";
import t from "../../translate";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Lessons = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const allAnswers = useSelector(state => state.answersLessons);
  const courseFinished = useSelector(state => state.finishedCourse);

  const [lessonList, setLessonList] = useState([]);
  const [illustration, setIllustration] = useState('');
  const [badgesActive, setBadgesActive] = useState(false);
  const [lessonsIntro, setLessonsIntro] = useState('');
  const [notificationOptions, setNotificationOptions] = useState('');
  //tbv ssi:
  const [ssiLessonList, setSsiLessonList] = useState([]);
  const [ssiActiveLessons, setSsiActiveLessons] = useState([]);
  const [ssiShowModuleSelector, setSsiShowModuleSelector] = useState(false);
  const [ssiUnfinishedModule, setSsiUnfinishedModule] = useState(true);
  const [ssiProgramFinished, setSsiProgramFinished] = useState(false);
  //tbv flipcard
  const [showCards, setShowCards] = useState([])
  //tbv text waitinglist RCT LifeHack
  const [languageId, setLanguageId] = useState(1); //NL

  useEffect(() => {
    if (typeof auth.preferences !== 'undefined' && Array.isArray(auth.preferences)) {
      for (const pref of auth.preferences) {
        if (pref.option === 'language_id') {
          setLanguageId(pref.value);
          break;
        }
      }
    }
  }, [auth.user_id])

  const closeModal = msg => {
    $("#ssi_module_selector").modal("toggle");
  };

  const [modalState, setModalState] = useState({
     name: "ssi_module_selector",
     label: "ssi_module_selector_label",
     title: '',
     component: SsiModuleSelector,
     btnValue: t("Ga verder"),
     cancelBtnValue: t("Terug"),
     closable: false,
     cancellable: false,
     submitable: false,
     ssiShowSelector: 'part1',
     componentData: {
       intervention_id: intervention.id,
       ssiActiveLessons,
       ssiLessonList,
       setSsiActiveLessons,
       setSsiShowModuleSelector,
       closeModal
     }
   });

  const lessonFinished = (lesson_id) => {
    let currentLessonAnswers = allAnswers.answers.find((answer) => {
      return parseInt(answer.the_id) === parseInt(lesson_id)
    });
    if (typeof currentLessonAnswers !== 'undefined' &&  currentLessonAnswers.hasOwnProperty('finished') && currentLessonAnswers.finished === true) {
      return true;
    } else {
      return false;
    }
  }

  const getActiveLessonLink = (lesson_id) => {
    //ipv obv index obv lesson_id de les selecteren
    let lesson = lessonList.find(lesson => {
      return parseInt(lesson.id) === parseInt(lesson_id)
    })
    if(typeof lesson !== "undefined" && lesson.status === 'active') {
      let aFinishedLesson = false;
      let anActiveLesson = false;
      for (const lesson of intervention.settings.selfhelp.lessons) {
        if ((lesson.id == lesson_id) || (parseInt(lesson.parent_id) === parseInt(lesson_id) && (!lesson.hasOwnProperty('sub_id') || parseInt(lesson.sub_id) === 0))) {
          if (!anActiveLesson && !lessonFinished(lesson.id)) {
            lesson_id = lesson.id
            break;
          }
        }
      }
      return lesson_id
    } else {
      return lesson_id
    }
  }

  const changeActiveLesson = (e, lesson_id, index) => {
    e.preventDefault();
    //tbv iSleep & BioClock checken of les al is opengesteld
    if (hasAccessToLessons(lesson_id)) {
      /// ga na welke les actief is in de actieve les
      lesson_id = getActiveLessonLink(lesson_id)
      dispatch(setActivePart("lesson"));
      dispatch(setActiveLesson(lesson_id));
      history.push("/course/" + intervention.id + "/lesson/" + lesson_id);
    } else {
      setNotificationOptions({
        show: "true",
        text: t("Deze module is toegankelijk vanaf "+getsAccessToLessonsWhen()),
        confirmText: t("Ok")
      })
    }
  }

  const fakeEmptyFunc = () => {
    //om react tevreden te stellen
  }

  useEffect(() => {
    //lessons intro zetten
    if(typeof intervention.settings.lessonsIntro !== 'undefined' &&  intervention.settings.lessonsIntro !== '') {
      setLessonsIntro(intervention.settings.lessonsIntro);
    }
    //Badges?
    if(auth.user_id > 0 && typeof intervention.settings.gamification !== "undefined" && intervention.settings.gamification.badges) {
      if(typeof intervention.settings.gamification.badgesFromDateActive !== "undefined" && typeof intervention.settings.gamification.badgesFromDate !== "undefined" && intervention.settings.gamification.badgesFromDateActive) {
        if(typeof intervention.settings.gamification.badgesFromDate[0] !== "undefined" && intervention.settings.gamification.badgesFromDate[0] !== "") {
          //er is een datum vanaf ingesteld voor de gamification, alleen accounts die na deze datum zijn aangemaakt
          //krijgen deze optie te zien.
          if (auth.dtc > intervention.settings.gamification.badgesFromDate[0].substring(0,10)) {
            setBadgesActive(true);
          }
        }
      } else {
        setBadgesActive(true);
      }
    }

    //tbv SSI
    let newSsiLessonList = [];
    intervention.settings.selfhelp.lessons.forEach(lesson => {
      if (parseInt(lesson.parent_id) === 0 && parseInt(lesson.sub_id) === 0) {
        newSsiLessonList.push(lesson);
      }
    })
    setSsiLessonList(newSsiLessonList);
    let newModalState = {...modalState};
    newModalState.componentData = {...modalState.componentData};
    newModalState.componentData.ssiLessonList = newSsiLessonList;
    let programFinished = false;
    let newSsiActiveLessons = [];
    
    auth.preferences.forEach(pref => {
      if (pref.option === "ssi") {     
        pref.value.forEach(value => {
          if (typeof value === 'string') {
            let parts = value.split("_");
            if (parseInt(parts[1]) === parseInt(intervention.id)) {
              if (parts[2] !== 'programfinished') {
                newSsiActiveLessons.push(parts[2]);
              } else {
                programFinished = true;
                setSsiProgramFinished(true);
              }
            }
          }
        })
        newSsiActiveLessons = newSsiActiveLessons.reverse()
      }
    })
    
    
    if (checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') && intervention.settings.selfhelp.alternative_menu_flipcard === true) {
      //flipcard menu, alle modules tonen...
      newSsiActiveLessons = [];
      intervention.settings.selfhelp.lessons.forEach(lesson => {
        if (parseInt(lesson.parent_id) === 0) {
          newSsiActiveLessons.push(lesson.id);
        }
      })
    }
    setSsiActiveLessons(newSsiActiveLessons);
    newModalState.componentData.ssiActiveLessons = newSsiActiveLessons;

    //lessen doorlopen tbv bepalen afgeronde modules, actieve modules, gesloten modules
    let newlessonList = [];
    let unregisteredLesson = {
      id: 0,
      title: '',
      status: 'closed'
    };
    let allFinished = true;
    let someFinished = false;
    let unfinishedModules = 0;
    
    //tbv ssi, zijn er nog modules die niet zijn afgerond?
    let finishedModules = 0;
    let someFinishedModule = 0;

    for (const lesson of intervention.settings.selfhelp.lessons) {

      if (parseInt(lesson.parent_id) === 0 && parseInt(lesson.sub_id) === 0) {//les op hoofdniveau
        if (unregisteredLesson.title.length > 0) { //niet de eerste module/les
          if (allFinished) { //alle lessen van de vorige module zijn afgerond
            unregisteredLesson.status = 'finished'; //dus zetten we de status o pfinished
            finishedModules++;
          } else {
            //is dit een module waarvoor de deelnemer zich heeft aangemeld en is deze nog niet afgerond?
            if (newSsiActiveLessons.includes(unregisteredLesson.id)) {
              unfinishedModules++;
            }
            if (someFinished) {
              someFinishedModule++;
              unregisteredLesson.status = 'active';
            } else {
              if ((checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') && intervention.settings.selfhelp.alternative_menu_flipcard === true)) {
                unregisteredLesson.status = 'closed';
              } else {
                unregisteredLesson.status = 'active';
              }
            }
          } 
          //les toevoegen aan de lijst
          if (newSsiActiveLessons.includes(unregisteredLesson.id)) {
            newlessonList.push(unregisteredLesson);
          }
          //plaatje gekoppeld aan 1e les van nieuwe module tonen
          if (unregisteredLesson.status === 'active') {
            let activeLesson = intervention.settings.selfhelp.lessons.find(lesson => {
              return parseInt(lesson.id) === parseInt(unregisteredLesson.id)
            })
            setIllustration(typeof activeLesson.settings.image != 'undefined' ? activeLesson.settings.image :'')
          }
        }
        //in geval van alternatief menu plaatje vastleggen
        let alternative_menu_image = '';
        if (typeof lesson.alternative_menu_image !== "undefined") {
          alternative_menu_image = lesson.alternative_menu_image;
        }
        //unregistered lessen inititaliseren voor volgende module..
        unregisteredLesson = {
          id: lesson.id,
          title: lesson.title,
          alternative_menu_image,
          status: 'closed'
        };
        allFinished = true;
        someFinished = false;
      }

      //bepalen of les is afgerond...
      let lessonAnswers = allAnswers.answers.find((answer) => {
        return parseInt(answer.the_id) === parseInt(lesson.id)
      });
      if (typeof lessonAnswers === 'undefined' || !lessonAnswers.hasOwnProperty('finished') || lessonAnswers.finished === false) {
        allFinished = false;
      } else {
        someFinished = true;
      }
    }

    //laatste module afhandelen
    if (allFinished) {
      unregisteredLesson.status = 'finished';
      finishedModules++;
    } else {
      //is dit een module waarvoor de deelnemer zich heeft aangemeld en is deze nog niet afgerond?
      if (newSsiActiveLessons.includes(unregisteredLesson.id)) {
        unfinishedModules++;
      }
      if (someFinished) {
        someFinishedModule++;
        unregisteredLesson.status = 'active';
      } else {
        if ((checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') && intervention.settings.selfhelp.alternative_menu_flipcard === true)) {
          unregisteredLesson.status = 'closed';
        } else {
          unregisteredLesson.status = 'active';
        }
      }
    }
    if (unfinishedModules < 1) {
      setSsiUnfinishedModule(false);
    }

    //laatste les toevoegen aan de lijst
    if (newSsiActiveLessons.includes(unregisteredLesson.id)) {
      newlessonList.push(unregisteredLesson);
    }
    //plaatje gekoppeld aan 1e les van nieuwe module tonen
    if (unregisteredLesson.status === 'active') {
      let activeLesson = intervention.settings.selfhelp.lessons.find(lesson => {
        return parseInt(lesson.id) === parseInt(unregisteredLesson.id)
      })
      setIllustration(typeof activeLesson.settings.image != 'undefined' ? activeLesson.settings.image :'')
    }

    setLessonList(newlessonList);

    if(courseFinished) {
      setEndCourse('true');
      dispatch(setFinishedCourse(false));
    }

    //tbv SSI
    if ((((newSsiActiveLessons.length < 1) || (unfinishedModules < 1 && !programFinished)) && !auth.doT0) && !ssiShowModuleSelector || ((checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') && intervention.settings.selfhelp.alternative_menu_flipcard === true) && finishedModules > 0 && !programFinished && someFinishedModule === 0)) {
    if (newSsiActiveLessons.length > 0) {
      //er is een module afgerond, maar er is nog geen nieuwe module gekozen
      //gebruiker aan laten geven of ze het programma willen afsluiten of een nieuwe module willen kiezen
      newModalState.ssiShowSelector = 'part2';
    }
    //iggv flipcar menu selector alleen voor part 2 tonen
    if (((!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) || newSsiActiveLessons.length > 0 ) && !auth.onWaitingListRct) {
      setSsiShowModuleSelector(true);
      setTimeout(() => {
        if(!$('#ssi_module_selector').is(':visible')) {
          $("#ssi_module_selector").modal("toggle");
        }
      }, 1000);
    }
  }
  setModalState(newModalState);
}, [auth, intervention, allAnswers])

  const [endCourse, setEndCourse] = useState(false)

  const hasAccessToLessons = (lesson_id) => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen en modules (behalve intro)
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26 && parseInt(lesson_id) !== 1278) { //alleen testen voor interventie 26, anders true
      if (typeof allAnswers.answers !== 'undefined') { //zijn er antwoorden, anders false
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') { //startTimestamp al gezet?
          var today = new Date();
          var sixdaysago = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
          //var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60*24*6);
          var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60);
          if (allAnswers.answers[lessonIndex].finished && allAnswers.answers[lessonIndex].startTimestamp < sixDaysAgoTimestamp) {
            return true;
          }
        }
      } 
      return false;
    } */
    return true;
  }

  const getsAccessToLessonsWhen = () => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen (tools) en de modules (behalve intro)
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26) {
      if (typeof allAnswers.answers !== 'undefined') {
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') {
          //var date = new Date((parseInt(allAnswers.answers[lessonIndex].startTimestamp) + (60*60*24*6)) * 1000);
          var date = new Date((parseInt(allAnswers.answers[lessonIndex].startTimestamp) + (60*60)) * 1000);
          return date.getDate()+"-"+(date.getMonth() + 1)+"-"+date.getFullYear()+" "+date.getHours()+":"+(date.getMinutes()<10?'0':'') + date.getMinutes();
        }
      }
    } */
    return ''
  }

  const goBaseline = () => {
    dispatch(setActivePart("baseline"));
    history.push("/baseline/");
  }

  const goToProfile = () => {
    dispatch(setActivePart("settings"));
    dispatch(setShowLeftMenu(false))
    history.push("/course/"+intervention.id+"/settings");
  }

  function flipCard(index){
    let showCardsNew = [...showCards]
    if(showCardsNew.includes(index)){
      let indexOfIndex = showCardsNew.indexOf(index);
      if (indexOfIndex !== -1) showCardsNew.splice(indexOfIndex, 1);
    } else {
      showCardsNew.push(index)
    }
    setShowCards(showCardsNew)
  }

  return (
    <>
      
      <div className='lessons'>
          <div className="welkom">{t("Welkom bij")}</div>
          <div className="title"><span dangerouslySetInnerHTML={{__html: intervention.title}} /></div>
        {/* {typeof intervention.settings.subtitle !== "undefined" && intervention.settings.subtitle !== "" ?
          <div className="title">
            <h1 id="typed_1" className="subTitle"><Typewriter options={{delay:40}}
            onInit={(typewriter) => {
              typewriter.typeString(intervention.settings.subtitle)
                .callFunction(() => {
                  document.getElementById("typed_1").className = "finished subTitle"
                })
                //.pauseFor(2500)
                //.deleteAll()
                .start();
            }}
            /></h1>
            <h1 className="dummy">{intervention.settings.subtitle} oho</h1>
          </div>
        :''} */}


        {
          typeof intervention !== "undefined" && typeof intervention.settings !== "undefined" && typeof intervention.settings.coverPhoto !== "undefined" && intervention.settings.coverPhoto.length > 0 ?
            <div className="illustration">
              <img src={intervention.settings.coverPhoto} />
            </div>
          :
            ''
        }
        <div className="lessonsHolder">
          {
            auth.doT0 ? 
            <div className="items" style={{marginTop: '70px', background: 'none'}}  onClick={()=>goBaseline()}>
              <div className="item active">
                  <span style={{paddingLeft: '20px'}}>{t("Start hier: Waar sta je nu?")}</span>
              </div> 
            </div> : <></>
          }
          <div className="intro">
            {/* <h2>{typeof intervention.settings.menu != "undefined" ? intervention.settings.menu.modules:''}</h2> */}
            <span dangerouslySetInnerHTML={{__html: lessonsIntro}}></span>
          </div>
          <div className="items_alt">
          {
          ssiActiveLessons.map((lesson_id, index) => {
          //lessonList.map((lesson, index) => { //lessonList
            //if (ssiActiveLessons.includes(lesson.id)) { //ssiActiveLessons
            let lesson = lessonList.find(lesson => {
              return parseInt(lesson.id) === parseInt(lesson_id)
            })
            if (typeof lesson !== 'undefined') {
              if (typeof lesson !== 'undefined') {
                if (auth.doT0) {
                  lesson.status = 'closed'
                }
                if (!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) {
                  return (
                    <div key={index} className={'pointer item_alt '+lesson.status} onClick={(lesson.status === 'finished' || lesson.status === 'active') ? (e)=>changeActiveLesson(e, lesson.id, index) : ()=>fakeEmptyFunc()} style={lesson.status === 'active' ? {border: '1px solid #000'} : lesson.status === 'closed' ? {cursor: 'not-allowed'} : {}}>
                      <div className={'title '+lesson.status}>{lesson.title}</div>
                      <div className={'image '+lesson.status} style={{backgroundImage: (typeof lesson.alternative_menu_image !== "undefined" && lesson.alternative_menu_image.length > 0) ? 'url("'+lesson.alternative_menu_image+'")' : ''}}></div>
                        <div className='activated'>
                          <div className="vinkseq">
                            {
                              lesson.status === 'active' ? <img src={activeSVG} /> : 
                                <>
                                  {
                                  lesson.status === 'closed' ? <img src={lockedSVG} /> : <img src={doneSVG}/>
                                  }
                                </>
                            }
                          </div>
                        </div>
                    </div>)
                } else {
                  //flipcard tonen...
                  return(
                  <div className={"flip-card pointer " + (showCards.includes(lesson.id) ? 'back':'front')} onClick={() => flipCard(lesson.id)}>
                    <div className="inner">
                      <div className="front" style={lesson.status === 'active' ? {border: '1px solid #e76d71'} : {}}>
                        <div className={'title '+lesson.status}>{lesson.title}</div>
                        <div className={'image '+lesson.status} style={{backgroundImage: (typeof lesson.alternative_menu_image !== "undefined" && lesson.alternative_menu_image.length > 0) ? 'url("'+lesson.alternative_menu_image+'")' : ''}}></div>
                        <div className='activated'>
                          <div className="vinkseq">
                            {
                              lesson.status === 'active' ? <img src={activeSVG} /> : 
                                <>
                                  {
                                  lesson.status === 'closed' ? <img src={lockedSVG} /> : <img src={doneSVG}/>
                                  }
                                </>
                            }
                          </div>
                        </div>
                    </div>
                      <div className="back" style={lesson.status === 'active' ? {border: '1px solid #e76d71'} : {}}>
                        <div className="flip-card-text">{checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcardtexts', lesson.id) ? intervention.settings.selfhelp.alternative_menu_flipcardtexts[lesson.id] : ''}</div>
                        <button className="btn btn-primary" onClick={(e) => changeActiveLesson(e, lesson.id, index)}>{t("Start module")}</button>
                      </div>
                    </div>
                  </div>)
                }
              }
            }})
          }

          {
            ssiProgramFinished && !ssiUnfinishedModule ?
            <button className="btn btn-light" style={{display: 'block'}} onClick={()=>goToProfile()}>{t("Toch nog een module doen")}</button> : <></>
          }
          {/* {"doT0: " + auth.doT0}
          {"onWaitingListRct: " + auth.onWaitingListRct} */}
          {
            
            ssiActiveLessons.length < 1 && (!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) && !(!auth.doT0 && auth.onWaitingListRct) ?
               <div className="item_alt item_alt_ssi_pp active">
                  <img className="image" src={personalisedProgramSSI} />
                  <div className="title active" dangerouslySetInnerHTML={{__html: intervention.settings.selfhelp.ssi_texts.start}} />
               </div>
            : <></>
          }

          {
            //tbv RCT Lifehack wachtlijst....
            !auth.doT0 && auth.onWaitingListRct && parseInt(intervention.id) === 48 ?
              <div style={{marginTop: '160px'}}>
                {
                  parseInt(languageId) === 1 ?
                  <>
                    <span>
                    Dankjewel voor jouw interesse in LifeHack en deelname aan ons onderzoek!<br/>
                    <br />
                    Door middel van loting ben je toegewezen aan de groep studenten die 4 weken moet wachten voordat ze kunnen beginnen aan het programma.<br />
                    <br />
                    Door de helft van de studenten te vragen om te wachten, kunnen wij onderzoeken wat de effectiviteit is van LifeHack. Daarom willen we je namens het MoodLift onderzoeksteam bij voorbaat danken voor jouw geduld!<br />
                    <br />
                    Na 4 weken krijg je een mail van ons en kun je beginnen met LifeHack.<br />
                    </span>
                  </>
                  :
                  <>
                    <span>
                    Thank you for your interest in LifeHack and for participating in our research!<br/>
                    <br />
                    By drawing lots, you have been assigned to the group of students who are required to wait for four weeks before commencing the program.<br />
                    <br />
                    By requesting half of the students to wait, we aim to investigate the effectiveness of LifeHack. Therefore, on behalf of the MoodLift research team, we would like to express our gratitude in advance for your patience!<br />
                    <br />
                    In 4 weeks you’ll receive an email from us and can start LifeHack.<br />
                    </span>
                  </>
                }
              </div> : <></>
          }
          </div>
        </div>
        {endCourse  ?
          <ConfettiFullScreen setEndCourse={setEndCourse}/>
          : <></>
          /* badgesActive ? <Rewards /> : <></> */
        }
        <OptionalLessons lessonList={lessonList}/>
        
        <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions} />
        {
          ssiShowModuleSelector ?
            <Modal {...modalState} modalState={modalState} setModalState={setModalState} /> : <></>
        }
        
      </div>
    </>
  )
}

export default Lessons;
