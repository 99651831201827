import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getClone } from "../utils";
import t from "../translate";
import { useHistory } from "react-router-dom";
import testImage from "../../images/wijn.jpg";
import ReactTooltip from 'react-tooltip';

const InterventionLinks = props => {

  const auth = useSelector(state => state.auth);

  const [interventions, setInterventions] = useState([])

  useEffect(() => {
    if(props.interventions != ""){
      setInterventions(props.interventions)
    }
  }, [props]);

  let history = useHistory();

  /*
  const showStats = (event, intervention_id) => {
    event.stopPropagation();
    history.push("/intervention/stats/" + intervention_id);
  };
  */

  const showSingleStats = (event, intervention_id) => {
    event.stopPropagation();
    history.push("/intervention/stats/" + intervention_id);
  };

  const getNrStudents = (intervention_id) => {
    let total_students = 0;
    Object.keys(props.activity.total.intervention).map(function(key, index) {
      if(key == intervention_id){
        total_students =  props.activity.total.intervention[key]
      }
    })
    return total_students;
  };
  const getNrFinishedStudents = (intervention_id) => {
    let total_students = 0;
    Object.keys(props.activity.total.finished).map(function(key, index) {
      if(key == intervention_id){
        total_students =  props.activity.total.finished[key]
      }
    })
    return total_students;
  };

  return (
    <>
    <div className="intervention_links clearfix card_holder">
      {interventions.map(intervention => {

        if (auth.userType === 'admin' && (typeof auth.rights.config_access === 'undefined' ||
            !auth.rights.config_access ||
            typeof auth.rights.config_access_programs === 'undefined' ||
            auth.rights.config_access_programs.indexOf(intervention.id) === -1)) {
              return <></>;
        }
        return (
          <div
            key={intervention.id}
            className="card"
            onClick={event => props.onClickHandler(event, intervention)}
          >
            <div className="image" style={{ backgroundImage: "url('"+(typeof intervention.settings.coverPhoto != "undefined" && intervention.settings.coverPhoto != "" ? intervention.settings.coverPhoto:testImage)+"')" }}>
            </div>
            <div className="content">
              <h2 dangerouslySetInnerHTML={{__html: intervention.title}}></h2>
              {props.activity ?
                <div className="stats">
                  <i className="fa fa-users" aria-hidden="true"></i>{getNrStudents(intervention.id)}
                  <i className="fa fa-check" aria-hidden="true"></i>{getNrFinishedStudents(intervention.id)}
                </div>
                :false}
              <div className="actions">
                  <div className="more">
                    <i className="fas fa-ellipsis-h"></i>
                  </div>
                  {auth.userType == "admin" && typeof auth.rights.stats_access !== 'undefined' && auth.rights.stats_access === true ?
                    <>
                    {props.activity ?
                      <i className="fas fa-chart-bar" onClick={(e)=>showSingleStats(e, intervention.id)} data-tip={t("Statistieken")}></i>
                      :
                      <i className="fas fa-spinner fa-spin"></i>
                    }
                    </>
                  :false}

                <i className="fas fa-chevron-right" data-tip={t("Bewerken")}></i>
              </div>
            </div>
            <ReactTooltip place="top" effect="solid" delayShow={200}   />
          </div>
        );
      })}

    </div>
    </>
  );
};

export default InterventionLinks;
