import React, { useEffect, useState } from "react";
import apiCall from "../../api";
import { useSelector } from "react-redux";
import t from "../../translate";
import SleepDiaryWeeks from "../../course/content/sleep_diary/weeks.js";
import SleepDiaryThisWeek from "../../course/content/sleep_diary/this_week.js";

const  StudentDetailsSleepdiary = props => {

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const [show, setShow] = useState('this_week');

  const [entriesPerWeek, setEntriesPerWeek] = useState({})

  useEffect(() => {
    /// get sleepdiary student
    apiCall({
      action: "get_sleep_diary",
      token: auth.token,
      data: {
        intervention_id: parseInt(props.studentInterventionID),
        student_id: parseInt(props.studentId)
      }
    }).then(resp => {
      setEntriesPerWeek(resp.content_per_week)
    });

  }, [props.studentId]);

  const getEfficiencyClass= (value) => {
    if(value > 50 && value < 75){
      return 'middle'
    } else if (value >= 75){
      return 'high'
    } else {
      return 'low'
    }
  }

  return (
    <div className="sleep-diary">
      <h3>{t("Slaapdagboek")}</h3>
      <div className="menu">
        <span className={show == 'this_week' ? 'active':''} onClick={()=>setShow('this_week')}><i className="fas fa-chart-area"></i>{t("Deze week")}</span>
        <span className={show == 'weeks' ? 'active':''} onClick={()=>setShow('weeks')}><i className="fas fa-list-alt"></i>{t("Alle weken")}</span>
      </div>
      {show == "weeks" ?
        <SleepDiaryWeeks getEfficiencyClass={getEfficiencyClass} entriesPerWeek={entriesPerWeek} coach={true} />
        :false
      }
      {show == "this_week" ?
        <SleepDiaryThisWeek getEfficiencyClass={getEfficiencyClass} coach={true} student_id={props.studentId} intervention_id={parseInt(intervention.id)}/>
        :false
      }
    </div>
  )
}

export default StudentDetailsSleepdiary
