import React from "react";
import t from "../translate";
//import { setSavingStatus } from "../../actions";

const Saved = ({ savingStatus }) => {

  return(
    <div className={savingStatus + ' saveStatus'}>
      {t('Saved')}
    </div>
  )
  
}

export default Saved;