import React, { useState, useEffect, Fragment } from "react";
import t from "../../../translate";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../../../api";
import { login } from "../../../../actions";
import Select from 'react-select'

const SleepDiarySettings = () => {

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);

  const [reminder, setReminder] = useState(false)
  const [reminderType, setReminderType] = useState('sms')
  const [reminderTime, setReminderTime] = useState("10:00")

  useEffect(() => {
    if(activeIntervention > 0 && auth.rights.interventions.length > 0){
      let this_right_obj = auth.rights.interventions.filter(function (intervention) {
        return parseInt(intervention.id) === activeIntervention
      });
      if(this_right_obj.length != 0)
      {
        if(typeof this_right_obj[0].preferences != "undefined" && typeof this_right_obj[0].preferences.sleep_diary != "undefined"){
          setReminder(this_right_obj[0].preferences.sleep_diary.reminder_active)
          setReminderType(this_right_obj[0].preferences.sleep_diary.reminder_type)
          setReminderTime(this_right_obj[0].preferences.sleep_diary.reminder_time)
        }
      }
    }
  }, [auth, activeIntervention]);

  const timeSelectOptions = []

  for(let i = 0 ; i < 24 ; i++){
    timeSelectOptions.push({value:i+":00",label:i+":00"})
    timeSelectOptions.push({value:i+":15",label:i+":15"})
    timeSelectOptions.push({value:i+":30",label:i+":30"})
    timeSelectOptions.push({value:i+":45",label:i+":45"})
  }

  const toggleReminder = () => {
    let reminderTemp = reminder ? false : true;
    setReminder(reminderTemp)
    saveSettings(reminderTemp, reminderType, reminderTime)
  }
  const toggleReminderType = (value) => {
    setReminderType(value)
    saveSettings(reminder, value, reminderTime)
  }

  const saveSettings = (on_off, type, time) => {
    var d = new Date();
    apiCall({
      action: "save_sleep_diary_preferences",
      token: auth.token,
      data: {
        intervention_id: parseInt(activeIntervention),
        reminder: on_off,
        reminderType: type,
        reminderTime: time,
      }
    }).then(resp => {

      if (resp.error == 0) {
        dispatch(
          login(
            auth.user_id,
            auth.dtc,
            auth.name,
            auth.token,
            auth.userType,
            resp.rights,
            auth.preferences,
            auth.t0_scores,
            auth.t1_scores,
            auth.rewards,
            auth.profile_pic, /// deze wordt niet opgeslagen wel het plaatje
            auth.email,
            auth.private_email,
            auth.gender,
            auth.education,
            auth.firstname,
            auth.insertion,
            auth.lastname,
            auth.organisation_id,
            auth.oldRegistrationFlow,
            auth.specialAccountType,
            auth.doT0,
            auth.onWaitingListRct
          )
        );
      }
    });
  }

  return (
    <div className="settings">
      <h1>{t("Settings")}</h1>
      <div className="intro">
        {t("Hier kun je de instellingen van je reminder bewerken.")}
      </div>
      <div className="box">
      <table>
        <tbody>
          <tr>
            <td>
              <h5>{t("Reminder")}</h5>
            </td>
            <td>
              <label className="switch">
                <input type="checkbox" onClick={()=>toggleReminder()} checked={reminder}/>
                <span className="slider_switch round"></span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      {reminder ?
        <div className="questions">

          <div className="question">
            <table>
            <tbody>
              <tr>
                <td>
                  {t("Verstuur")} :
                </td>
                <td>
                  <input type="radio" name="reminder_type" id="reminder_type_email" value="email" checked={reminderType == "email" ? true:false}/>
                  <label htmlFor="intervention_type_selfhelp" onClick={()=>toggleReminderType('email')} >{t("Email")}</label>
                  <input type="radio" name="reminder_type" id="reminder_sms" value="sms" checked={reminderType == "sms" ? true:false}/>
                  <label htmlFor="intervention_type_selfhelp" onClick={()=>toggleReminderType('sms')}>{t("Sms")}</label>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
          <div className="question">
            <table>
            <tbody>
              <tr>
                <td>
                  {t("Verstuur om")} :
                </td>
                <td>
                  <Select
                    className="time_select"
                    options={timeSelectOptions}
                    onChange={(option)=>saveSettings(reminder, reminderType, option.value)}
                    onBlur= { event => event.preventDefault()}
                    value = {
                       timeSelectOptions.filter(option =>
                          option.value === reminderTime)
                    }
                    />
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        :false}
      </div>
    </div>
  );
};

export default SleepDiarySettings;
