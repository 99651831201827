import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom"; //laten staan ivm nav in leftmenu...
import LeftMenu from "../leftmenu";
import NavBar from "./settingparts/navbar.js";
import Intro from "./settingparts/intro.js";
import InterventionSettingsLanguage from "./settingparts/settings_language.js";
import InterventionSettingsTranslations from "../translations/translations.js";
import InterventionSettingsTitle from "./settingparts/title.js";
import InterventionSettingsSubTitle from "./settingparts/subtitle.js";
import InterventionSettingsApply from "./settingparts/apply.js";
import InterventionSettingsType from "./settingparts/type.js";
import InterventionSettingsCoach from "./settingparts/coach.js";
import InterventionSettingsJournal from "./settingparts/journal.js";
import InterventionSettingsStress from "./settingparts/stress.js";
import InterventionSettingsSleepdiary from "./settingparts/sleep_diary.js";
import InterventionSettingsLessons from "./settingparts/lessons.js";
import InterventionSettingsOptionalLessons from "./settingparts/optional_lessons.js";
import InterventionSettingsGoals from "./settingparts/goals.js";
import InterventionSettingsPages from "./settingparts/pages.js";
import InterventionSettingsQuestionnaires from "./settingparts/questionnaires.js";
import InterventionSettingsGamification from './settingparts/gamification.js'
import InterventionSettingsJson from "./settingparts/json.js";
import InterventionSettingsProgressBar from "./settingparts/progressbar";
import InterventionTheme from "./settingparts/thema.js";
import apiCall from "../api";
import t from "../translate";
import { setIntervention } from "../../actions";
import { type } from "jquery";

let saveSettingsTimeout = null;

const InterventionEdit = props => {

  //const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const settingsType = window.location.href.split("/")[6];

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState("general");

  if(settingsType != activeTab && typeof settingsType != "undefined" && settingsType != "")
  {
    setActiveTab(settingsType);
  }

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  const getSettings = intervention_id => {
    //voldoende rechten?
    if (!auth.rights.config_access || typeof auth.rights.config_access_programs === "undefined" || auth.rights.config_access_programs.indexOf(intervention_id) < 0) {
      history.push("/");
    }
    //api aanroepen
    apiCall({
      action: "get_intervention_settings",
      token: auth.token,
      data: {
        intervention_id
      }
    }).then(resp => {
      //om bestaande interventies te voorzien van pages...
      if (!resp.settings.hasOwnProperty("pages"))
      {
        resp.settings.pages = [];
      }
      //set settings in global state:
      dispatch(
        setIntervention(
          resp.intervention_id,
          resp.organisation_id,
          resp.title,
          resp.settings
        )
      );
    });
  };

  useEffect(() => {

    if (intervention.id == 0 || intervention.id != window.location.href.split("/")[5]) {
      let intervention_id = window.location.href.split("/")[5];
      getSettings(intervention_id);
    }
    setActiveTab("general");
  }, []);



  const cleanSettings = settings => {
    //het intervention object wordt misbruikt in de app om allerlei data aan te hangen
    //die voor het saven in de DB niet relevant is. Daarom hier een cleanup
    if (settings.hasOwnProperty("goals")) {
      for (var key in settings.goals) {
        if (settings.goals[key].hasOwnProperty("settings")) {
          delete settings.goals[key].settings;
        }
      }
    }
    if (settings.hasOwnProperty("pages")) {
      for (var key in settings.pages) {
        if (settings.pages[key].hasOwnProperty("settings")) {
          delete settings.pages[key].settings;
        }
      }
    }
    if (settings.hasOwnProperty("questionnaires")) {
      for (var key in settings.questionnaires) {
        if (settings.questionnaires[key].hasOwnProperty("settings")) {
          delete settings.questionnaires[key].settings;
        }
      }
    }
    if (
      settings.hasOwnProperty("selfhelp") &&
      settings.selfhelp.hasOwnProperty("lessons")
    ) {
      for (var key in settings.selfhelp.lessons) {
        if (settings.selfhelp.lessons[key].hasOwnProperty("settings")) {
          delete settings.selfhelp.lessons[key].settings;
        }
      }
      for (var key in settings.selfhelp.optionalLessons) {
        if (settings.selfhelp.optionalLessons[key].hasOwnProperty("settings")) {
          delete settings.selfhelp.optionalLessons[key].settings;
        }
      }
    }
    return settings;
  };

  const saveSettings = (intervention_id, organisation_id, title, settings) => {
    settings = cleanSettings(settings);

    apiCall({
      action: "save_intervention",
      token: auth.token,
      data: {
        intervention: {
          id: intervention_id,
          title: title,
          settings: settings,
        }
      }
    }).then(resp => {
      //set settings in global state:
      dispatch(
        setIntervention(
          intervention.id,
          organisation_id,
          resp.title,
          resp.settings /// kan dit of moet de save gewoon alles teruggeven <= dat wordt ook complex .... hier zijn wel de nieuwe toegevoegd items nodig met juiste settings + deleted items....
        )
      );
      setMessage(resp.msg);
      setTimeout(() => {
        setMessage("");
      }, 5000);
    });
  };

  const showTab = tab => {
    setActiveTab(tab);
  };

  return (
    <div className="whiteWrapper intervention_edit">
      <LeftMenu />
      <div className="container dashboard_container">
        <div className="settings_container">
          <InterventionSettingsTitle
            saveSettings={saveSettings}
            setErrorMessage={setErrorMessage}
          />
          <InterventionSettingsSubTitle
            saveSettings={saveSettings}
            setErrorMessage={setErrorMessage}
          />

          <NavBar showTab={showTab} />
          <br />

          <div
            className={
              errorMessage.length < 1 ? "hidden" : "alert alert-danger"
            }
            role="alert"
          >
            {errorMessage}
          </div>
          <span>
            <div
              id="settings-general"
              className={activeTab === "general" ? "" : "hidden"}
            >
              <InterventionSettingsLanguage
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
              <InterventionSettingsType
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
              <InterventionSettingsCoach
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
              {
                //progress bar op overzichtspagina modules (lessons.js) is niet mogelijk wanneer er een alternatief menu is ingesteld waarbij de lesses op willekeurige volgorde kunnen worden gevolgd
                (typeof intervention.settings.selfhelp.alternative_menu === "undefined" || !intervention.settings.selfhelp.alternative_menu || (intervention.settings.selfhelp.alternative_menu && !intervention.settings.selfhelp.alternative_menu_seq === false)) ?
                    <InterventionSettingsProgressBar 
                    saveSettings={saveSettings}
                    setErrorMessage={setErrorMessage}
                  /> : <></>
              }
              <InterventionSettingsJournal
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
              <InterventionSettingsStress
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
              <InterventionSettingsSleepdiary
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
              <InterventionSettingsApply
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div
              id="settings-lessons"
              className={activeTab === "lessons" ? "" : "hidden"}
            >
              <Intro
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
                placeholder={t("Intro lessen")}
                type="lessons"
              />
              <InterventionSettingsLessons
                intervention={intervention}
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />

            </div>
            <div
              id="settings-optional-lessons"
              className={activeTab === "optional-lessons" ? "" : "hidden"}
            >
              <Intro
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
                placeholder={t("Intro optionele lessen")}
                type="optional-lessons"
              />
              <InterventionSettingsOptionalLessons
                intervention={intervention}
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />

            </div>
            <div
              id="settings-chatlessons"
              className={activeTab === "chatlessons" ? "" : "hidden"}
            >
              Chatlessons
            </div>
            <div
              id="settings-chathomework"
              className={activeTab === "chathomework" ? "" : "hidden"}
            >
              Chathomework
            </div>
            <div
              id="settings-goals"
              className={activeTab === "goals" ? "" : "hidden"}
            >
              <Intro
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
                placeholder="Intro doelen"
                type="goals"
              />
              <InterventionSettingsGoals
                intervention={intervention}
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div
              id="settings-questionnaires"
              className={activeTab === "questionnaires" ? "" : "hidden"}
            >
              <InterventionSettingsQuestionnaires
                intervention={intervention}
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div
              id="settings-pages"
              className={activeTab === "pages" ? "" : "hidden"}
            >
              <InterventionSettingsPages
                intervention={intervention}
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div
              id="settings-translations"
              className={activeTab === "translations" ? "" : "hidden"}
            >
              <InterventionSettingsTranslations
                intervention={intervention}
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div
              id="settings-json"
              className={activeTab === "json" ? "" : "hidden"}
            >
              <InterventionSettingsJson
                saveSettings={saveSettings}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div id="settings-gamifcation" className={"form-group" + (activeTab === "gamification" ? "" : " hidden")}>
              <InterventionSettingsGamification
                setErrorMessage={setErrorMessage}
                saveSettings={saveSettings}
              />
            </div>
            {intervention.id > 0 ? (
              <div
                id="settings-theme"
                className={activeTab === "theme" ? "" : "hidden"}
              >
                <InterventionTheme
                  saveSettings={saveSettings}
                  setErrorMessage={setErrorMessage}
                />
              </div>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InterventionEdit;
