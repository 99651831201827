import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { login, setAuthenticatedNull, setAuthenticatedFalse } from "../../actions"
import { getCookie } from "../utils"
import apiCall from "../api"
import { getClone } from "../utils"
import t from "../translate"
import { setUiTranslation } from "../../actions"
import ForgotPassword from "./forgotPassword"
import ResetPassword from "./resetPassword"
import Header from './header';
import logoMoodlift from '../../images/logo_moodlift.png';
import arrowRight from '../../images/svg/noun-arrow-3134209.svg';
import vector1 from '../../images/home/vector1.svg';
import vector2 from '../../images/home/vector2.svg';
import vector3 from '../../images/home/vector3.svg';
import vector4 from '../../images/home/vector4.svg';
import vector5 from '../../images/home/vector5.svg';
import vector6 from '../../images/home/vector6.svg';

const Login = (props) => {

  let [localState, setLocalState] = useState({
    username: "",
    password: "",
  });

  const auth = useSelector(state => state.auth);
  const language_id = useSelector(state => state.uiTranslation.language_id);

  const [resetPassword, setResetPassword] = useState(false)
  const [errorMessage, setErrorMessage]  = useState("")

  const querystring = window.location.href.split("/")

  const dispatch = useDispatch();

  useEffect(() => {

    //indien automatisch uitgelogd als gevolg van inactiviteit, dan een melding geven (aangestuurd door api)
    if (querystring[3] === '?sessionEnded') {
      setErrorMessage("Je sessie is verlopen. Log opnieuw in.")
    }

    //// password reset request
    if(querystring[3] == 'resetpassword' && false !== querystring[4])
    {
      setResetPassword(true);
    } 
    else
    {
      //history.push("/"); //terug naar base-url waarom?
      if (getCookie("token").length > 0 && auth.status === null) {
        //api aanroepen
        apiCall({
          action: "login_token",
          data: {
            token: getCookie("token")
          }
        }).then(resp => {
          if (resp) {
            if (resp.login_token === true) {

              //ui translation???
              if (resp.preferences != null && typeof resp.preferences.language_id !== 'undefined')
              {
                if (parseInt(resp.preferences.language_id) === 1)
                {
                  //dutch...
                  dispatch(setUiTranslation(1, []));
                }
                else
                {
                  dispatch(setUiTranslation(resp.preferences.language_id, resp.ui_translation));
                }
              }

              //token was ok, vastleggen in store
              dispatch(
                login(
                  resp.user_id,
                  resp.dtc,
                  resp.name,
                  resp.token,
                  resp.userType,
                  resp.rights,
                  resp.preferences,
                  resp.t0_scores,
                  resp.t1_scores,
                  resp.rewards,
                  resp.profile_pic,
                  resp.email,
                  resp.private_email,
                  resp.gender,
                  resp.education,
                  resp.firstname,
                  resp.insertion,
                  resp.lastname,
                  resp.organisation_id,
                  resp.oldRegistrationFlow,
                  resp.specialAccountType,
                  resp.doT0,
                  resp.onWaitingListRct
                )
              );
              //voor akls dit component is ingebed in confirmation component
              if (typeof props.setConfirmation !== "undefined") {
                props.setConfirmation(false)
              }

            } else {
              //token niet meer geldig, cookie weggooien
              document.cookie =
                "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }
          }
        });
      }
    }

    if (typeof props.heading !== "undefined" && props.heading.length > 0) {
        setErrorMessage(t(props.heading));
    }
  }, []);


  /// nog vraagstuk email en gebruikersnaam <=> studenten niet inloggen met email? anders theoretisch meerdere accounts mogelijk?
  const [showForgot, setShowForgot] = useState(false)

  const toggleForgotPassword = e => {
    setShowForgot(showForgot ? false:true)
  }

  const onChange = e => {
    e.preventDefault();

    //evt. melding foute inlog laten verdwijnen
    if (auth.status === false) {
      dispatch(setAuthenticatedNull());
    }
    //localstate up to date houden
    const dataObj = getClone(localState);
    dataObj[e.target.name] = e.target.value;
    setLocalState(dataObj);
  };

  const onSubmit = e => {
    e.preventDefault();

    //loginApi(localState.username, localState.password)
    apiCall({
      action: "login",
      data: {
        username: localState.username,
        password: localState.password
      }
    }).then(resp => {
      if (resp.login === true) {
        //inlog ok, token en usertype vastleggen in store

        //ui translation???
        if (resp.preferences != null && typeof resp.preferences.language_id !== 'undefined')
        {
          if (parseInt(resp.preferences.language_id) === 1)
          {
            //dutch...
            dispatch(setUiTranslation(1, []));
          }
          else
          {
            dispatch(setUiTranslation(resp.preferences.language_id, resp.ui_translation));
          }
        }
        dispatch(
          login(
            resp.user_id,
            resp.dtc,
            resp.name,
            resp.token,
            resp.userType,
            resp.rights,
            resp.preferences,
            resp.t0_scores,
            resp.t1_scores,
            resp.rewards,
            resp.profile_pic,
            resp.email,
            resp.private_email,
            resp.gender,
            resp.education,
            resp.firstname,
            resp.insertion,
            resp.lastname,
            resp.organisation_id,
            resp.oldRegistrationFlow,
            resp.specialAccountType,
            resp.doT0,
            resp.onWaitingListRct
          )
        );
        //token vastleggen in cookie
        document.cookie = "token=" + resp.token + "; path=/";
        //voor akls dit component is ingebed in confirmation component
        if (typeof props.setConfirmation !== "undefined") {
          props.setConfirmation(false)
        }
      } else {
        //localstate bijwerken
        const dataObj = getClone(localState);
        dataObj.username = "";
        dataObj.password = "";
        setLocalState(dataObj);
        //state.auth.status van null naar false
        dispatch(setAuthenticatedFalse());
        setErrorMessage("Je hebt een ongeldige gebruikersnaam of wachtwoord ingevoerd.");
      }
    });
  };

  const registrate = () => {
    window.location.href = "/registration/";
  }

  return (
    <div>

      <div className="loginScreen">
        <span className="homeScreenVectors">
            <img src={vector1} className="vector1" />
            <img src={vector2} className="vector2" />
            <br />
            <img src={vector3} className="vector3" />
            <img src={vector4} className="vector4" />
            <br />
            <img src={vector5} className="vector5" />
            <img src={vector6} className="vector6" />
        </span>
        <Header />
        <div
          id="loginContainer"
          className="mb-5 bg-white"
        >
          <img src={logoMoodlift} style={{width: '300px', display: 'block', margin: 'auto', marginBottom: '20px'}} />
          {resetPassword ?
          <ResetPassword code={querystring[4]} setResetPassword={setResetPassword} />
            :
          <div>
            <form
              className={"login" + (showForgot ? ' hide':'')}
              onSubmit={e => {
                onSubmit(e);
              }}
            >
            {
              errorMessage.length > 0 ?
                <div className="alert alert-danger" role="alert">
                  <i className="fas fa-exclamation-circle"></i> {errorMessage}
                </div> : <></>
            }
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  aria-describedby="username"
                  placeholder={t("Gebruikersnaam")}
                  value={localState.username}
                  onChange={onChange}
                  autoCorrect="off" 
                  autoCapitalize="none"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  aria-describedby="password"
                  placeholder={t("Wachtwoord")}
                  value={localState.password}
                  onChange={onChange}
                  autoCorrect="off" 
                  autoCapitalize="none"
                />
              </div>

              <div className="alignCenter">
                <button type="submit" className="btn btn-primary btn-login">
                  {t("Log in op mijn account")}
                  <img src={arrowRight} />
                </button>
                <button type="button" className="btn btn-create-account" onClick={()=>registrate()}>
                  {t("Account aanmaken")}
                </button>
              </div>
              <div className="forgotPassword" onClick={()=>toggleForgotPassword()}>{t('Wachtwoord vergeten')}</div>
            </form>
              <ForgotPassword showForgot={showForgot} toggleForgotPassword={toggleForgotPassword}/>
          </div>
          }
        </div>

      </div>
      <div style={{display: 'none'}}>{language_id}</div>
    </div>
  );
};

export default Login;
