import React from 'react';

const SingleImage = (props) => {

  return(
    <div>
      {(props.images.length > 0) && (props.subtype == "gecentreerd" || props.subtype == "volle breedte") ?
        <div className="imageHolder">
          {props.buildImage(props.images[0].url)}
        </div>
      :''}
    </div>
  )
}

export default SingleImage;
