import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import apiCall from "../api";
import LeftMenu from "../leftmenu";
import Modal from "../modal";
import LoadScreen from "../loadScreen";
import NavBar from "../navBar";
import ReactTooltip from "react-tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import StudentDetails from "./studentdetails.js";
import Pagination from "../pagination";
import StudentsTable from "./studentsTable.js";
import t from "../translate";
import Edituser from "./edituser.js";


window.jQuery = $;
window.$ = $;
global.jQuery = $

const Students = (props) => {
  const auth = useSelector((state) => state.auth);
  const uiTranslation = useSelector((state) => state.uiTranslation);

  const [studentsLoading, setStudentsLoading] = useState(true);
  const [interventions, setInterventions] = useState([]);
  const [registrationableInterventions, setRegistrationableInterventions] = useState([]);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [sortedStudents, setSortedStudents] = useState([]);
  const [sortedStudentsPaginated, setSortedStudentsPaginated] = useState([]);
  const [nrSortedStudents, setNrSortedStudents] = useState(0);
  const [sort, setSort] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [showFilter, setShowFilter] = useState(false);
  const [filterOnKeyword, setFilterOnKeyword] = useState("");
  const [filterOnProgram, setFilterOnProgram] = useState("");
  const [filterOnUnreadMessages, setFilterOnUnreadMessages] = useState(false);
  const [filterOnUnseenLessons, setFilterOnUnseenLessons] = useState(false);
  const [filterOnCalls, setFilterOnCalls] = useState(false);
  const [filterOnblendedCare, setFilterOnBlendedcare] = useState(false);
  const [filterOnTestAccount, setFilterOnTestAccount] = useState(false);

  const [pagingStart, setPagingStart] = useState(0);
  const [pagingLength, setPagingLength] = useState(10);

  const [detailsVisible, setDetailsVisible] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [studentName, setStudentName] = useState("");
  const [showUnseenLessonBadge, setShowUnseenLessonsBadge] = useState(false);
  const [showUnreadMessagesBadge, setShowUnreadMessagesBadge] = useState(false);
  const [tab, setTab] = useState("info");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = (msg, intervention_id) => {
    $("#user_add").modal("toggle");
    setMessage(msg);
    getStudents();
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }

  const [state, setState] = useState({
    users: [],
    modalState: {
      name: "user_add",
      label: "user_edit_label",
      title: "",
      component: Edituser,
      btnValue: t("Opslaan"),
      componentData: {
        user: {
          id: 0,
          firstname: "",
          insertion: "",
          lastname: "",
          email: "",
          phone: "",
          gender: "",
          education: "",
          organisation_id: 0,
          type: "",
          login: "",
          password: "",
          password_check: "",
          removeUser: false,
          anoymous: 0,
          preferences: [
            {option: 'language_id', value: 0}
          ],
            rights: {
                interventions: [],
            },
        },
        registrationableInterventions: [],
        closeModal: closeModal
      }
    }
  });

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = () => {
    apiCall({
        action: "get_students",
        token: auth.token,
        data: {},
      }).then((resp) => {
        setFilteredStudents(resp.students);
        setInterventions(resp.interventions);
        let regInterventions = [];
        Object.keys(resp.interventions).map(intervention_id => {
            regInterventions.push({
                intervention_id: intervention_id,
                title: resp.interventions[intervention_id].title,
            });
        })
        setRegistrationableInterventions(regInterventions);
        setSortedStudentsPaginated(
          resp.students.slice(pagingStart, pagingStart + pagingLength)
        );
        setSortedStudents(resp.students);
        setNrSortedStudents(resp.students.length);
        setStudents(resp.students);
        setStudentsLoading(false);
      });
    }

  useEffect(() => {
    setSortedStudentsPaginated(
      sortedStudents.slice(pagingStart, pagingStart + pagingLength)
    );
  }, [pagingStart, pagingLength]);

  const changeSort = (newSortVal) => {
    //sort en sortorder vastleggen
    let localSortOrder = "ASC";
    if (sort == newSortVal) {
      if (sortOrder == "ASC") {
        setSortOrder("DESC");
        localSortOrder = "DESC";
      } else {
        setSortOrder("ASC");
      }
    } else {
      setSortOrder("ASC");
      setSort(newSortVal);
    }
    doSort(localSortOrder, newSortVal);
  };

  useEffect(() => {
    doFilter();
  }, [
    filterOnProgram,
    filterOnKeyword,
    filterOnUnreadMessages,
    filterOnUnseenLessons,
    filterOnCalls,
    filterOnblendedCare,
    filterOnTestAccount,
  ]);

  useEffect(() => {
    setPagingStart(0);
    doSort(sortOrder, sort);
  }, [filteredStudents]);

  const doSort = (localSortOrder, newSortVal) => {
    let sortedStudents = filteredStudents.sort((a, b) => {
      if (localSortOrder == "ASC") {
        return a[newSortVal] < b[newSortVal] ? -1 : 1;
      } else {
        return a[newSortVal] > b[newSortVal] ? -1 : 1;
      }
    });
    setSortedStudentsPaginated(
      sortedStudents.slice(pagingStart, pagingStart + pagingLength)
    );
    setSortedStudents(sortedStudents);
    setNrSortedStudents(sortedStudents.length);
  };

  const showStudentDetails = (studentId, studentName, newTab) => {
    setStudentId(studentId);
    setStudentName(studentName);
    setTab(newTab);
    setDetailsVisible(true);
    let student = students.find((student) => student.id === studentId);
    if (typeof student !== "undefined") {
        setShowUnseenLessonsBadge(student.unseen_lesson);
        setShowUnreadMessagesBadge(student.unread_message);
    } else {
        setShowUnseenLessonsBadge(false);
        setShowUnreadMessagesBadge(false);
    }
  };

  const doFilter = () => {
    let filteredStudentsProgramKeyword = students
      .filter((student) => {
        if (filterOnProgram != "") {
          return parseInt(student.intervention_id) == parseInt(filterOnProgram);
        } else {
          return true;
        }
      })
      .filter((student) => {
        if (filterOnKeyword != "") {
          //if any of the student properties contains the keyword, return true
          let found = false;
          Object.keys(student).forEach((key) => {
            if (
              typeof student[key] === "string" &&
              student[key]
                .toString()
                .toLowerCase()
                .indexOf(filterOnKeyword.toLowerCase()) > -1
            ) {
              found = true;
            }
          });
          return found;
        } else {
          return true;
        }
      });

    let filteredStudentsBadges = [];
    if (filterOnUnreadMessages || filterOnUnseenLessons || filterOnCalls) {
      let filteredUnreadMessages = students.filter((student) => {
        if (filterOnUnreadMessages) {
          return student.unread_message;
        } else {
          return true;
        }
      });
      let filteredUnseenLessons = students.filter((student) => {
        if (filterOnUnseenLessons) {
          return student.unseen_lesson;
        } else {
          return true;
        }
      });
      let filteredOnCalls = students.filter((student) => {
        if (filterOnCalls) {
          return (
            student.call_t1 ||
            student.call_t2 ||
            student.call_t2a ||
            student.call_t3
          );
        }
        return true;
      });

      //bepalen hoe de subset moet worden samengesteld:
      if (filterOnUnreadMessages && !filterOnUnseenLessons && !filterOnCalls) {
        filteredStudentsBadges = filteredUnreadMessages;
      } else if (
        !filterOnUnreadMessages &&
        filterOnUnseenLessons &&
        !filterOnCalls
      ) {
        filteredStudentsBadges = filteredUnseenLessons;
      } else if (
        !filterOnUnreadMessages &&
        !filterOnUnseenLessons &&
        filterOnCalls
      ) {
        filteredStudentsBadges = filteredOnCalls;
      } else if (
        filterOnUnreadMessages &&
        filterOnUnseenLessons &&
        !filterOnCalls
      ) {
        //add up all the unique items in arrays filteredUnreadMessages, filteredUnseenLessons
        filteredStudentsBadges = new Set([
          ...filteredUnreadMessages,
          ...filteredUnseenLessons,
        ]);
        //filteredStudentsBadges = filteredUnreadMessages.filter(x => [...filteredUnseenLessons].includes(x));
      } else if (
        filterOnUnreadMessages &&
        !filterOnUnseenLessons &&
        filterOnCalls
      ) {
        //add up all the unique items in  arrays filteredUnreadMessages, filteredOnCalls
        filteredStudentsBadges = new Set([
          ...filteredUnreadMessages,
          ...filteredOnCalls,
        ]);
        //filteredStudentsBadges = filteredUnreadMessages.filter(x => [...filteredOnCalls].includes(x));
      } else if (
        !filterOnUnreadMessages &&
        filterOnUnseenLessons &&
        filterOnCalls
      ) {
        //add up all the unique items in arrays filteredUnseenLessons, filteredOnCalls
        filteredStudentsBadges = new Set([
          ...filteredUnseenLessons,
          ...filteredOnCalls,
        ]);
        //filteredStudentsBadges = filteredUnseenLessons.filter(x => [...filteredOnCalls].includes(x));
      } else if (
        filterOnUnreadMessages &&
        filterOnUnseenLessons &&
        filterOnCalls
      ) {
        //add up all the unique items in  arrays filteredUnreadMessages, filteredUnseenLessons, filteredOnCalls
        filteredStudentsBadges = new Set([
          ...filteredUnreadMessages,
          ...filteredUnseenLessons,
          ...filteredOnCalls,
        ]);
        //let intersection = filteredUnreadMessages.filter(x => [...filteredUnseenLessons].includes(x));
        //filteredStudentsBadges = intersection.filter(x => [...filteredOnCalls].includes(x));
      }

      //samenvoegen en unieke waarden (new Set)
      //filteredStudentsBadges = new Set([...filteredUnreadMessages, ...filteredUnseenLessons, ...filteredOnCalls])
    } else {
      filteredStudentsBadges = new Set([...students]);
    }

    let filteredOnAccountType = [];
    if (filterOnblendedCare || filterOnTestAccount) {
      let filteredOnBlendendCare = students.filter((student) => {
        if (filterOnblendedCare) {
          return student.specialAccountType === "B";
        }
        return true;
      });
      let filteredOnSpecialAccountType = students.filter((student) => {
        if (filterOnTestAccount) {
          return student.specialAccountType === "T";
        }
        return true;
      });

      //filteredOnAccountType = new Set([...filteredOnBlendendCare, ...filteredOnSpecialAccountType])

      if (filterOnblendedCare && !filterOnTestAccount) {
        filteredOnAccountType = new Set([...filteredOnBlendendCare]);
      } else if (!filterOnblendedCare && filterOnTestAccount) {
        filteredOnAccountType = new Set([...filteredOnSpecialAccountType]);
      } else if (filterOnblendedCare && filterOnTestAccount) {
        filteredOnAccountType = new Set([
          ...filteredOnBlendendCare,
          ...filteredOnSpecialAccountType,
        ]);
      }
    } else {
      filteredOnAccountType = new Set([...students]);
    }

    //de deelverzameling van de drie gefilterde verzamelingen bepalen
    let intersection = filteredStudentsProgramKeyword.filter((x) =>
      [...filteredStudentsBadges].includes(x)
    );
    let filteredStudents = intersection.filter((x) =>
      [...filteredOnAccountType].includes(x)
    );

    setFilteredStudents(filteredStudents);
  };

  const setUnreadMessage = (user_id, unreadMessage) => {
    //in de gefilterde array de unread_message aanpassen
    let newFilteredStudents = [...filteredStudents];
    newFilteredStudents.find((user) => user.id === user_id).unread_message =
      unreadMessage;
    setFilteredStudents(newFilteredStudents);
    //en in de originele array...
    let newStudents = [...students];
    newStudents.find((user) => user.id === user_id).unread_message =
      unreadMessage;
    setStudents(newStudents);
  };

  const setUnseenLesson = (user_id, unseenLesson) => {
    //in de gefilterde array de unread_message aanpassen
    let newFilteredStudents = [...filteredStudents];
    newFilteredStudents.find((user) => user.id === user_id).unseen_lesson =
      unseenLesson;
    setFilteredStudents(newFilteredStudents);
    //en in de originele array...
    let newStudents = [...students];
    newStudents.find((user) => user.id === user_id).unseen_lesson =
      unseenLesson;
    setStudents(newStudents);
  };

  const setCalled = (e, tx, user_id) => {
    e.stopPropagation();

    if (
      window.confirm(
        t(
          "Heeft u deze deelnemer gebeld met het verzoek vragenlijst " +
            tx +
            " in te vullen? Klik dan op OK"
        )
      )
    ) {
      apiCall({
        action: "set_user_called",
        token: auth.token,
        data: {
          user_id,
          description: tx,
        },
      }).then((resp) => {
        if (resp.msg == "OK") {
          let call = "call_" + tx;
          //in de gefilterde array de unread_message aanpassen
          let newFilteredStudents = [...filteredStudents];
          newFilteredStudents.find((user) => user.id === user_id)[call] = false;
          setFilteredStudents(newFilteredStudents);
          //en in de originele array...
          let newStudents = [...students];
          newStudents.find((user) => user.id === user_id)[call] = false;
          setStudents(newStudents);
        } else {
          //setErrorMessage(resp.msg);
        }
      });
    }
  };

  const setStateCallback = (user, title) => {
    let newState = {...state};
    newState.modalState.componentData.user = user;
    newState.modalState.componentData.registrationableInterventions = registrationableInterventions;
    newState.modalState.title = title;
    setState(newState);
  }

  const addUser = () => {

    setStateCallback(
      {
        id: 0,
        firstname: "",
        insertion: "",
        lastname: "",
        email: "",
        phone: "",
        gender: "",
        education: "",
        organisation_id: auth.organisation_id,
        type: "student",
        login: "",
        password: "",
        password_check: "",
        rights: {
          interventions: [],
        },
        preferences: [{ option: "language_id", value: "0" }],
      },
      t("Toevoegen deelnemer")
    );
    $("#user_add").modal("toggle");
    $("#firstname").focus();
  }

  return (
    <>
      <NavBar />
      <div className="coachInterface students">
        <LeftMenu />
        {detailsVisible ? (
          <div className="list listVisible">
            <div className="holder">
              <StudentDetails
                studentId={studentId}
                studentName={studentName}
                showUnseenLessonBadge={showUnseenLessonBadge}
                showUnreadMessagesBadge={showUnreadMessagesBadge}
                goTo={tab}
                interventions={interventions}
                setInterventions={setInterventions}
                setUnreadMessageMain={setUnreadMessage}
                setUnseenLessonMain={setUnseenLesson}
                setDetailsVisible={setDetailsVisible}
              />
            </div>
          </div>
        ) : (
          <div className="list">
            <table className="theIntervention">
              <tbody>
                <tr>
                  <td>
                    <h1>{t("Studenten")}</h1>
                  </td>
                  <td className="options">
                    <button
                      className="btn btn-sm btn-secondary"
                      data-tip={t("Filter resultaten")}
                      onClick={() => {
                        setShowFilter(!showFilter);
                      }}
                    >
                      <i className="fas fa-filter"></i>
                    </button>{" "}
                    &nbsp;
                    <button
                      className="btn btn-sm btn-primary"
                      data-tip={t("Voeg student toe")}
                      onClick={addUser}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                    <ReactTooltip place="top" effect="solid" delayShow={200} />
                  </td>
                </tr>
              </tbody>
            </table>

            {message.length < 1 ? (
              <></>
            ) : (
              <div className="alert alert-success" role="alert">
                {message}
              </div>
            )}

            {errorMessage.length < 1 ? (
              <></>
            ) : (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}

            {studentsLoading ? (
              <LoadScreen />
            ) : (
              <>
                {
                  <>
                    {showFilter ? (
                      <div className="filter">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={" " + t("Zoeken op naam of e-mailadres")}
                          onChange={(e) => {
                            setFilterOnKeyword(e.target.value);
                          }}
                        />
                        &nbsp;
                        <select
                          className="custom-select"
                          value={filterOnProgram}
                          onChange={(e) => {
                            setFilterOnProgram(e.target.value);
                          }}
                        >
                          <option value="">{t("Programma")}</option>
                          {Object.values(interventions).map(
                            (intervention, index) => {
                              return (
                                <option
                                  key={index}
                                  value={intervention.intervention_id}
                                >
                                  {
                                    intervention.title
                                  }
                                </option>
                              );
                            }
                          )}
                        </select>
                        <br />
                        <br />
                        &nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="filterOnUnreadMessages"
                          checked={filterOnUnreadMessages}
                          onChange={(e) => {
                            setFilterOnUnreadMessages(e.target.checked);
                          }}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="filterOnUnreadMessages"
                        >
                          {" "}
                          {t("Ongelezen berichten")}
                        </label>
                        <br />
                        &nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="filterOnUnseenLessons"
                          checked={filterOnUnseenLessons}
                          onChange={(e) => {
                            setFilterOnUnseenLessons(e.target.checked);
                          }}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="filterOnUnseenLessons"
                        >
                          {" "}
                          {t("Nog te bekijken lessen")}
                        </label>
                        <br />
                        &nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="filterOnCalls"
                          checked={filterOnCalls}
                          onChange={(e) => {
                            setFilterOnCalls(e.target.checked);
                          }}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="filterOnCalls"
                        >
                          {" "}
                          {t("Nog te bellen")}
                        </label>
                        <br />
                        <br />
                        &nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="filterOnBlendedcare"
                          checked={filterOnblendedCare}
                          onChange={(e) => {
                            setFilterOnBlendedcare(e.target.checked);
                          }}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="filterOnBlendedcare"
                        >
                          {" "}
                          {t("Blended care")}
                        </label>
                        <br />
                        &nbsp;
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="filterOnTestAccount"
                          checked={filterOnTestAccount}
                          onChange={(e) => {
                            setFilterOnTestAccount(e.target.checked);
                          }}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="filterOnTestAccount"
                        >
                          {" "}
                          {t("Test accounts")}
                        </label>
                        <br />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="holder">
                      <div className="users">
                        <Pagination
                          pagingStart={pagingStart}
                          pagingLength={pagingLength}
                          setPagingStart={setPagingStart}
                          setPagingLength={setPagingLength}
                          nrResults={nrSortedStudents}
                        />
                        <StudentsTable
                          students={sortedStudentsPaginated}
                          nrResults={nrSortedStudents}
                          interventions={interventions}
                          changeSort={changeSort}
                          showStudentDetails={showStudentDetails}
                          detailsVisible={detailsVisible}
                          setCalled={setCalled}
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            )}
          </div>
        )}
      </div>

      <Modal {...state.modalState} />

    </>
  );
};

export default Students;
