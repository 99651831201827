import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import ContentEditable from 'react-contenteditable';
import pencilSVG from '../../../../images/course/standard/svg/pencil.svg';
import t from '../../../translate';

const QuestionOpen = (props) => {

  //const [empty, setEmpty] = useState('')

  /*
  useEffect(() => {
    if(props.answer == '' && props.part.must && !props.nextAllowed){
      setEmpty(' empty')
    } else {
      setEmpty('')
    }

  }, [props]);
  */

  return (
    <div className={"question_open" + (props.part.must ? ' must':'')}>
      <div className="center">
        <div className="question">
          {parse(props.part.question)}
        </div>
        <div className="openQuestion">
            <div className="icon">
                <img src={pencilSVG} alt="pencil" />
            </div>
            <div className="input">
                {props.part.subtype == "tekstvlak" ?
                <ContentEditable
                      //innerRef={props.focus !== false && typed == false ? focus:false}
                      html={props.answer}
                      id={props.part.id}
                      placeholder={typeof props.placeholder !== 'undefined' && props.placeholder.length > 0 ? props.placeholder : t("Jouw antwoord...")}
                      disabled={props.hasOwnProperty("disabled") && props.disabled === "true" ? true:false}
                      onChange={(e) =>   props.updateAnswer(props.part.id, e.target.value)}
                      className="textarea"
                />
                :
                ''}
                {props.part.subtype === "tekstveld" || props.part.subtype === "numveld" ? <input id={props.part.id} type={props.part.subtype === "numveld" ? 'number' : 'text'} onChange={(e) => props.updateAnswer(props.part.id, e.target.value)} value={props.answer} disabled={props.hasOwnProperty("disabled") && props.disabled === "true" ? true:false} style={{width: '100%'}} min={props.part.subtype === "numveld" ? 0 : ''} />:''}
            </div>
        </div>
        {/* <div className="icon">
            <img src={pencilSVG} alt="pencil" />
         </div>
        {props.part.subtype == "tekstvlak" ?
          <ContentEditable
              //innerRef={props.focus !== false && typed == false ? focus:false}
              html={props.answer}
              id={props.part.id}
              placeholder={props.placeholder}
              disabled={props.hasOwnProperty("disabled") && props.disabled === "true" ? true:false}
              onChange={(e) =>   props.updateAnswer(props.part.id, e.target.value)}
              className="textarea"
            />
          :
        ''}
        {props.part.subtype === "tekstveld" || props.part.subtype === "numveld" ? <input id={props.part.id} type={props.part.subtype === "numveld" ? 'number' : 'text'} onChange={(e) => props.updateAnswer(props.part.id, e.target.value)} value={props.answer} disabled={props.hasOwnProperty("disabled") && props.disabled === "true" ? true:false} style={{width: '100%'}} min={props.part.subtype === "numveld" ? 0 : ''} />:''} */}
      </div>
    </div>
  );
}

export default QuestionOpen;
