import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getClone } from "../../utils";
import { setActivePart, setActiveIntervention, setShowLeftMenu, setIntervention } from "../../../actions/";
import t from "../../translate";

import modulesSVG from '../../../images/svg/course_menu_modules.svg';
import modulesLockedSVG from '../../../images/svg/course_menu_modules_locked.svg';
import scoresSVG from '../../../images/svg/course_menu_scores.svg';
import scoresLockedSVG from '../../../images/svg/course_menu_scores_locked.svg';
import coachSVG from '../../../images/svg/course_menu_coach.svg';
import coachLockedSVG from '../../../images/svg/course_menu_coach_locked.svg';
import badgesSVG from '../../../images/svg/course_menu_badges.svg';
import badgesLockedSVG from '../../../images/svg/course_menu_badges_locked.svg';
import moreSVG from '../../../images/svg/course_menu_more.svg';
import moreLockedSVG from '../../../images/svg/course_menu_more_locked.svg';
import toolsSVG from '../../../images/svg/course_menu_tools.svg';
import toolsLockedSVG from '../../../images/svg/course_menu_tools_locked.svg';
import journalSVG from '../../../images/svg/course_menu_journal.svg';
import journalLockedSVG from '../../../images/svg/course_menu_journal_locked.svg';
import sleepdiarySVG from '../../../images/svg/course_menu_sleepdiary.svg';
import sleepdiaryLockedSVG from '../../../images/svg/course_menu_sleepdiary_locked.svg';
import stressmeterSVG from '../../../images/svg/course_menu_stressmeter.svg';
import stressmeterLockedSVG from '../../../images/svg/course_menu_stressmeter_locked.svg';

const MenuItems = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const activePart = useSelector(state => state.activePart);
  const intervention = useSelector(state => state.intervention);
  const allAnswers = useSelector(state => state.answersLessons);
  const showLeftMenu = useSelector(state => state.showLeftMenu);
  const newBadges = useSelector(state => state.newBadges);

  const shortenItems = {
    "Slaapdagboek": "Slaap",
    "Sleepdiary": "Sleap",
    "Sleepjournal": "Sleap",
    "Jouw scores": "Scores",
    "Your scores": "Scores",
    "Stressmeter": "Stress",
  }

  const changeActivePart = (activePart) => {
    dispatch(setActivePart(activePart));
    dispatch(setShowLeftMenu(false));
    history.push("/course/"+intervention.id+"/"+activePart);

    /// als we chat openen zet 0 nieuwe berichten
    if(activePart == "chat" && intervention.settings.numberOfNewMessages > 0){
      let newIntervention = getClone(intervention);
      newIntervention.settings.numberOfNewMessages = 0;
      dispatch(
        setIntervention(
          intervention.id,
          intervention.organisation_id,
          intervention.title,
          newIntervention.settings
        )
      );
    }
  }

  /// dit nog uitzoeken <= heeft betrekking op het mobiele menu
  const [showMenu, setShowMenu] = useState(false)

  function toggleMenu(){
    dispatch(setShowLeftMenu(showLeftMenu?false:true))
  }

  const [modulesTitle, setModulesTitle] = useState('Lessen')
  const [objectivesTitle, setObjectivesTitle] = useState('Activiteiten')
  const [journalTitle, setJournalTitle] = useState('Dagboek')
  const [stressTitle, setStressTitle] = useState('Stemming')
  const [coachTitle, setCoachTitle] = useState('Coach')
  const [badgesTitle, setBadgesTitle] = useState('Badges')
  const [sleepDiaryTitle, setSleepDiaryTitle] = useState('Slaapdagboek')
  const [scoresTitle, setScoresTitle] = useState('Jouw scores')

  useEffect(() => {

    if(typeof intervention.settings.menu != "undefined")
    {
      if(intervention.settings.menu.modules != ""){
        setModulesTitle(intervention.settings.menu.modules)
      }
      if(intervention.settings.menu.objectives != ""){
        setObjectivesTitle(intervention.settings.menu.objectives)
      }
      if(intervention.settings.menu.journal != ""){
        setJournalTitle(intervention.settings.menu.journal)
      }
      if(intervention.settings.menu.stress != ""){
        setStressTitle(intervention.settings.menu.stress)
      }
      if(intervention.settings.menu.coach != ""){
        setCoachTitle(intervention.settings.menu.coach)
      }
      if(typeof intervention.settings.menu.badges !== 'undefined' && intervention.settings.menu.badges !== "") {
        setBadgesTitle(intervention.settings.menu.badges)
      }
      if(typeof intervention.settings.menu.sleepdiary !== 'undefined' && intervention.settings.menu.sleepdiary !== ""){
        setSleepDiaryTitle(intervention.settings.menu.sleepdiary)
      }
      if(typeof intervention.settings.menu.scores !== "undefined" && intervention.settings.menu.scores !== ""){
        setScoresTitle(intervention.settings.menu.scores)
      }
    }

  }, [intervention]);

  /// check of badges aan staan en of de datum van start indien gezet later is
  const [badgesActive, setBadgesActive] = useState(false);

  useEffect(() => {
    if(auth.user_id > 0 && typeof intervention.settings.gamification !== "undefined" && intervention.settings.gamification.badges) {
      if(typeof intervention.settings.gamification.badgesFromDateActive !== "undefined" && typeof intervention.settings.gamification.badgesFromDate !== "undefined" && intervention.settings.gamification.badgesFromDateActive) {
        if(typeof intervention.settings.gamification.badgesFromDate[0] !== "undefined" && intervention.settings.gamification.badgesFromDate[0] !== "") {
          //er is een datum vanaf ingesteld voor de gamification, alleen accounts die na deze datum zijn aangemaakt
          //krijgen deze optie te zien.
          if (auth.dtc > intervention.settings.gamification.badgesFromDate[0].substring(0,10)) {
            setBadgesActive(true);
          }
        }
      } else {
        setBadgesActive(true);
      }
    }
  }, [auth, intervention]);

  const hasAccessToGoals = () => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen (tools) => is komen te vervallen
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26) { //alleen testen voor interventie 26, anders true
      if (typeof allAnswers.answers !== 'undefined') { //zijn er antwoorden, anders false
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') { //startTimestamp al gezet?
          var today = new Date();
          var sixdaysago = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
          //var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60*24*6);
          var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60);
          if (allAnswers.answers[lessonIndex].finished && allAnswers.answers[lessonIndex].startTimestamp < sixDaysAgoTimestamp) {
            return true;
          }
        }
      }
      return false;
    } */
    return true;
  }

  const fakeEmptyFunc = () => {

  }

  return(
    <div className="items clearfix">

        <div className={activePart == "lessons" ? "menuItem pointer active" : "menuItem pointer"} onClick={() => changeActivePart("lessons")}>
            <img src={modulesSVG} />
            <span className="Desktop">{modulesTitle}</span>
            <span className="Phone">{typeof shortenItems[modulesTitle] !== "undefined" ? shortenItems[modulesTitle] : modulesTitle}</span>

        </div>

        {
            //goals
            intervention.settings.goals.length > 0 && hasAccessToGoals() ?
                <div className={activePart == "goals" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("goals") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                    <img src={auth.doT0 ? toolsLockedSVG : toolsSVG} />
                    <span className="Desktop">{objectivesTitle}</span>
                    <span className="Phone">{typeof shortenItems[objectivesTitle] !== "undefined" ? shortenItems[objectivesTitle] : objectivesTitle}</span>
                </div> : <></>
        }

        {
            //diary
            typeof intervention.settings !== "undefined" && typeof intervention.settings.include_journal !== "undefined" &&  intervention.settings.include_journal === 1 ?
                <div className={activePart == "journal" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("journal") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                    <img src={auth.doT0 ? journalLockedSVG : journalSVG} />
                    <span className="Desktop">{journalTitle}</span>
                    <span className="Phone">{typeof shortenItems[journalTitle] !== "undefined" ? shortenItems[journalTitle] : journalTitle}</span>
                </div> : <></>
        }
           
         {
            //Stressmeter?
            typeof intervention.settings !== "undefined" && typeof intervention.settings.include_stress_meter !== "undefined" &&  intervention.settings.include_stress_meter === 1 ?
                <div className={activePart == "stress" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("stress") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                <img src={auth.doT0 ? stressmeterLockedSVG : stressmeterSVG} />
                <span className="Desktop">{stressTitle}</span>
                <span className="Phone">{typeof shortenItems[stressTitle] !== "undefined" ? shortenItems[stressTitle] : stressTitle}</span>
            </div> : <></>
        }

        {
            //Slaap dagboek?
            typeof intervention.settings !== "undefined" && typeof intervention.settings.include_sleepdiary !== "undefined" &&  intervention.settings.include_sleepdiary === 1 ?
                <div className={activePart == "sleep-diary" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("sleep-diary") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                <img src={auth.doT0 ? sleepdiaryLockedSVG : sleepdiarySVG} />
                <span className="Desktop">{sleepDiaryTitle}</span>
                <span className="Phone">{typeof shortenItems[sleepDiaryTitle] !== "undefined" ? shortenItems[sleepDiaryTitle] : sleepDiaryTitle}</span>
            </div> : <></>
        }

        {
            //Coach
            //RCT iSleep & BioClock controle groepen hebben geen coach ondersteuning.
            intervention.settings.intervention_type !== "selfhelp" && parseInt(intervention.id) !== 46 && !auth.onWaitingListRct ?
                <div className={activePart == "chat" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("chat") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                    <img src={auth.doT0 ? coachLockedSVG : coachSVG} />
                    <span className="Desktop">{coachTitle}</span>
                    <span className="Phone">{typeof shortenItems[coachTitle] !== "undefined" ? shortenItems[coachTitle] : coachTitle}</span>
                </div> : <></>
        }
        {
            //Badges
            badgesActive ?
                <div className={activePart == "badges" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("badges") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                <img src={auth.doT0 ? badgesLockedSVG : badgesSVG} />
                <span className="Desktop">{badgesTitle}</span>
                <span className="Phone">{typeof shortenItems[coachTitle] !== "undefined" ? shortenItems[badgesTitle] : badgesTitle}</span>
            </div> : <></>
        }
        {
            //Scores 
            !auth.oldRegistrationFlow ? 
                <div className={activePart == "scores" ? "menuItem pointer active" : "menuItem pointer"} onClick={!auth.doT0 ? () => changeActivePart("scores") : ()=>fakeEmptyFunc()} style={auth.doT0 ? {cursor: 'not-allowed'} : {}}>
                <img src={auth.doT0 ? scoresLockedSVG : scoresSVG} />
                <span className="Desktop">{t(scoresTitle)}</span>
                <span className="Phone">{typeof shortenItems[scoresTitle] !== "undefined" ? shortenItems[scoresTitle] : scoresTitle}</span>
            </div> : <></>
        }

        <div className={activePart == "more" ? "menuItem pointer active" : "menuItem pointer"} onClick={() => changeActivePart("more")}>
            <img src={moreSVG} />
            <span className="Desktop">{t("Meer")}</span>
            <span className="Phone">{t("Meer")}</span>
        </div>


    {/* <table className="holder ">
      <tbody>
      <tr
        className={activePart == "lessons" ? "active" : ""}
        onClick={() => changeActivePart("lessons")}
      >
        <td>
          <img src={modulesSVG} className='desktop'/>
        </td>
        <td>
          <img src={modulesSVG} className='phone'/>
          <span>{modulesTitle}</span>
        </td>
      </tr>
      {intervention.settings.goals.length > 0 && hasAccessToGoals() ?
        <tr className='spacer'><td></td><td></td></tr>
        : <></>}
      {intervention.settings.goals.length > 0 && hasAccessToGoals() ?
        <tr
          onClick={!auth.doT0 ? () => changeActivePart("goals") : ()=>fakeEmptyFunc()}
          className={activePart == "goals" || activePart == "goal" || activePart == "goal-edit" ? "active" : ""}
          style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
        >
          <td>
            <img src={(activePart == "goals" || activePart == "goal" || activePart == "goal-edit") ? goalsSVG : auth.doT0 ? goalsSVG : goalsSVG} className='desktop'/>
          </td>
          <td>
            <img src={auth.doT0 ? goalsSVG : goalsSVG} className='phone'/>
            <span style={auth.doT0 ? {color: '#ccc'} : {}}>{objectivesTitle}</span>
          </td>
        </tr>
        : <></>}
      <tr className='spacer'><td></td><td></td></tr>

      {
        //Dagboek?
        (typeof intervention.settings !== "undefined" && typeof intervention.settings.include_journal !== "undefined" &&  intervention.settings.include_journal === 1) ?
          <>
            <tr
              onClick={!auth.doT0 ? () => changeActivePart("journal") : ()=>fakeEmptyFunc()}
              className={activePart == "journal" ? "active" : ""}
              style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
            >
              <td>
                <img src={activePart == "journal" ? diaryWhiteSVG : auth.doT0 ? diaryGreySVG : diarySVG} className='desktop'/>
              </td>
              <td>
                <img src={auth.doT0 ? diaryGreySVG : diarySVG} className='phone'/>
                <span style={auth.doT0 ? {color: '#ccc'} : {}}>{journalTitle}</span>
              </td>
            </tr>
            <tr className='spacer'><td></td><td></td></tr>
          </> : <></>
      }

      {
        //Stressmeter?
        (typeof intervention.settings !== "undefined" && typeof intervention.settings.include_stress_meter !== "undefined" &&  intervention.settings.include_stress_meter === 1) ?
          <>
            <tr
              onClick={!auth.doT0 ? () => changeActivePart("stress") : ()=>fakeEmptyFunc()}
              className={activePart == "stress" ? "active" : ""}
              style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
            >
              <td>
                <img src={activePart == "stress" ? stressWhiteSVG : auth.doT0 ? stressGreySVG : stressSVG} className='desktop'/>
              </td>
              <td>
                <img src={auth.doT0 ? stressGreySVG : stressSVG} className='phone'/>
                <span style={auth.doT0 ? {color: '#ccc'} : {}}>{stressTitle}</span>
              </td>
            </tr>
            <tr className='spacer'><td></td><td></td></tr>
          </> : <></>
      }
      {
        //Slaap dagboek?
        (typeof intervention.settings !== "undefined" && typeof intervention.settings.include_sleepdiary !== "undefined" &&  intervention.settings.include_sleepdiary === 1) ?
          <>
            <tr
              onClick={!auth.doT0 ? () => changeActivePart("sleep-diary") : ()=>fakeEmptyFunc()}
              className={activePart == "sleep-diary" ? "active" : ""}
              style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
            >
              <td>
                <img src={activePart == "sleep-diary" ? sleapWhiteSVG : auth.doT0 ? sleapGreySVG : sleapSVG} className='desktop'/>
              </td>
              <td>
                <img src={auth.doT0 ? sleapGreySVG : sleapSVG} className='phone'/>
                <span style={auth.doT0 ? {color: '#ccc'} : {}}>{sleepDiaryTitle}</span>
              </td>
            </tr>
            <tr className='spacer'><td></td><td></td></tr>
          </> : <></>
      }
      {
      //RCT iSleep & BioClock controle groepen hebben geen coach ondersteuning.
      intervention.settings.intervention_type !== "selfhelp" && parseInt(intervention.id) !== 46 && !auth.onWaitingListRct ?
        <tr
          onClick={!auth.doT0 ? () => changeActivePart("chat") : ()=>fakeEmptyFunc()}
          className={activePart == "chat" ? "active" : ""}
          style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
        >
          <td>
            <img src={activePart == "chat" ? chatWhiteSVG : auth.doT0 ? chatGreySVG : chatSVG} className='desktop'/>
          </td>
          <td>
            <img src={auth.doT0 ? chatGreySVG : chatSVG} className='phone'/>
            <span style={auth.doT0 ? {color: '#ccc'} : {}}>{coachTitle}</span>

            {intervention.settings.numberOfNewMessages > 0 && !auth.doT0 ?
              <div className='newMessage'>
                {intervention.settings.numberOfNewMessages}
              </div>
              : <></>}
          </td>
        </tr> : <></>
    }
      {
        !auth.oldRegistrationFlow ? 
          <>
            <tr className='spacer'><td></td><td></td></tr>
            <tr
              onClick={!auth.doT0 ? () => changeActivePart("scores") : ()=>fakeEmptyFunc()}
              className={activePart == "scores" ? "active" : ""}
              style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
            >
            <td>
              <img src={activePart == "scores" ?  scoresWhiteSVG : auth.doT0 ? scoresGreySVG : scoresSVG} className='desktop' style={{height: '80%', top: '5px'}}/>
            </td>
            <td>
              <img src={auth.doT0 ? scoresGreySVG : scoresSVG} className='phone' style={{height: '70%'}} />
              <span style={auth.doT0 ? {color: '#ccc'} : {}}>{t(scoresTitle)}</span>
            </td>
          </tr>
        </>
        :
        <></>
      }
      {badgesActive ?
        <>
        <tr className='spacer'><td></td><td></td></tr>
        <tr
          onClick={!auth.doT0 ? () => changeActivePart("badges") : ()=>fakeEmptyFunc()}
          className={activePart == "badges" ? "active" : ""}
          style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
        >
          <td>
            <img src={activePart == "badges" ?  badgesWhiteSVG : auth.doT0 ? badgesGreySVG : badgesSVG} className='desktop' style={{height: '80%', top: '5px'}}/>
          </td>
          <td>
            <img src={auth.doT0 ? badgesGreySVG : badgesSVG} className='phone' style={{height: '70%'}} />
            <span style={auth.doT0 ? {color: '#ccc'} : {}}>{badgesTitle}</span>
            {newBadges.badges.length > 0 && !auth.doT0 ?
              <div className='newBadges'>
                {newBadges.badges.length}
              </div>
              :false}
          </td>
        </tr>

        </>
        :
        <></>
      }
      <tr className='spacer'><td></td><td></td></tr>
      <tr
        onClick={!auth.doT0 ? () => changeActivePart("more") : ()=>fakeEmptyFunc()}
        className={"desktop " + (activePart == "more" || activePart == "page" ? "active" : "")}
        style={auth.doT0 ? {cursor: 'not-allowed'} : {}}
      >
        <td>
          <img src={activePart == "more" || activePart == "page" ? moreWhiteSVG : auth.doT0 ? moreGreySVG : moreSVG } className='desktop'/>
        </td>
        <td>
          <img src={auth.doT0 ? moreGreySVG : moreSVG} className='phone'/>
          <span style={auth.doT0 ? {color: '#ccc'} : {}}>{t("Meer")}</span>
        </td>
      </tr>
      <tr className={"phone" + ((activePart == "more" || activePart == "page") ? " active" : "")} onClick={!auth.doT0 ? ()=>toggleMenu() : ()=>fakeEmptyFunc()}>
        <td></td>
        <td>
          <img src={auth.doT0 ? moreGreySVG : moreSVG} className='phone'/>
          <span style={auth.doT0 ? {color: '#ccc'} : {}}>{t("Meer")}</span>
        </td>
      </tr>
    </tbody>
    </table> */}
    </div>
  )
}

export default MenuItems;
