import {GetDate} from "./convertTimestamp";
import {getTimeStamp} from "./changeFormatDate";
import moment from "moment";

export const getStartDateWeek = (week, year) => {
  let date =  moment().year(year).week(week).day("Monday").toDate();
  return GetDate(getTimeStamp(date), "-");
}

export const getEndDateWeek = (week, year) => {
  let date =  moment().year(year).week(week).day("Monday").add(6, 'days').toDate();
  return GetDate(getTimeStamp(date), "-");
}
