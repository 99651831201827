import React from "react";
import t from "../translate";

export const ConvertTimestamp = (timestamp) => {
  var date = new Date(timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  var date = date.getDate();
  var time =  date + '-' + (month + 1) + '-' + year + " " + hour + ':' + min + ':' + sec ;

  return time;
}

const weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
const nights = ["zondagnacht", "maandagnacht", "dinsdagnacht", "woensdagnacht", "donderdagnacht", "vrijdagnacht", "zaterdagnacht"];

export const GetDay = (timestamp) => {
  var date = new Date(timestamp * 1000);

  return t(weekdays[date.getDay()]);
}
export const GetNight = (timestamp) => {
  var date = new Date(timestamp * 1000);

  return t(nights[date.getDay()]);
}
export const GetTommorow = (timestamp) => {
  var date = new Date(timestamp * 1000);

  if(date.getDay() + 1 == 7){
    return t(weekdays[0]);
  } else {
    return t(weekdays[date.getDay() + 1]);
  }
}

export const GetDate = (timestamp, seperator = ' / ') => {
  var date = new Date(timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  var date = date.getDate();
  var time =  date + seperator + (month + 1) + seperator + year  ;
  return time;
}


export const GetTime = (timestamp) => {
  var date = new Date(timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  var date = date.getDate();
  var time =  hour + ':' + (min < 10 ? '0' : '') + min

  return time;
}

export const GetHour = (timestamp) => {
  var date = new Date(timestamp * 1000);

  return date.getHours();
}
export const GetMinutes = (timestamp) => {
  var date = new Date(timestamp * 1000);

  return date.getMinutes();
}
