import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import ContentEditable from 'react-contenteditable';
import t from "../translate"
import LeftMenu from "../leftmenu"
import NavBar from "./navbar"
import Panels from "./panels"
import Saved from "../saved"
import apiCall from "../api"
import { registrationTextObject } from './registrationTextObjects';


let saveTimeOut = null;

const RegistrationTexts = () => {

   const history = useHistory()
   
   const auth = useSelector(state => state.auth)
   const appUrl = useSelector(state => state.url)

   const [interventions, setInterventions] = useState([])
   const [activeTab, setActiveTab] = useState('panel1')
   const [firstEffect, setFirstEffect] = useState(true)
   const [registrationTexts, setRegistrationTexts] = useState(registrationTextObject)
   const [savingStatus, setSavingStatus] = useState('saved')
   const [registrationTextsloaded, setRegistrationTextsLoaded] = useState(false)
   const [interventionsLoaded, setInterventionsLoaded] = useState(false)

   useEffect(() => {
      //voldoende rechten?
      if (typeof auth.rights.registration_texts_access === 'undefined' || !auth.rights.registration_texts_access) {
         history.push('/')
      }
      //registration_texts ophalen
      apiCall({
         action: "get_registration_texts",
         token: auth.token,
         data: {}
      }).then(resp => {
         let registrationTextsTemp = Object.assign(registrationTexts, resp.content)
         //interventies ophalen
         apiCall({
            action: "get_interventions",
            token: auth.token,
            data: {}
         }).then(resp => {
            setInterventions(resp.interventions)
            return resp.interventions
         }).then((interventions) => {
            interventions.forEach((intervention, index) => {
               let findIt = registrationTextsTemp.panel6.interventions.findIndex(int => {
                  return int.id === intervention.id
               })
               if (findIt === -1) {
                  let newIntervention = {
                     id: intervention.id,
                     introfeedback: {
                        nl: '',
                        eng: ''
                     },
                     outrofeedback: {
                        nl: '',
                        eng: ''
                     },
                     introscores: {
                        nl: '',
                        eng: '',
                     }, 
                     outroscores: {
                        nl: '',
                        eng: '',
                     },
                     T0T1scores: []
                  }
                  registrationTextsTemp.panel6.interventions.push(newIntervention)
               }               
            })

            let key = registrationTextsTemp.panel6.interventions.length;   
            while (key--) {
            //registrationTextsTemp.panel6.interventions.forEach((int, key) => {
               if (!registrationTextsTemp.panel6.interventions[key]) {
                  registrationTextsTemp.panel6.interventions.splice(key, 1);
               }
               
               if (typeof registrationTextsTemp.panel6.interventions[key].introfeedback === "undefined") {
                  registrationTextsTemp.panel6.interventions[key].introfeedback = {
                     nl: '',
                     eng: ''
                  }
               }
               if (typeof registrationTextsTemp.panel6.interventions[key].outrofeedback === "undefined") {
                  registrationTextsTemp.panel6.interventions[key].outrofeedback = {
                     nl: '',
                     eng: ''
                  }
               }
               if (typeof registrationTextsTemp.panel6.interventions[key].introscores === "undefined") {
                  registrationTextsTemp.panel6.interventions[key].introscores = {
                     nl: '',
                     eng: ''
                  }
               }
               if (typeof registrationTextsTemp.panel6.interventions[key].outroscores === "undefined") {
                  registrationTextsTemp.panel6.interventions[key].outroscores = {
                     nl: '',
                     eng: ''
                  }
               }
            }
            key = registrationTextsTemp.panel2.interventions.length;   
            while (key--) {
               if (typeof registrationTextsTemp.panel2.interventions[key].waitinglist === "undefined") {
                  registrationTextsTemp.panel2.interventions[key].waitinglist = {
                     nl: '',
                     eng: ''
                  }
               }
            }
            setRegistrationTexts(registrationTextsTemp);    
         })
      })

   }, [])

   useEffect(() => {

      if (firstEffect) {
         setFirstEffect(false)
      } else {
         setSavingStatus('not_saved');
         clearTimeout(saveTimeOut);

         saveTimeOut = setTimeout(() => {
            apiCall({
               action: "save_registration_texts",
               token: auth.token,
               data: {
                  content: registrationTexts
               }
             }).then(resp => {
               setSavingStatus('saved');
               //setRegistrationTexts(resp.content)
             })
            }, 3000)
      }

   }, [registrationTexts])

   function onChange(e, name = '') {
      let parts = [];
      if (typeof e.target.name !== "undefined") {
         parts = e.target.name.split('_')
      } else {
         parts = name.split('_')
      }
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      if (parts[0] === 'panel1' && parts[1] === 'thirdpartiesContent') {
         registrationTextsTemp[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] = e.target.value
      } else if (parts[0] === 'panel2' && parts[1] === 'interventions') {
         let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
            return parseInt(int.id) === parseInt(parts[2])
         })
         if (typeof intervention_index !== "undefined") {
            if (parts[3] === 'studentsReviewsContent' || parts[3] === 'mediaContent') {
               registrationTextsTemp[parts[0]][parts[1]][intervention_index][parts[3]][parts[4]][parts[5]] = e.target.value
            } else {
               registrationTextsTemp[parts[0]][parts[1]][intervention_index][parts[3]][parts[4]] = e.target.value
            }
         }
      } else {
         if (parts.length > 2) {
            registrationTextsTemp[parts[0]][parts[1]][parts[2]] = e.target.value
         } else {
            registrationTextsTemp[parts[0]][parts[1]] = e.target.value
         }
      }
      setRegistrationTexts(registrationTextsTemp)
   }

   function onChangeEditor(param, value) {

      let parts = param.split('_')
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      if (parts[0] === 'panel2' && parts[1] === 'interventions') {
         let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
            return parseInt(int.id) === parseInt(parts[2])
         })
         if (typeof intervention_index !== "undefined") {
            registrationTextsTemp[parts[0]][parts[1]][intervention_index][parts[3]][parts[4]] = value
         }
      } else if (parts[0] === 'panel6' && parts[1] === 'interventions') {
         let intervention_index = registrationTextsTemp.panel6.interventions.findIndex(int => {
            return parseInt(int.id) === parseInt(parts[2])
         })
         if (typeof intervention_index !== "undefined") {
            if (parts[3] === 'introfeedback' || parts[3] === 'outrofeedback' || parts[3] === 'introscores' || parts[3] === 'outroscores') {
               registrationTextsTemp[parts[0]][parts[1]][intervention_index][parts[3]][parts[4]] = value
            } else {
               registrationTextsTemp[parts[0]][parts[1]][intervention_index][parts[3]][parts[4]][parts[5]][parts[6]] = value
            }
         }
      } else if (parts[0] === 'panel1' && parts[1] === 'popup') {
         let index = registrationTextsTemp.panel1.popups.findIndex(item => item.intervention_id === parts[2])
         if (index > -1) {
            registrationTextsTemp.panel1.popups[index].content[parts[3]] = value
         }
      } else {
         registrationTextsTemp[parts[0]][parts[1]][parts[2]] = value
      }
      setRegistrationTexts(registrationTextsTemp)
   }

   function getTextInput(param, value, placeholder) {
      return(
         <input
            type="text" 
            style={{width: '100%'}} 
            placeholder={t(placeholder)} 
            id={param}
            name={param} 
            onChange={(e) => onChange(e) } 
            value={value} 
         />
      )
   }

   function getContentEditable(param, value, placeholder) {
      return (
         <ContentEditable
           html={value}
           placeholder={t(placeholder)}
           id={param}
           name={param} 
           onChange={(e) => onChange(e, param   ) } 
           className="contentEditable"
         />
      )
   }

   function getEditor(param, value) {
      return (
         <Editor
            apiKey="k68mc81xjxepc3s70sz7ns6ddgsx6bcgzpn3xgftlxgshmb3"
            inline
            value={value}
            init={{
               menubar:false,
               plugins: 'link image code lists advlist',
               toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect | link | removeformat | code"
            }}
            onEditorChange={(content, editor) => onChangeEditor(param, content)}
         />
      )
   }

   function addThirdPartyProgram() {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      registrationTextsTemp.panel1.thirdpartiesContent.push({
            title: {
               nl: '',
               eng: ''
            },
            shortdescription: {
               nl: '',
               eng: ''
            },
            longdescription: {
               nl: '',
               eng: ''
            },
            url: {
               nl: '',
               eng: ''
            },
         }
      )
      setRegistrationTexts(registrationTextsTemp)
   }

   function removeThirdPartyProgram(index) { 
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      registrationTextsTemp.panel1.thirdpartiesContent.splice(index, 1)
      setRegistrationTexts(registrationTextsTemp)
   }

   function addStudentsReview(intervention_id) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
         return parseInt(int.id) === parseInt(intervention_id)
      })
      if (typeof intervention_index !== "undefined") {
         registrationTextsTemp.panel2.interventions[intervention_index].studentsReviewsContent.push({
            nl: '',
            eng: ''
         })
      }
      setRegistrationTexts(registrationTextsTemp)
   }

   function removestudentsReview(intervention_id, index) { 
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
         return parseInt(int.id) === parseInt(intervention_id)
      })
      if (typeof intervention_index !== "undefined") {
         registrationTextsTemp.panel2.interventions[intervention_index].studentsReviewsContent.splice(index, 1)
         setRegistrationTexts(registrationTextsTemp)
      }
   }

   function addMediaContent(intervention_id, type) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
         return parseInt(int.id) === parseInt(intervention_id)
      })
      if (typeof intervention_index !== "undefined") {
         if (type === 'youtube' || type === 'image') {
            registrationTextsTemp.panel2.interventions[intervention_index].mediaContent.push({
               type: type,
               nl: '',
               eng: ''
            })
         }
      }
      setRegistrationTexts(registrationTextsTemp)
   }

   function removeMediaContent(intervention_id, index) { 
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
         return parseInt(int.id) === parseInt(intervention_id)
      })
      if (typeof intervention_index !== "undefined") {
         registrationTextsTemp.panel2.interventions[intervention_index].mediaContent.splice(index, 1)
         setRegistrationTexts(registrationTextsTemp)
      }
   }

   function setMediaContentImage(intervention_id, index, property, value) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      let intervention_index = registrationTextsTemp.panel2.interventions.findIndex(int => {
         return parseInt(int.id) === parseInt(intervention_id)
      })
      if (typeof intervention_index !== "undefined") {
         if (value.length < 1) {
            registrationTextsTemp.panel2.interventions[intervention_index].mediaContent[index][property] = ''
         } else {
            registrationTextsTemp.panel2.interventions[intervention_index].mediaContent[index][property] = appUrl + '/uploads/intervention/' + value
         }
         setRegistrationTexts(registrationTextsTemp)
      }
   }

   return (
      <div className="whiteWrapper whiteWrapper intervention_edit registration_texts">
         <Saved savingStatus={savingStatus} />
         <LeftMenu />
         <div className="container dashboard_container">
            <div>
               <NavBar activeTab={activeTab} setActiveTab={setActiveTab} />
               <Panels activeTab={activeTab} registrationTexts={registrationTexts} setRegistrationTexts={setRegistrationTexts} getTextInput={getTextInput} getContentEditable={getContentEditable}  getEditor={getEditor} addThirdPartyProgram={addThirdPartyProgram} removeThirdPartyProgram={removeThirdPartyProgram} interventions={interventions} addStudentsReview={addStudentsReview} removestudentsReview={removestudentsReview} addMediaContent={addMediaContent} removeMediaContent={removeMediaContent} setMediaContentImage={setMediaContentImage} />
            </div>
         </div>
      </div>
   )
}

export default RegistrationTexts
