import React, {useState, useEffect} from "react";
import ContentEditable from 'react-contenteditable'
import t from "../../../translate";
import BadgeInput from "./badge_input";
import PointsInput from "./points_input";
import Message from "./message";

const Badges = props => {

  const [showBadges, setShowBadges] = useState(true)
  const [showBadgesSub, setShowBadgesSub] = useState(true)
  const [badges, setBadges] = useState([])
  const [badgesData, setbadgesData] = useState([])
  const [timesOptions, setTimesOptions] = useState(false)

  function toggleBadges(type){
    props.toggleView(type, showBadges ? false:true)
  }

  useEffect(() => {
    if(typeof props.value !== "undefined"){
      setBadges(props.value);
    }
    if(props.badgesData){
      setbadgesData(props.badgesData)
    }
    if(typeof props.visible !== "undefined"){
      setShowBadges(props.visible);
    }
  }, [props]);


  function changeTimes(action, id, i, times = false){
    let this_times_obj = badgesData.filter(function (badge) {
      return badge.id === id
    });

    if(this_times_obj.length !== 0){
      let index = badgesData.indexOf(this_times_obj[0]);
      if(action == "min"){
        if(badgesData[index].options[i].times > 1){
          badgesData[index].options[i].times = parseInt(badgesData[index].options[i].times) - 1;
        }
      }
      if(action == "plus"){
        badgesData[index].options[i].times = parseInt(badgesData[index].options[i].times) + 1;
      }
      if(action == "set"){
        badgesData[index].options[i].times = times;
      }

      setbadgesData(badgesData)
      props.setTimes(props.type, id, 'updateTimes', i, badgesData[index].options[i].times)
    }

  }

  function getBadge(option){

    let image = "";
    if(typeof badgesData !== "undefined"){
      let this_badge_obj = badgesData.filter(function (badge) {
        return badge.option === option
      });

      if(this_badge_obj.length !== 0){
        if(typeof this_badge_obj[0].image !== "undefined"){
            image = this_badge_obj[0].image
        }
      }
    }

    return image;

  }
  function getText(option){

    let text = "";
    if(typeof badgesData !== "undefined"){
      let this_badge_obj = badgesData.filter(function (badge) {
        return badge.option === option
      });

      if(this_badge_obj.length !== 0){
        if(typeof this_badge_obj[0].text !== "undefined"){
            text = this_badge_obj[0].text
        }
      }
    }

    return text;

  }

  function getTimesOptions(id){
    if(typeof badgesData !== "undefined" && checkIf("active", id)){
      let this_times_obj = badgesData.filter(function (times) {
        return times.id === id
      });
      if(this_times_obj.length !== 0){
        return this_times_obj[0].options;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  function checkIf(action, id, i = false){
    if(typeof badgesData !== "undefined"){
      let this_times_obj = badgesData.filter(function (badge) {
        return badge.id === id
      });

      if(this_times_obj.length !== 0){
        let index = badgesData.indexOf(this_times_obj[0]);
        if(action == "active"){
          return badgesData[index].active;
        }
        if(action == "getTimes"){
          return badgesData[index].options[i].times;
        }
      }
    }
  }

  return(
    <div className={"type_holder" + (showBadges ? ' showBadges':'')}>
      <div onClick={()=>toggleBadges(props.type)} className="pointer">
        <span className="type">
          {props.title}
        </span>
        {badges.length > 0 ?
          <span data-tip="Wijzig les" className="btn edit disabled" ><i className="fas fa-chevron-up"></i></span>
          :''}
      </div>

      {showBadges ?
        <div className="setBadges">
          {props.type == "lesson" || props.type == "optionalLesson" || props.type == "start" || props.type == "questionnaires" || props.type == "uncovered" ?
            <>
            {badges.map((option, index) => (
              <span key={index} className={(getBadge(option.id) == "" ? 'disabled':'')}>
                <div className="image">
                  {getBadge(option.id) !== "" ?
                    <span className="btn delete" onClick={()=>props.imageAction('', props.type + "_" + option.id)}>
                      <i className="fas fa-minus"></i>
                    </span>
                  :<></>}
                  <span className="btn edit" onClick={()=>props.showBadgesLibrary("", props.type + "_" + option.id)}>
                    <i className="fas fa-certificate"></i>
                  </span>
                  <span className="title" title={option.title}>
                    {t(option.title)}
                  </span>
                  <img src={getBadge(option.id)}/>
                </div>
                {
                  props.type !== "uncovered" ?
                    <Message
                    id={option.id}
                    disabled={getBadge(option.id) == "" ? true:false}
                    index={index}
                    type={props.type}
                    value={getText(option.id)}
                    updateRewardText={props.updateRewardText}
                    /> : <></>
                }
                
              </span>
            ))}
            </>
            :<></>
          }
          {props.type == "goals" || props.type == "other" ?
            <>
            {badges.map((item, index) => (
              <div className="part_holder" key={index}>
                <div className={'part' + (checkIf("active", item.id) ? ' active':'')}>
                  {t(item.title)}
                  <label className="switch">
                    <input type="checkbox" checked={checkIf("active", item.id)} onChange={(e) => props.setTimes(props.type, item.id, 'active')}/><span className="slider_switch round"></span>
                  </label>
                </div>
                {item.id == 2 && props.type == "other" && checkIf("active", item.id)?
                  <>
                    <table className="setPoints">
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              {t("Inloggen")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="login"
                                value={props.pointsData.login}
                              />
                            </div>
                            <div>
                              {t("Starten interventie")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="start_intervention"
                                value={props.pointsData.start_intervention}
                              />
                            </div>
                            <div>
                              {t("Doen van doel")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="start_goal"
                                value={props.pointsData.start_goal}
                              />
                            </div>
                            <div>
                              {t("Starten vragenlijst")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="start_questionnaire"
                                value={props.pointsData.start_questionnaire}
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              {t("Afronden les")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="finish_lesson"
                                value={props.pointsData.finish_lesson}
                              />
                            </div>
                            <div>
                              {t("Afronden optionele les")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="finish_optional_lesson"
                                value={props.pointsData.finish_optional_lesson}
                              />
                            </div>
                            <div>
                              {t("Afronden vragenlijst")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="finish_questionnaire"
                                value={props.pointsData.finish_questionnaire}
                              />
                            </div>
                            <div>
                              {t("Afronden interventie")}
                              <PointsInput
                                placeholder={t("punten")}
                                updatePoints={props.updatePoints}
                                type="finish_intervention"
                                value={props.pointsData.finish_intervention}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                  :<></>
                }
                {getTimesOptions(item.id).map((option, i) => (
                  <span key={i}>
                    <div className="title no_marge">
                      <table className="changeNumber">
                        <tbody>
                          <tr>
                            {checkIf("getTimes", item.id, i) == 1 ?
                              <td></td>
                              :
                              <td onClick={()=>changeTimes('min', item.id, i)}>
                                <i className="fas fa-minus"></i>
                              </td>
                            }
                            <td>
                              <BadgeInput
                                id={item.id}
                                index={i}
                                value={checkIf("getTimes", item.id, i)}
                                changeTimes={changeTimes}
                              />
                            </td>
                            <td onClick={()=>changeTimes('plus', item.id, i)}>
                              <i className="fas fa-plus"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="image">
                      <span className="btn delete" onClick={()=>props.setTimes(props.type, item.id, 'delete', i)}>
                        <i className="fas fa-minus"></i>
                      </span>
                      <span className="btn edit" onClick={()=>props.showBadgesLibrary("", props.type + "_" + item.id + "_" + i)}>
                        <i className="fas fa-certificate"></i>
                      </span>
                      <img src={option.image}/>
                    </div>

                    <Message
                      id={item.id}
                      index={i}
                      disabled={false}
                      type={props.type}
                      value={typeof option.text !== "undefined" ? option.text:''}
                      updateRewardText={props.updateRewardText}
                      />
                  </span>
                ))}

                {checkIf("active", item.id) ?
                  <span className="holder_edit">
                    <span className="add btn edit" onClick={()=>props.setTimes(props.type, item.id, 'add')}>
                      <i className="fas fa-plus"></i>
                    </span>
                  </span>
                  :<></>
                }
              </div>
            ))}
            </>
            :<></>
          }
        </div>
        :''}
    </div>
  )
}

export default Badges
