import React, {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import apiCall from "../../../api"
import t from "../../../translate";

const PlatformPage = (props) => {

  const [chosenPageId, setChosenPageId] = useState(false);
  const [platformPages, setPlatformPages] = useState([]);

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  //////////////////////
  ///On init
  useEffect(() => {
    apiCall({
      action: 'get_platform_pages',
      token: auth.token,
      data: {}
   }).then(resp => {   
    setPlatformPages(resp.pages)
   })
   if(typeof props.part.page_id !== "undefined") {
      setChosenPageId(props.part.page_id)
   }
  }, []);

  function updateChosenPage(page_id){
    props.updatePart(props.index, 'page_id', page_id)
    setChosenPageId(page_id)
  }

  return(
    <div className="forms center">
      <select onChange={(e) => updateChosenPage(e.target.value)} value={chosenPageId}>
        <option>{t("Selecteer platform pagina")}</option>
        {
          platformPages.map((page, key) => {
            return(
              <option key={key} value={page.id}>{t("Platform pagina: ")}: {page.settings.title}</option>
            )
          })
        }
      </select>
    </div>
  )
}
export default PlatformPage
