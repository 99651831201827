import React, {useState, useEffect} from "react";
import t from "../../../translate";

const Message = props => {
  const [value, setValue] = useState(false);
  let ref = React.createRef()

  useEffect(() => {
    if(typeof props.value != "undefined"){
      setValue(props.value);
    }
  }, [props.value]);

  function change(value){

    setValue(value)

    props.updateRewardText(value, props.type, props.id, props.index)
  }

  function blurOnEnter(event) {
    if(event.key === 'Enter'){
      ref.current.blur();
    }
  }

  return(
    <>
      <input
        ref={ref}
        type="text"
        className="message"
        value={value}
        onChange={(e)=>change(e.target.value)}
        onKeyPress={(e)=>blurOnEnter(e)}
        placeholder={t("Boodschap")}
        disabled={props.disabled}
        />

    </>
  )
}

export default Message
