import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCookie } from "../utils";
import ShowPoints from "./points";
import BadgeOverlay from "./badge";
import { useLocation } from 'react-router-dom'
import apiCall from "../api";
import {login} from "../../actions";
import { setNewBadges } from "../../actions";

const Rewards = (props) =>{

  let location = useLocation();

  const [rewards, setRewards] = useState([]); ///place rewards to state
  const [showReward, setShowReward] = useState([]);///rewards to show
  const [showData, setShowData] = useState(false);///data to show if not false badge will be shown
  const [showPoints, setShowPoints] = useState(false);

  const auth = useSelector(state => state.auth);
  const allAnswers = useSelector(state => state.answersLessons);
  const intervention_id = useSelector(state => state.intervention.id);

  const dispatch = useDispatch();

  /////////////////
  ////Check for new reward data every s
  /* useEffect(() => {
    if (props.badgesActive) {
      const interval = setInterval(() => {
           if(showReward.length == 0){
             setShowData(false)
             checkRewards();
           }
         }, 1000);
         return () => clearInterval(interval);
    }
  }, [intervention_id, showReward, props.badgesActive]); */

  useEffect(() => {
    checkRewards();
  }, [allAnswers, location])


  ///check if rewards object is changed
  function checkRewards() {
    if(intervention_id > 0 && getCookie("token").length > 0){
      apiCall({
        action: "check_rewards",
        token: getCookie("token"),
        data:{intervention_id:intervention_id}
      }).then(resp => {
        if (resp.rewards) {
          if(btoa(resp.rewards) != btoa(auth.rewards)){
            updateAuth(resp.rewards)
          }

          setRewards(resp.rewards)

          let this_rewards_obj = resp.rewards.filter(function (reward) {
            return parseInt(reward.id) === parseInt(intervention_id)
          });
          if(this_rewards_obj.length != 0){
            if(this_rewards_obj[0].new_badges){
              dispatch(setNewBadges(intervention_id, this_rewards_obj[0].new_badges));
            }
          }
        }
      });
    }
  }



  ///Close reward and remove to show object from to show array in db
  function closeRewards(){
    if(getCookie("token").length > 0){
      props.setHideMenu(false)
      apiCall({
        action: "update_reward_is_shown",
        token: getCookie("token"),
        data:{intervention_id:intervention_id}
      }).then(resp => {
        if (resp.rewards) {
          updateAuth(resp.rewards)
        }
        setRewards(resp.rewards)

      });
    }
  }

  ///update local rewards in de redux
  function updateAuth(rewardsNew){
    dispatch(
      login(
        auth.user_id,
        auth.dtc,
        auth.name,
        auth.token,
        auth.userType,
        auth.rights,
        auth.preferences,
        auth.t0_scores,
        auth.t1_scores,
        rewardsNew,
        auth.profile_pic,
        auth.email,
        auth.private_email,
        auth.gender,
        auth.education,
        auth.firstname,
        auth.insertion,
        auth.lastname,
        auth.organisation_id,
        auth.oldRegistrationFlow,
        auth.specialAccountType,
        auth.doT0,
        auth.onWaitingListRct
      )
    );
  }

  ///Close reward and remove to show object from to show array in db
  function closeShownPoints(){
    if(getCookie("token").length > 0){
      apiCall({
        action: "update_points_are_shown",
        token: getCookie("token"),
        data:{intervention_id:intervention_id}
      }).then(resp => {
        if (resp.rewards) {
          updateAuth(resp.rewards)
        }
        setRewards(resp.rewards)
        setShowPoints(false)
      });
    }
  }

  return (
    <>
      <ShowPoints rewards={rewards} showData={showData} closeShownPoints={closeShownPoints} showPoints={showPoints} setShowPoints={setShowPoints}/>
      <BadgeOverlay rewards={rewards} showReward={showReward} showData={showData} setShowData={setShowData} closeRewards={closeRewards} setShowReward={setShowReward} setHideMenu={props.setHideMenu}/>
    </>
  )
}

export default Rewards;
