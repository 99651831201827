import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LeftMenu from "../leftmenu";
import LoadScreen from "../loadScreen";
import apiCall from "../api";
import t from "../translate";
import AdminOptions from "../dashboard/admin/options.js";

const CoachesOverview = () => {

  const [data, setData] = useState({});

  const auth = useSelector(state => state.auth);
  
  useEffect(() => {

    //alle coaches ophalen
    apiCall({
      action: "get_coach_overview",
      token: auth.token,
      data: {}
    }).then(resp => {
      setData(resp);
    });

  }, [])

  function header() {
    return (
        <tr>
          <th>#</th>
          <th>{t("Gebruikersnaam")}</th>
          <th>{t("Coached maximaal")}</th>
          <th>{t("Coached op dit moment")}</th>
          <th>{t("Nederlands")}</th>
          <th>{t("Engels")}</th>
          {
            data.interventions.map(intervention => {
              return (
                <th key={intervention.id} dangerouslySetInnerHTML={{__html: intervention.title}}></th>
              )
            })
          }
        </tr>
    )
  }

  return(
    <div className="coachInterface coaches admin">

      <LeftMenu />
      <div className="list listHidden">
      <AdminOptions activity={true}/>
      <h2>{t("Overzicht coaches")}</h2>
        {
        Object.entries(data).length > 0 ? 
          <>
          <table className="table table-striped coach_overview_table">
            {header()}
            <tbody>
              {

                data.coaches.map(coach => {
                   
                   return (
                    <tr key={coach.id}>
                      <td>{coach.id}</td>
                      <td>{coach.name}</td>
                      <td>{coach.capacity}</td>
                      <td>{coach.coaches}</td>
                      <td>{coach.nl ? 'X' : ''}</td>
                      <td>{coach.en ? 'X' : ''}</td>
                      {
                        data.interventions.map((intervention, key) => {
                          return (
                            <>
                              <td key={key}>{coach.interventions[intervention.id] ? 'X' : ''}</td>
                            </>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }

              {header()}
              
              <tr>
                <td colSpan="6">
                  <strong>{t("Max capaciteit NL")}</strong>
                </td>
                {
                  data.interventions.map(intervention => {
                    return (
                      <td key={'tot_'+intervention.id}>{data.maxcapacity[intervention.id]['nl']}</td>
                    )
                  })
                }
              </tr>

              <tr>
                <td colSpan="6">
                  <strong>{t("Max capaciteit ENG")}</strong>
                </td>
                {
                  data.interventions.map(intervention => {
                    return (
                      <td key={'tot_'+intervention.id}>{data.maxcapacity[intervention.id]['en']}</td>
                    )
                  })
                }
              </tr>

              <tr>
                <td colSpan="6">
                  <strong>{t("NL Registraties afgelopen week")}</strong>
                </td>
                {
                  data.interventions.map(intervention => {
                    return (
                      <td key={'tot_'+intervention.id}>{data.registrations[intervention.id]['nl']}</td>
                    )
                  })
                }
              </tr>

              <tr>
                <td colSpan="6">
                  <strong>{t("ENG Registraties afgelopen week")}</strong>
                </td>
                {
                  data.interventions.map(intervention => {
                    return (
                      <td key={'tot_'+intervention.id}>{data.registrations[intervention.id]['en']}</td>
                    )
                  })
                }
              </tr>

              {/* <tfoot>
              <tr>
                <th>#</th>
                <th>{t("Gebruikersnaam")}</th>
                <th>{t("Coached maximaal")}</th>
                <th>{t("Coached op dit moment")}</th>
                <th>{t("Nederlands")}</th>
                <th>{t("Engels")}</th>
                {
                  interventions.map(intervention => {
                    return (
                      <th key={intervention.id} dangerouslySetInnerHTML={{__html: intervention.title}}></th>
                    )
                  })
                }
              </tr>
            </tfoot> */}
              
            </tbody>
            {/* <tfoot>
              <tr>
                <th colSpan="2"></th>
                {
                  interventions.map(intervention => {
                    return (
                      <th key={'foot_'+intervention.id}>{intervention.title}</th>
                    )
                  })
                }
              </tr>
            </tfoot> */}
          </table> 
          <p>
            Ongebruikte capaciteit NL: {data.capacityleft_nl}, ingezet afgelopen week: {data.registrations_nl}<br />
            Ongebruikte capaciteit ENG: {data.capacityleft_en}, ingezet afgelopen week {data.registrations_en}<br />

          </p>
          </>
          : <LoadScreen />
        }
      </div>
    </div>
  )

}

export default CoachesOverview;
