import React, { useState, useEffect } from "react";
import t from "../../translate";

const HighlightRound = (props) => {

  return(
    <div className={"highlight_round " + props.color}>
      <div className="center">
        <i className={props.icon}></i>
        <div className="content big">
          {props.content}
        </div>
        <div className="label">
          {t(props.label)}
        </div>
      </div>
    </div>
  )
}

export default HighlightRound;
