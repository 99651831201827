import React from "react"
import t from "../translate"

const Panel4 = ({ registrationTexts, getTextInput, getContentEditable, getEditor }) => {

   function addTableRow(cell1, cell2, cell3) {
      return (
         <tr><td>{cell1}</td><td>{cell2}</td><td>{cell3}</td></tr>
      )
   }

   return (
      <div>
         <h2>{t("Scherm 4: Kies coach")}</h2>
         <table className="table table-striped">
            <thead>
               <tr>
                  <th width="20%"></th>
                  <th width="40%">{t("Nederlands")}</th>
                  <th width="40%">{t("Engels")}</th>
               </tr>
            </thead>
            <tbody>
               {
                  addTableRow(
                     t("Titel"), 
                     getTextInput('panel4_intro_nl', registrationTexts.panel4.intro.nl, 'Titel NL'),
                     getTextInput('panel4_intro_eng', registrationTexts.panel4.intro.eng, 'Titel ENG'),
                  ) 
               }
               {
                  addTableRow(
                     t("Omschrijving"), 
                     getEditor('panel4_description_nl', registrationTexts.panel4.description.nl),
                     getEditor('panel4_description_eng', registrationTexts.panel4.description.eng),
                  )
               }
            </tbody>
         </table>
      </div>
   )
} 

export default Panel4