import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClone } from "../../utils";
import apiCall from "../../api";
import { setIntervention } from "../../../actions";
import t from "../../translate";

const ListItemSettings = props => {
  const dispatch = useDispatch();
  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  const [goal, setGoal] = useState(false)
  const [lessons, setLessons] = useState([])

  const [feedbackQuestionnaireInLesson, setFeedbackQuestionnaireInLesson] = useState('')

  const [chosenReleaseAfter, setChosenReleaseAfter] = useState('')
  const [chosenLogOff, setChosenLogOff] = useState('')
  const [skipFirstPage, setSkipFirstPage] = useState('')

  /// er wordt hier nog veel op undefined gecheckt daar kan nog iets beter denk ik

  useEffect(() => {
    if(intervention.id > 0){
      let tempLessons = []
      for(let i = 0 ; i < intervention.settings.selfhelp.lessons.length ; i++){
        if(intervention.settings.selfhelp.lessons[i].parent_id == 0){
          tempLessons.push(intervention.settings.selfhelp.lessons[i])
        }
      }
      for(let i = 0 ; i < intervention.settings.selfhelp.optionalLessons.length ; i++){
        if(intervention.settings.selfhelp.optionalLessons[i].parent_id == 0){
          tempLessons.push(intervention.settings.selfhelp.optionalLessons[i])
        }
      }
      setLessons(tempLessons)

      if(props.type == "questionnaire"){
        if(intervention.settings.questionnaires[props.index]){
          if(intervention.settings.questionnaires[props.index].settings){
            if(intervention.settings.questionnaires[props.index].settings.feedbackQuestionnaireInLesson){
              setFeedbackQuestionnaireInLesson(intervention.settings.questionnaires[props.index].settings.feedbackQuestionnaireInLesson)
            }
          }
        }
      }

      if(props.type == "optional_lesson"){
        if(intervention.settings.selfhelp.optionalLessons[props.index]){
          if(intervention.settings.selfhelp.optionalLessons[props.index].settings){
            if(intervention.settings.selfhelp.optionalLessons[props.index].settings.releaseAfterFinished){
              setChosenReleaseAfter(intervention.settings.selfhelp.optionalLessons[props.index].settings.releaseAfterFinished)
            }
          }
        }

      }
      if(props.type == "goal"){
        if(intervention.settings.goals[props.index]){
          if(intervention.settings.goals[props.index].settings){
            if(intervention.settings.goals[props.index].settings.releaseAfterFinished){
              setChosenReleaseAfter(intervention.settings.goals[props.index].settings.releaseAfterFinished)
            }
          }
        }
        /// misschien  alleen maar vorige laten zien en laten inspringen
        for(let i = (intervention.settings.goals.length - 1) ; i > 0   ; i--){
          if(intervention.settings.goals[i].id == props.id)
          {
            setGoal(intervention.settings.goals[(i - 1)])

            if(intervention.settings.goals[i].settings && intervention.settings.goals[i].settings.logOff){
              setChosenLogOff(intervention.settings.goals[i].settings.logOff)
            }

            if (intervention.settings.goals[i].settings && intervention.settings.goals[i].settings.skipFirstPage)
            {
              setSkipFirstPage(intervention.settings.goals[i].settings.skipFirstPage);
            }
            break;
          }
        }
      }
    }
  }, [props, intervention]);

  function selectChangeReleaseAfter(chosenOption){
    let interventionC = getClone(intervention);
    if(props.type == "goal"){
      interventionC.settings.goals[props.index].settings.releaseAfterFinished = chosenOption
      saveInterventionSettings(interventionC.settings.goals[props.index].settings, interventionC.settings)
    }
    if(props.type == "optional_lesson"){
      interventionC.settings.selfhelp.optionalLessons[props.index].settings.releaseAfterFinished = chosenOption
      saveInterventionSettings(interventionC.settings.selfhelp.optionalLessons[props.index].settings, interventionC.settings)
    }
    setChosenReleaseAfter(chosenOption)

  }
  function selectChangeLogObjectOf(chosenOption){
    let toSave = chosenLogOff == chosenOption ? '':chosenOption
    setChosenLogOff(toSave)
    let interventionC = getClone(intervention);
    interventionC.settings.goals[props.index].settings.logOff = toSave
    saveInterventionSettings(interventionC.settings.goals[props.index].settings, interventionC.settings)
  }

  function selectChangeSkipFirstPage(chosenOption) {

    let toSave = false;
    if (chosenOption === true) {
      setSkipFirstPage(false);
    } else {
      toSave = true;
      setSkipFirstPage(true);
    }

    let interventionC = getClone(intervention);
    interventionC.settings.goals[props.index].settings.skipFirstPage = toSave;
    saveInterventionSettings(interventionC.settings.goals[props.index].settings, interventionC.settings);
  }

  function saveInterventionSettings(settings, allSettingsUpdated) {
    ////newPart + removePart maar toevoegen indien nodig voor de check
    if(typeof settings.newPart == "undefined"){
      settings.newPart = false;
    }
    if(typeof settings.removePart == "undefined"){
      settings.removePart = false;
    }

    /// door het cleanen van de settings bij de saveSettings in edit kan ik niks opslaan in de settings via die weg......... gewoon een enkel item saven dan maar
    let action = "save_lesson";
    if(props.type == "goal"){
      action = "save_goal";
    }
    if(props.type == "questionnaire"){
      action = "save_questionnaire";
    }
    apiCall({
      action: action,
      token: auth.token,
      data: {
        id: props.id,
        settings: settings
      }
    }).then(resp => {

      /// ook dispatchen <= maar dat levert een error op zonder melding....

      dispatch(
        setIntervention(
          intervention.id,
          intervention.organisation_id,
          intervention.title,
          intervention.settings
        )
      );

    });
  }

  function hasLog(){
    if(props.type == "goal"){
      let nextIndex = props.index + 1
      /// bestaat die wel???
      if(nextIndex != intervention.settings.goals.length && intervention.settings.goals[nextIndex] && intervention.settings.goals[nextIndex].settings){
        if(intervention.settings.goals[nextIndex].settings.logOff){
          //// dit is een logitem
          if(intervention.settings.goals[nextIndex].settings.logOff != ""){
            return true
          }
        }
      }
    }
  }

  function prevIsLog(){
    if(props.type == "goal"){
      let prevIndex = props.index - 1
      /// bestaat die wel???
      if(prevIndex != 0){
        if(intervention.settings.goals[prevIndex] && intervention.settings.goals[prevIndex].settings && intervention.settings.goals[prevIndex].settings.logOff){
          //// dit is een logitem
          if(intervention.settings.goals[prevIndex].settings.logOff != ""){
            return true
          }
        }
      }
    }
  }

  function selectFeedbackQuestionnaireInLesson(){
    let toSave = feedbackQuestionnaireInLesson ? false:true
    setFeedbackQuestionnaireInLesson(toSave)
    let interventionC = getClone(intervention);
    interventionC.settings.questionnaires[props.index].settings.feedbackQuestionnaireInLesson = toSave
    saveInterventionSettings(interventionC.settings.questionnaires[props.index].settings, interventionC.settings)
  }

  return (
    <div>
      {props.type == "goal" && chosenReleaseAfter == "" && goal && !hasLog() && !prevIsLog()  ?
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <h5>
                    Log item van {goal.title}
                  </h5>
                </td>
                <td>
                  <label className="switch">
                    <input type="checkbox" checked={chosenLogOff == goal.id ? true:false} onClick={(e) => selectChangeLogObjectOf(goal.id)}/> {chosenLogOff}
                    <span className="slider_switch round" ></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      : <></>}
      {props.type == "goal" && chosenLogOff == "" ?
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <h5>
                    Tussenpagina niet tonen
                  </h5>
                </td>
                <td>
                  <label className="switch">
                    <input type="checkbox" checked={skipFirstPage ? true : false} onClick={(e) => selectChangeSkipFirstPage(skipFirstPage)}/> {skipFirstPage}
                    <span className="slider_switch round" ></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      : <></>}
      {(props.type == "goal" || props.type == "optional_lesson") && chosenLogOff == "" ?
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <h5>
                    Ontsluiten na voltooien les
                  </h5>
                </td>
                <td>
                <select onChange={(e) => selectChangeReleaseAfter( e.target.value)} value={chosenReleaseAfter}>
                  <option value="">Direct benaderbaar</option>
                  {lessons.map((lesson, index) =>
                    <option key={index} value={lesson.id}>{lesson.title}</option>
                  )}
                </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      : <></>}
      {props.type == "questionnaire" ?
        <div>
          <div className="part_title">{t("In de les")}</div>
          <table>
            <tbody>
              <tr>
                <td>
                  <h5>
                    {t("Toon feedback scherm einde vragenlijst")}
                  </h5>
                </td>
                <td>
                  <label className="switch">
                    <input type="checkbox" checked={feedbackQuestionnaireInLesson == true ? true:false} onClick={(e) => selectFeedbackQuestionnaireInLesson()}/>
                    <span className="slider_switch round" ></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        :false}
    </div>
  )
};

export default ListItemSettings;
