import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../translate";
import ContentEditable from "react-contenteditable";
import parse from "html-react-parser";
import apiCall from "../../api";
import { transformDate } from "../../utils";
import $ from "jquery";

const  StudentDetailsChat = ({studentId, student, setUnreadMessage, setUnreadMessageMain}) => {

    const auth = useSelector(state => state.auth);
    const [messagesLoaded, setMessagesLoaded] = useState(false)
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState("")
    const [lastCheckedByStudent, setLastCheckedByStudent] = useState(0)

    useEffect(() => {
        //chatdata ophalen
        if (typeof student.rights !== "undefined" && student.rights.interventions.length > 0 && typeof student.rights.interventions[0]["id"] !== "undefined")
        {
            apiCall({
                action: "get_chat",
                token: auth.token,
                data: {
                    intervention_id: parseInt(student.rights.interventions[0]["id"]),
                    student_id: parseInt(studentId)
                }
              }).then(resp => {
                if (resp.data) {
                  setMessages(resp.data);
                  setLastCheckedByStudent(resp.lastCheckedByStudent);
                  setTimeout(() => {
                    if(document.getElementById('chatFrame')){
                      $('html,body').animate({scrollTop: document.body.scrollHeight});
                    }
                  }, 500);
                  //evt badges niet meer weergeven...
                  setUnreadMessage(false);
                  setUnreadMessageMain(studentId ,false);
                  setMessagesLoaded(true);
                }
          });
        }
    }, [studentId]);

    function updateNewMessage(value, keyCode) {
        setNewMessage(value);
      }

    ///TODO echt saven en ophalen
    function sendNewMessage() {
      apiCall({
        action: "save_chat",
        token: auth.token,
        data: {
          intervention_id: parseInt(student.rights.interventions[0]["id"]),
          student_id: parseInt(student.id),
          content: newMessage
        }
      }).then(resp => {
        if (resp.data) {
          setMessages(resp.data);
          setNewMessage("");
          setTimeout(() => {
            document.getElementById('chatFrame').scrollTop = document.getElementById('chatFrame').scrollHeight;
          }, 500);
        }
      });
    }

    return (
        <div className="chat">
          <div className="chatContent">
            {
                !messagesLoaded ?
                    <i className="fas fa-spinner fa-spin"></i> :
            
                    <div className="messages" id="chatFrame">
                      {messages.map((message, index) => (
                         <div key={index} className={"message " + message.type}>
                          <div className="name">
                            {
                            (message.type === 'sent') ? 
                              <>
                              {
                                (student.anonymous === 1) ?
                                  student.login
                                  :
                                  student.firstname + ' ' + student.insertion + ' ' + student.lastname
                              }
                              </>
                            : message.name
                            }
                            </div>
                          <div className="content">{parse(message.content)}</div>
                          <div className="sendingTime">
                            {transformDate(message.sendingTime)}
                            {
                              message.type == 'received' ?
                              <>
                                &nbsp;
                                <i className={lastCheckedByStudent > message.sendingTimeUnix ? 'blue fas fa-check' : 'fas fa-check'}></i>
                                <i className={lastCheckedByStudent > message.sendingTimeUnix ? 'blue fas fa-check' : 'fas fa-check'} style={{"margin-left":"-8px"}}></i>
                              </>
                              : <></>
                            }
                          </div>
                        </div>
                      ))}
                    </div>
            }
            <div className="newMessage">
              <ContentEditable
                html={newMessage}
                placeholder={t("Je bericht")}
                disabled={false}
                onChange={e => updateNewMessage(e.target.value)}
                className="input_no_bg"
              />
            </div>
            <span className="btn btn-primary" onClick={e => sendNewMessage()}>
                {t("Verzend bericht")}
            </span>
          </div>
        </div>
      )
}

export default StudentDetailsChat;
