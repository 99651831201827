import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import t from "../../translate";
import { getClone } from "../../utils";
import ConfirmBox from "../../alert/confirm";

const DeleteCoach = (props) => {

  const [confirmOptions, setConfirmOptions] = useState({});
  const [toDeleteIndex, setToDeleteIndex] = useState(-1); /// doet niks maar is nodig in ingeladen custom confirm

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  function deleteConfirm() {
    let confirmOptionsToSet = {
      show: "true",
      text: "<h4>"+t("Weet u zeker dat u deze coach (#"+props.coach.id+") wilt verwijderen?")+"</h4>",
      cancelText: t("Annuleer"),
      confirmText: t("Verwijder"),
      confirmAction: () => doDeleteCoach()
    };
    //setToDeleteIndex(-1);
    setConfirmOptions(confirmOptionsToSet);
  }

  function doDeleteCoach(){
    props.coach.removeUser = true;
    if (props.coach.removeUser && props.coach.rights.interventions.length > 1) {
      let intervention_index = 0;
      //zo ja, dan alleen coach loskoppelen van deze interventie
      props.coach.rights.interventions.map((interv, index) => {
        if (intervention.id == interv.id) {
          intervention_index = index;
        }
      });
      props.coach.rights.interventions.splice(intervention_index, 1);
      props.coach.removeUser = false;
    }

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: props.coach
      }
    }).then(resp => {
      const stateN = getClone(props.state);
      for(let i = 0 ; i < stateN.users.length ; i++){
        if (stateN.users[i].id === props.coach.id){
          stateN.users.splice(i, 1);
          break;
        }
      }
      props.setState(stateN)
    });
  }

  return (
    <>
      {
        props.coach.hasStudents ?
          <p>{t("Coach kan niet worden verwijderd: er zijn nog studenten aan deze coach gekoppeld.")}</p>
          :
          <>
          <span className="btn btn-primary red" onClick={()=>deleteConfirm()}>{t("Verwijder gebruiker")} <i className="fa fa-times"></i></span>
          <ConfirmBox confirmOptions={confirmOptions} setConfirmOptions={setConfirmOptions} setToDeleteIndex={setToDeleteIndex}/>
          </>
      }
    
    </>
  )
}
export default DeleteCoach;
