const text_26 = {
  intervention: 26,
  description_short: "Overwin je slapeloosheid en leer je biologische klok te herstellen",
  step_2_title: "Dus je wilt eindelijk weer beter slapen? Goed dat je er bent!",
  description_part1: "i-Sleep & BioClock is een zelfhulpprogramma waarin je leert je slaap te verbeteren. Het programma is gebaseerd op Cognitieve Gedragstherapie en is ontwikkeld door experts op het gebied van slaap. De onderwerpen die hier aan bod komen zijn de biologische klok, het veranderen van gedrag dat slaap belemmert (slaaphygiëne), veranderen van het slaappatroon, ontspanning, en omgaan met piekeren en negatieve gedachten over slaap.",
  description_part2: "Het programma bestaat uit 5 modules die elk tussen 30 en 60 minuten duren. We raden aan om 1 module per week te doen gedurende 5 weken. Na iedere sessie volgen een paar opdrachten en ga je tijdens het programma een slaapdagboek bijhouden. Tijdens het programma krijg je ondersteuning van een online coach (geheel gratis) die tekstuele feedback geeft op de interactieve opdrachten en de gehele voortgang.",
  description_part3: "Je deelname aan het onderzoek duurt in totaal ongeveer 8 weken. Je gaat aan het begin en aan het eind van deze periode een vragenlijst invullen. Nadat je je hebt aangemeld ga je een week lang je slaap in een slaapdagboek bijhouden. Dit slaapdagboek is ook onderdeel van het programma zelf. Na 7 weken vragen we je opnieuw om het slaapdagboek bij te houden. Dit geeft je inzicht in waar je nu staat en hoe je slaap is verbeterd. Ook zijn de vragenlijsten en het dagboek zijn erg belangrijk voor ons als onderzoekers, want zo komen wij erachter of het programma effectief is bij studenten.",
  description_part4: "Als je dit programma wilt volgen, lees de onderstaande informatie dan zorgvuldig door:",
}

export {text_26};
