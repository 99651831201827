import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveIntervention } from "../../../actions/";
//import logo from "../../../images/caring_universities.png";
import logo from "../../../images/logo_moodlift.png";
import LinkBox from "../../profile/linkBox.js";

const LeftBottom = (props) => {

  const history = useHistory();
  const activePart = useSelector(state => state.activePart);
  const intervention = useSelector(state => state.intervention);
  const [logoToUse, setLogoToUse] = useState('');
  const auth = useSelector(state => state.auth);


  const resetActiveIntervention = () => {
    /// wat is dit?
    //dispatch(setActiveIntervention(0));
  }

  useEffect(() => {
    if(intervention.id > 0){
      if(typeof intervention.settings.logo != 'undefined' && intervention.settings.logo != '')
      {
        setLogoToUse(intervention.settings.logo)
      } else {
        setLogoToUse(logo)
      }
    }
  }, [intervention]);

  return(
    <div className="leftBottom">
      {!(activePart == 'lesson' || activePart == 'optional-lesson') ?
        <div>
          <span onClick={()=>resetActiveIntervention()}>
            <img src={logoToUse} className="logo"/>
          </span>
          { auth.name.length > 0 ?
            <LinkBox highlightName={activePart == 'settings'?'btn btn-primary':''}/>
            :
            ''
          }
        </div>
      :''}
    </div>
  )
}

export default LeftBottom;
