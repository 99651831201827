import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import LoadScreen from "../../loadScreen"
import apiCall from "../../api"
import t from "../../translate"

const PlatformQuestionnaires = props => {

   const history = useHistory()

   const [questionnaires, setQuestionnaires] = useState([])
   const [newQuestionnaireTitle, setNewQuestionnaireTitle] = useState("")
   const [editable, setEditable] = useState(false)

   const auth = useSelector(state => state.auth)

   useEffect(() => {
      //voldoende rechten?
      if (typeof auth.rights.platform_access === 'undefined' || !auth.rights.platform_access) {
         history.push('/')
      }
      apiCall({
         action: 'get_platform_questionnaires',
         token: auth.token,
         data: {}
      }).then(resp => {
         setQuestionnaires(resp.questionnaires)
         setEditable(true)
      })
   }, [])

   function updateTitle(key, questionnaire_id, title) {

      let newQuestionnaires = [...questionnaires]
      newQuestionnaires[key].settings.title = title
      newQuestionnaires[key].settings.newPart = false
      newQuestionnaires[key].settings.removePart = false

      setQuestionnaires(newQuestionnaires)

      apiCall({
         action: 'save_platform_questionnaire',
         token: auth.token,
         data: {
            id: questionnaire_id,
            settings: newQuestionnaires[key].settings,
         }
      })
   }

   function addQuestionnaire(e) {
      if (e.key === "Enter") {
         apiCall({
            action: 'save_platform_questionnaire',
            token: auth.token,
            data: {
               id: 0,
               title: newQuestionnaireTitle,
            }
         }).then(resp => {
            setQuestionnaires(resp.questionnaires)
            setNewQuestionnaireTitle("")
         })
      }
    }

   function copyQuestionnaire(questionnaire_id) {
      apiCall({
         action: 'copy_platform_questionnaire',
         token: auth.token,
         data: {
            questionnaire_id
         }
      }).then(resp => {
         setQuestionnaires(resp.questionnaires)
      })
   }

   function deleteQuestionnaireConfirm(questionnaire_id) {
      if (window.confirm(t("Weet je zeker dat je deze vragenlijst wilt verwijderen?"))) {
         apiCall({
            action: 'delete_platform_questionnaire',
            token: auth.token,
            data: {
               questionnaire_id
            }
         }).then(resp => {
            setQuestionnaires(resp.questionnaires)
         })
      }
   }

   return (
   <div className="intervention_edit coachInterface platform">
       <div className="settings_container">
          <div id="settings-questionnaires">
             {/*<h1>{t("Platform vragenlijsten")}</h1>*/}
             {
                editable ?
                <>
                   <div className="items questionnaires">
                   {
                      questionnaires.map((questionnaire, key) => {
                         return (
                            <div key={key} className="item">
                               <div className="form-group item">
                               <table>
                                  <tbody>
                                     <tr>
                                       <td className="title">
                                         <input
                                           type="text"
                                           className="form-control"
                                           id={"questionnaire_" + questionnaire.id}
                                           name={"questionnaire_" + questionnaire.id}
                                           placeholder={t("Titel")}
                                           aria-describedby={"questionnaire_" + questionnaire.id}
                                           value={questionnaire.settings.title}
                                           onChange={e => updateTitle(key, questionnaire.id, e.target.value)}
                                         />
                                       </td>
                                       <td>
                                          <div className="lesson-controls">
                                              <span className="btn edit disabled" data-tip={t("Wijzig vragenlijst")} onClick={() => { history.push('/platformquestionnaires/edit/' + questionnaire.id)}}>
                                                <i className="fas fa-pen"></i>
                                              </span>

                                              <span className="otherActionsToggle btn">
                                                <i className="fas fa-ellipsis-h"></i>
                                                <div className="otherActions">
                                                  <table onClick={() => { copyQuestionnaire(questionnaire.id) }}>
                                                    <tbody>
                                                      <tr>
                                                        <td>{t("Kopieer")}</td>
                                                        <td>
                                                          <i className="fa fa-copy"></i>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <table onClick={() => { deleteQuestionnaireConfirm(questionnaire.id) }}>
                                                    <tbody>
                                                      <tr>
                                                        <td>{t("Verwijder")}</td>
                                                        <td>
                                                          <i className="fas fa-minus"></i>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </span>
                                          </div>
                                       </td>
                                     </tr>
                                  </tbody>
                               </table>
                              </div>
                            </div>
                         )
                      })
                   }
                   </div>
                   <div className="form-group">
                     <input
                       type="text"
                       className="form-control"
                       id="new"
                       placeholder={t("Voeg nieuwe vragenlijst toe")}
                       value={newQuestionnaireTitle}
                       onChange={e => setNewQuestionnaireTitle(e.target.value)}
                       onKeyPress={addQuestionnaire}
                     />
                   </div>
                </>
                :
                   <LoadScreen />
             }

          </div>
       </div>
   </div>
   )
}

export default PlatformQuestionnaires
