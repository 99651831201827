import React, {useState, useEffect} from "react";

let saveSettingsTimeout = null;

const PointsInput = props => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    if(typeof props.value != "undefined"){
      setValue(props.value);
    }
  }, [props.value]);

  function updatePoints(type, value){

    setValue(value)

    clearTimeout(saveSettingsTimeout);

    //dispatch(setSavingStatus("not_saved"));

    saveSettingsTimeout = setTimeout(() => {
      props.updatePoints(type, value);
    }, 200);
    //props.changeTimes('set', item.id, i, e.target.value)
  }

  return(
    <>
      <input type="number" min="0" value={value != "undefined" ? value:''} placeholder={props.placeholder} onChange={(e)=>updatePoints(props.type, e.target.value)}/>
    </>
  )
}

export default PointsInput
