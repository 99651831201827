import React, {useState, useEffect}  from 'react';
import parse from 'html-react-parser';
import { getClone } from "../../../../utils";
import slideArrowDownSVG from "../../../../../images/course/standard/svg/down.svg";
import slideArrowUpSVG from "../../../../../images/course/standard/svg/up.svg";
import stripHtmlTags from 'duct-strip-html-tags'

const Accordion = (props) => {

  const [state, setState] = useState({
    items:[],
    active_item:0
  });

  const placeholder_title = "";
  const placeholder_text = "";

  //////////////////////
  ///Get content
  //////////////////////
  useEffect(() => {

    if(props.items != "")
    {
      let newState = getClone(state)
      newState.items = props.items
      for(let i = 0 ; i < newState.items.length ; i++){
        newState.items[i].visible = false;
      }
      setState(newState);
    }
  }, [props.items]);


  //////////////////////
  ///Toggle items
  //////////////////////
  function toggleVisibility(index)
  {
    let clonedState = getClone(state);
    if(clonedState.items[index].visible == true)
    {
      clonedState.items[index].visible = false;
      clonedState.active_item = 0
    } else {
      for(let i = 0 ; i < clonedState.items.length ; i ++)
      {
        clonedState.items[i].visible = false;
      }
      clonedState.items[index].visible = clonedState.items[index].visible == true ? false:true;
      clonedState.active_item = index
    }

    setState(clonedState)
  }

  //////////////////////
  ///Content
  //////////////////////
  return (
    <div className="special accordion">
      <div className="items">
        { state.items.map((item, index) =>
          <div key={index} className="item">
            <div className={"title pointer" + (item.visible == true ? ' opened':'')} onClick={() => toggleVisibility(index)}>
               <span dangerouslySetInnerHTML={{__html: stripHtmlTags(item.content)}} />
               {
                item.visible !== true ?
                    <img src={slideArrowDownSVG} alt="open" /> : <img src={slideArrowUpSVG} alt="close" />
               }
            </div>
            <div className={(item.visible == true ? 'slideDown':'slideUp')}>
                {parse(item.content2)}
                {typeof item.image != "undefined" && item.image != '' ?
                  <div className="ImageHolderRelative">
                    <img src={item.image}/>
                  </div>
                :''}
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default Accordion;
