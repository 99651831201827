import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";

const ProgressBarDesktop = (props) => {
   
   const intervention = useSelector(state => state.intervention);
   const activeLesson = useSelector(state => state.activeLesson);
   const activeSubLesson = useSelector(state => state.activeSubLesson);

   const [progress, setProgress] = useState(0)

      useEffect(() => {
    
      let allLessons = [];
  
      if(typeof intervention.settings !== "undefined")
      {
        let this_lesson_obj = intervention.settings.selfhelp.lessons.filter(function (lesson) {
          return lesson.id === activeLesson && lesson.sub_id === activeSubLesson
        });
  
        if (this_lesson_obj.length > 0) {
          allLessons = intervention.settings.selfhelp.lessons
        } else {
          //optionele les?
          this_lesson_obj = intervention.settings.selfhelp.optionalLessons.filter(function (lesson) {
            return lesson.id === activeLesson && lesson.sub_id === activeSubLesson
          });
          if (this_lesson_obj.length > 0) {
            allLessons = intervention.settings.selfhelp.optionalLessons
          }
        }
        if (this_lesson_obj.length > 0) {
          let this_lesson_index = allLessons.indexOf(this_lesson_obj[0]);
          let indexFirst = false;
          let indexLast = false;
  
          //// get eerste les
          for(let i = this_lesson_index ; i < (allLessons.length - 1) ; i--){
            if(allLessons[i].parent_id == 0 && allLessons[i].sub_id == 0) {
              indexFirst = i;
              break;
            }
          }
          //// get laatste les
          for(let i = this_lesson_index ; i < (allLessons.length - 1) ; i++) {
            if((allLessons[i].parent_id == 0 && allLessons[i].sub_id == 0) && i != this_lesson_index) {
              indexLast = (i - 1);
              break;
            }
          }
          if (indexLast === false) {
            indexLast = allLessons.length - 1;
          }
        if(this_lesson_index != indexFirst) {
          setProgress((100 / (indexLast - indexFirst + 1)) * (this_lesson_index - indexFirst))
        } else {
          setProgress(0)
        }
  
      }
  
      }
    }, [intervention, activeLesson, activeSubLesson]);

   return (
      <div className="progress-bar-desktop onlyondesktop">
         <div className="progress" style={{width:progress+'%'}}></div>
      </div>
   )
}

export default ProgressBarDesktop