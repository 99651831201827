import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import LeftMenu from "../leftmenu";
import apiCall from "../api";
import t from "../translate";

const Emails = () => {

  const [students, setStudents] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [emails, setEmails] = useState([]);
       
  const auth = useSelector(state => state.auth);

  useEffect(() => {

    //alle interventies ophalen
    apiCall({
      action: "get_interventions",
      token: auth.token,
      data: {}
    }).then(resp => {
      setInterventions(resp.interventions);
    });

    //alle studenten ophalen
    apiCall({
      action: "get_all_students",
      token: auth.token,
      data: {}
    }).then(resp => {
      setStudents(resp.students);
    });
    
  }, [])

  const getEmails = (e) => {
    let user_id =  e.target.value;

    apiCall({
      action: "get_student_emails",
      token: auth.token,
      data: {
        user_id
      }
    }).then(resp => {
      setEmails(resp.emails);
    });
  }

  const openClose = (email_id) => {
    if (document.getElementById("body_"+email_id).classList.contains("hidden"))
    {
      document.getElementById("body_"+email_id).classList.remove("hidden");
      document.getElementById("body_"+email_id).removeAttribute('href');
    }
    else
    {
      document.getElementById("body_"+email_id).classList.add("hidden");
    }
  }

  return(
    <div className="coachInterface coaches admin">
      <nav className="navbar navbar-expand-lg navbar-light">
        <h2 className="noPadding">
          {t("Emails")}
        </h2>
      </nav>
      <LeftMenu />
      <div className="list listHidden">

        <select className="form-control" onChange={getEmails}>
          <option value="0">{t("Selecteer een student waarvan je de mails in wilt zien.")}</option>
          {
            students.map(student => {

              let intervention = interventions.find(i => {
                return parseInt(i.id) === parseInt(student.intervention_id);
              })

              return (
                <option key={student.id} value={student.id}>#{student.id} {intervention !== undefined ? intervention.title : ''}</option>
              )
            })
          }
        </select>

        <div>
          <table className="table table-striped">
            <tbody>
              {
                emails.map(email => {
                  return (
                    <tr key={email.id}>
                      <td>
                        <div className="emailHeader pointer" onClick={() => openClose(email.id)}>
                          {moment.unix(email.date_time_sent).format("DD-MM-YYYY HH:mm:ss", { trim: false })}
                          {'  '  + email.subject}
                        </div>
                        <div className="emailHeader hidden" id={'body_'+email.id} dangerouslySetInnerHTML={{ __html: '<br /><br />' + email.body +  '<br /><br />'}} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
       
      </div>
    </div>
  )

}

export default Emails;