import React, {useRef, useEffect} from 'react';
import t from "../translate";

const Modal = (props) => {
    const childRef = useRef();
    const ContentComponent = props.component;

    return (
        <div className="modal fade" id={props.name} role="dialog" aria-labelledby={props.label} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {
                        props.title.length > 0 ?
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                                {
                                    typeof props.closable === "undefined" || props.closable ?
                                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        : <></>
                                }
                            </div>
                        : <></> 
                    }
                    <div className="modal-body">
                        <ContentComponent {...props.componentData} ref={childRef} modalState={props.modalState} setModalState={props.setModalState} />
                    </div>
                    <div className="modal-footer">
                        {
                        typeof props.cancellable === "undefined" || props.cancellable ?
                            <button type="button" className="btn btn-secondary" onClick={() => props.componentData.closeModal('')}>{typeof props.cancelBtnValue !== "undefined" ? props.cancelBtnValue : t("Annuleer")}</button>
                            : <></>
                        }
                        {
                            typeof props.submitable === "undefined" || props.submitable ?
                                <button type="button" className="btn btn-primary" onClick={() => childRef.current.submitHandler()}>{props.btnValue}</button>
                                : <></>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Modal;
