import React, { useState } from 'react';
import { useSelector } from "react-redux";
import Logo1 from "../../../images/logos/leiden.png";
import Logo2 from "../../../images/logos/maastricht.png";
import Logo3 from "../../../images/logos/uu.png";
import Logo4 from "../../../images/logos/vu.png";
import Logo5 from "../../../images/logos/erasmus.png";
import Logo6 from "../../../images/logos/inholland.png";
import Logo7 from "../../../images/logos/uva.png";
import Logo8 from "../../../images/logos/avans.png";
import Logo9 from "../../../images/logos/hogeschoolrotterdam_en.png";

const Universities = ({ step, registrationTexts, languageCode }) => {

  const url = useSelector(state => state.url);

  return (
    <div className="universities">
      <span>{registrationTexts.panel1.unisTitle[languageCode]}</span>
      {
      step === 2 ?
        <p dangerouslySetInnerHTML={{__html: registrationTexts.panel1.unisContent[languageCode]}} />
        : <></>
      }
      <div className="logos">
        <div>
          <img src={Logo1} />
        </div>
        <div>
          <img src={Logo2} />
        </div>
        <div>
          <img src={Logo3} />
        </div>
        <div>
          <img src={Logo4} />
        </div>
        <div>
          <img src={Logo5} />
        </div>
        <div>
          <img src={Logo6} />
        </div>
        <div>
          <img src={Logo7} />
        </div>
        <div>
          <img src={Logo8} />
        </div>
        <div>
          <img src={Logo9} />
        </div>
      </div>
    </div>
  )
}

export default Universities
