import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setActivePart, setActiveLesson, setActiveSubLesson } from "../../../actions";
import openSVG from '../../../images/course/standard/svg/open.svg';
import activeSVG from '../../../images/course/standard/svg/active.svg';
import doneSVG from '../../../images/course/standard/svg/done.svg';
import lockedSVG from '../../../images/course/standard/svg/locked.svg';

const OptionalLessons = (props) => {

  const [optionalLessonList, setOptionalLessonList] = useState([])
  const dispatch = useDispatch();
  const history = useHistory();

  const intervention = useSelector(state => state.intervention);
  const answersLessons = useSelector(state => state.answersLessons.answers);

  const changeActiveLesson = (lesson_id, status) => {
    if(status != "closed"){
      dispatch(setActivePart("optional-lesson"));
      dispatch(setActiveLesson(lesson_id));
      history.push("/course/" + intervention.id + "/optional-lesson/" + lesson_id);
    }
  }

  //status bepalen
  useEffect(() => {
    let newlessonList = [];

    for (const lesson of intervention.settings.selfhelp.optionalLessons) {
      if (parseInt(lesson.parent_id) === 0 && parseInt(lesson.sub_id) === 0) //les op hoofdniveau
      {

        if(typeof lesson.settings.releaseAfterFinished == "undefined" || lesson.settings.releaseAfterFinished == ""){
          lesson.status = 'open'
        } else {
          if (checkStatusParentLesson(lesson.settings.releaseAfterFinished) !== 'finished') {
            lesson.status = 'closed'
          } else {
            lesson.status = 'open'
          }
        }
        newlessonList.push(lesson)
      }
    }
    setOptionalLessonList(newlessonList)
  }, [props, intervention, answersLessons]);

  function checkStatusParentLesson(id){
    let lesson_obj = props.lessonList.filter(function (lesson) {
      return lesson.id === id
    });
    if(lesson_obj.length > 0){
      return lesson_obj[0].status
    }
  }

  //// en wat als een les geen vragen heeft?
  function checkIfOptionalLessonIsFinished(the_id){
    let answer_obj = answersLessons.filter(function (answersLesson) {
      return answersLesson.the_id === the_id
    });
    if(answer_obj.length > 0){
      return answer_obj[0].finished
    }
  }

  return(
    <div>
      {optionalLessonList.length > 0 ?
        <div className='optional-lessons'>
          <div className="intro">
            {
              parseInt(intervention.id) !== 32 ? <h2>Extra modules</h2> : ''
            }            
            {typeof intervention.settings.optionalLessonsIntro != 'undefined' &&  intervention.settings.optionalLessonsIntro != '' ? intervention.settings.optionalLessonsIntro:''}
          </div>
          <div className={'items clearfix' + (props.allLessonsFinished != 'true' ? ' closed':'')}>
            {
              optionalLessonList.map((lesson, index) => {

                if (lesson.title.length > 8 && lesson.title.substr(0, 9).toLowerCase() === '[heading]') {

                  return  (
                  <div key={index} className="item" style={{fontWeight:"bold", marginTop: "20px"}}>{lesson.title.substr(9)}</div>
                  )
                } else {
                  return (
                  <div key={index} className={'item ' + (lesson.status != 'active' ? lesson.status:'')} onClick={()=>changeActiveLesson(lesson.id, lesson.status)} >
                      {
                        lesson.status == "finished" || lesson.status == "open" ?
                            <>
                                {
                                    !checkIfOptionalLessonIsFinished(lesson.id) ?
                                        <img src={openSVG} />
                                        :
                                        <img src={doneSVG} />
                                }
                            </>
                            :
                            <img src={lockedSVG} />
                      }
                      <span className="lessonTitle">{lesson.title}</span>
                  </div>)
                }
              })
            }
          </div>
        </div>
        : <></>}
    </div>
  )
}

export default OptionalLessons;
