import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import t from "../translate";
import { validateEmail, checkNestedProperties } from "../utils";
import apiCall from "../api";
import { login, setActiveLanguage, setIntervention, setUiTranslation } from "../../actions";
import standardAvatar from "../../images/course/standard/avatar.png";
import editSVG from "../../images/course/standard/svg/edit.svg";
import photoSVG from "../../images/course/standard/svg/photo.svg";
import pencilSmallSVG from "../../images/course/standard/svg/pencil_small.svg";
import Logout from "../logout";

let saveTimeout = false;

const Profile = (props) =>{

  const dispatch = useDispatch();

  const [editName, setEditName] = useState(false)
  const [activePart, setActivePart] = useState('')
  const [localAuth, setLocalAuth] = useState({
    user_id: 0,
    token: '',
    userType: 'student',
    rights: {},
    firstname: '',
    insertion: '',
    lastname: '',
    name: '',
    email: '',
    gender: '',
    education: '',
  })
  const [localPreferences, setLocalPreferences] = useState([])
  const [profileImage, setProfileImage] = useState(standardAvatar)

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([0]);

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const activeLanguage = useSelector(state => state.activeLanguage);
  const url = useSelector(state => state.url);
  
  const [emailValid, setEmailValid] = useState(true);  

  //tbv SSI
  const [ssiIntervention, setSsiIntervention] = useState(false);
  const [ssiLessonList, setSsiLessonList] = useState([]);
  const [ssiActiveLessons, setSsiActiveLessons] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (auth != "") {
      setLocalAuth(auth);
      setLocalPreferences(auth.preferences);
      if(auth.profile_pic){
        //setProfileImage(url+"/uploads/user/"+ auth.user_id + "/" + auth.profile_pic + "?"+new Date().getTime())
        //setProfileImage(url + "/profile/picture.php?t=" + auth.token + "&" + new Date().getTime())
        setProfileImage(url + "/api/?t=" + auth.token + "&a=get_profile_pic&" + new Date().getTime())
      }
    }
  }, []);

  useEffect(() => {

    if (intervention.id > 0)
    {

      let language_id = intervention.settings.language_id;
      if (!Number.isInteger(language_id))
      {
        language_id = 1; //dutch
      }
      let newOptions = [];
      intervention.settings.languages.forEach(language => {
        let translationFound = intervention.settings.translations.find(transl => {
          return language.code === transl.code
        })
        if (parseInt(language_id) === parseInt(language.id) || translationFound !== 'undefined')
        {
          newOptions.push(language);
        }
      })
      setOptions(newOptions);

      //tbv SSI
      if (typeof intervention.settings.selfhelp.alternative_menu_seq !== "undefined" && intervention.settings.selfhelp.alternative_menu_seq === "ssi") {
        setSsiIntervention(true);
        let newSsiLessonList = [];
        intervention.settings.selfhelp.lessons.forEach(lesson => {
          if (parseInt(lesson.parent_id) === 0) {
            newSsiLessonList.push(lesson);
          }
        })
        setSsiLessonList(newSsiLessonList);        

        auth.preferences.forEach(pref => {
          if (pref.option === "ssi") {
            let newSsiActiveLessons = [];
            pref.value.forEach(value => {
              let parts = value.split("_");
              if (parseInt(parts[1]) === parseInt(intervention.id)) {
                newSsiActiveLessons.push(parts[2]);
              }
            })
            setSsiActiveLessons(newSsiActiveLessons);
          }
        })
      }
    }

  }, [intervention])

  useEffect(() => {

    if (activeLanguage !== 0)
    {
      setSelectedOption(parseInt(activeLanguage));
    }

  }, [activeLanguage])

  const changeLanguage = (language_id) => {
    setSelectedOption(parseInt(language_id));
    loadLanguage(parseInt(language_id));
  }

  const loadLanguage = (language_id) => {
    //interventie settings ophalen en in redux store plaatsen
    apiCall({
      action: "get_intervention_settings",
      token: auth.token,
      data: {
        intervention_id: intervention.id,
        include_forms: true, //tijdelijk...
        language_id: language_id
      }
    }).then(resp => {
      dispatch(
        setIntervention(
          intervention.id,
          resp.organisation_id,
          resp.title,
          resp.settings
        )
      );
      dispatch(setActiveLanguage(parseInt(language_id)));
      //UI wijzigen?
      if (parseInt(language_id) === 1)
      {
        dispatch(setUiTranslation(1, []));
      }
      else
      {
        dispatch(setUiTranslation(language_id, resp.settings.ui_translation));
      }
      //leg voorkeur vast in preferences:
      let languagePref = auth.preferences.find(pref => {
        return pref.option === 'language_id'
      })
      if (typeof languagePref === 'undefined' || parseInt(languagePref.value) !== parseInt(language_id))
      {
        let newAuth = {...auth};
        let foundLanguagePreference = false;
        newAuth.preferences.forEach((pref, index) => {
          if (pref.option === 'language_id')
          {
            foundLanguagePreference = true;
            newAuth.preferences[index].value = language_id;
          }
        })
        if (!foundLanguagePreference)
        {
          newAuth.preferences.push({
            option: 'language_id',
            value: language_id
          })
        }
        //tbv van test- en begeleide accounts waarvoor tot 31-05-2023 geen gender werd opgegeven.
        if (newAuth.gender === '') {
          newAuth.gender = 'F';
        }
        saveProfile(newAuth);
       }
    });
  }

  function updateSetting(newAuthToSet) {

    setErrorMessage("")

    let newAuth = {...localAuth};
    for (var key in newAuthToSet) {
      if (key == 'private_email' && !validateEmail(newAuthToSet[key])) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
      newAuth[key] = newAuthToSet[key];
    }
    //tbv van test- en begeleide accounts waarvoor tot 31-05-2023 geen gender werd opgegeven.
    if (newAuth.gender === '') {
      newAuth.gender = 'F';
    }
    setLocalAuth(newAuth);
    saveProfile(newAuth)

    /// dispatch om naams wijziging door te voeren
  }

  function keyDown(e){
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  //////////////////////
  ///Upload image to server
  function uploadImage(uploadedFiles) {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_user_image",
        token: auth.token,
        data: {
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error === 0){
            //setProfileImage(url+"/uploads/user/"+ auth.user_id + "/"+ files[0].name + "?"+new Date().getTime());
            //setProfileImage(url + "/profile/picture.php?t=" + auth.token + "&" + new Date().getTime())
            setProfileImage(url + "/api/?t=" + auth.token + "&a=get_profile_pic&" + new Date().getTime())
            updateSetting({profile_pic: true})
          }
        }
      });
    });
  }

  //////////////////////
  ///Delete image from server
  function deleteImage(e, name) {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_user_image",
      token: auth.token,
      data:{
        name:name
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error == 0) {
          setProfileImage(standardAvatar);
          updateSetting({profile_pic: false})
        }
      }
    });
  }

  ////////////////////////////////////////////
  /////////Image action
  ///////////////////////////////////////////
  let inputFileRef = React.createRef();
  function triggerInputFile() {
    inputFileRef.current.click();
  }

  function saveProfile(newAuth) {

    if (emailValid) {
      clearTimeout(saveTimeout);
      saveTimeout = setTimeout(() => {
        newAuth.name = newAuth.firstname + ' ' + newAuth.insertion + ' ' + newAuth.lastname
        apiCall({
          action: "save_user_profile",
          token: auth.token,
          data: {
            firstname: newAuth.firstname,
            insertion: newAuth.insertion,
            lastname: newAuth.lastname,
            private_email: newAuth.private_email,
            gender: newAuth.gender,
            preferences: newAuth.preferences,
          }
        }).then(resp => {
          if (resp.error == 0) {
            if (resp.save_user_profile === 1) {
              dispatch(
                login(
                  localAuth.user_id,
                  localAuth.dtc,
                  newAuth.name,
                  localAuth.token,
                  localAuth.userType,
                  localAuth.rights,
                  newAuth.preferences,
                  newAuth.t0_scores,
                  newAuth.t1_scores,
                  newAuth.rewards,
                  newAuth.profile_pic, /// deze wordt niet opgeslagen wel het plaatje
                  newAuth.email,
                  newAuth.private_email,
                  newAuth.gender,
                  newAuth.education,
                  newAuth.firstname,
                  newAuth.insertion,
                  newAuth.lastname,
                  localAuth.organisation_id,
                  localAuth.oldRegistrationFlow,
                  localAuth.specialAccountType,
                  localAuth.doT0,
                  localAuth.onWaitingListRct
                )
              )
            } else {
              setErrorMessage(resp.msg)
            } 
          }
        });
      }, 3000)
    }
  }

  function changePreference(preference){
    let newPreferences = [...localPreferences]
    let this_pref_obj = newPreferences.filter(function (pref) {
      return pref.option === preference
    });

    if(this_pref_obj.length != 0)
    {
      let this_pref_obj_index = newPreferences.indexOf(this_pref_obj[0]);
      let newValue = newPreferences[this_pref_obj_index].value == 'false' ? 'true':'false';
      newPreferences[this_pref_obj_index].value = newValue
    } else {
      newPreferences.push({option:preference, value:'true'})
    }
    setLocalPreferences(newPreferences)
    updateSetting({preferences: newPreferences})
  }

  function checkIfTrue(preference){
    let this_pref_obj = localPreferences.filter(function (pref) {
      return pref.option === preference
    });

    if(this_pref_obj.length != 0)
    {
      let this_pref_obj_index = localPreferences.indexOf(this_pref_obj[0]);

      return localPreferences[this_pref_obj_index].value == 'false' ? false:true
    } else {
      return false;
    }
  }

  function toggleEditName(){
    let newValue = editName == '' ? 'edit':''
    setEditName(newValue)
  }

  const setSSI = (ssiLesson, lesson_id) => {
    //ssiActiveLessons array updaten
    if (ssiActiveLessons.includes(lesson_id)) {
      let newActiveLessons = [...ssiActiveLessons];
      let index = newActiveLessons.indexOf(lesson_id);
      newActiveLessons.splice(index, 1);
      setSsiActiveLessons(newActiveLessons);
    } else {
      let newActiveLessons = [...ssiActiveLessons];
      newActiveLessons.push(lesson_id);
      setSsiActiveLessons(newActiveLessons);
    }
    //preferences updaten
    let newAuth = {...auth};
    let foundSsiPreference = false;
    let foundSsiLesson = false;
    newAuth.preferences.forEach((pref, index) => {
      if (pref.option === 'ssi') {
        foundSsiPreference = true;
        pref.value.forEach((ssi, ssiIndex) => {
          if (ssi === ssiLesson) {
            foundSsiLesson = true;
            newAuth.preferences[index].value.splice(ssiIndex, 1);
          }
        })
        if (!foundSsiLesson) {
          newAuth.preferences[index].value.push(ssiLesson);
        }
      }
    })
    if (!foundSsiPreference) {
      newAuth.preferences.push({
        option: 'ssi',
        value: [ssiLesson]
      })
    }
    saveProfile(newAuth);
  }

  return(
    <div className="profile">
        <strong>{t("Mijn profiel")}</strong>
        {
          errorMessage.length > 0 ?
            <div className="alert alert-danger" role="alert">
              <i className="fas fa-exclamation-circle"></i> &nbsp;
              <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
            </div> : <></>
        }
        <div className="personalia">
            <div className="profile-top">
                <div className='image_name'>
                        <div className='image_holder' style={{ backgroundImage: "url(" + profileImage + ")" }}>
                          <input
                            type="file"
                            onChange={e => uploadImage(e.target.files)}
                            name="file"
                            ref={inputFileRef}
                            multiple={true}
                          />
                          <div className="options">
                            <span className="btn handle" onClick={triggerInputFile}>
                              <img src={photoSVG} />
                            </span>
                            {profileImage != standardAvatar ?
                              <span className="btn delete"   onClick={e => deleteImage(e, profileImage)}>
                                <i className="fas fa-trash"></i>
                              </span>
                              :''}

                          </div>
                        </div>
                        <div className="name">
                          {localAuth.name}
                          {/* <span className="handle" onClick={() => toggleEditName()}>
                            <img src={editSVG} />
                          </span> */}
                        </div>
                        <Logout button='true'/>
                </div>
            </div>
            <div className="profile-bottom">
                <div className="nameGridContainer">
                    <div className="nameGridItem">
                        <label for="firstname" className="form-label">{t("Voornaam")}</label>
                        <div className="nameInput">
                            <input type="text" className="form-control" id="firstname" onChange={(e) => updateSetting({firstname: e.target.value})} value={localAuth.firstname} placeholder={t("Voornaam")} />
                            <img src={pencilSmallSVG} />
                        </div>
                    </div>
                    <div className="nameGridItem">
                        <label for="insertion" className="form-label">{t("Tussenvoegsel")}</label>
                        <div className="nameInput">
                            <input type="text" className="form-control" id="insertion" onChange={(e) => updateSetting({insertion: e.target.value})} value={localAuth.firstnaminsertione} placeholder={t("Tussenvoegsel")} />
                            <img src={pencilSmallSVG} />
                        </div>
                    </div>
                    <div className="nameGridItem">
                        <label for="lastname" className="form-label">{t("Achternaam")}</label>
                        <div className="nameInput">
                            <input type="text" className="form-control" id="lastname" onChange={(e) => updateSetting({lastname: e.target.value})} value={localAuth.lastname} placeholder={t("Achternaam")} />
                            <img src={pencilSmallSVG} />
                        </div>
                    </div>
                </div>

                <div className="emailGridContainer">
                    <div className="emailGridItem">
                        <label for="email" className="form-label">{t("Email")}</label>
                        <div className="emailInput">
                            <input type="text" className="form-control" id="email" value={localAuth.email} placeholder={t("E-mail")} disabled="true" />
                            <img src={pencilSmallSVG} />
                        </div>
                    </div>
                    <div className="emailGridItem">
                        <label for="private_email" className="form-label">{t("Privé e-mail")}</label>
                        <div className="emailInput">
                            <input type="text" className="form-control" id="private_email" onChange={(e) => updateSetting({private_email: e.target.value})} value={localAuth.private_email} placeholder={t("Privé e-mail")} />
                            <img src={pencilSmallSVG} />
                        </div>
                        <em style={{fontSize: '0.7em'}}> { emailValid ? '' : t("Geef een geldig e-mailadres op!") }</em>
                    </div>
                </div>
        
            <div className="genderContainer">
                <label for="gender" className="block">{t('Gender')}</label>
                <div className="genderContainerItem">
                    <input
                          type="radio"
                          className="form-control"
                          id="gender_f"
                          name="gender"
                          aria-describedby="gender"
                          value="F"
                          onChange={(e) => updateSetting({gender: 'F'})}
                          checked={localAuth.gender === 'F'}
                        /> <label htmlFor="gender_f">{t("Vrouw")}</label>
                </div>
                <div className="genderContainerItem">
                    <input
                      type="radio"
                      className="form-control"
                      id="gender_m"
                      name="gender"
                      aria-describedby="gender"
                      value="M"
                      onChange={(e) => updateSetting({gender: 'M'})}
                      checked={localAuth.gender === 'M'}
                      /> <label htmlFor="gender_m">{t("Man")}</label>
                </div>
                <div className="genderContainerItem">
                    <input
                      type="radio"
                      className="form-control"
                      id="gender_x"
                      name="gender"
                      aria-describedby="gender"
                      value="X"
                      onChange={(e) => updateSetting({gender: 'X'})}
                      checked={localAuth.gender === 'X'}
                      /> <label htmlFor="gender_x">{t("Anders")}</label>
                </div>
            </div>

            <div className="languageContainer">
                <label for="language" className="block">{t('Taal')}</label>
                <div className="languageContainerItem">
                    <input
                          type="radio"
                          className="form-control"
                          id="language_nl"
                          name="language"
                          aria-describedby="language"
                          value="1"
                          onChange={(e) => changeLanguage(1)}
                          checked={selectedOption === 1}
                        /> <label htmlFor="language_nl">{t("Nederlands")} 🇳🇱</label>
                </div>
                <div className="languageContainerItem">
                    <input
                          type="radio"
                          className="form-control"
                          id="language_en"
                          name="language"
                          aria-describedby="language"
                          value="1"
                          onChange={(e) => changeLanguage(2)}
                          checked={selectedOption === 2}
                        /> <label htmlFor="language_en">{t("Engels")} 🇬🇧</label>
                </div>
            </div>
      </div>
      
      {
        ssiIntervention && !auth.doT0 && !auth.onWaitingListRct && (!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) ? 
          <div className="ssi-profile">
            <br />
            <h4>{t("Jouw modules")}</h4>
            {
              ssiLessonList.map((lesson, index) => {
                if (lesson.title.length > 8 && lesson.title.substr(0, 9).toLowerCase() === '[heading]') {
                  return <strong key={index}>{lesson.title.substr(9)}</strong>
                } else {
                  return (
                    <div key={index} className="ssi-profile-lesson">
                      <input className="form-check-input" type="checkbox" name={'ssi_'+intervention.id+'_'+lesson.id} id={'ssi_'+intervention.id+'_'+lesson.id} onChange={e => setSSI(e.target.name, lesson.id)} checked={ssiActiveLessons.includes(lesson.id)} /> <label className="form-check-label ssi-profile-label" htmlFor={'ssi_'+intervention.id+'_'+lesson.id}> {lesson.title}</label><br />
                    </div>
                  )
                }
              })
            }
          </div>
        : <></>
      }
        </div>
    </div>
  )
}

export default Profile
