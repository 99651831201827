import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import t from "../../translate";
import apiCall from "../../api"
import Page from "./page.js";

const TranslatePlatformPages = (props) => {

  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  const [loaded, setLoaded] = useState(-1)
  const [pages, setPages] = useState([])
  const [activePage, setActivePage] = useState(0)

  useEffect(() => {
    //voldoende rechten?
    if (typeof auth.rights.platform_access === 'undefined' || !auth.rights.platform_access) {
      history.push('/')
    }
    apiCall({
       action: 'get_platform_pages',
       token: auth.token,
       data: {}
    }).then(resp => {
       setPages(resp.pages)
       setLoaded(1)
    })
  }, [])

  useEffect(() => {
    if(pages[0]){
        setActivePage(pages[0]);
    }
  }, [pages])

  const selectPage = (e) => {
    let page = pages.filter((page) => {
      return page.id == e.target.value;
    })
    if (typeof page !== 'undefined') {
      setActivePage(page[0]);
    }
  }

  return (
    <div>
      {loaded == -1 ? <i className="fas fa-spinner fa-spin" ></i>
      :
      <>
        {pages.length > 0 ?
          <>
          <select className="custom-select" onChange={(e) => selectPage(e)}>
          {
            pages.map((page, index) => {
              return (
                <option key={index} value={page.id}>
                  {
                    parseInt(page.parent_id) === 0 ? '' : ' - - - - - - '
                  }
                  {page.settings.title}
                </option>
              )
            })
          }
          </select>
          <br /><br />
          <Page activePage={activePage} activeTranslation={props.activeTranslation}  setActiveTranslation={props.setActiveTranslation} />
          </>
          :
          <div className="no_results">
            {t("Er zijn nog pagina's.")}
          </div>
        }
        </>
       }

    </div>
  )

}

export default TranslatePlatformPages;
