import React, { useState, useEffect } from "react";
import t from "../../translate";
//import CoachRegistrationData from "./registration_data.js";
import StudentDetailsLog from "./log.js";
import StudentInterventions from "./interventions.js";
import Emails from "./emails.js";
import Delete from "./delete.js";
import RegistrationData from "./registration_data.js";
import ProfileImage from "./image.js";
import ChangePassword from "./change_password.js";
import { useSelector, useDispatch } from "react-redux";
import standardAvatar from "../../../images/course/standard/avatar.png";
import apiCall from "../../api";
import ReactTooltip from 'react-tooltip';

const StudentDetails = (props) => {

  const [activeTab, setActiveTab] = useState(false);
  const [student, setStudent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [anonymous, setAnonymous] = useState(false);

  const auth = useSelector(state => state.auth);
  const url = useSelector(state => state.url);

  useEffect(() => {
    if (props.student) {
      props.student.password = '';
      props.student.password_check = '';
      setStudent(props.student)
      setProfileImage(props.student.profile_pic);
      let anon = props.student.preferences.find(pref => {
        if (pref.option === 'anonymous' && pref.value === '1') {
          return true;
        }
      })
      setAnonymous(anon)
    }
    if(!activeTab){
      setActiveTab(1)
    }
  }, []);

  const [profileImage, setProfileImage] = useState('');

  //////////////////////
  ///Upload image to server
  const uploadImage = (uploadedFiles) => {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_student_image",
        token: auth.token,
        data: {
          user_id: student.id,
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error === 0){
            setProfileImage(resp.image);
          }
        }
      });
    });
  }

    //////////////////////
  ///Delete image from server
  const deleteImage = (e, name) => {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_student_image",
      token: auth.token,
      data:{
        user_id: student.id
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error === 0) {
          setProfileImage('');
        }
      }
    });
  }

  let inputFileRef = React.createRef();
  const triggerInputFile = () => {
    inputFileRef.current.click();
  }

  return(
    <div className="content details">
      
      <i className="fa fa-times close" onClick={()=>props.hideDetails(props.student.id)} data-tip={t("Sluiten")}></i>
      {
        anonymous ?
        <em style={{color: 'red'}}>{t("Let op: deze deelnemer volgt het programma anoniem, de coach ziet alleen de gebruikersnaam van deze student.")}</em> : <></>
      }
      {
        errorMessage.length > 0 ?
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-circle"></i> &nbsp;
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </div> : <></>
      }
      <ReactTooltip place="top" effect="solid" delayShow={200}   />
      <div className="flex">
        <ProfileImage student={student}/>
        <div className="right">
        <div className="tabs">
          <div className={"tab" + (activeTab === 1 ? ' active':'')} onClick={()=>setActiveTab(1)}>
            {t("Interventie")}
          </div>
          <div className={"tab" + (activeTab === 2 ? ' active':'')} onClick={()=>setActiveTab(2)}>
            {t("Personalia")}
          </div>
          <div className={"tab" + (activeTab === 4 ? ' active':'')} onClick={()=>setActiveTab(4)}>
            {t("Log")}
          </div>
          <div className={"tab" + (activeTab === 3 ? ' active':'')} onClick={()=>setActiveTab(3)}>
            {t("Emails")}
          </div>
          <div className={"tab" + (activeTab === 5 ? ' active':'')} onClick={()=>setActiveTab(5)}>
            {t("Wachtwoord")}
          </div>
          <div className={"tab" + (activeTab === 6 ? ' active':'')} onClick={()=>setActiveTab(6)}>
            {t("Verwijderen")}
          </div>
        </div>
        <div className="tabContent">
          {activeTab === 1 ?
            <StudentInterventions student={student} interventions={props.interventions} coaches={props.coaches} students={props.students} setStudents={props.setStudents} setErrorMessage={setErrorMessage} />
            :false}
          {activeTab === 2 ?
            <RegistrationData student={student} setStudent={setStudent} setActiveTab={setActiveTab} students={props.students} setStudents={props.setStudents} />
            :false}
          {activeTab === 3 ?
            <Emails studentId={student.id}/>
            :false}
          {activeTab === 4 ?
            <StudentDetailsLog studentId={student.id} studentInterventionID={student.intervention_id} />
            :false}
          {activeTab === 5 ?
            <ChangePassword student={student}/>
            :false}
          {activeTab === 6 ?
            <Delete student={student} setStudent={setStudent} setStudents={props.setStudents} students={props.students}/>
            :false}
        </div>
        </div>
      </div>
      {
          /*
          <div className="align_right">
            <span className="btn btn-secondary" onClick={()=>props.hide(props.student.id)}>{t("Sluiten")}</span>
          </div>
          */
      }
    </div>
  )
}
export default StudentDetails;
