import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import QuestionnaireT0RCT from "../../questionnaires/T0RCT";
import apiCall from "../../api";
import t from '../../translate';
import { setQuestionnaire, setAnswersLessons, setActiveSubLesson, setActiveLesson } from "../../../actions";
import { Cookies, useCookies } from 'react-cookie';
import LoadScreen from '../../loadScreen';

const Step3a = ({ registrationTexts, changeLanguage, registrationData, setRegistrationData }) => {

  const dispatch = useDispatch();

  const allAnswers = useSelector(state => state.answersLessons);

  const [t0Id, setT0Id] = useState(0);

  const [showExclusion, setShowExclusion] = useState(false);
  const [exclusionHtml, setExclusionHtml] = useState("");
  const [part, setPart] = useState(0); //toegevoegd tbv T0 GetStarted (RCT), deze bestaat uit 2 delen: #214 en #215
  const [token, setToken] = useState("");
  const [status, setStatus] = useState('registrationNotSaved'); //registrationNotSaved, registrationSaved, registrationSavedAndT0Saved
  const [excludeToProgram, setExcludeToProgram] = useState(0);
  const [showLoadScreen, setShowLoadScreen] = useState(false);

  const doExclusion = (message, exclude_to_program) => {
    setExclusionHtml(message);
    setShowExclusion(true);
    setExcludeToProgram(exclude_to_program);
  }

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const registrationExists = {
   1: "Er is een eerdere registratie aangetroffen met dit e-mailadres, je kunt je maar één maal registreren. Als je deze eerdere registratie niet hebt afgerond dan kun je je na vier weken opnieuw registreren. Had je deze eerdere registratie wel afgerond maar nog niet geactiveerd dan ontvang je zo dadelijk opnieuw een e-mail waarmee je je account kunt activeren.", 
   2: "A previous registration was found with this email address, you can only register once. If you have not completed this previous registration, you can register again after four weeks. If you had completed this previous registration but not yet activated it, you will immediately receive another e-mail with which you can activate your account."
 }

  const goToStep = (step) => {

    if (step === '3a' && part === 1) {
      setPart(2);
      get_T0(2);
    } else {
      let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
      registrationDataTemp.step = step           
      setRegistrationData(registrationDataTemp)
    }

  }

  useEffect(() => {
    setShowLoadScreen(true);

    //is er al een registratie token bekend?
    if (!cookies.hasOwnProperty("registrationToken")) {
      //Nee? Dan eerst voorlopige registratie vastleggen
      apiCall({
         action: "new_registration",
         data: {
           email: registrationData.email,
           intervention_id: registrationData.intervention_id,
           language_id: registrationData.language_id,
           follow_up_research: registrationData.followUp,
           qualtrics_id: registrationData.qualtrics_id
         }
       }).then(resp => {
         if (resp.msg === "OK") {
           setToken(resp.token);
           //cookie met token zetten tbv reload...
           let now = new Date();
           let time = now.getTime();
           let expireTime = time + 1000*3600*24;
           now.setTime(expireTime);
           setCookie('registrationToken', resp.token, { path: '/registration', expires: now });
         } else {
           alert(registrationExists[registrationData.language_id]);
           goToStep(3);
         }
         setShowLoadScreen(false);
       })
    } else {
      setToken(cookies.registrationToken);
    }
  }, [])

  useEffect(() => {
   if (token !== "" && part === 0) {
      get_T0(1);
   }
  }, [token])

  const get_T0 = (part) => {
    setShowLoadScreen(true);
    //api aanroepen
    apiCall({
      action: "get_t0_rct_answers",
      data: {
        language_id: registrationData.language_id,
        token: token,
        part
        //questionnaire_id: props.T0_questionnaires[props.intervention_id],
      }
    }).then(resp => {

      setPart(part);
      setT0Id(resp.id);

      setActiveLesson(resp.id);
      setActiveSubLesson(0);

      dispatch(
        setQuestionnaire(
          resp.id,
          resp.questionnaires,
        )
      )

      dispatch(
        setAnswersLessons(
          resp.intervention_id,
          resp.answers
          )
      )

      setShowLoadScreen(false);
      window.scrollTo(0,0);
    });
  }

  const excludeToOtherProgram = (exclude_to_program) => { 
    let newRegistrationData = JSON.parse(JSON.stringify(registrationData));
    newRegistrationData.agree = false;
    newRegistrationData.followUp = false;
    newRegistrationData.coachChosen = 0;
    newRegistrationData.intervention_id = exclude_to_program;
    newRegistrationData.step = 3;
    newRegistrationData.suicide = false;
    setRegistrationData(newRegistrationData);
  }

  return (
    <div className="step4">
      {/* <button type="button" className="btn prev" onClick={() => goToStep(3)}>{t("Terug")}</button> */}
      <div className="container lessoncontent front">
        <div className="step">
          <b>{t("stap 2 van 4")}</b> {t("voormeting")}
        </div>
        {
          showLoadScreen ?
            <LoadScreen /> : <></>
        }
        {
          (!showExclusion) ?
            <QuestionnaireT0RCT intervention_id={registrationData.intervention_id} language_id={registrationData.language_id.language_id} questionnaire_id={t0Id} token={token} doExclusion={doExclusion} goToStep={goToStep} registrationData={registrationData} setRegistrationData={setRegistrationData} />
          :
            <>
              <div className="exclusion" dangerouslySetInnerHTML={{__html: exclusionHtml}}></div>
              {
                parseInt(excludeToProgram) !== 0 ?
                  <button type="button" className="btn btn-primary" onClick={() => excludeToOtherProgram(excludeToProgram)} style={{marginTop: '20px'}}>{t("Ga naar inschrijving")}</button>
                  :
                  <></>

              }
            </>

        }
      </div>
    </div>
  )

}

export default Step3a;
