import React, { useState, useEffect } from "react";
import t from "../../translate";
import { useSelector, useDispatch } from "react-redux";
import standardAvatar from "../../../images/course/standard/avatar.png";
import apiCall from "../../api";

const ProfileImage = (props) => {

  const [student, setCoach] = useState(false);

  const auth = useSelector(state => state.auth);
  const url = useSelector(state => state.url);
  useEffect(() => {
    if (props.student) {
      props.student.password = '';
      props.student.password_check = '';
      setCoach(props.student)
      setProfileImage(props.student.profile_pic);
    }
  }, [props.student]);

  const [profileImage, setProfileImage] = useState('');

  //////////////////////
  ///Upload image to server
  const uploadImage = (uploadedFiles) => {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_student_image",
        token: auth.token,
        data: {
          user_id: student.id,
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error == 0){
            setProfileImage(resp.image);
          }
        }
      });
    });
  }

    //////////////////////
  ///Delete image from server
  const deleteImage = (e, name) => {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_student_image",
      token: auth.token,
      data:{
        user_id: student.id
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error == 0) {
          setProfileImage('');
        }
      }
    });
  }

  let inputFileRef = React.createRef();
  const triggerInputFile = () => {
    inputFileRef.current.click();
  }

  return(
    <div className='image' style={{ backgroundImage: "url( "+url+"/uploads/user/"+ student.id + "/" + profileImage + ")" }}>
      <input
        type="file"
        onChange={e => uploadImage(e.target.files)}
        name="file"
        ref={inputFileRef}
        multiple={false}
      />
      {/*
        <div className="options">
          <span className="btn handle">
            <i
              className="fas fa-camera-retro"
              onClick={triggerInputFile}
            ></i>
          </span>

          <span className="btn delete">
            <i
              className="fas fa-trash"
              onClick={e => deleteImage(e, profileImage)}
            ></i>
          </span>
        </div>
        */}
    </div>
  )
}
export default ProfileImage;
