import React from 'react';
import { Bar } from 'react-chartjs-2';

const ChartBar = (props) => {

  return (
      <div>
        <Bar
          data={
          {
            labels: props.labels,
            datasets: [{
              label: "",
              data: props.values,
              fill: 'none',
              backgroundColor: typeof props.colors != "undefined" ? props.colors:["#f50"],
              pointRadius: 2,
              borderColor: "#eaeaea",
              borderWidth: 1,
              lineTension: 0
            }]}
        }
        width={100}
        height={500}
        options={{
          plugins: {
           legend: {
             display: false,
           },
         },
         tooltips: {
           enabled: true
        },
          maintainAspectRatio: false,
          scales: {
           yAxes: {
               title: {
                   display: typeof props.part.label_value === "undefined" || props.part.label_value == '' ? false:true,
                   text: typeof props.part.label_value !== "undefined" ? props.part.label_value : '',
                   font: {
                       size: 15
                   }
               },
               ticks: {
                   beginAtZero: true
               }
           },
           xAxes: {
               title: {
                   display: typeof props.part.label_items === "undefined" || props.part.label_items == '' ? false:true,
                   text: typeof props.part.label_items !== "undefined" ? props.part.label_items : '',
                   font: {
                       size: 15
                   }
               },
               ticks: {
                   beginAtZero: true
               }
           }
         },
        }}
        />
    </div>
  );
}

export default ChartBar;
