import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { combineReducers } from "redux";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../../api";
import $ from "jquery";
import "popper.js/dist/popper"; //als package geinstalleerd
import "bootstrap/dist/js/bootstrap"; //als package geinstalleerd
import { getClone } from "../../utils";
import InterventionLinks from "../../interventionlinks";
import InterventionStats from "../../stats/all.js";
import Editintervention from "./editIntervention.js";
import AdminOptions from "./options.js";
import Modal from "../../modal";
import t from "../../translate";
import LeftMenu from "../../leftmenu";
import { resetIntervention } from "../../../actions";
import LoadScreen from "../../loadScreen/index.js";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Admin = props => {

  let location = useLocation();
  let history = useHistory();
  let dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshInterventionLinks, setRefreshInterventionLinks] = useState(0);

  const auth = useSelector(state => state.auth);

  const closeModal = msg => {
    $("#intervention_edit").modal("toggle");
    setMessage(msg);
    //getInterventions();
    let refresh = refreshInterventionLinks + 1;
    setRefreshInterventionLinks(refresh); //zorgt ervoor dat props van InterventionLinks wijzigen en deze opnieuw rendert...
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  const [state, setState] = useState({
    interventions: [],
    nr_interventions: 0,
    modalState: {
      name: "intervention_edit",
      label: "intervention_edit_label",
      title: "",
      component: Editintervention,
      btnValue: t("Opslaan"),
      componentData: {
        intervention: {
          id: 0,
          title: "",
          settings: {}
        },
        closeModal: closeModal
      }
    }
  });

  const show = (event, intervention) => {

  };

  const onClickHandler = (event, intervention) => {
    if (auth.rights.config_access) {
      history.push("/intervention/edit/" + intervention.id);
    } else {
      if (auth.rights.coaches_access) {
        history.push("/intervention/coaches/" + intervention.id);
      } else {
        if (auth.rights.data_access) {
          history.push("/intervention/data/" + intervention.id);
        }
      }
    }
  };

  const addIntervention = () => {
    let newState = getClone(state);
    newState.modalState.componentData.intervention = {
      id: 0,
      title: "",
      settings: {
        intervention_type: "selfhelp",
        selfhelp: {
          lessons: [],
          optionalLessons: [],
          lesson_new_title: ""
        },
        research: false,
        goals: [],
        questionnaires: []
      }
    };
    newState.modalState.title = t("Toevoegen interventie");
    setState(newState);

    $("#intervention_edit").modal("toggle");
  };

  const [interventions, setInterventions] = useState([])
  const [interventionsLoaded, setInterventionsLoaded] = useState(false)

  const [stats, setStats] = useState({})
  const [universities, setUniversities] = useState([])
  const [activity, setActivity] = useState(false)
  const [registrations, setRegistrations] = useState(false)
  const [mainlessons, setMainlessons] = useState([])
  const [optionallessons, setOptionallessons] = useState([])

  useEffect(() => {
    //api aanroepen
    apiCall({
      action: "get_stats_all",
      token: auth.token,
      data: {

      }
    }).then(resp => {
      setStats(resp)
      setUniversities(resp.universities)
      setActivity(resp.activity.overall)
      setRegistrations(resp.registrations.overall)
      setMainlessons(resp.activity.mainlessons)
      setOptionallessons(resp.activity.optionallessons)
    });
  }, []);

  useEffect(() => {
    //geen leeg object?
    if (Object.keys(stats).length > 0) {
      //indien gebruiker geen rechten heeft voor overall stats dan direct naar eerste universiteit gaan
      if (typeof auth.rights.stats_access_universities !== 'undefined' && auth.rights.stats_access_universities.indexOf('overall') === -1 && auth.rights.stats_access_universities.length > 0) {
          setTimeout(() => {
            setStatsType(auth.rights.stats_access_universities[0]);
          }, 1000);
      }
    }
  }, [stats]);

  useEffect(() => {

    //intervention legen in redux
    dispatch(resetIntervention());

    //api aanroepen
    apiCall({
      action: "get_interventions",
      token: auth.token,
      data: {}
    }).then(resp => {
      setInterventions(resp.interventions)

      let newState = getClone(state);
      newState.nr_interventions = resp.nr_interventions;
      setState(newState);
      setInterventionsLoaded(true)
    });

  }, []);

  const setViewAction = (part) => {
    if(part == "stats"){
      history.push("/intervention/stats-all/");
    } else if(part == "users"){
      history.push("/intervention/users/");
    } else {
      history.push("/");
    }
  };

  const getInterventionTitle = (index) => {
    var this_intervention = interventions.find(int => int.id === index);
    return this_intervention.title;
  };
  const getInterventionCoverPhoto = (index) => {
    var this_intervention = interventions.find(int => int.id === index);
    return this_intervention.settings.coverPhoto;
  };

  function goTo(goTo){
    history.push(goTo);
  }

  function setStatsType(type) {
    if (type === "overall") {
      setActivity(stats.activity.overall)
      setRegistrations(stats.registrations.overall)
    } else {
      setActivity(stats.activity.peruni[type])
      setRegistrations(stats.registrations.peruni[type])
    }
  }

  return (
    <div className="container dashboard admin">
      <LeftMenu />
      {
        !interventionsLoaded ? <LoadScreen /> : <></>
      }
      {
        /* typeof auth.rights.stats_access !== 'undefined' && auth.rights.stats_access && typeof auth.rights.stats_access_universities !== 'undefined' && auth.rights.stats_access_universities.length > 0 ? */
          <>
      
            <div
              className={message.length < 1 ? "hidden" : "alert alert-success"}
              role="alert"
            >
              {message}
            </div>
            <div
              className={
                errorMessage.length < 1 ? "hidden" : "alert alert-danger"
              }
              role="alert"
            >
              {errorMessage}
            </div>
            <table className="table_options">
            <tbody>
              <tr>
                <td>
                <AdminOptions activity={activity} />
                {
                   location.pathname == "/intervention/stats-all/" ?
                     <div className="statsSelector">
                        <select className="custom-select" onChange={(e) => setStatsType(e.target.value)} >
                          {
                            typeof auth.rights.stats_access_universities !== 'undefined' && auth.rights.stats_access_universities.indexOf('overall') > -1 ?
                              <option value="overall">Overall</option> : <></>
                          }                          
                          {
                            universities.map((university, index) => {
                              if (typeof auth.rights.stats_access_universities !== 'undefined' && auth.rights.stats_access_universities.indexOf(university) === -1) {
                                return false;
                              }
                              return <option key={index} value={university}>{university}</option>
                            })
                          }   
                        </select><br /><br /><br />
                     </div> : <></>
                }
                </td>
                <td>
                  {interventionsLoaded && state.nr_interventions > interventions.length ?
                    <button
                      className="btn-secondary btn"
                      onClick={addIntervention}
                      style={{width: 'max-content'}}
                    >
                      {t("Nieuwe interventie")} (
                      {state.nr_interventions - interventions.length})
                    </button>
                    :
                    false
                  }
                </td>
              </tr>
            </tbody>
            </table>

            {location.pathname == "/" ?
              <InterventionLinks
                refreshState={refreshInterventionLinks}
                onClickHandler={onClickHandler}
                interventions={interventions}
                activity={activity}
              />
            :
              false
            }
            {location.pathname == "/intervention/stats-all/" ?
              <InterventionStats activity={activity} registrations={registrations} interventions={interventions} getInterventionTitle={getInterventionTitle} getInterventionCoverPhoto={getInterventionCoverPhoto} mainlessons={mainlessons} optionallessons={optionallessons} />
              :
              false
            }
      </> /* : <> {t("U heeft onvoldoende rechten voor deze module.")} </> */
      }
      <Modal {...state.modalState} />
    </div>
  );
};

export default Admin;
