import React, {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import apiCall from "../../../api"
import t from "../../../translate";

const Forms = (props) => {

  const [chosenFormId, setChosenFormId] = useState(false);
  const [platformQuestionnaires, setPlatformQuestionnaires] = useState([]);

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);

  //////////////////////
  ///On init
  useEffect(() => {
    apiCall({
      action: 'get_platform_questionnaires',
      token: auth.token,
      data: {}
   }).then(resp => {   
    setPlatformQuestionnaires(resp.questionnaires)
   })
    if(typeof props.part.form_id != "undefined")
    {
      setChosenFormId(props.part.form_id)
    }
  }, []);

  function updateChosenForm(form_id){
    props.updatePart(props.index, 'form_id', form_id)
    setChosenFormId(form_id)
  }

  return(
    <div className="forms center">
      <select onChange={(e) => updateChosenForm(e.target.value)} value={chosenFormId}>
        <option>{t("Selecteer vragenlijst")}</option>
        {
          platformQuestionnaires.map((questionnaire, key) => {
            return(
              <option key={key} value={questionnaire.id}>Platform: {questionnaire.settings.title}</option>
            )
          })
        }
        {intervention.settings.questionnaires.map((questionnaire, index) =>
          <option key={index} value={questionnaire.id}>Vragenlijst : {questionnaire.settings.title}</option>
        )}
      </select>
    </div>
  )
}
export default Forms
