import React, { useState, useEffect } from "react";
import t from "../translate";
import LeftMenu from "../leftmenu";
import AdminOptions from "../dashboard/admin/options.js";
import PlatformPages from "../pages/platform/index.js";
import PlatformQuestionnaires from "../questionnaires/platform/index.js";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Platform = (props) => {

  const location = useLocation();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(false);

  useEffect(() => {
    if(location.pathname.split("/")[2]){
      setActiveTab(location.pathname.split("/")[2])
    } else {
      setActiveTab('pages')
    }
  }, [location])

  const changeTab = (tab) => {
    history.push("/platform/" + tab + "/");
    setActiveTab(tab)
  }

  return (
    <div className="container dashboard admin platform">
      <LeftMenu />
      <AdminOptions activity={true}/>
      <div className="list listHidden intervention_edit">
      {/*<table className="language_selector">
        <tbody>
        <tr>
        <td>
          <h2>{t("Platform")}</h2>
        </td>
        <td>

        </td>
        </tr>
        </tbody>
      </table>*/}
        <nav className="navbar navbar-intervention-settings navbar-expand-lg navbar-light">
          <div id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <span id="settings-navbar-general" className={"nav-item nav-link" +  (activeTab == "pages" ? " active":"")} onClick={()=>changeTab('pages')}>{t("Paginas")}</span>
              <span id="settings-navbar-general" className={"nav-item nav-link" +  (activeTab == "questionnaires" ? " active":"")} onClick={()=>changeTab('questionnaires')}>{t("Vragenlijsten")}</span>
            </div>
          </div>
        </nav>
        {activeTab == "pages" ? <PlatformPages />:false}
        {activeTab == "questionnaires" ? <PlatformQuestionnaires />:false}
      </div>
    </div>

  )
}
export default Platform;
