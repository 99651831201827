import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import NightDetails from "./night_details.js";

const NightsThisWeek = (props) => {

  const [thisWeek, setThisWeek] = useState([]);

  useEffect(() => {
    if(thisWeek.length == 0){
      let tempThisWeek = []
      let dateToCompare;
      for(let i = 0 ; i < props.lastWeekDates.length ; i++){
        dateToCompare = new Date(props.lastWeekDates[i])
        dateToCompare = dateToCompare.getFullYear() + "-"+ (dateToCompare.getMonth() < 9 ? "0":'') + (dateToCompare.getMonth()+1) +"-"+ (dateToCompare.getDate() < 10 ? "0":'') + dateToCompare.getDate()

        let this_night_obj = props.entriesThisWeek.filter(function (entrie) {
          return entrie.date_sleep === dateToCompare
        });

        if(this_night_obj.length != 0){
          tempThisWeek.push(props.entriesThisWeek[props.entriesThisWeek.indexOf(this_night_obj[0])])
        } else {
          ///2022-4-20 enkel empty tonen als na start cursus
          if(props.startDate < (props.lastWeekDates[i] / 1000)){
            tempThisWeek.push({empty:true, date_sleep:dateToCompare, timestamp:props.lastWeekDates[i]})
          }
        }
      }

      setThisWeek(tempThisWeek)
    }

  }, [props]);

  return (
    <div className="nights">
      <h1>{t("Details")}</h1>
      <div className="items">
        {thisWeek.map((entrie, index) =>
          <NightDetails
            key={index}
            index={index}
            entrie={entrie}
            getEfficiencyClass={props.getEfficiencyClass}
            //class="box"
            class=""
          />

        )}
      </div>
    </div>
  )
}

export default NightsThisWeek
