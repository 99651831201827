import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import { getClone } from "../../../../utils";
import Slider from '../../../../content/front/parts/slider.js'

const SleepDiaryWizzardStep6 = (props) => {

  const [feel, setFeel] = useState('')
  const [gradeSleep, setGradeSleep] = useState('')

  useEffect(() => {
    if(props.content){
      setFeel(props.content.feel)
      setGradeSleep(props.content.grade_sleep)
    }
  }, []);

  useEffect(() => {
    let tempContent = getClone(props.content);
    tempContent.feel = feel;
    tempContent.grade_sleep = gradeSleep;
    props.setContent(tempContent)
  }, [feel, gradeSleep]);

  function updateAnswer(id, value){
    if(id == "feel"){
      document.getElementById("cph_feel").classList.remove("empty_must");
      setFeel(value)
    }
    if(id == "gradeSleep"){
      document.getElementById("cph_grade_sleep").classList.remove("empty_must")
      setGradeSleep(value)
    }
  }

  return (
    <>
    <div className="question box" id={'cph_feel'}>
      <Slider
        index="0"
        part={{
          id:'feel',
          must:true,
          question:t("Hoe uitgerust voel je je vanmorgen?"),
          minLabel:t(" = helemaal niet uitgerust"),
          maxLabel:t(" = heel erg uitgerust"),
          rangeMax:"10",
          rangeMin:"0"
        }}
        updateAnswer={updateAnswer}
        answer={feel}
        nextAllowed={false}/>
    </div>
    <div className="question box" id={'cph_grade_sleep'}>
      {t("")}
      <Slider
        index="1"
        part={{
          id:'gradeSleep',
          must:true,
          question:t("Hoe zou je de kwaliteit van je slaap beoordelen?") + "<br><br><b>"  + t("Uitleg") + ":</b> " + t("Kwaliteit van slaap is je gevoel of je goed of slecht hebt geslapen."),
          minLabel:t(" = zeer slecht"),
          maxLabel:t(" = zeer goed"),
          rangeMax:"10",
          rangeMin:"0"
        }}
        updateAnswer={updateAnswer}
        answer={gradeSleep}
        nextAllowed={false}/>
    </div>
    </>
  )
}
export default SleepDiaryWizzardStep6
