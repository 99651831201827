import React from "react";
import { useSelector } from "react-redux";
import Lessons from "./content/lessons.js";
import LessonsAltMenu from "./content/lessons_alt_menu.js";
import LessonsAltMenuSeq from "./content/lessons_alt_menu_seq.js";
import LessonsAltMenuSSI from "./content/lessons_alt_menu_ssi.js";
import OptionalLesson from "./content/optional_lesson.js";
import Lesson from "./content/lesson.js";
import Goals from "./content/goals.js";
import Goal from "./content/goal.js";
import GoalFillOut from "../goal/fillOut.js";
import Journal from "./content/journal.js";
import Stress from "./content/stress.js";
import Chat from "./content/chat.js";
import Overview from "../pages";
import Single from "../pages/single.js";
import Profile from "../profile";
import Badges from "./content/badges";
import SleepDiary from "./content/sleep_diary.js";
import Scores from "./content/scores.js";

const Content = (props) => {

  const activePart = useSelector(state => state.activePart);
  const intervention = useSelector(state => state.intervention);

  const getContent = () => {
    let dynamicContent = "";
    switch (activePart) {
      case "lessons":
        if (typeof intervention.settings.selfhelp.alternative_menu === "undefined" || !intervention.settings.selfhelp.alternative_menu)
        {
          dynamicContent = <Lessons />
        }
        else
        {
          if (typeof intervention.settings.selfhelp.alternative_menu_seq !== "undefined" && intervention.settings.selfhelp.alternative_menu_seq === true) {
            dynamicContent = <LessonsAltMenuSeq />
          } else {
            if (typeof intervention.settings.selfhelp.alternative_menu_seq !== "undefined" && intervention.settings.selfhelp.alternative_menu_seq === "ssi") {
              dynamicContent = <LessonsAltMenuSSI />
              } else {
                dynamicContent = <LessonsAltMenu />
              }
          }
        }
        break;
      case "optional-lesson":
        dynamicContent = <OptionalLesson />
        break;
      case "lesson":
        dynamicContent = <Lesson />
        break;
      case "goal":
        dynamicContent = <Goal />;
        break;
      case "goal-edit":
        dynamicContent = <GoalFillOut />;
        break;
      case "goals":
        dynamicContent = <Goals />;
        break;
      case "journal":
        dynamicContent = <Journal />;
        break;
      case "stress":
        dynamicContent = <Stress />;
        break;
      case "chat":
        dynamicContent = <Chat />;
        break;
      case "settings":
        dynamicContent = <Profile />;
        break;
      case "more":
        dynamicContent = <Overview />;
        break;
      case "page":
        dynamicContent = <Single />;
        break;
      case "badges":
        if(props.badgesActive){
          dynamicContent = <Badges />;
        } else {
          dynamicContent = <Lessons />
        }
        break;
      case "sleep-diary":
        dynamicContent = <SleepDiary />;
        break;
      case "scores":
        dynamicContent = <Scores />;
        break;
    }

    return dynamicContent;
  }

  return (
    <div className="content">
      {getContent()}
    </div>
  )
}

export default Content;
