import React from "react"
import t from "../translate";


const NavBar = ({ activeTab, setActiveTab }) => {

   return (
   <nav className="navbar navbar-intervention-settings navbar-expand-lg navbar-light bg-light">
      <div id="navbarNavAltMarkup">
        <div className="navbar-nav">

          <span id="settings-navbar-general"
            className={activeTab == "panel1" ? "nav-item nav-link active" : "nav-item nav-link"}
            onClick={() => { setActiveTab("panel1") }}
          >
            {t("Scherm 1")}
          </span>

          <span id="settings-navbar-general"
            className={activeTab == "panel2" ? "nav-item nav-link active" : "nav-item nav-link"}
            onClick={() => { setActiveTab("panel2") }}
          >
            {t("Scherm 2")}
          </span>

          <span id="settings-navbar-general"
            className={activeTab == "panel3" ? "nav-item nav-link active" : "nav-item nav-link"}
            onClick={() => { setActiveTab("panel3") }}
          >
            {t("Scherm 3")}
          </span>

          <span id="settings-navbar-general"
            className={activeTab == "panel4" ? "nav-item nav-link active" : "nav-item nav-link"}
            onClick={() => { setActiveTab("panel4") }}
          >
            {t("Scherm 4")}
          </span>

          <span id="settings-navbar-general"
            className={activeTab == "panel5" ? "nav-item nav-link active" : "nav-item nav-link"}
            onClick={() => { setActiveTab("panel5") }}
          >
            {t("Scherm 5")}
          </span>

          <span id="settings-navbar-general"
            className={activeTab == "panel6" ? "nav-item nav-link active" : "nav-item nav-link"}
            onClick={() => { setActiveTab("panel6") }}
          >
            {t("Score uitslagen")}
          </span>
          
        </div>
      </div>
   </nav>
   )
}

export default NavBar
