import React, { useEffect } from "react"
import t from "../translate"


const Panel3 = ({ registrationTexts, setRegistrationTexts, getTextInput, getContentEditable, getEditor, interventions}) => {

   /* useEffect(() => {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      registrationTextsTemp.panel3.informedConsent = {
         nl: '',
         eng: ''
      }
      setRegistrationTexts(registrationTextsTemp)
   }, []) */

   function addTableRow(cell1, cell2, cell3) {
      return (
         <tr><td>{cell1}</td><td>{cell2}</td><td>{cell3}</td></tr>
      )
   }

   function toggleSuicideQuestion(intervention_id) {
      let registrationTextsTemp = JSON.parse(JSON.stringify(registrationTexts))
      if (registrationTexts.panel3.suicideQuestion.includes(intervention_id)) {
         let suicideQuestionIndex = registrationTextsTemp.panel3.suicideQuestion.findIndex(item => item === intervention_id)
         registrationTextsTemp.panel3.suicideQuestion.splice(suicideQuestionIndex, 1)
      } else {
         registrationTextsTemp.panel3.suicideQuestion.push(intervention_id)
      }
      setRegistrationTexts(registrationTextsTemp)
   }

   return (
      <div>
         <h2>{t("Scherm 3: Informed consent")}</h2>
         <table className="table table-striped">
            <thead>
               <tr>
                  <th width="20%"></th>
                  <th width="40%">{t("Nederlands")}</th>
                  <th width="40%">{t("Engels")}</th>
               </tr>
            </thead>
            <tbody>
               {
                  addTableRow(
                     t("Informed consent"), 
                     getEditor('panel3_informedConsent_nl', registrationTexts.panel3.informedConsent.nl),
                     getEditor('panel3_informedConsent_eng', registrationTexts.panel3.informedConsent.eng)
                  )
               }
               <tr>
                  <td>{t("E-mail domains")}</td>
                  <td colSpan="2">
                     {getContentEditable('panel3_mailDomains', registrationTexts.panel3.mailDomains, 'E-mail domains waarmee geregistreerd mag worden.')}
                  </td>
               </tr>
               {
                  addTableRow(
                     t("Vraag 1"), 
                     getEditor('panel3_question1_nl', registrationTexts.panel3.question1.nl),
                     getEditor('panel3_question1_eng', registrationTexts.panel3.question1.eng)
                  )
               }
               {/* {
                  addTableRow(
                     t("Vraag 2"), 
                     getEditor('panel3_question2_nl', registrationTexts.panel3.question2.nl),
                     getEditor('panel3_question2_eng', registrationTexts.panel3.question2.eng)
                  )
               } */}
               {
                  addTableRow(
                     t("Vraag 2"), 
                     getEditor('panel3_question3_nl', registrationTexts.panel3.question3.nl),
                     getEditor('panel3_question3_eng', registrationTexts.panel3.question3.eng)
                  )
               }
               {
                  addTableRow(
                     t("Vraag 3"), 
                     getEditor('panel3_question4_nl', registrationTexts.panel3.question4.nl),
                     getEditor('panel3_question4_eng', registrationTexts.panel3.question4.eng)
                  )
               }
               <tr>
                  <td>{t("Suïcidevraag tonen")}</td>
                  <td colSpan="2">
                     {
                        interventions.map(int => {
                           return (
                              <div key={int.id}>
                                 <input type="checkbox" id={'suicide_question_'+int.id} checked={registrationTexts.panel3.suicideQuestion.includes(int.id)} onChange={() => toggleSuicideQuestion(int.id)} /> <label htmlFor={'suicide_question_'+int.id} >{int.title}</label>
                              </div>
                           )
                        })
                     }
                  </td>
               </tr>
               {
                  addTableRow(
                     t("Popup foutief emaildomain"), 
                     getEditor('panel3_wrongUni_nl', registrationTexts.panel3.wrongUni.nl),
                     getEditor('panel3_wrongUni_eng', registrationTexts.panel3.wrongUni.eng)
                  )
               }
            </tbody>
         </table>
      </div>
   )
} 

export default Panel3
