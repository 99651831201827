import React, { useState, useEffect } from "react";
import t from "../../../../translate";

const Menu = props => {
  const [showSecondaryMenu, toggleSecondaryMenu] = useState(false);
  const [showSecondaryOption, toggleSecondaryOptions] = useState(false);
  const [selectedComponent, setselectedComponent] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  //////////////////////
  ///Get content
  useEffect(() => {
    if(props.showHideMenu != ""){
      setShowMenu(props.showHideMenu)
    }
  }, [props]);

  ////////////////////////////////////////////
  /////////Menu click functions
  ///////////////////////////////////////////
  function handleFirstLevelMenuClick(option){
    if(option.subtypes.length == 0)
    {
      toggleSecondaryMenu(false);
      props.addPart(props.menuIndex, JSON.parse(JSON.stringify(option.content)))
    } else if(option.subtypes.length == 1) /// enkele meer keuze optie items hebben er nog maar 1 nu...
    {
      toggleSecondaryMenu(false);
      let tempSelectedComponent = option;
      //*tempSelectedComponent.content.subtype = option.subtypes[0].ref;*/
      props.addPart(props.menuIndex, JSON.parse(JSON.stringify(tempSelectedComponent.content)))
    } else {
      toggleSecondaryOptions(false);
      toggleSecondaryMenu(true);
      setTimeout(function(){
        setselectedComponent(option);
        toggleSecondaryOptions(true);
      },250);
    }
  }
  function handleSecondLevelMenuClick(selectedComponent, chosenSubtype)
  {
    selectedComponent.content.subtype = chosenSubtype;
    hideMenu()
    props.addPart(props.menuIndex, JSON.parse(JSON.stringify(selectedComponent.content)))

  }

  ////////////////////////////////////////////
  /////////Hide menu
  ///////////////////////////////////////////
  function hideMenu(){
    props.hideMenu()
    setShowMenu(false)
    toggleSecondaryMenu(false);
  }

  return(
    <div>
    <div className={"menu " + (showMenu == true ? 'show' : '')}>
      <div className={"left " + (showSecondaryMenu == false ? 'shadow' : '')}>
        <div className="holderClose"><i className="fas fa-times" onClick={() => hideMenu()}></i></div>
        <div className="components">
          {props.options.map((option, index) =>
            <div key={index}>
              {option.title == "Tekst" ?
                <div className="group">
                  {t("Content")}
                </div>
                :''}
              {option.title == "Video" ?
                <div className="group">
                  {t("Media")}
                </div>
                :''}
              {option.title == "Checkboxes" ?
                <div className="group">
                  {t("Vragen")}
                </div>
                :''}
              {option.title == "Speciaal" ?
                <div className="group">
                  {t("Speciaal")}
                </div>
                :''}
              <div key={index} onClick={() => handleFirstLevelMenuClick(option)} className={"part" + (selectedComponent.title == option.title ? ' selected' : '')}>
                {t(option.niceName)}
              </div>

            </div>
          )}
        </div>
      </div>
      <div className={"right " + (showSecondaryMenu != false ? 'show' : '')} >
        <ul className={(showSecondaryOption != false ? 'show' : 'hide')}>
          {selectedComponent != false ? selectedComponent.subtypes.map((chosenSubtype, index) =>
            <li key={index} onClick={() => handleSecondLevelMenuClick(selectedComponent, chosenSubtype.ref)}>
              {chosenSubtype.ref}
            </li>
          ) : ''}
        </ul>
      </div>
    </div>
    <div
      className={"menuOverlay " + (showMenu == true ? 'show' : '')}
      onClick={() => hideMenu()}
      ></div>
    </div>
  )
}

export default Menu;
