import React, { useEffect, useState } from "react";
import t from "../translate";
import apiCall from "../api";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import testImage from "../../images/wijn.jpg";
import LeftBottom from "../course/content/leftBottom.js";

const Courses = (props) =>{
  const auth = useSelector(state => state.auth);
  const history = useHistory();

  const [interventions, setInterventions] = useState([])

  /// get available interventions
  useEffect(() => {
    apiCall({
      action: "get_interventionsStudent",
      token: auth.token,
      data: {}
    }).then(resp => {
      setInterventions(resp.interventions);
    });
  }, [props]);

  function openCourse(id){
    history.push(
      "/course/" + id
    );
  }
  return(
    <div>
    <LeftBottom/>
    <div className='courses center'>
      <h1>{t("Courses")}</h1>
      <div className="items">
        {interventions.map((intervention, index) =>
          <div
            key={intervention.id}
            className="item"
            onClick={event =>openCourse(intervention.id)}
          >
            <div className="image" style={{ backgroundImage: "url('"+(typeof intervention.settings.coverPhoto != "undefined" && intervention.settings.coverPhoto != "" ? intervention.settings.coverPhoto:testImage)+"')" }}>
            </div>
            <div className="content">
              <h2>{intervention.title}</h2>
              {intervention.subTitle}
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  )
}

export default Courses
