import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { setIntervention } from "../../../actions";
import t from "../../translate";
import { getClone } from "../../utils";
import Intro from "./intro.js";

let saveSettingsTimeout = null;

const InterventionSettingsSleepdiary = props => {
  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = e => {
    let interventionC = getClone(intervention);
    if (e.target.checked)
    {
      interventionC.settings.include_sleepdiary = 1;
    }
    else
    {
      interventionC.settings.include_sleepdiary = 0;
    }
    saveThemSettings(interventionC.settings);
  };
  const onChangeLight = e => {
    let interventionC = getClone(intervention);
    if (e.target.checked)
    {
      interventionC.settings.include_sleepdiary_light = 1;
    }
    else
    {
      interventionC.settings.include_sleepdiary_light = 0;
    }
    saveThemSettings(interventionC.settings);
  };

  const saveThemSettings = (newSettings) => {

    dispatch(
      setIntervention(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      )
    );

    props.setErrorMessage("");
    clearTimeout(saveSettingsTimeout);
    saveSettingsTimeout = setTimeout(() => {
      props.saveSettings(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      );
    }, 1500);
  }

  return (
    <div className="form-group">

        <br />
      <h5>{t("Slaapdagboek")}</h5>
      <div className="question">
        <input
            type="checkbox"
            name="include_sleepdiary"
            id="include_sleepdiary"
            value="1"
            checked={
              intervention.settings.include_sleepdiary === 1
            }
            onChange={onChange}
          />
        <label
            className="question"
            htmlFor="include_sleepdiary"
          >
          {t("Slaapdagboek ontsluiten voor deze interventie")}
        </label>
      </div>
      {intervention.settings.include_sleepdiary == 1 ?
        <div className="question marginLeft30">
          <input
              type="checkbox"
              name="include_sleepdiary_light"
              id="include_sleepdiary_light"
              value="1"
              checked={
                intervention.settings.include_sleepdiary_light === 1
              }
              onChange={onChangeLight}
            />
          <label
              className="question"
              htmlFor="include_sleepdiary_light"
            >
            {t("Inclusief lichtdagboek")}
          </label>
        </div>
        :false}
      <Intro
        saveSettings={props.saveSettings}
        setErrorMessage={setErrorMessage}
        placeholder={t("Intro slaapdagboek")}
        type="sleepdiary"
      />
    </div>
  );
};

export default InterventionSettingsSleepdiary;
