import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import t from "../../translate";
import parse from "html-react-parser";
import apiCall from "../../api";
import { transformDate } from "../../utils";
import $ from "jquery";

const StudentDetailsJournal = (props) => {
  const auth = useSelector((state) => state.auth);
  const [journalItems, setJournalItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //journal data ophalen
    apiCall({
      action: "get_journal",
      token: auth.token,
      data: {
        intervention_id: parseInt(props.studentInterventionID),
        student_id: parseInt(props.studentId),
      },
    }).then((resp) => {
      if (resp.content) {
        setJournalItems(resp.content);
      }
      setIsLoading(false);
    });
  }, [props.studentId]);

  return isLoading ? (
    <i className="fas fa-spinner fa-spin"></i>
  ) : (
    <div className="journal">
      <div className="journal_items">
        <div className="items">
          {
          journalItems.length === 0 ?
            <div className="item">
                <span>{t("Deze deelnemer heeft nog geen bijdragen geleverd aan zijn dagboek.")}</span>
            </div> : <></>
          }
          {journalItems.map((item, index) => (
            <div key={index} className="item">
              <table>
                <tbody>
                  <tr className="dateTime">
                    <td>
                      <i className="fas fa-calendar-week"></i>
                      {moment(item.dateTime).format("LL")}
                    </td>
                    <td>
                      <i className="far fa-clock"></i>
                      {moment(item.dateTime).format("LT")}
                    </td>
                  </tr>
                </tbody>
              </table>
              {parse(item.content)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentDetailsJournal;
