const text_41 = {
  intervention: 41,
  description_short: "Werk aan het verbeteren van je eigenwaarde en zelfvertrouwen",
  step_2_title: "​Dus je wilt meer zelfvertrouwen of een hogere eigenwaarde? Je bent aan het juiste adres!",
  description_part1: "EsteemUp is een online programma barstensvol tips en tools om je zelfbeeld te verbeteren en meer zelfvertrouwen te krijgen. Het programma bestaat uit 4 modules gebaseerd op Cognitieve Gedragstherapie (CGT). De onderwerpen die aan bod komen zijn: veranderen van je negatieve gedachten over jezelf, meer focusen op het positieve, stapsgewijs zelfvertrouwen opbouwen, en je zelfverzekerd opstellen in sociale interacties. ",
  description_part2: "Tijdens het programma krijg je ondersteuning van een online coach (geheel gratis) die tekstuele feedback geeft op de interactieve opdrachten en je algehele voortgang.",
  description_part3: "Als je dit programma wilt volgen, lees de onderstaande informatie dan zorgvuldig door:",
}

export {text_41};
