import React, {useEffect, useState} from "react";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import t from "../../../../translate";

const BarChartSleepWeek = (props) => {

  const [labels, setLabels] = useState([])
  const [values, setValues] = useState([])

  useEffect(() => {
    if(props.entriesPerWeek){
      let nr_weeks_to_show = 7
      let add_weeks = 0
      let tempLabels = []
      let tempValues = []
      //let year;
      //let week;

      if(Object.keys(props.entriesPerWeek).length > nr_weeks_to_show){
        add_weeks = Object.keys(props.entriesPerWeek).length - nr_weeks_to_show
      }

      Object.keys(props.entriesPerWeek).slice(-nr_weeks_to_show).map(function(key, index) {
        //year = key.substring(0,4)
        //week = key.substring(4,6)
        //tempLabels.push(t("Week") + " " + week + " (" + year + ")")
        tempLabels.push(t("Week") + " " + (add_weeks + index + 1))

        let total_awake = 0;
        let nights = 0;

        for(let i = 0 ; i < props.entriesPerWeek[key].length ; i++){
          total_awake = (props.entriesPerWeek[key][i].content.awake_quarters * 15) + (props.entriesPerWeek[key][i].content.out_of_bed_quarters * 15) + total_awake
          nights++;
        }

        tempValues.push(total_awake/nights);
      })

      setValues(tempValues)
      setLabels(tempLabels)
    }
  }, [props.entriesPerWeek]);

  return(
    <>
    {labels.length > 0 ?
      <div className="box week bar">
        <h2>{t("Gemiddeld aantal minuten wakker per nacht")}</h2>
        <div className="holderGraph">

          <Bar
            plugins={[ChartDataLabels]}
            data={
            {
              labels: labels,
              datasets: [{
                label: "",
                data: values,
                fill: 'none',
                backgroundColor: "#253761",
                pointRadius: 2,
                borderColor: "#253761",
                borderWidth: 3,
                lineTension: 0
              }]}
          }
          width={100}
          height={500}
          options={{

            layout: {
              padding: {
                  top: 50
              }
            },
            datalabels: {
               display: true,
               color: 'black'
            },
            plugins: {

              tooltip: {
  enabled: false
  },
             datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                } ,
                align: 'end',
                anchor: 'end',
                /*backgroundColor: function(context) {
                  return context.dataset.backgroundColor;
                },*/
                borderRadius: 4,
                formatter: function(value, context) {
                    if(value > 0){
                      return Math.round(value);
                    } else {
                      return '';
                    }
                  },
                padding: 6,
                color: '#253761',
                font: {
                  size:20,
                  weight: 'bold'
                },

             },
             legend: {
               display: false
            },
          },

            maintainAspectRatio: false,
            scales: {
                y:{
                  title: {
                    display: true,
                    font: {
                      size:16,
                    },
                    text: t("Minuten wakker")
                  },
                  ticks: {
                      font: {
                        size:14,
                      },
                  },
                  grid : {
                      display : false
                  }
                },
                x : {
                  title: {
                    display: false,
                    labelString: t("Datum")

                  },
                  ticks:{
                    font: {
                      size:16,
                    },
                  },
                grid : {
                    display : false
                }
            }
            }
          }}
          />
        </div>
      </div>
      :false}
    </>
  )
}
export default BarChartSleepWeek
