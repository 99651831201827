import React, {useEffect, useState} from "react";
import t from "../../../../translate";
import { GetTime } from "../../../../helpers/convertTimestamp.js";

const SleepDiaryFillScheme = (props) => {

  const getStatusName = (status, minutes) => {
    if(status == "sleep"){
      return t("Slaap")
    } else if(status == "awake"){
      return t("Wakker in bed")
    } else if(status == "out_of_bed"){
      return t("Uit bed")
    } else if(status == "nap"){
      return t("Dutje")
    } else if(status == "light"){
      return t("Buiten")
    } else{
      return minutes;
    }
  }

  const changeStatus = (index, quarter) => {
    if(props.content.sleepdiary[index].quarters[quarter].type != 'sleep'){
      props.changeStatus(index,quarter)
    }
  }

  const [betTimeIndex, setBedTimeIndex] = useState(false);
  const [wakeupTimeIndex, setWakeupTimeIndex] = useState(false);
  const [showNextWakeupTimeHour, setShowNextWakeupTimeHour] = useState(false);

  useEffect(() => {
    if(props.content.sleepdiary){
      setBedTimeIndex(props.getIndexTime(props.content.sleepdiary, props.content.timestamp_to_bed))
      setWakeupTimeIndex(props.getIndexTime(props.content.sleepdiary, props.content.timestamp_wake_up))

      if(parseInt(GetTime(props.content.timestamp_wake_up).split(":")[1]) > 0){
        setShowNextWakeupTimeHour(true)
      }
    }

  }, [props.content.sleepdiary]);

  return (
    <>
      <div className={"fill " + props.type}>
        {props.content.sleepdiary.map((item, index) =>
          <span key={index}>
          {(((index < wakeupTimeIndex && !showNextWakeupTimeHour) ||
            (index <= wakeupTimeIndex && showNextWakeupTimeHour)) && ((index >= betTimeIndex && props.type == 'sleep')) ||
            ((props.type == 'nap' || props.type == 'light') && index <= betTimeIndex)) ?
          <div className="hour">
            <table>
              <tbody>
                <tr>
                  <td>
                    {item.hour} : 00
                  </td>
                  <td>
                    {index == (props.content.sleepdiary.length - 1) ?
                      <>
                      {props.content.sleepdiary[0].hour} : 00
                      </>
                    :
                      <>
                      {props.content.sleepdiary[(index + 1)].hour} : 00
                      </>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="quarters">
              <div className={"quarter " + (item.quarters[0].status != "" ? item.quarters[0].status:'empty')} onClick={()=>changeStatus(index,0)} >{getStatusName(item.quarters[0].status, "00")}</div>
              <div className={"quarter " + (item.quarters[1].status != "" ? item.quarters[1].status:'empty')} onClick={()=>changeStatus(index,1)} >{getStatusName(item.quarters[1].status, "15")}</div>
              <div className={"quarter " + (item.quarters[2].status != "" ? item.quarters[2].status:'empty')} onClick={()=>changeStatus(index,2)} >{getStatusName(item.quarters[2].status, "30")}</div>
              <div className={"quarter " + (item.quarters[3].status != "" ? item.quarters[3].status:'empty')} onClick={()=>changeStatus(index,3)} >{getStatusName(item.quarters[3].status, "45")}</div>
            </div>
          </div>
          :false}
          </span>
        )}
      </div>
    </>
  )
};

export default SleepDiaryFillScheme;
