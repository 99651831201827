import React, { useState, useEffect } from "react";
import t from "../../translate";
import { useSelector, useDispatch } from "react-redux";
import standardAvatar from "../../../images/course/standard/avatar.png";
import apiCall from "../../api";

const CoachImage = (props) => {

  const [coach, setCoach] = useState(false);

  const auth = useSelector(state => state.auth);
  const url = useSelector(state => state.url);
  useEffect(() => {
    if (props.coach) {
      props.coach.password = '';
      props.coach.password_check = '';
      setCoach(props.coach)
      if (props.coach.profile_pic) {
        setProfileImage(url + "/api/?a=get_profile_pic_coach&user_id=" + props.coach.id + "&" + new Date().getTime())
      }
    }
  }, [props.coach]);

  const [profileImage, setProfileImage] = useState('');

  //////////////////////
  ///Upload image to server
  const uploadImage = (uploadedFiles) => {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_coach_image",
        token: auth.token,
        data: {
          user_id: coach.id,
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error == 0){
            setProfileImage(url + "/api/?a=get_profile_pic_coach&user_id=" + coach.id + "&" + new Date().getTime())
          }
        }
      });
    });
  }

    //////////////////////
  ///Delete image from server
  const deleteImage = (e, name) => {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_coach_image",
      token: auth.token,
      data:{
        user_id: coach.id
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error == 0) {
          setProfileImage('');
        }
      }
    });
  }

  let inputFileRef = React.createRef();
  const triggerInputFile = () => {
    inputFileRef.current.click();
  }

  return(
    

    <div className='image' style={{ backgroundImage: "url("+ profileImage + ")" }}>
      <input
        type="file"
        onChange={e => uploadImage(e.target.files)}
        name="file"
        ref={inputFileRef}
        multiple={false}
      />
      <div className="options">
        <span className="btn handle">
          <i
            className="fas fa-camera-retro"
            onClick={triggerInputFile}
          ></i>
        </span>

        <span className="btn delete">
          <i
            className="fas fa-trash"
            onClick={e => deleteImage(e, profileImage)}
          ></i>
        </span>
      </div>
    </div>
  )
}
export default CoachImage;
