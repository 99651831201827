import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import apiCall from "../api";
import t from "../translate";
import ConfirmBox from "../alert/confirm";

const Edituser = forwardRef((props, ref) => {
  const [state, setState] = useState({
    user: {
      id: 0,
      firstname: "",
      insertion: "",
      lastname: "",
      email: "",
      phone: "",
      organisation_id: 0,
      type: "",
      login: "",
      password: "",
      password_check: "",
      isSupervisor: 0,
      hasSupervisor: 0,
      hasCoaches: 0,
      hasStudents: false,
      profile_pic: false,
      rights: {
        max_students: 0,
        bio: '',
        bioTranslations: [],
        languages: [],
        interventions: []
      },
      preferences: {}
    },
    coaches: [],
    supervisorFor: [],
    organisations: [],
    types: [],
    showPassword: false
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [profileImage, setProfileImage] = useState('');

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const url = useSelector(state => state.url);
  const [languages, setLanguages] = useState([]);
  const [interventions, setInterventions] = useState([]);

  //laden state met data uit props
  useEffect(() => {
    //api aanroepen, talen ophalen
    apiCall({
        action: "get_languages",
        token: auth.token,
        data: {}
      }).then(resp => {
        setLanguages(resp.languages);
      });
    //api aanroepen, interventie id's en namen ophalen
    if (interventions.length === 0) {
        apiCall({
            action: "get_all_interventions",
            token: auth.token,
            data: {}
            }).then(resp => {
                setInterventions(resp.interventions);
            });
    }
    const newState = {...state}
    newState.user = props.user;
    newState.user.password = "";
    newState.user.password_check = "";
    newState.organisations = props.organisations;
    newState.coaches = props.coaches;
    //om te voorkomen dat een lege de volle overschrijft??
    if (props.supervisorFor.length > 0) {
        newState.supervisorFor = props.supervisorFor;
    }
    newState.types = ["coach"];
    if (newState.user.id == 0) {
      newState.showPassword = true;
    } else {
      newState.showPassword = false;
    }
    //temp tbv nieuwe parameter
    if (typeof newState.user.rights.languages === "undefined") {
      newState.user.rights.languages = [];
    }
    if (typeof newState.user.rights.bioTranslations === "undefined") {
      newState.user.rights.bioTranslations = [];
    }
    //
    setState(newState);
    if (newState.user.profile_pic) {
      setProfileImage(url + "/api/?a=get_profile_pic_coach&user_id=" + newState.user.id + "&" + new Date().getTime())
    } else {
      setProfileImage('')
    }
    setErrorMessage("");

  }, [props.user]);

  //////////////////////
  ///Upload image to server
  const uploadImage = (uploadedFiles) => {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_coach_image",
        token: auth.token,
        data: {
          user_id: state.user.id,
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error == 0){
            setProfileImage(url + "/api/?a=get_profile_pic_coach&user_id=" + state.user.id + "&" + new Date().getTime())
          }
        }
      });
    });
  }

    //////////////////////
  ///Delete image from server
  const deleteImage = (e, name) => {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_coach_image",
      token: auth.token,
      data:{
        user_id: state.user.id
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error == 0) {
          setProfileImage('');
        }
      }
    });
  }

  let inputFileRef = React.createRef();
  const triggerInputFile = () => {
    inputFileRef.current.click();
  }

  const onChange = e => {

    //e.preventDefault(); // misschien heeft deze wel een functie maar hij blokt de checkbox toggle checked unchecked
    const newState = {...state};

    if(e.target.name == "bioTranslations"){
      let bioTranslations = newState.user.rights.bioTranslations.filter(function (translation) {
        return translation.code === e.target.dataset.code
      });
      if(bioTranslations.length > 0){
        newState.user.rights.bioTranslations[newState.user.rights.bioTranslations.indexOf(bioTranslations[0])]['content'] = e.target.value
      } else {
        newState.user.rights.bioTranslations.push({"code":e.target.dataset.code,"content":e.target.value})
      }
    } else if(e.target.name == "languages"){
      if(newState.user.rights.languages.includes(e.target.value)){
        let indexOfLanguage = newState.user.rights.languages.indexOf(e.target.value);
        newState.user.rights.languages.splice(indexOfLanguage, 1);
      } else {
        newState.user.rights.languages.push(e.target.value)
      }
    }  else if(e.target.name == "rights_intervention_ids") {
      e.target.value = parseInt(e.target.value);
      if(typeof newState.user.rights.interventions.find(i => parseInt(i.id) === parseInt(e.target.value)) === 'undefined'){
        newState.user.rights.interventions.push({
          id: parseInt(e.target.value),
          isSupervisor: false,
          hasSupervisor: parseInt(auth.user_id)
        });
      } else {
        let intervention_index = 0;
        newState.user.rights.interventions.map((interv, index) => {
          if (parseInt(e.target.value) == parseInt(interv.id)) {
            intervention_index = index;
          }
        });
        newState.user.rights.interventions.splice(intervention_index, 1);
      }
    } else if(e.target.name == "max_students") {
      newState.user.rights.max_students = e.target.value;
    } else if(e.target.name == "bio") {
        newState.user.rights.bio = e.target.value;
      } else  {
        newState.user[e.target.name] = e.target.value;
    }
    setState(newState);
    setErrorMessage("");
  };

  const toggleShowPassword = e => {
    const newState = {...state}
    if (newState.showPassword) {
      newState.showPassword = false;
    } else {
      newState.showPassword = true;
    }
    setState(newState);
  };

  //deze functie wordt aangeroepen door button in component Modal -> ref functie koppelt
  //componenten aan elkaar
  useImperativeHandle(ref, () => ({

    submitHandler() {
      apiCall({
        action: "save_user",
        token: auth.token,
        data: {
          user: state.user
        }
      }).then(resp => {
      if (resp.save_user) {
        state.selectedCoach = 0;
        props.closeModal(resp.msg, intervention.id);
      } else {
        setErrorMessage(resp.msg);
      }
      });
    }
  }));

  const selectExistingUser = e => {
    const newState = {...state}
    newState.selectedCoach = e.target.value;
    setState(newState);
    setErrorMessage("");
  };

  const getBioTranslationContent = (code) => {
    let bioTranslations = state.user.rights.bioTranslations.filter(function (translation) {
      return translation.code === code
    });

    if(bioTranslations.length > 0){
      return bioTranslations[0].content
    } else {
      return ''
    }
  }

  return (
    <div>
      {
        errorMessage.length > 0 ?
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-circle"></i> &nbsp;
            <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </div> : <></>
      }
      <form>

                <div className="form-group">
                <label>{t("Begeleidt programma's")}</label>
                {interventions.map((interv, index) => {
                    
                    let intervention_id = interv.id;
                    if (state.user.id !== 0 && typeof state.user.rights.interventions.find(i => parseInt(i.id) === parseInt(intervention_id)) !== 'undefined' || state.supervisorFor.includes(parseInt(intervention_id))) {

                        let hasStudents = false;
                        let intervention = state.user.rights.interventions.find(i => parseInt(i.id) === parseInt(intervention_id));
                        if (typeof intervention !== 'undefined' && typeof intervention.hasStudents !== 'undefined' && !intervention.hasStudents === false) {
                            hasStudents = intervention.hasStudents;
                        }
                 
                        return (
                            <div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={"intervention_id_" + intervention_id}
                                  name="rights_intervention_ids"
                                  value={parseInt(intervention_id)}
                                  onChange={onChange}
                                  checked={typeof state.user.rights.interventions.find(i => parseInt(i.id) === parseInt(intervention_id)) !== 'undefined'}
                                  disabled={!state.supervisorFor.includes(parseInt(intervention_id)) || hasStudents}
                                />
                                <label className="form-check-label" htmlFor={"intervention_id_" + intervention_id} >
                                {
                                    typeof interventions.find(i => parseInt(i.id) === parseInt(intervention_id)) !== 'undefined' ?
                                        <span dangerouslySetInnerHTML={{__html:interventions.find(i => parseInt(i.id) === parseInt(intervention_id)).title}} />
                                        : <span> #{intervention_id} Onbekend</span>
                                }
                                {
                                    hasStudents ? <span> ({t("begeleidt")} {hasStudents} {t("students")})</span> : ''
                                }
                                {
                                    !state.supervisorFor.includes(parseInt(intervention_id)) ? <span> ({t("U bent geen supervisor")})</span> : ''
                                }
                                </label>
                            </div>
                        )
                    }
                })}
              </div>
        <div>
        {/* <div className={state.selectedCoach != 0 ? "hidden" : ""}> */}
          <div className={state.user.id === 0 ? "hidden" : "form-row align-items-center"}>
            <div className='image_holder' style={{ backgroundImage: "url("+ profileImage + ")" }}>
              <input
                type="file"
                onChange={e => uploadImage(e.target.files)}
                name="file"
                ref={inputFileRef}
                multiple={false}
              />
              <div className="options">
                <span className="btn handle">
                  <i
                    className="fas fa-camera-retro"
                    onClick={triggerInputFile}
                  ></i>
                </span>

                <span className="btn delete">
                  <i
                    className="fas fa-trash"
                    onClick={e => deleteImage(e, profileImage)}
                  ></i>
                </span>
              </div>
            </div>
          </div>

          <div className="form-row align-items-center">
            <div className="col-auto">
              <label htmlFor="firstname">{t("Voornaam")}</label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="firstname"
                aria-describedby="firstname"
                placeholder=""
                value={state.user.firstname}
                onChange={onChange}
              />
            </div>
            <div className="col-auto col-sm">
              <label htmlFor="insertion">{t("Tussenvoegsel")}</label>
              <input
                type="text"
                className="form-control"
                id="insertion"
                name="insertion"
                aria-describedby="insertion"
                placeholder=""
                value={state.user.insertion}
                onChange={onChange}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="lastname">{t("Achternaam")}</label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                name="lastname"
                aria-describedby="lastname"
                placeholder=""
                value={state.user.lastname}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col">
              <label htmlFor="email">{t("E-mail")}</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="email"
                placeholder=""
                value={state.user.email}
                onChange={onChange}
              />
            </div>
            <div className="col">
              <label htmlFor="phone">{t("Telefoonnummer")}</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                aria-describedby="phone"
                placeholder=""
                value={state.user.phone}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="login">{t("Gebruikersnaam")}</label>
            <input
              type="text"
              className="form-control"
              id="login"
              name="login"
              aria-describedby="login"
              placeholder=""
              value={state.user.login}
              onChange={onChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="login">{t("Begeleidt max. aantal deelnemers")}</label>
            <input
              type="number"
              min="0"
              max="999"
              step="1"
              className="form-control"
              id="max_students"
              name="max_students"
              aria-describedby="max_students"
              placeholder=""
              value={state.user.rights.max_students}
              onChange={onChange}
            />
          </div>

          {/*
            kan weg lijkt me of niet
            <div className="form-group">
              <label htmlFor="login">{t("Foto")}</label>
            </div>
          */}
          <div className="form-group">
            <label>{t("Talen")}</label>
            {languages.map((language, index) => (
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"language_" + language.code}
                  name="languages"
                  value={language.code}
                  onChange={onChange}
                  checked={state.user.rights.languages.includes(language.code)}
                />
                <label className="form-check-label" htmlFor={"language_" + language.code}>
                  {t(language.language)}
                </label>
              </div>
            ))}
          </div>
          {state.user.rights.languages.includes('nl') ?
            <div className="form-group">
              <label htmlFor="login">{t("Bio Nederlands")}</label>
              <textarea
                  className="form-control"
                  id="bio"
                  name="bio"
                  value={state.user.rights.bio}
                  onChange={onChange}
                  style={{
                    height: '100px'
                  }}
                  >
              </textarea>
            </div>
           :''}

          {languages.map((language, index) => (
            <span>
            {language.code != "nl" && state.user.rights.languages.includes(language.code) ?
              <div className="form-group">
                <label htmlFor="login">{t("Bio " + language.language)}</label>
                <textarea
                    className="form-control"
                    name="bioTranslations"
                    data-code={language.code}
                    value={getBioTranslationContent(language.code)}
                    onChange={onChange}
                    style={{
                      height: '100px'
                    }}
                    >
                </textarea>
              </div>
            :''}
            </span>
          ))}
          <div className={state.user.id != 0 ? "form-check" : "hidden"}>
            <input
              className="form-check-input"
              type="checkbox"
              id="showPassword"
              onChange={toggleShowPassword}
              checked={state.showPassword}
            />
            <label className="form-check-label" htmlFor="showPassword">
              {t("Wachtwoord wijzigen")}
            </label>
            <br />
            <br />
          </div>
          <div
            className={
              state.showPassword ? "form-row align-items-center" : "hidden"
            }
          >
            <div className="col">
              <label htmlFor="login">{t("Wachtwoord")}</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                aria-describedby="password"
                placeholder=""
                value={state.user.password}
                onChange={onChange}
              />
            </div>
            <div className="col">
              <label htmlFor="login">{t("Wachtwoord ter controle")}</label>
              <input
                type="password"
                className="form-control"
                id="password_check"
                name="password_check"
                aria-describedby="password_check"
                placeholder=""
                value={state.user.password_check}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default Edituser;
