import React, { useState, useEffect } from "react";
import t from "../../translate";
import apiCall from "../../api";
import { useSelector, useDispatch } from "react-redux";
import { getClone } from "../../utils";
import { validateEmail } from "../../utils";
import Flatpickr from "react-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

const CoachRegistrationData = (props) => {

  const [coach, setCoach] = useState({registration_data:[]});
  const [emailValid, setEmailValid] = useState(true);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  const intervention = useSelector(state => state.intervention);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if(props.coach){
      props.coach.registration_data = [];
      setCoach(props.coach)
    }
  },[])

  function updateRegistrationData(e) {
    const coachN = getClone(coach);
    coachN.registration_data[e.target.name] = e.target.value;
    setCoach(coachN)
  }
  function updateRegistrationDateData(value, key) {
    const coachN = getClone(coach);
    coachN.registration_data[key] = value;
    setCoach(coachN)
  }
  function updateSetting(e) {
    const coachN = getClone(coach);
    coachN[e.target.name] = e.target.value;
    setCoach(coachN)
    if(props.setCoach){
      props.setCoach(coachN)
    }
  }

  function saveChanges(){

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: coach
      }
    }).then(resp => {

      if(props.setState){
        const stateN = getClone(props.state);
        for(let i = 0 ; i < stateN.users.length ; i++){
          if (stateN.users[i].id === coach.id){
            stateN.users[i] = coach;
            break;
          }
        }
        props.setState(stateN) /// dit nog oplossen
      }

      setMessage(t("Gegevens zijn gewijzigd"))
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }

  /*
  const [newRegistrationDataField, setNewRegistrationDataField] = useState('');
  function addRegistrationDataField(){
    if(typeof coach.registration_data.newRegistrationDataField == "undefined"){
      const coachN = getClone(coach);
      coachN.registration_data[newRegistrationDataField] = '';
      setCoach(coachN)
    }
  }
  */

  function updateDateTimeBirth(){

  }

  return(
    <div className="registration_data">
    {
      /*
      <input type="text" value={newRegistrationDataField} onChange={(e)=>setNewRegistrationDataField(e.target.value)}/>
      <span className="btn add" onClick={()=>addRegistrationDataField()}>+</span>
      */
    }
    <table>
      <tbody>
        <tr className={message != "" ? 'saved':''}>
          <td>
            <label>{t("Gebruikersnaam")}</label>
            <br/>
            <input type="text" value={coach.login} name='login' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Voornaam")}</label>
            <br/>
            <input type="text" value={coach.firstname} name='firstname' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Tussenvoegsel")}</label>
            <br/>
            <input type="text" value={coach.insertion} name='insertion' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Achternaam")}</label>
            <br/>
            <input type="text" value={coach.lastname} name='lastname' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("E-mailadres")}</label>
            <br/>
            <input type="email" value={coach.email} name='email' className="form-control" onChange={(e)=>updateSetting(e)}/>
            <label>{t("Telefoonnummer")}</label>
            <br/>
            <input type="text" value={coach.phone} name='phone' className="form-control" onChange={(e)=>updateSetting(e)}/>
            {Object.keys(coach.registration_data).map(function(key) {
              if(['geboortedatum'].includes(key)){
                return <>
                <label>{t(key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " "))}</label>
                <br/>
                <Flatpickr
                  options={{ locale: Dutch, dateFormat: "d.m.Y" }}
                  value={coach.registration_data[key] * 1000}
                  name={key}
                  className="form-control white"
                  onChange={date => {
                    updateRegistrationDateData(date[0].getTime()/1000, key)
                  }}
                />
                </>;
              }
            })}
          </td>
          {Object.keys(coach.registration_data).length > 0 ?
            <>
            <td>
            {Object.keys(coach.registration_data).map(function(key) {
              if(!['intervention_id', 'from', 'achternaam', 'voornaam', 'voorletters', 'gebruikersnaam', 'email', 'geboortedatum'].includes(key)){
                return <div key={key}>
                <label>{t(key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " "))}</label>
                <br/>
                <input type="text" value={coach.registration_data[key]} name={key} className="form-control" onChange={(e)=>updateRegistrationData(e)} disabled={(key == "entry_id" ?true:false)}/>
                </div>;
              }
            })}
            </td>
            </>
            : false}

        </tr>
        <tr>
          <td Colspan={Object.keys(coach.registration_data).length > 0 ? 2:0} className="align_right" >
            {message ?
              <span className="btn btn-primary btn-success">
                {message} <i class="fa fa-check"></i>
              </span>
              :
              <span className="btn btn-primary" onClick={()=>saveChanges()}>{t("Opslaan")}</span>
            }
          </td>
        </tr>
      </tbody>
    </table>

    </div>
  )
}

export default CoachRegistrationData;
