import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import t from "../translate";

const StudentsTable = ({students, interventions, changeSort, showStudentDetails, setCalled, detailsVisible}) => {

    return (
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th className="pointer" onClick={() => changeSort("id")}>#</th>
                    <th className="pointer" onClick={() => changeSort("firstname")}>{t("Naam")} </th>
                    {
                    !detailsVisible ?
                        <>
                            <th className="pointer" onClick={() => changeSort("program")}>{t("Programma")} </th>
                            <th></th>
                        </> : <></>
                    }
                    <th className="pointer" onClick={() => changeSort("last_action")}>{t("Laatste actie")} </th>
                </tr>
            </thead>
            <tbody>
                {students.map((student, index) => {
                    let name = student.firstname +  ' ' + student.insertion + ' ' + student.lastname;
                    return (
                        <tr key={index}>
                            <td className="pointer" onClick={() => showStudentDetails(student.id, name, 'info')}
                            style={student.ssi_program_finished ? {backgroundColor: '#14a607'} : {}} >
                                {student.id}
                            </td>
                            <td className="pointer" onClick={() => showStudentDetails(student.id, name, 'info')}>
                                {student.firstname} {student.insertion} {student.lastname}
                                {
                                    student.specialAccountType === 'T' ?
                                    <span> ({t("Test account")})</span> : student.specialAccountType === 'B' ?
                                    <span> ({t("Begeleid account")})</span> : <></>
                                }
                                &nbsp; <span className={(student.unread_message) ? 'badge badge-success' : 'hidden'}><i className="far fa-comments"></i></span>
                                &nbsp; <span className={(student.unseen_lesson) ? 'badge badge-warning' : 'hidden'}><i className="fas fa-atlas"></i></span>
                                &nbsp; <span className={(student.call_t1) ? 'badge badge-info' : 'hidden'} onClick={(e) => setCalled(e, 't1', student.id)} data-tip={'Tel. '+student.phone+' Invite '+student.call_t1_invite_date}><i className="fas fa-phone-square-alt"></i> {parseInt(student.intervention_id) === 38 ? 't4' : parseInt(student.intervention_id) === 48 ? 't3' : 't1'}</span>
                                &nbsp; <span className={(student.call_t2) ? 'badge badge-info' : 'hidden'} onClick={(e) => setCalled(e, 't2', student.id)} data-tip={'Tel. ' + student.phone+' Invite ' + student.call_t2_invite_date}><i className="fas fa-phone-square-alt"></i> {parseInt(student.intervention_id) === 38 ? 't5' : parseInt(student.intervention_id) === 48 ? 't4' : 't2'}</span>
                                &nbsp; <span className={(student.call_t2a) ? 'badge badge-info' : 'hidden'} onClick={(e) => setCalled(e, 't2a', student.id)} data-tip={'Tel. '+student.phone+' Invite '+student.call_t2a_invite_date}><i className="fas fa-phone-square-alt"></i> t2a</span>
                                &nbsp; <span className={(student.call_t3) ? 'badge badge-info' : 'hidden'} onClick={(e) => setCalled(e, 't3', student.id)} data-tip={'Tel. '+student.phone+' Invite '+student.call_t3_invite_date}><i className="fas fa-phone-square-alt"></i> t3</span>
                                &nbsp; <span className={(student.call_t2_lifehack_controle) ? 'badge badge-info' : 'hidden'} onClick={(e) => setCalled(e, 't2_lifehack_controle', student.id)} data-tip={'Tel. '+student.phone+' Invite '+student.call_t2_lifehack_controle_invite_date}><i className="fas fa-phone-square-alt"></i> t2</span>
                                <ReactTooltip place="top" effect="solid" delayShow={200} />
                            </td>
                            {
                                !detailsVisible ?
                                    <>
                                        <td className="pointer" onClick={() => showStudentDetails(student.id, name, 'info')}>{student.program}</td>
                                        <td className='userActions'>
                                            <i
                                              className="far fa-user-circle pointer"
                                              onClick={() => showStudentDetails(student.id, name, 'info')}
                                              data-tip={t("Info")}
                                            ></i>

                                            {interventions[student.intervention_id].showChat ?
                                              <i
                                                className="far fa-comments pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'chat')}
                                                data-tip={t("Chat")}
                                              ></i>
                                             : ''}

                                            {interventions[student.intervention_id].showStress ?
                                              <i
                                                className="fas fa-chart-area pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'stress')}
                                                data-tip={t("Stressmeter")}
                                              ></i>
                                             : ''}

                                             {interventions[student.intervention_id].showSleepdiary ?
                                              <i
                                                className="fas fa-bed pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'sleepdiary')}
                                                data-tip={t("Slaapdagboek")}
                                              ></i>
                                             : ''}

                                            {interventions[student.intervention_id].showJournal ?
                                              <i
                                                className="fas fa-book pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'journal')}
                                                data-tip={t("Journal")}
                                              ></i>
                                             : ''}

                                            {interventions[student.intervention_id].showLessons ?
                                              <i
                                                className="fas fa-atlas pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'lessons')}
                                                data-tip={t("Lessen")}
                                              ></i>
                                              : ''}

                                            {interventions[student.intervention_id].showGoals ?
                                              <i
                                                className="fas fa-bullseye pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'goals')}
                                                data-tip={t("Doelen")}
                                              ></i>
                                               : ''}

                                            {interventions[student.intervention_id].showQuestionnaires ?
                                              <i
                                                className="far fa-list-alt pointer"
                                                onClick={() => showStudentDetails(student.id, name, 'questionnaires')}
                                                data-tip={t("Vragenlijsten")}
                                              ></i>
                                              : ''}
                                            <ReactTooltip place="top" effect="solid" delayShow={200} />
                                        </td>
                                        <td>{student.last_action}</td>
                                    </> : <></>
                                }
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )}

 export default StudentsTable