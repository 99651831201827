import React from 'react';

const Video = (props) => {
  return (
    <div className='video center'>
      <div className={"embed " + (props.part.url == "" ? 'emtpy':'')}>
        <iframe src={props.part.url}></iframe>
      </div>
    </div>
  );
}

export default Video;
