import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAuthenticatedNull } from "../../actions";
import apiCall from "../api";
import t from "../translate";
import $ from "jquery";

const LogOut = () => {

  let history = useHistory();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onClickLogoff = e => {
    e.preventDefault();

    apiCall({
      action: "logoff",
      data: {
        token: auth.token
      }
    }).then(resp => {
      if (resp.logoff === true) {
        //logoff ok, token en usertype vastleggen in store
        dispatch(setAuthenticatedNull());
        //alle reducers resetten naar default state:
        dispatch({
          type: 'LOGOUT',
          payload: {}
        })
        //token niet meer geldig, cookie weggooien
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.getElementsByTagName('body')[0].classList.remove("menuIsVisible");
        history.push("/");
      } else {
        //??
      }
    });

    $(".overlay").fadeOut();
  };

  return(
    <div
      className="pointer displayflex"
      onClick={onClickLogoff}
    >
      <div className="icon">
        <i className="fas fa-sign-out-alt"></i>
      </div>
      <div className="menu-left-link">{t("Log uit")}</div>
    </div>
  )

}
export default LogOut;
