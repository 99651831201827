import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import { getClone } from "../../utils";
import t from "../../translate";

const TalenCoach = (props) => {
  const [coach, setCoach] = useState();
  const [languages, setLanguages] = useState([]);

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const [message, setMessage] = useState('');

  useEffect(() => {
    apiCall({
      action: "get_languages",
      token: auth.token,
      data: {}
    }).then(resp => {
      setLanguages(resp.languages);
    });
  },[])
  useEffect(() => {
    if(props.coach){
      setCoach(props.coach)
    }
  },[])

  function saveCoach(){
    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: coach
      }
    }).then(resp => {
      setMessage(t("Gegevens zijn gewijzigd"))
      setTimeout(() => {
        setMessage("");
      }, 3000);
    });
  }

  const getBioTranslationContent = (code) => {
    let bioTranslations = coach.rights.bioTranslations.filter(function (translation) {
      return translation.code === code
    });

    if(bioTranslations.length > 0){
      return bioTranslations[0].content
    } else {
      return ''
    }
  }

  function updateSetting(e) {
    const coachN = getClone(coach);
    if(!(e.target.name == "bioTranslations" || e.target.name == "languages" || e.target.name == "bio")){
        coachN[e.target.name] = e.target.value;
    } else if(e.target.name == "bioTranslations"){
      let bioTranslations = coachN.rights.bioTranslations.filter(function (translation) {
        return translation.code === e.target.dataset.code
      });
      if(bioTranslations.length > 0){
        coachN.rights.bioTranslations[coachN.rights.bioTranslations.indexOf(bioTranslations[0])]['content'] = e.target.value
      } else {
        coachN.rights.bioTranslations.push({"code":e.target.dataset.code,"content":e.target.value})
      }
    } else if(e.target.name == "languages"){
      if(coachN.rights.languages.includes(e.target.value)){
        let indexOfLanguage = coachN.rights.languages.indexOf(e.target.value);
        coachN.rights.languages.splice(indexOfLanguage, 1);
      } else {
        coachN.rights.languages.push(e.target.value)
      }
    } else if(e.target.name == "bio"){
        coachN.rights[e.target.name] = e.target.value;
    }
    setCoach(coachN)
  }

  return (
    <div className="talen">
      {coach ?
        <>
        <div className="form-group">
          {languages.map((language, index) => (
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                id={"language_" + language.code + "_" + coach.id}
                name="languages"
                value={language.code}
                onChange={updateSetting}
                checked={coach.rights.languages.includes(language.code)}
              />
              <label className="form-check-label" htmlFor={"language_" + language.code + "_" + coach.id}>
                {t(language.language)}
              </label>
            </div>
          ))}
        </div>
        {coach.rights.languages.includes('nl') ?
          <div className="form-group">
            <label htmlFor="login">{t("Bio Nederlands")}</label>
            <textarea
                className="form-control"
                id="bio"
                name="bio"
                value={coach.rights.bio}
                onChange={updateSetting}
                style={{
                  height: '100px'
                }}
                >
            </textarea>
          </div>
         :''}
         {languages.map((language, index) => (
           <span>
           {language.code != "nl" && coach.rights.languages.includes(language.code) ?
             <div className="form-group">
               <label htmlFor="login">{t("Bio " + language.language)}</label>
               <textarea
                   className="form-control"
                   name="bioTranslations"
                   data-code={language.code}
                   value={getBioTranslationContent(language.code)}
                   onChange={updateSetting}
                   style={{
                     height: '100px'
                   }}
                   >
               </textarea>
             </div>
           :''}
           </span>
         ))}
         <div className="align_right">
           {message ?
             <span className="btn btn-primary btn-success">
               {message} <i class="fa fa-check"></i>
             </span>
             :
             <span className="btn btn-primary" onClick={()=>saveCoach()}>{t("Opslaan")}</span>
           }
         </div>
        </>
        :false}
    </div>
  )
}
export default TalenCoach;
