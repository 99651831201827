import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Modal from "../../modal";
import Edituser from "../edituser.js";
import { getClone, calcAgeByTimestamp } from "../../utils";
import LoadScreen from "../../loadScreen";
import t from "../../translate";
import $ from "jquery";
import standardAvatar from "../../../images/course/standard/avatar.png";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const  StudentDetailsInfo = ({studentId, user}) => {

    const url = useSelector(state => state.url);
    const auth = useSelector(state => state.auth);

    const closeModal = msg => {
        $("#user_edit").modal("toggle");
        setMessage(msg);
        setTimeout(() => {
          setMessage("");
        }, 5000);
      };

    const [state, setState] = useState({
    modalState: {
        name: "user_edit",
        label: "user_edit_label",
        title: t("Wijzigen profiel"),
        component: Edituser,
        btnValue: t("Opslaan"),
        componentData: {
            user: {
                id: 0,
                date_time_create: "1970-01-01 00:00:00",
                firstname: "",
                insertion: "",
                lastname: "",
                email: "",
                phone: "",
                gender: "",
                education: "",
                organisation_id: 0,
                type: "",
                login: "",
                password: "",
                password_check: "",
                removeUser: false,
                anonymous: 0,
                preferences: [
                  {option: 'language_id', value: '0'}
                ],
            },
            registrationableInterventions: [],
            closeModal: closeModal
        }
    }
    });

    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(Object.keys(user).length > 0){
            const newState = getClone(state);
            newState.modalState.componentData.user = user;
            setState(newState);
            setLoading(false);
        }
    }, [user]);

    const editUser = user => {
        $("#user_edit").modal("toggle");
      };

    const getImage = () => {
      if(state.modalState.componentData.user.profile_pic == ""){
        return standardAvatar;
      } else {
        return url + "/api/?t=" + auth.token + "&a=get_profile_pic&user_id=" + state.modalState.componentData.user.id + "&" + new Date().getTime()
        //return url+"/uploads/user/"+ state.modalState.componentData.user.id + "/" + state.modalState.componentData.user.profile_pic
      }
    }

    return (
        <div>
            <div className="studentDetailsInfoPersonal">
                <div className={message.length < 1 ? "hidden" : "alert alert-success"} role="alert">
                    {message}
                </div>
                <div className={errorMessage.length < 1 ? "hidden" : "alert alert-danger"} role="alert">
                    {errorMessage}
                </div>
                {
                loading ? <LoadScreen /> :
                <>
                    {/*<em>{moment(state.modalState.componentData.user.date_time_create).format('MM-DD-YYYY h:mm ')}</em><br />*/}
                    <div className="fields">
                        <div className='image' style={{ backgroundImage: "url("+getImage()+")" }}>
                    </div>
                    <div className='field name'>
                        {
                        (state.modalState.componentData.user.anonymous === 1) ?
                          <>{state.modalState.componentData.user.login}</>
                          :
                          <>
                            {state.modalState.componentData.user.firstname} {state.modalState.componentData.user.insertion} {state.modalState.componentData.user.lastname}
                          </>
                        }
                    </div>
                    <div className='field'>
                        ({state.modalState.componentData.user.login}), {state.modalState.componentData.user.gender == 'M' ? t("Man") : state.modalState.componentData.user.gender == 'F' ? t("Vrouw") : t("Anders")}    
                    </div>
                    <div className='field'>
                        {t("Opleiding")}: {state.modalState.componentData.user.education}<br />
                    </div>
                    <br/>
                    <div className={(state.modalState.componentData.user.anonymous === 1) ? 'hidden' : 'field'}>
                        <i className="far fa-envelope"></i> <a href={"mailto:"+state.modalState.componentData.user.email}>{state.modalState.componentData.user.email}</a>
                    </div>
                        {
                        state.modalState.componentData.user.phone != "" ?
                            <div className='field'>
                                <i className="fas fa-phone"></i> <a href={"tel:"+state.modalState.componentData.user.phone}>{state.modalState.componentData.user.phone}</a>
                            </div>
                        : <></>
                        }
                    </div>
                    <br /><br />
                    <span className={(state.modalState.componentData.user.anonymous === 1) ? 'hidden' : 'btn btn-primary'} onClick={() => editUser(state.modalState.componentData.user)}>{t("Wijzig profiel")}</span>

                    <div className="studentDetailsInfoLog">

                    </div>
                    <div className="studentDetailsInfoMail">
                           
                    </div>
                </>
                }
                <Modal {...state.modalState} />
            </div>
        </div>
    )
}

export default StudentDetailsInfo;
