import React from 'react';
import t from "../../../../translate";

const SingleImage = (props) => {

  return(
    <div>
      {(props.images.length > 0) && (props.subtype === "gecentreerd" || props.subtype === "volle breedte") ?
        <div className="imageHolder">
          {props.buildImage(props.images[0].url)}

          <span className="btn btn-primary showOnHover" onClick={() => props.showMediaLibrary(props.index)}>{t("Selecteer andere afbeelding")} <i className="fa fa-plus"></i>
          </span>
        </div>
      :
      <div>
        {props.part.subtype === "volle breedte" || props.part.subtype === "gecentreerd" ?
          <div className="empty center">
            <span className="btn btn-primary" onClick={() => props.showMediaLibrary(props.index)}>{t("Selecteer afbeelding")} <i className="fa fa-plus"></i></span>
          </div>
        :''}
      </div>
      }
    </div>
  )
}

export default SingleImage;
