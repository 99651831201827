import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import { getClone } from "../../../../utils";
import SleepDiaryFillScheme from "./fill";

const SleepDiaryWizzardStep3 = (props) => {

  const [sleepDiary, setSleepDiary] = useState([]);
  const [sleepDiaryReset, setSleepDiaryReset] = useState([]);

  useEffect(() => {

  }, []);

  const changeStatus = (index, quarterIndex) => {
    let tempSleepDiary = JSON.parse(JSON.stringify(props.content.sleepdiary));
    if(tempSleepDiary[index].quarters[quarterIndex].status == 'nap'){
      tempSleepDiary[index].quarters[quarterIndex].status = '';
      tempSleepDiary[index].quarters[quarterIndex].type = '';
    } else if(tempSleepDiary[index].quarters[quarterIndex].status == ''){
      tempSleepDiary[index].quarters[quarterIndex].status = 'nap';
      tempSleepDiary[index].quarters[quarterIndex].type = 'nap';
    }

    let tempContent = getClone(props.content);
    tempContent.sleepdiary = tempSleepDiary;
    props.setContent(tempContent)
  }

  return (
    <SleepDiaryFillScheme
      content={props.content}
      changeStatus={changeStatus}
      type="nap"
      getIndexTime={props.getIndexTime}
      />
  );
};

export default SleepDiaryWizzardStep3;
