import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setIntervention } from "../../../actions";
import t from "../../translate";
import { getClone } from "../../utils";

let saveSettingsTimeout = null;

const InterventionSettingsJournal = props => {
  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);

  const onChange = e => {
    let interventionC = getClone(intervention);
    if (e.target.checked)
    {
      interventionC.settings.include_journal = 1;
    }
    else
    {
      interventionC.settings.include_journal = 0;
    }
    saveThemSettings(interventionC.settings);
  };

  const saveThemSettings = (newSettings) => {
    
    dispatch(
      setIntervention(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      )
    );

    props.setErrorMessage("");
    clearTimeout(saveSettingsTimeout);
    saveSettingsTimeout = setTimeout(() => {
      props.saveSettings(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      );
    }, 1500);
  }

  return (
    <div className="form-group">
        
        <br />
      <h5>{t("Dagboek")}</h5>
      <div className="question">
        <input
            type="checkbox"
            name="include_journal"
            id="include_journal"
            value="1"
            checked={
              intervention.settings.include_journal === 1
            }
            onChange={onChange}
          />
        <label
            className="question"
            htmlFor="include_journal"
          >
          {t("Dagboek ontsluiten voor deze interventie.")}
        </label>
      </div>
    </div>
  );
};

export default InterventionSettingsJournal;
