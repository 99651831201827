import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../api";
import LeftMenu from "../leftmenu";
import { setIntervention } from "../../actions";
import InterventionData from "./content/intervention.js";
import t from "../translate";

const InterventionStats = (props) => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const intervention = useSelector(state => state.intervention);

  useEffect(() => {
    if (intervention.id == 0 || intervention.id != window.location.href.split("/")[5]) {
      let intervention_id = window.location.href.split("/")[5];
      getSettings(intervention_id);
    }
  }, []);

  const getSettings = intervention_id => {
    //api aanroepen
    apiCall({
      action: "get_intervention_settings",
      token: auth.token,
      data: {
        intervention_id
      }
    }).then(resp => {
      //om bestaande interventies te voorzien van pages...
      if (!resp.settings.hasOwnProperty("pages"))
      {
        resp.settings.pages = [];
      }
      //set settings in global state:
      dispatch(
        setIntervention(
          resp.intervention_id,
          resp.organisation_id,
          resp.title,
          resp.settings
        )
      );
    });
  };

  const [activity, setActivity] = useState(false);

  useEffect(() => {
    //api aanroepen
    apiCall({
      action: "get_stats_all",
      token: auth.token,
      data: {

      }
    }).then(resp => {
      setActivity(resp.activity)
      //setRegistrations(resp.registrations)
    });
  }, []);

  const getInterventionTitle = () => {
    return ''
  };
  const getInterventionCoverPhoto = () => {
    return '';
  };

  return (
    <div className="whiteWrapper ">
      <LeftMenu />
      <div className="the_intervention_header">
        <div className="image" style={{ backgroundImage: "url('"+(typeof intervention.settings.coverPhoto != "undefined" && intervention.settings.coverPhoto != "" ? intervention.settings.coverPhoto:'')+"')" }}></div>
        <h1>{t("Statistieken")} {intervention.title}</h1>
      </div>
      <div className="container dashboard admin">
        <div className="stats">
          {activity ?
            <InterventionData getInterventionCoverPhoto={getInterventionCoverPhoto} activity={activity} okey={intervention.id} getInterventionTitle={getInterventionTitle} showDetails={true}/>
            :false}
        </div>
      </div>
    </div>
  );
};

export default InterventionStats;
