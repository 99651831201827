const text_43 = {
  intervention: 43,
  description_short: "Overwin je perfectionisme en doorbreek het patroon van 'nooit goed genoeg'",
  step_2_title: "",
  description_part1: "",
  description_part2: "",
  description_part3: "",
}

export {text_43};
