import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { checkNestedProperties } from "../../utils";
import t from "../../translate";
import { getClone } from "../../utils";
import GenerateTranslationPart from "../generate_translation_part";

const Questionnaire = (props) => {

  const onChange = (e) => {
    let newTranslation = getClone(props.activeTranslation);
    newTranslation[e.target.name] = e.target.value;
    props.setActiveTranslation(newTranslation)
  }

  const onChangeEditor = (indexKey, updatefield, content) => {
    let newTranslation = getClone(props.activeTranslation);
    newTranslation[updatefield] = content;
    props.setActiveTranslation(newTranslation)
  }

  return (
    <div className="translation-edit">
      {typeof props.activeQuestionnaire.settings !== 'undefined' ?
      <div className="translation_item">
        <div className="title">
          <label>{t("Titel")}</label><br />
          <div className="clearfix">
            <div className="original">
                {props.activeQuestionnaire.settings.title}
            </div>
            <div className="editor_holder">
            <input type="text" name={'questionnaire_title_'+props.activeQuestionnaire.id} onChange={(e) => onChange(e)} value={
                checkNestedProperties(props.activeTranslation, 'questionnaire_title_'+props.activeQuestionnaire.id) ? props.activeTranslation['questionnaire_title_'+props.activeQuestionnaire.id] : props.activeQuestionnaire.settings.title } />
            </div>
          </div>
        </div>

            {
              (typeof props.activeQuestionnaire.settings !== 'undefined' && typeof props.activeQuestionnaire.settings.parts !== 'undefined') ?

                <span>
                {
                  props.activeQuestionnaire.settings.parts.map((part, index) => {
                    return (
                    <GenerateTranslationPart key={index} part={part} index={index} onChange={onChange} onChangeEditor={onChangeEditor} translation={props.activeTranslation} mainId={props.activeQuestionnaire.id} />
                    )
                  })
                }
                </span>
              : ''
            }

            {
              (typeof props.activeQuestionnaire.settings !== 'undefined' && typeof props.activeQuestionnaire.settings.ranges !== 'undefined') ?

                <span>
                {
                  props.activeQuestionnaire.settings.ranges.map((part, index) => {
                    return (
                    <GenerateTranslationPart key={index} part={part} index={index+'_range'} onChange={onChange} onChangeEditor={onChangeEditor} translation={props.activeTranslation} mainId={props.activeQuestionnaire.id} />
                    )
                  })
                }
                </span>
              : <></>
            }
      </div>
      :false}
    </div>
  )
}

export default Questionnaire;
