import React, {useEffect}  from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslateTitle from "./title";
import TranslateLessons from "./lessons";
import TranslateOptionalLessons from "./optional_lessons";
import TranslateQuestionnaires from "./questionnaires";
import TranslateGoals from "./goals";
import TranslatePages from "./pages";
import TranslateBadges from "./badges";
import TranslateUI from "./ui";
import { setActiveTranslationTab } from "../../actions";

const TranslationsTab = () => {

  const dispatch = useDispatch();
  const activeTranslationTab = useSelector(state => state.activeTranslationTab);

  useEffect(() => {
    let url_arr = window.location.href.split("/")
    if (url_arr[6] && url_arr[6] != activeTranslationTab)
    {

      dispatch(setActiveTranslationTab(url_arr[6]));
    }
  }, [window.location.href])

  switch (activeTranslationTab) {

    case "title":
      return <TranslateTitle />
      break;
    case "lessons":
      return <TranslateLessons />
      break;
    case "questionnaires":
      return <TranslateQuestionnaires />
      break;
    case "optional-lessons":
      return <TranslateOptionalLessons />
      break;
    case "goals":
      return <TranslateGoals />
      break;
    case "pages":
      return <TranslatePages />
      break;
    case "gamification":
      return <TranslateBadges />
      break;
    case "ui":
      return <TranslateUI />
      break;
  }

}

export default TranslationsTab;
