import React from 'react';

const ImageColumns = (props) => {

  return(
    <div>
      {props.gridColumns > 0 && props.images.length > 3 ?
        <div className={"columns grid-"+props.gridColumns+" clearfix"}>
          <div className='column'>
            <div className='ImageHolderRelative'>
              <div className="image" style={{ background: "url('"+ props.images[0].url + "') center center" }}></div>
            </div>
          </div>
          <div className='column'>
            <div className='ImageHolderRelative'>
              <div className="image" style={{ background: "url('"+ props.images[1].url + "') center center" }}></div>
              </div>
            </div>
          {props.gridColumns > 2?
            <div className='column'>
              <div className='ImageHolderRelative'>
                <div className="image" style={{ background: "url('"+ props.images[2].url + "') center center" }}></div>
              </div>
            </div>
          :''}
          {props.gridColumns > 3?
            <div className='column'>
              <div className='ImageHolderRelative'>
                <div className="image" style={{ background: "url('"+ props.images[3].url + "') center center" }}></div>
                </div>
            </div>
          :''}
        </div>
      :''}
    </div>
  )
}

export default ImageColumns;
