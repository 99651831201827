import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkNestedProperties } from "../utils";
import { getClone } from "../utils";
import t from "../translate";
import { setTranslation } from "../../actions";
import parse from 'html-react-parser';
import EditorPart from '../content/edit/parts/editor_part.js';

const TranslateBadges = () => {

  const [badgesData, setBadgesData] = useState(false);
  const [badgesDataLesson, setBadgesDataLesson] = useState(false);
  const [badgesDataOptionalLesson, setBadgesDataOptionalLesson] = useState(false);
  const [badgesDataGoals, setBadgesDataGoals] = useState(false);
  const [badgesDataQuestionnaire, setBadgesDataQuestionnaire] = useState(false);
  const [badgesDataJournal, setBadgesDataJournal] = useState(false);
  const [badgesDataPoints, setBadgesDataPoints] = useState(false);

  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);
  const translation = useSelector(state => state.translation);

  useEffect(() => {
    if(typeof intervention.settings.gamification != "undefined" && typeof intervention.settings.gamification.badgesData != "undefined"){
      setBadgesData(intervention.settings.gamification.badgesData)

      if(typeof intervention.settings.gamification.badgesData.lesson_badges != "undefined"){
        let tempBadgesDataLesson = [];
        for(let i = 0 ; i < intervention.settings.gamification.badgesData.lesson_badges.length ; i++){
          if(intervention.settings.gamification.badgesData.lesson_badges[i].text != "" && intervention.settings.gamification.badgesData.lesson_badges[i].image != ""){
            tempBadgesDataLesson.push(intervention.settings.gamification.badgesData.lesson_badges[i])
          }
        }
        setBadgesDataLesson(tempBadgesDataLesson)
      }

      if(typeof intervention.settings.gamification.badgesData.questionnaires_badges != "undefined"){
        let tempBadgesDataQuestionnaire = [];
        for(let i = 0 ; i < intervention.settings.gamification.badgesData.questionnaires_badges.length ; i++){
          if(intervention.settings.gamification.badgesData.questionnaires_badges[i].text != "" && intervention.settings.gamification.badgesData.questionnaires_badges[i].image != ""){
            tempBadgesDataQuestionnaire.push(intervention.settings.gamification.badgesData.questionnaires_badges[i])
          }
        }
        setBadgesDataQuestionnaire(tempBadgesDataQuestionnaire)
      }

      if(typeof intervention.settings.gamification.badgesData.optionalLesson_badges != "undefined"){
        let tempBadgesDataOptionalLesson = [];
        for(let i = 0 ; i < intervention.settings.gamification.badgesData.optionalLesson_badges.length ; i++){
          if(intervention.settings.gamification.badgesData.optionalLesson_badges[i].text != "" && intervention.settings.gamification.badgesData.optionalLesson_badges[i].image != ""){
            tempBadgesDataOptionalLesson.push(intervention.settings.gamification.badgesData.optionalLesson_badges[i])
          }
        }
        setBadgesDataOptionalLesson(tempBadgesDataOptionalLesson)
      }

      if(typeof intervention.settings.gamification.badgesData.goals_badges != "undefined"){
        let tempBadgesDataGoals = [];
        for(let i = 0 ; i < intervention.settings.gamification.badgesData.goals_badges.length ; i++){
          if(intervention.settings.gamification.badgesData.goals_badges[i].options.length > 0 && intervention.settings.gamification.badgesData.goals_badges[i].active){
            tempBadgesDataGoals.push(intervention.settings.gamification.badgesData.goals_badges[i])
          }
        }
        setBadgesDataGoals(tempBadgesDataGoals)
      }

      if(typeof intervention.settings.gamification.badgesData.other_badges != "undefined"){
        for(let i = 0 ; i < intervention.settings.gamification.badgesData.other_badges.length ; i++){
          ///journal
          if(intervention.settings.gamification.badgesData.other_badges[i].id == 1){
            if(intervention.settings.gamification.badgesData.other_badges[i].options.length > 0 && intervention.settings.gamification.badgesData.other_badges[i].active && typeof intervention.settings !== "undefined" && typeof intervention.settings.include_journal !== "undefined" &&  intervention.settings.include_journal === 1){
              setBadgesDataJournal(intervention.settings.gamification.badgesData.other_badges[i])
            }
          }
          ///punten
          if(intervention.settings.gamification.badgesData.other_badges[i].id == 2){
            if(intervention.settings.gamification.badgesData.other_badges[i].options.length > 0 && intervention.settings.gamification.badgesData.other_badges[i].active){
              setBadgesDataPoints(intervention.settings.gamification.badgesData.other_badges[i])
            }
          }

        }
      }
    }
  }, [intervention]);

  const onChange = (e) => {
    let newTranslation = getClone(translation);
    newTranslation[e.target.name] = e.target.value;
    //newTranslation.gamification_start_ + "start"
    dispatch(setTranslation(newTranslation));
  }

  const onChangeEditor = (indexKey, updatefield, content) => {
    let newTranslation = getClone(translation);
    newTranslation[updatefield] = content;
    dispatch(setTranslation(newTranslation));
  }
  const getGoalTitle = (id) => {
    return id;
  }

  return(
    <>
    {badgesData ?
      <>
      {badgesData.start_badges.map((option, index) =>
        <div className="translation_item" key={index}>
          <label>{t("Badges")} {t(option.option)}</label><br />
          <div className="clearfix">
            <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
            <div className="editor_holder">
              <input type="text" id={"gamification_start_" + option.option} name={"gamification_start_" + option.option} onChange={(e) => onChange(e)} value={
                    checkNestedProperties(translation, "gamification_start_" + option.option) ? translation["gamification_start_" + option.option]:''  }  />
            </div>
          </div>
        </div>
      )}
      {badgesDataLesson.length > 0 ?
        <div className="translation_item">
          <label>{t("Badges lessen")}</label><br />
          {badgesDataLesson.map((option, index) =>
            <div className="clearfix" key={index}>
              <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
              <div className="editor_holder">
                <input type="text" id={"gamification_lesson_" + option.option} name={"gamification_lesson_" + option.option} onChange={(e) => onChange(e)} value={
                      checkNestedProperties(translation, "gamification_lesson_" + option.option) ? translation["gamification_lesson_" + option.option]:''  }  />
              </div>
            </div>
          )}
        </div>
        :false}
      {badgesDataOptionalLesson.length > 0 ?
        <div className="translation_item">
          <label>{t("Badges optionele lessen")}</label><br />
          {badgesDataOptionalLesson.map((option, index) =>
            <div className="clearfix" key={index}>
              <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
              <div className="editor_holder">
                <input type="text" id={"gamification_optional_lesson_" + option.option} name={"gamification_optional_lesson_" + option.option} onChange={(e) => onChange(e)} value={
                      checkNestedProperties(translation, "gamification_optional_lesson_" + option.option) ? translation["gamification_optional_lesson_" + option.option]:''  }  />
              </div>
            </div>
          )}
        </div>
        :false}
      {badgesDataGoals.length > 0 ?
          <>
          {badgesDataGoals.map((goal, index) =>
            <div className="translation_item" key={index}>
              <label>{t("Badges doel:")} {getGoalTitle(goal.id)}</label><br />
              {goal.options.map((option, i)=>
                <span key={i}>
                {option.text != "" && option.image != "" ?
                  <div className="clearfix">
                    <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
                    <div className="editor_holder">
                      <input type="text" id={"gamification_goal_" + goal.id + "_" + i} name={"gamification_goal_" + goal.id + "_" + i} onChange={(e) => onChange(e)} value={
                            checkNestedProperties(translation, "gamification_goal_" + goal.id + "_" + i) ? translation["gamification_goal_" + goal.id + "_" + i]:''  }  />
                    </div>
                  </div>
                :false}
                </span>
              )}
            </div>
          )}
          </>
        :false}
        {badgesDataQuestionnaire.length > 0 ?
          <div className="translation_item">
            <label>{t("Badges vragenlijsten")}</label><br />
            {badgesDataQuestionnaire.map((option, index) =>
              <div className="clearfix" key={index}>
                <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
                <div className="editor_holder">
                  <input type="text" id={"gamification_questionnaire_" + option.option} name={"gamification_questionnaire_" + option.option} onChange={(e) => onChange(e)} value={
                        checkNestedProperties(translation, "gamification_questionnaire_" + option.option) ? translation["gamification_questionnaire_" + option.option]:''  }  />
                </div>
              </div>
            )}
          </div>
          :false}
          {badgesDataJournal ?
            <>
            <div className="translation_item">
              <label>{t("Dagboek badges")}</label><br />
              {badgesDataJournal.options.map((option, index)=>
                <span key={index}>
                {option.text && option.text != "" && option.image != "" ?
                  <div className="clearfix">
                    <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
                    <div className="editor_holder">
                      <input type="text" id={"gamification_diary_" + 1 + "_" + index} name={"gamification_diary_" + 1 + "_" + index} onChange={(e) => onChange(e)} value={
                            checkNestedProperties(translation, "gamification_diary_" + 1 + "_" + index) ? translation["gamification_diary_" + 1 + "_" + index]:''  }  />
                    </div>
                  </div>
                :false}
                </span>
              )}
            </div>
            </>
          :false}
          {badgesDataPoints ?
            <>
            <div className="translation_item">
              <label>{t("Punten badges")}</label><br />
              {badgesDataPoints.options.map((option, index)=>
                <span key={index}>
                {option.text && option.text != "" && option.image != "" ?
                  <div className="clearfix">
                    <div className="original" dangerouslySetInnerHTML={{__html: option.text}}></div>
                    <div className="editor_holder">
                      <input type="text" id={"gamification_points_" + 2 + "_" + index} name={"gamification_points_" + 2 + "_" + index} onChange={(e) => onChange(e)} value={
                            checkNestedProperties(translation, "gamification_points_" + 2 + "_" + index) ? translation["gamification_points_" + 2 + "_" + index]:''  }  />
                    </div>
                  </div>
                :false}
                </span>
              )}
            </div>
            </>
          :false}
      </>
      :false
    }
    </>
  )
}
export default TranslateBadges
