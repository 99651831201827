import React from "react";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import t from "../../../../translate";

const BarChartThisWeek = (props) => {

  return(
    <div className="box">
      <h2>{t("Slaapefficiëntie per nacht")}{t(", in %")}</h2>
      {t("tijd geslapen / tijd doorgebracht in bed")}
      <div className="holderGraph">

        <Bar
          plugins={[ChartDataLabels]}
          data={
          {
            labels: props.labels,
            datasets: [{
              label: "",
              data: props.values,
              fill: 'none',
              backgroundColor: "#FDBD59",
              pointRadius: 2,
              borderColor: "#FDBD59",
              borderWidth: 3,
              lineTension: 0
            }]}
        }
        width={100}
        height={500}
        options={{
          layout: {
            padding: {
                top: 50
            }
          },
          datalabels: {
             display: true,
             color: 'black'
          },
          plugins: {
            tooltip: {
             enabled: false
           },
           datalabels: {
              display: function(context) {
                return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
              } ,
              align: 'end',
              anchor: 'end',
              /*backgroundColor: function(context) {
                return context.dataset.backgroundColor;
              },*/
              borderRadius: 4,
              //formatter: Math.round,
              formatter:  function(value, context) {
                  return Math.round(value) + "%";
                },
              padding: 6,
              color: '#FDBD59',
              font: {
                size:20,
                weight: 'bold'
              },

           },
           legend: {
             display: false
          },
        },

         tooltips: {
           enabled: true
        },
          maintainAspectRatio: false,
          scales: {
              y:{
                title: {
                  display: true,
                  font: {
                    size:16,
                  },
                  text: "% " + t("Slaapefficiëntie")
                },
                ticks: {
                    font: {
                      size:14,
                    },

                },
                beginAtZero: true,
                max:100,
                grid : {
                    display : false
                }
              },
              x : {
                title: {
                  display: false,
                  labelString: t("Datum")

                },
                ticks:{
                  font: {
                    size:16,
                  },
                },
              grid : {
                  display : false
              }
          }
          }
        }}
        />
      </div>
    </div>
  )
}
export default BarChartThisWeek
