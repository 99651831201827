import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SleepDiarySettings from "./sleep_diary/settings.js";
import SleepDiaryWizard from "./sleep_diary/wizard.js";
import SleepDiaryThisWeek from "./sleep_diary/this_week.js";
import SleepDiaryWeeks from "./sleep_diary/weeks.js";
import SleepDiaryInfo from "./sleep_diary/info.js";
import t from "../../translate";
import apiCall from "../../api";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import NotificationBox from "../../alert/notification";

const SleepDiary = () => {

  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);
  const intervention = useSelector(state => state.intervention);

  const [view, setView] = useState('');
  const [wizardAvailable, setWizardAvailable] = useState(false)
  const [showIntroduction, setShowIntroduction] = useState(false)
  const [checked, setChecked] = useState(false)
  const [entries, setEntries] = useState([])
  const [datesToFill, setDatesToFill] = useState([])
  const [activeDateIndex, setActiveDateIndex] = useState(0)
  const [showFinishNotification, setShowFinishNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(false)

  const history = useHistory();
  let location = useLocation();

  const start= (part) => {
    setShowIntroduction(false)
    setPart("wizard")
  }
  const setPart= (part) => {
    setView(part)
    history.push("/course/" + activeIntervention + "/sleep-diary/" + part);
  }

  const getEfficiencyClass= (value) => {
    if(value > 50 && value < 75){
      return 'middle'
    } else if (value >= 75){
      return 'high'
    } else {
      return 'low'
    }
  }

  useEffect(() => {
    if(showFinishNotification){
      setNotificationOptions({
        show: "true",
        text: "<h3>"+t("Voltooid!")+"</h3>"+t("Bedankt voor het invullen van je slaapdagboek! Denk eraan om het morgenochtend weer te doen :)"),
        confirmText: t("Ok")
      });
    }
  }, [showFinishNotification]);

  useEffect(() => {
    /// get chosen values from server and set in chosenvalues en dates
    if (activeIntervention > 0) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      apiCall({
        action: "check_if_sleep_diary_is_filled",
        token: auth.token,
        data: {
          intervention_id: parseInt(activeIntervention),
          date_sleep: yesterday.toISOString().split("T")[0],
        }
      }).then(resp => {
        setChecked(true)
        setDatesToFill(resp.dates_still_to_set)

        //laatste update is last active
        if(resp.latest_date_time_update_index !== false){
          setActiveDateIndex(resp.latest_date_time_update_index)
        }

        if(resp.show_introduction == true){
          setShowIntroduction(true)
        }

        if(resp.wizard == true){
          setWizardAvailable(true)
        }

        let querystring = location.pathname.split("/");

        if(['this-week', 'weeks', 'settings'].indexOf(querystring[4]) > -1){
          setPart(querystring[4])
        } else {
          if(resp.wizard == true){
            setPart('wizard')
          } else {
            setPart('this-week')
          }
        }
      });

      apiCall({
        action: "get_sleep_diary",
        token: auth.token,
        data: {
          intervention_id: parseInt(activeIntervention)
        }
      }).then(resp => {
        if (resp.error == 0 && resp.content) {
          setEntries(resp.content)
        }
      });
    }
  }, []);

  const getView = () => {
    let dynamicContent = "";

    switch (view) {
      case "settings":
        dynamicContent = <SleepDiarySettings />
        break;
      case "this-week":
        dynamicContent = <SleepDiaryThisWeek getEfficiencyClass={getEfficiencyClass}/>
        break;
      case "weeks":
        dynamicContent = <SleepDiaryWeeks getEfficiencyClass={getEfficiencyClass} coach={false}/>
        break;
      case "info":
        dynamicContent = <SleepDiaryInfo/>
        break;
      default:
        if(wizardAvailable){
          dynamicContent = <SleepDiaryWizard setPart={setPart} setWizardAvailable={setWizardAvailable} datesToFill={datesToFill} setDatesToFill={setDatesToFill} setActiveDateIndex={setActiveDateIndex} activeDateIndex={activeDateIndex} setShowFinishNotification={setShowFinishNotification}/>
        } else {
          dynamicContent = <SleepDiaryThisWeek getEfficiencyClass={getEfficiencyClass}/>
        }
        break;
    }

    return dynamicContent;
  }

  return (
    <div className="sleep_diary">
      {checked ?
        <>
        {!showIntroduction ?
          <>
          <div className="menu">
            {wizardAvailable ?
              <>
                <i className={"fas fa-plus" + (view == 'wizard' ? ' active':'')} onClick={()=>setPart('wizard')} data-tip={t("Slaap opgeven")}></i>
                <ReactTooltip place="top" effect="solid" delayShow={200} className="menu_tooltip"   />
              </>
              :false}
            <i className={"fas fa-chart-area"  + (view == 'this-week' ? ' active':'')} onClick={()=>setPart('this-week')} data-tip={t("Deze week")}></i>
            <i className={"fas fa-list-alt" + (view == 'weeks' ? ' active':'')} onClick={()=>setPart('weeks')} data-tip={t("Alle weken")}></i>
            <i className={"fas fa-cog"   + (view == 'settings' ? ' active':'')}  onClick={()=>setPart('settings')} data-tip={t("Settings")}></i>
            <i className={"fas fa-info-circle" + (view == 'info' ? ' active':'')} onClick={()=>setPart('info')} data-tip={t("Info")}></i>
            <ReactTooltip place="top" effect="solid" delayShow={200} className="menu_tooltip"   />
          </div>
          {getView()}
          </>
          :
          <div className="introduction">
            <h1>{t("Introductie slaapdagboek")}</h1>
            <div className="text" dangerouslySetInnerHTML={{__html: intervention.settings.sleepdiaryIntro}}></div>
            <span className="btn btn-primary" onClick={()=>start()}>{t("Verder")}</span>
          </div>
        }
        </>
        :false}
        {showFinishNotification ?
          <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions}/>
          :false}
    </div>
  );
};

export default SleepDiary;
