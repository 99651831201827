import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';
import t from "../../translate";

const AdminOptions = props => {

  let location = useLocation();
  let history = useHistory();

  const auth = useSelector(state => state.auth);

  const setViewAction = (part) => {
    if(part == "stats"){
      history.push("/intervention/stats-all/");
    } else if(part == "users"){
      history.push("/intervention/users/");
    } else {
      history.push("/");
    }
  };

  function goTo(goTo){
    history.push(goTo);
  }

  return (
    <div className="main_options">
      {
        (typeof auth.rights.config_access !== 'undefined' && auth.rights.config_access) ?
          <i className={"fas fa-list" + (location.pathname == "/" ? ' active':'')} onClick={(e)=>setViewAction('edit')} data-tip={t("Interventies")}></i>
          : <></>
      }
      {
        (typeof auth.rights.stats_access !== 'undefined' && auth.rights.stats_access && typeof auth.rights.stats_access_universities !== 'undefined' && auth.rights.stats_access_universities.length > 0) ?
          <i className={"fas fa-chart-bar" + (location.pathname == "/intervention/stats-all/" ? ' active':'')} onClick={(e)=>setViewAction('stats')} data-tip={t("Statistieken")}></i>
          : <></>
      }
      {
        (typeof auth.rights.coaches_access !== 'undefined' && auth.rights.coaches_access) ?
          <i className={"far fa-user" + (location.pathname == "/coaches/" ? ' active':'')} onClick={()=>goTo('/coaches/')} data-tip={t("Coaches")}></i>
          : <></> 
      }
      {
        (typeof auth.rights.students_access !== 'undefined' && auth.rights.students_access) ?
          <i className={"fas fa-users" + (location.pathname == "/intervention/users/" ? ' active':'')} onClick={(e)=>setViewAction('users')} data-tip={t("Studenten")}></i>
          : <></>
      }
      {
        (typeof auth.rights.unfinished_registrations_access !== 'undefined' && auth.rights.unfinished_registrations_access) ?
          <i className={"fas fa-registered" + (location.pathname.split("/")[1] == "unfinished-registrations" ? ' active':'')} onClick={(e)=>goTo('/unfinished-registrations/')} data-tip={t("Onafgeronde registraties")}></i>
          : <></>
      }
      {
        (typeof auth.rights.platform_access !== 'undefined' && auth.rights.platform_access) ?
          <>
            <i className={"fas fa-pager" + (location.pathname.split("/")[1] == "platform" ? ' active':'')} onClick={(e)=>goTo('/platform/')} data-tip={t("Platform pagina's en vragenlijsten")}></i>
            <i className={"fas fa-globe-europe" + (location.pathname.split("/")[1] == "platform-translations" ? ' active':'')} onClick={(e)=>goTo('/platform-translations/')} data-tip={t("Platform vertalingen")}></i>
          </>
          : <></>
      }
      {
        (typeof auth.rights.registration_texts_access !== 'undefined' && auth.rights.registration_texts_access) ?
          <>
            <i className={"fas fa-keyboard" + (location.pathname.split("/")[1] == "registration-texts" ? ' active':'')} onClick={(e)=>goTo('/registration-texts/')} data-tip={t("Registratie teksten")}></i>
          </>
          : <></>
      }
      
      <ReactTooltip place="top" effect="solid" delayShow={200}   />
    </div>
  );
};

export default AdminOptions;
