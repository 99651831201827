import React, { useState, useEffect } from "react";
import HighlightRound from "./highlight_round.js";
import t from "../../translate";

const RecentHighlights = (props) => {

  const [showDetails, setShowDetails] = useState(true);

  const toggleDetails = () => {
    if(showDetails){
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  };

  return(
    <>
    <h3>{t(props.header)}</h3>
    <HighlightRound icon="fas fa-door-open" label="Logins" content={props.data.logins} />
    <HighlightRound icon="fas fa-mail-bulk" label="Vragenlijsten verstuurd" content={props.data.questionnaires_total_invites_total} color="orange"/>
    <HighlightRound icon="fas fa-check" label="Vragenlijsten afgerond" content={props.data.questionnaires_total_finished} color="yellow"/>
    {
      /*
      <div className="show_details highlight_round" onClick={()=>toggleDetails()}>
        <div className="content">
          <i className="fas fa-chevron-down"></i>
          <div className="label">
            {t("Details")}
          </div>
        </div>
      </div>
      */
    }
    {showDetails ?
      <div className="details">
        <div className="content">
        {Object.keys(props.data.questionnaires_total_invites).map(function(key, index) {
          return(
            <div className="program">
              {props.data.questionnaires_total_invites[key]} {t("voor")} {props.getInterventionTitle(key)}
              {Object.keys(props.data.questionnaire_invites_spec[key]).length > 0 ?
                <>
                {Object.keys(props.data.questionnaire_invites_spec[key]).map(function(kkey, index) {
                  return (
                       <div>
                       {kkey} x {props.data.questionnaire_invites_spec[key][kkey]}
                       </div>
                  )
                })}
                </>
                :false}
            </div>
          )
        })}
        {/* <div className="asterix">
          <em>{t("* Uitnodigingen T3 voor GetStarted (controle) worden direct na randomisatie klaargezet, de uitnodigingsmail met de link wordt 4 weken later verzonden.")}</em>
        </div> */}

        </div>
      </div>
      :false}
    </>
  )
}

export default RecentHighlights;
