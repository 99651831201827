import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkNestedProperties } from "../utils";
import { getClone } from "../utils";
import t from "../translate";
import { setTranslation } from "../../actions";
import parse from 'html-react-parser';
import EditorPart from '../content/edit/parts/editor_part.js';

const TranslateTitle = () => {

  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);
  const translation = useSelector(state => state.translation);

  const onChange = (e) => {
    let newTranslation = getClone(translation);
    newTranslation[e.target.name] = e.target.value;
    dispatch(setTranslation(newTranslation));
  }

  const onChangeEditor = (indexKey, updatefield, content) => {
    let newTranslation = getClone(translation);
    newTranslation[updatefield] = content;
    dispatch(setTranslation(newTranslation));
  }

  return (
    <div className="translation_item">
      <div className="translation_item">
        <label>{t("Titel")}</label><br />
        <div className="clearfix">
          <div className="original" dangerouslySetInnerHTML={{__html: intervention.title}}></div>
          <div className="editor_holder">
            <input type="text" id="title" name="title" onChange={(e) => onChange(e)} value={
                  checkNestedProperties(translation, 'title') ? translation.title : '' }  />
          </div>
        </div>

        {
          (typeof intervention.settings.subtitle !== 'undefined' && intervention.settings.subtitle.length > 0) ?
            <div>
              <br />
              <label>{t("Subtitel")}</label><br />
              <div className="clearfix">
                <div className="original" dangerouslySetInnerHTML={{__html: intervention.settings.subtitle}}></div>

                <div className="editor_holder">
                  <input type="text" name="subtitle" onChange={(e) => onChange(e)} value={
                    checkNestedProperties(translation, 'subtitle') ? translation.subtitle : '' } />
                </div>
              </div>
            </div>
          :
            ''
        }
      </div>
      <div className="translation_item">
        <label>{t("Intro lessen")}</label><br />
        <div className="clearfix">
          <div className="original" dangerouslySetInnerHTML={{__html: intervention.settings.lessonsIntro}}></div>
          <div className="editor_holder">
            <input type="text" id="lessonsIntro" name="lessonsIntro" onChange={(e) => onChange(e)} value={
                  checkNestedProperties(translation, 'lessonsIntro') ? translation.lessonsIntro : '' }  />
          </div>
        </div>
      </div>

      <div className="translation_item">
        <label>{t("Intro optionele lessen")}</label><br />
        <div className="clearfix">
          <div className="original" dangerouslySetInnerHTML={{__html: parse("'"+intervention.settings.optionalLessonsIntro+"'")}}></div>
          <div className="editor_holder">
            <input type="text" id="optionalLessonsIntro" name="optionalLessonsIntro" onChange={(e) => onChange(e)} value={
                  checkNestedProperties(translation, 'optionalLessonsIntro') ? translation.optionalLessonsIntro : '' }  />
          </div>
        </div>
      </div>

      <div className="translation_item">
        <label>{t("Intro doelen")}</label><br />
        <div className="clearfix">
          <div className="original" dangerouslySetInnerHTML={{__html: intervention.settings.goalsIntro}}></div>
          <div className="editor_holder">
            <input type="text" id="goalsIntro" name="goalsIntro" onChange={(e) => onChange(e)} value={
                  checkNestedProperties(translation, 'goalsIntro') ? translation.goalsIntro : '' }  />
          </div>
        </div>
      </div>

      <div className="translation_item">
        <label>{t("Intro Stress")}</label><br />
        <div className="clearfix">
          <div className="original" dangerouslySetInnerHTML={{__html: intervention.settings.stressIntro}}></div>
          <div className="editor_holder">
            <input type="text" id="stressIntro" name="stressIntro" onChange={(e) => onChange(e)} value={
                  checkNestedProperties(translation, 'stressIntro') ? translation.stressIntro : '' }  />
          </div>
        </div>
      </div>

      <div className="translation_item">
        <label>{t("Intro slaapdagboek")}</label><br />
        <div className="clearfix">
          <div className="original" dangerouslySetInnerHTML={{__html: intervention.settings.sleepdiaryIntro}}></div>
            <EditorPart updatePart={onChangeEditor} part_content={
                  checkNestedProperties(translation, 'sleepdiaryIntro') ? translation.sleepdiaryIntro : '' } update_field="sleepdiaryIntro" />
        </div>
      </div>
      
      {intervention.settings.menu ?
        <div className="translation_item">
          <label>{t("Menu opties")}</label><br />
          <div className="clearfix">
            <div className="original">
              {parse(intervention.settings.menu.modules)}
            </div>
            <div className="editor_holder">
              <input type="text" id="menuLessonTitle" name="menuLessonTitle" onChange={(e) => onChange(e)} value={
                    checkNestedProperties(translation, 'menuLessonTitle') ? translation.menuLessonTitle : '' }  />
            </div>
          </div>
          <div className="clearfix">
            <div className="original">
              {parse(intervention.settings.menu.objectives)}
            </div>
            <div className="editor_holder">
              <input type="text" id="menuObjectivesTitle" name="menuObjectivesTitle" onChange={(e) => onChange(e)} value={
                    checkNestedProperties(translation, 'menuObjectivesTitle') ? translation.menuObjectivesTitle : '' }  />
            </div>
          </div>
          {
            (typeof intervention.settings.menu.journal !== 'undefined')
              ?
                <div className="clearfix">
                  <div className="original">
                    {
                    parse(intervention.settings.menu.journal)
                    }
                  </div>
                  <div className="editor_holder">
                    <input type="text" id="menuJournalTitle" name="menuJournalTitle" onChange={(e) => onChange(e)} value={
                          checkNestedProperties(translation, 'menuJournalTitle') ? translation.menuJournalTitle : '' }  />
                  </div>
                </div>
              : <></>
          }
          {
            (typeof intervention.settings.menu.sleepdiary !== 'undefined' && intervention.settings.include_sleepdiary == 1)
              ?
                <div className="clearfix">
                  <div className="original">
                    {
                    parse(intervention.settings.menu.sleepdiary)
                    }
                  </div>
                  <div className="editor_holder">
                    <input type="text" id="menuSleepdiaryTitle" name="menuSleepdiaryTitle" onChange={(e) => onChange(e)} value={
                          checkNestedProperties(translation, 'menuSleepdiaryTitle') ? translation.menuSleepdiaryTitle : '' }  />
                  </div>
                </div>
              : <></>
          }
          <div className="clearfix">
            <div className="original">
              {parse(intervention.settings.menu.stress)}
            </div>
            <div className="editor_holder">
              <input type="text" id="goalsIntro" name="menuStressTitle" onChange={(e) => onChange(e)} value={
                    checkNestedProperties(translation, 'menuStressTitle') ? translation.menuStressTitle : '' }  />
            </div>
          </div>
          <div className="clearfix">
            <div className="original">
              {parse(intervention.settings.menu.coach)}
            </div>
            <div className="editor_holder">
              <input type="text" id="menuCoachTitle" name="menuCoachTitle" onChange={(e) => onChange(e)} value={
                    checkNestedProperties(translation, 'menuCoachTitle') ? translation.menuCoachTitle : '' }  />
            </div>
          </div>
          {
            typeof intervention.settings.menu.badges !== "undefined" ?
                        <div className="clearfix">
                <div className="original">
                  {parse(intervention.settings.menu.badges)}
                </div>
                <div className="editor_holder">
                  <input type="text" id="menuBadgesTitle" name="menuBadgesTitle" onChange={(e) => onChange(e)} value={
                        checkNestedProperties(translation, 'menuBadgesTitle') ? translation.menuBadgesTitle : '' }  />
                </div>
              </div>
              : <></>
          }
        </div>
        :false}

        {
          typeof intervention.settings.selfhelp.include_progressbar !== "undefined" && intervention.settings.selfhelp.include_progressbar === 1 ? 
            <div className="translation_item">
              <label>{t("Progressbar ballonteksten")}</label><br />
                <div className="clearfix">
                  <div className="original">
                    {parse(intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_0"])}
                  </div>
                  <div className="editor_holder">
                    <input type="text" id="progressbar_popover_0" name="progressbar_popover_0" onChange={(e) => onChange(e)} value={
                      checkNestedProperties(translation, 'progressbar_popover_0') ? translation.progressbar_popover_0 : '' }  />
                  </div>
                </div>
                
                {
                  intervention.settings.selfhelp.lessons.map((lesson, index) => {
                    if (parseInt(lesson.parent_id) === 0) {
                       return (
                        <div className="clearfix">
                          <div className="original">
                            {parse(intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_"+lesson.id])}
                          </div>
                          <div className="editor_holder">
                            <input type="text" id={"progressbar_popover_"+lesson.id} name={"progressbar_popover_"+lesson.id} onChange={(e) => onChange(e)} value={
                              checkNestedProperties(translation, 'progressbar_popover_'+lesson.id) ? translation["progressbar_popover_"+lesson.id] : '' }  />
                          </div>
                        </div>
                       )
                    }
                 })
                }

                <div className="clearfix">
                  <div className="original">
                    {parse(intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_00"])}
                  </div>
                  <div className="editor_holder">
                    <input type="text" id="progressbar_popover_00" name="progressbar_popover_00" onChange={(e) => onChange(e)} value={
                      checkNestedProperties(translation, 'progressbar_popover_00') ? translation.progressbar_popover_00 : '' }  />
                  </div>
                </div>

            </div> : <></>
        }

        {
          //Single Session Intervention?
          typeof intervention.settings.selfhelp.alternative_menu_seq !== "undefined" && intervention.settings.selfhelp.alternative_menu_seq === "ssi" ?
            <div className="translation_item">
              <label>{t("SSI teksten")}</label><br />
                <div className="clearfix" style={{paddingBottom: '1px'}}>
                  <div className="original">
                    {parse(intervention.settings.selfhelp.ssi_texts.start)}
                  </div>
                  <EditorPart updatePart={onChangeEditor} part_content={checkNestedProperties(translation, 'ssi_texts_start') ? translation.ssi_texts_start : '' } update_field="ssi_texts_start" />
                </div>
          
                <div className="clearfix" style={{paddingBottom: '1px'}}>
                  <div className="original">
                    {parse(intervention.settings.selfhelp.ssi_texts.intro_modal)}
                  </div>
                  <EditorPart updatePart={onChangeEditor} part_content={checkNestedProperties(translation, 'ssi_texts_intro_modal') ? translation.ssi_texts_intro_modal : '' } update_field="ssi_texts_intro_modal" /><br />
                </div>
                <div className="clearfix" style={{paddingBottom: '1px'}}>
                  <div className="original">
                    {parse(intervention.settings.selfhelp.ssi_texts.heading_category)}
                  </div>
                  <EditorPart updatePart={onChangeEditor} part_content={checkNestedProperties(translation, 'ssi_texts_heading_category') ? translation.ssi_texts_heading_category : '' } update_field="ssi_texts_heading_category" /><br />
                </div>
                <div className="clearfix" style={{paddingBottom: '1px'}}>
                  <div className="original">
                    {parse(intervention.settings.selfhelp.ssi_texts.heading_lesson)}
                  </div>
                  <EditorPart updatePart={onChangeEditor} part_content={checkNestedProperties(translation, 'ssi_texts_heading_lesson') ? translation.ssi_texts_heading_lesson : '' } update_field="ssi_texts_heading_lesson" /><br />
                </div>
                <div className="clearfix" style={{paddingBottom: '1px'}}>
                  <div className="original">
                    {parse(intervention.settings.selfhelp.ssi_texts.finish_program)}
                  </div>
                  <EditorPart updatePart={onChangeEditor} part_content={checkNestedProperties(translation, 'ssi_texts_finish_program') ? translation.ssi_texts_finish_program : '' } update_field="ssi_texts_finish_program" /><br />
                </div>
                {
                  typeof intervention.settings.selfhelp.alternative_menu_flipcardtexts !== "undefined" ?
                    <>
                    {
                      Object.keys(intervention.settings.selfhelp.alternative_menu_flipcardtexts).map(function(key, index) {
                        return (
                          <div className="clearfix" style={{paddingBottom: '1px'}}>
                            <div className="original">
                              {parse(intervention.settings.selfhelp.alternative_menu_flipcardtexts[key])}
                            </div>
                            <EditorPart updatePart={onChangeEditor} part_content={checkNestedProperties(translation, 'alternative_menu_flipcardtexts_'+key) ? translation['alternative_menu_flipcardtexts_'+key] : '' } update_field={'alternative_menu_flipcardtexts_'+key} /><br />
                          </div>

                        )
                      })
                    }
                    </> : <></>
                }
            </div> : <></>
        }
    </div>
  )

}

export default TranslateTitle;
