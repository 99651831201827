import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import t from "../../translate";

const InterventionSettingsNavbar = props => {
  const intervention = useSelector(state => state.intervention);
  const history = useHistory();

  const [navtab, setNavtab] = useState("");

  const settingsType = window.location.href.split("/")[6];

  if(settingsType != navtab && typeof settingsType != "undefined"  && settingsType != "")
  {
    setNavtab(settingsType);
  }

  useEffect(() => {
    setNavtab("general");
  }, []);

  const navigateTo = gotab => {
    if (navtab !== gotab) {
      history.push("/intervention/edit/" + intervention.id + "/" + gotab + "/");
      setNavtab(gotab);
      props.showTab(gotab);
      window.scrollTo(0, 0);
    }
  };

  return (
    <nav className="navbar navbar-intervention-settings navbar-expand-lg navbar-light bg-light">
      <div id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <span
            id="settings-navbar-general"
            className={
              navtab == "general"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              navigateTo("general");
            }}
          >
            {t("Algemeen")}
          </span>
          <span
            id="settings-navbar-lessons"
            className={
              typeof intervention.settings.intervention_type != "undefined" &&
              intervention.settings.intervention_type !== "selfhelp" &&
              intervention.settings.intervention_type !== "guided_selfhelp"
                ? "hidden"
                : navtab === "lessons"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              navigateTo("lessons");
            }}
          >
            {t("Lessen")}
          </span>
          <span
            id="settings-navbar-optional-lessons"
            className={
              typeof intervention.settings.intervention_type != "undefined" &&
              intervention.settings.intervention_type !== "selfhelp" &&
              intervention.settings.intervention_type !== "guided_selfhelp"
                ? "hidden"
                : navtab === "optional-lessons"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              navigateTo("optional-lessons");
            }}
          >
            {t("Optionele lessen")}
          </span>
          <span
            id="settings-navbar-goals"
            className={ navtab === "goals"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              navigateTo("goals");
            }}
          >
            {t("Doelen")}
          </span>
          <span
            id="settings-navbar-chatlessons"
            className={
              typeof intervention.settings.intervention_type != "undefined" && intervention.settings.intervention_type !== "chatcourse"
                ? "hidden"
                : navtab === "chatlessons"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              navigateTo("chatlessons");
            }}
          >
            {t("Infoschermen chat")}
          </span>
          <span
            id="settings-navbar-chathomework"
            className={
              intervention.settings.intervention_type !== "chatcourse"
                ? "hidden"
                : navtab === "chathomework"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            onClick={() => {
              navigateTo("chathomework");
            }}
          >
            {t("Huiswerkplek")}
          </span>
          <span
            id="settings-navbar-questionnaires"
            className={
              navtab == "questionnaires"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            href="#"
            onClick={() => {
              navigateTo("questionnaires");
            }}
          >
            {t("Vragenlijsten")}
          </span>
          <span
            id="settings-navbar-pages"
            className={
              navtab == "pages"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            href="#"
            onClick={() => {
              navigateTo("pages");
            }}
          >
            {t("Pagina's")}
          </span>
          <span
            id="settings-navbar-theme"
            className={
              navtab == "theme"
                ? "nav-item nav-link active"
                : "nav-item nav-link hidden"
            }
            onClick={() => {
              navigateTo("theme");
            }}
          >
            {t("Thema")}
          </span>
          <span
            id="settings-navbar-translations"
            className={
              navtab == "translations"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            href="#"
            onClick={() => {
              navigateTo("translations");
            }}
          >
            {t("Vertalingen")}
          </span>
          <span
            id="settings-navbar-exam-types"
            className={ `nav-item nav-link ${ navtab === 'gamification' ? 'active' : '' }` }
            onClick={ () => {
              navigateTo('gamification')
            } }>
            { t('Gamification') }
          </span>
          {
            /*
          <span
            id="settings-navbar-json"
            className={
              navtab == "json"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
            href="#"
            onClick={() => {
              navigateTo("json");
            }}
          >
            {t("JSON")}
          </span>
          */
          }
        </div>
      </div>
    </nav>
  );
};

export default InterventionSettingsNavbar;
