import React, {useEffect, useState} from "react"
import { useSelector } from "react-redux"
import apiCall from "../../api"
import t from "../../translate"

const  InviteT2StudentBegeleiding = props => {

  const auth = useSelector(state => state.auth)

  const [questionnaireDefined, setQuestionnaireDefined] = useState(false)
  const [questionnaireFinished, setQuestionnaireFinished] = useState(false)
  const [invited, setInvited] = useState(false);
  const [dateTimeInvite, setDateTimeInvite] = useState("")
  const [dateTimeReminder1, setDateTimeReminder1] = useState("")
  const [dateTimeReminder2, setDateTimeReminder2] = useState("")

  useEffect(() => {
    checkT1Invite()
  }, [props.user]) 

  const checkT1Invite = () => {
    //checken of er al een invite is verzonden
    apiCall({
      action: "check_invite_T1_begeleid",
      token: auth.token,
      data: {
          student_id: props.user.id,
          intervention_id: props.intervention_id
      }
      }).then(resp => {
        if (resp.t1_questionnaire) {
          setQuestionnaireDefined(true)
          if (resp.invite_set) {
            setInvited(true)
            setDateTimeInvite(resp.date_time_invite)
            setDateTimeReminder1(resp.date_time_reminder1)
            setDateTimeReminder2(resp.date_time_reminder2)
            if (resp.finished) {
              setQuestionnaireFinished(resp.finished)
            }
          }
        }
      })
  }

  const setT1Invite = () => {
    if (window.confirm(t("Weet u zeker dat u deze student een uitnodiging voor de T1 vragenlijst wil sturen?"))) {
      apiCall({
        action: "set_invite_T1_begeleid",
        token: auth.token,
        data: {
            student_id: props.user.id,
            intervention_id: props.intervention_id
        }
        }).then(resp => {
          if (resp.invite_set) {
            checkT1Invite()
          }
        })
    }
  }

  return (
    <>
    {
      questionnaireDefined ?
        <div className="anotherHolder">
          {
            invited ?
              questionnaireFinished ?
                <div>
                  {t("Deelnemer heeft T1 afgerond op") + " " + questionnaireFinished }
                </div> 
                :
                <div>
                  {t("Uitnodiging T1 verzonden op") + " " + dateTimeInvite}
                  {
                    dateTimeReminder1.length > 0 ? 
                      <>
                        <br />
                        {t("1e reminder verzonden op") + " " + dateTimeReminder1}
                      </> : <></>
                  } 
                  {
                    dateTimeReminder2.length > 0 ? 
                      <>
                        <br />
                        {t("2e reminder verzonden op") + " " + dateTimeReminder2}
                      </> : <></>
                  } 
                </div>
            :
              <div>
                <span className="btn btn-primary" onClick={e => setT1Invite()}>
                  {t("Verzend uitnodiging T1")}
                </span>
              </div>
          }
        </div> : <></>
    }
    </>
  )
}

export default InviteT2StudentBegeleiding