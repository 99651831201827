import React, { useState, useEffect } from "react";
import t from "../../../translate";
import apiCall from "../../../api";
import { useSelector } from "react-redux";
import { GetDate, GetDay, GetTommorow, GetNight } from "../../../helpers/convertTimestamp.js";
import { getTimeStamp, minutesToHours } from "../../../helpers/changeFormatDate.js";
import { getStartDateWeek, getEndDateWeek } from "../../../helpers/time.js";
import NightDetails from "./this_week/night_details.js";
import BarChartThisWeek from "./this_week/bar_chart.js";
///import LineChartWeeks from "./weeks/line_chart_weeks.js";
import BarChartSleepEfficiency from "./weeks/bar_chart_sleepefficiency.js";
import BarChartSleepWeek from "./weeks/bar_chart_sleep.js";
import BarChartAwakeWeek from "./weeks/bar_chart_awake.js";
import ReactTooltip from 'react-tooltip'

const SleepDiaryWeeks = (props) => {

  const maxMinutes = 10080/2;

  const auth = useSelector(state => state.auth);
  const activeIntervention = useSelector(state => state.activeIntervention);

  const [entriesPerWeek, setEntriesPerWeek] = useState({})
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    /// get chosen values from server and set in chosenvalues en dates
    if (activeIntervention > 0 && auth.userType == "student") {
      apiCall({
        action: "get_sleep_diary",
        token: auth.token,
        data: {
          intervention_id: parseInt(activeIntervention)
        }
      }).then(resp => {
        setEntriesPerWeek(resp.content_per_week)
        setLoaded(true)
      });
    } else if(props.entriesPerWeek){
      setEntriesPerWeek(props.entriesPerWeek)
      setLoaded(true)
    }
  }, [props.entriesPerWeek]);

  const getAverageAwakeTimeInBed= (entries) => {
    let awakeTimeInBed = 0;

    for(let i = 0 ; i <= entries.length - 1 ; i++){
      awakeTimeInBed = (entries[i].content.awake_quarters * 15) + (entries[i].content.out_of_bed_quarters * 15) + awakeTimeInBed
    }

    //return minutesToHours(awakeTimeInBed/entries.length);
    if(awakeTimeInBed >= 0){
      return Math.round(awakeTimeInBed / entries.length);
    } else {
      return 0;
    }

  }
  const getAverageSleepTime= (entries, return_type) => {
    let sleepTime = 0;

    for(let i = 0 ; i <= entries.length - 1 ; i++){
      sleepTime = (entries[i].content.sleeping_quarters * 15) + sleepTime
    }
    if(return_type == "hours"){
      return minutesToHours(sleepTime/entries.length);
    } else {
      return sleepTime/entries.length;
    }

  }

  const getLabelsWeek= (entries) => {
    let tempLabels = [];

    for(let i = 0 ; i <= entries.length - 1 ; i++){
      tempLabels.push(GetNight(getTimeStamp(entries[i].date_sleep)))
    }

    return tempLabels.reverse();
  }
  const getValuesWeek= (entries) => {
    let tempValues = [];

    for(let i = 0 ; i <= entries.length - 1 ; i++){
      tempValues.push(Math.round(entries[i].content.sleep_efficiency))
    }
    return tempValues.reverse();
  }
  const getSleepEfficiencyWeek= (entries) => {
    //if(entries.length > 3){
    if(true){
      let total_entries = 0;
      let total_score = 0;

      for(let i = 0 ; i < entries.length ; i++){
        total_score = entries[i].content.sleep_efficiency + total_score;
        total_entries++;
      }

      return <span className={props.getEfficiencyClass(Math.round(total_score/total_entries))} data-tip={t("Slaapefficiëntie")}>{(Math.round(total_score/total_entries))}%</span>
    }
  }

  const [showDetails, setShowDetails] = useState([])

  const openDetailsWeek= (week) => {
    let tempShowDetails = [...showDetails];
    if(tempShowDetails.includes(week)){
      const index = tempShowDetails.indexOf(week);
      if (index > -1) {
        tempShowDetails.splice(index, 1);
      }
    } else {
      tempShowDetails.push(week)
    }
    setShowDetails(tempShowDetails)
  }

  return (
    <div className="weeks">
      <h1>{t("Alle weken")}</h1>
      <div className="intro">
        {t("Hier zie je een overzicht van alle weken.")}
      </div>
      <BarChartSleepEfficiency entriesPerWeek={entriesPerWeek}/>
      <BarChartSleepWeek entriesPerWeek={entriesPerWeek}/>
      <BarChartAwakeWeek entriesPerWeek={entriesPerWeek}/>
      {Object.keys(entriesPerWeek).length == 0 && loaded ?
        <div className="no_nights">
          {t("Nog geen nachten opgegeven")}
        </div>
        :false}
      {Object.keys(entriesPerWeek).reverse().map(function(key, index) {
        return(
          <div className="box week" key={index}>
            <table className="top_table">
            <tbody>
              <tr className="to_block">
                <td className="to_block">

                  <div className='number'>{t("Week")} {Object.keys(entriesPerWeek).length - index } {/*key.substring(4,6)*/}</div>
                  {getStartDateWeek(key.substring(4,6), entriesPerWeek[key][0].date_sleep.split("-")[0])} {t("tot")}  {getEndDateWeek(key.substring(4,6), entriesPerWeek[key][0].date_sleep.split("-")[0])}
                  <br/><br/>
                </td>
                <td className="to_block">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="nr_nights" data-tip={t("Aantal nachten")}>
                            <i className="fas fa-moon" ></i>
                            {entriesPerWeek[key].length}
                          </div>
                        </td>
                        <td>
                        {getSleepEfficiencyWeek(entriesPerWeek[key])}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            </table>
            <table>
            <tbody>
              <tr>
                <td>
                  <b>{t("Gemiddeld aantal minuten wakker per nacht")}</b><br/> {getAverageAwakeTimeInBed(entriesPerWeek[key])} {t("minuten")}
                  {/*<div className="awakeTime" style={{width:((getAverageAwakeTimeInBed(entriesPerWeek[key])/maxMinutes) * 100) + "%"}}></div>*/}
                  <br/><br/>
                  <b>{t("Gemiddelde slaaptijd")}</b><br/>
                  {getAverageSleepTime(entriesPerWeek[key], 'hours')}
                  {/*<div className="sleepTime" style={{width:((getAverageSleepTime(entriesPerWeek[key], 'minutes')/maxMinutes) * 100) + "%"}}></div>*/}
                </td>
                <td className="chevron">
                  <i className={"fas fa-chevron-up" + (showDetails.indexOf(key) >= 0  ? ' open':'')} onClick={()=>openDetailsWeek(key)}></i>
                </td>
              </tr>
            </tbody>
            </table>
            <ReactTooltip place="top" effect="solid" delayShow={200} className="menu_tooltip"  />
            {showDetails.indexOf(key) >= 0 ?
              <>
              <BarChartThisWeek labels={getLabelsWeek(entriesPerWeek[key])} values={getValuesWeek(entriesPerWeek[key])} />
              {props.coach ?
                <div className="nights">
                  {entriesPerWeek[key].map((entrie, indexx) =>
                    <NightDetails
                      key={indexx}
                      index={indexx}
                      entrie={entrie}
                      getEfficiencyClass={props.getEfficiencyClass}
                      class=""
                    />
                  )}
                </div>
                :false}
              </>

              :false}
          </div>
        )
      })}
    </div>
  );
};

export default SleepDiaryWeeks;
