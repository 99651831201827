export const increment = () => {
  return {
    type: "INCREMENT"
  };
};

export const decrement = () => {
  return {
    type: "DECREMENT"
  };
};

export const setAuthenticatedFalse = () => {
  return {
    type: "SET_AUTHENTICATED_FALSE"
  };
};
export const setAuthenticatedNull = () => {
  return {
    type: "SET_AUTHENTICATED_NULL"
  };
};

export const login = (user_id, dtc, name, token, userType, rights, preferences, t0_scores, t1_scores, rewards, profile_pic, email, private_email, gender,  education, firstname, insertion, lastname, organisation_id, oldRegistrationFlow, specialAccountType, doT0, onWaitingListRct) => {
  return {
    type: "LOGIN",
    payload: {
      user_id,
      dtc,
      name,
      token,
      userType,
      rights,
      preferences,
      t0_scores,
      t1_scores,
      rewards,
      profile_pic,
      email,
      private_email,
      gender,
      education,
      firstname,
      insertion,
      lastname,
      organisation_id,
      oldRegistrationFlow,
      specialAccountType,
      doT0,
      onWaitingListRct
    }
  };
};

export const set_user_preferences = (preferences) => {
  return {
    type: "SET_USER_PREFERENCES",
    payload: {
      preferences
    }
  }
}

export const user_reset = () => {
  return {
    type: "USER_RESET"
  };
};

export const user_set = user => {
  return {
    type: "USER_SET",
    payload: {
      user
    }
  };
};

export const setIntervention = (
  intervention_id,
  organisation_id,
  title,
  settings
) => {
  return {
    type: "SET_INTERVENTION",
    payload: {
      intervention_id,
      organisation_id,
      title,
      settings
    }
  };
};

export const resetIntervention = () => {
  return {
    type: "RESET_INTERVENTION",
  }
}

export const setQuestionnaire = (
  questionnaire_id,
  lessons
) => {
  return {
    type: "SET_QUESTIONNAIRE",
    payload: {
      questionnaire_id,
      lessons
    }
  };
};

export const setAnswersLessons = (
  intervention_id,
  answers,
) => {
  return {
    type: "SET_ANSWERS_LESSONS",
    payload: {
      intervention_id,
      answers
    }
  };
};

export const setNewBadges = (
  intervention_id,
  badges,
) => {
  return {
    type: "SET_NEW_BADGES",
    payload: {
      intervention_id,
      badges
    }
  };
};

export const setPageHistory = (
  intervention_id,
  pageHistory,
) => {
  return {
    type: "SET_PAGE_HISTORY",
    payload: {
      intervention_id,
      pageHistory
    }
  };
};

export const setActiveIntervention = (intervention_id) => {
  return {
    type: "SET_ACTIVE_INTERVENTION",
    payload: {
      intervention_id
    }
  };
};

export const setActiveLesson = (lesson_id) => {
  return {
    type: "SET_ACTIVE_LESSON",
    payload: {
      lesson_id
    }
  };
};

export const setActiveSubLesson = (sub_lesson_id) => {
  return {
    type: "SET_ACTIVE_SUBLESSON",
    payload: {
      sub_lesson_id
    }
  };
};

export const setPreviousLessons = (previous_lessons) => {
  return {
    type: "SET_PREVIOUS_LESSONS",
    payload: {
      previous_lessons
    }
  };
};

export const setActivePart = (part) => {
  return {
    type: "SET_ACTIVE_PART",
    payload: {
      part
    }
  };
};

export const setActivePage = (page_id) => {
  return {
    type: "SET_ACTIVE_PAGE",
    payload: {
      page_id
    }
  };
};

export const setActiveGoal = (goal_id) => {
  return {
    type: "SET_ACTIVE_GOAL",
    payload: {
      goal_id
    }
  };
};
export const setFinishedCourse = (finished) => {
  return {
    type: "SET_COURSE_FINISHED",
    payload: {
      finished
    }
  };
};
export const setShowLeftMenu = (show) => {
  return {
    type: "SET_SHOW_LEFT_MENU",
    payload: {
      show
    }
  };
};

export const setChosenImage = (chosen_image, index) => {
  return {
    type: "SET_CHOSEN_IMAGE",
    payload: {
      chosen_image,
      index
    }
  };
};

export const setChosenBadge = (chosen_image, index) => {
  return {
    type: "SET_CHOSEN_BADGE",
    payload: {
      chosen_image,
      index
    }
  };
};

export const setActiveLanguage = (language_id) => {
  return {
    type: "SET_ACTIVE_LANGUAGE",
    payload: {
      language_id
    }
  };
};

export const setTranslation = (translation) => {
  return {
    type: "SET_TRANSLATION",
    payload: {
      translation
    }
  };
};

export const setActiveTranslationTab = (translation_tab) => {
  return {
    type: "SET_ACTIVE_TRANSLATION_TAB",
    payload: {
      translation_tab
    }
  };
};

export const setUiTranslation = (
  language_id,
  translation
) => {
  return {
    type: "SET_UI_TRANSLATION",
    payload: {
      language_id,
      translation
    }
  };
};

export const setSleepDiary = (content) => {
  return {
    type: "SET_SLEEP_DIARY",
    payload: {
      content
    }
  };
};
