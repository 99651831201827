import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import apiCall from "../../api";
import EmailContent from "./email_content.js";
import Pagination from "../../helpers/pagination.js";
import t from "../../translate";
import parse from 'html-react-parser';

const Emails = props => {

  const [emails, setEmails] = useState(false);
  const [emailsToShow, setEmailsToShow] = useState([]);
  const [message, setMessage] = useState('test');

  const auth = useSelector(state => state.auth);

  const [paginationFrom, setPaginationFrom] = useState(0)
  const [paginationStep, setPaginationStep] = useState(20)

  useEffect(() => {
     if(emails){
       setEmailsToShow(emails.slice(paginationFrom, (paginationStep + paginationFrom)))
     }
   }, [paginationFrom, emails])

  useEffect(() => {
    if(props.studentId){
      apiCall({
        action: "get_student_emails",
        token: auth.token,
        data: {
          user_id:props.studentId
        }
      }).then(resp => {
        setEmails(resp.emails);
      });
    }
  }, [props.studentId])

  const openClose = (email_id) => {
    if (document.getElementById("body_"+email_id).classList.contains("hidden"))
    {
      document.getElementById("body_"+email_id).classList.remove("hidden");
      document.getElementById("body_"+email_id).removeAttribute('href');
    }
    else
    {
      document.getElementById("body_"+email_id).classList.add("hidden");
    }
  }

  return(
    <div className="emails">
    {emails ?
      <>
      {emails.length > 0 ?
        <>
        <table className="table table-striped">
          <thead>
          <tr>
          <th>
            {t("#")}
          </th>
          <th>
            {t("Verzonden op")}
          </th>
          <th>
            {t("Onderwerp")}
          </th>
          </tr>
          </thead>
          <tbody>
            {
              emailsToShow.map(email => {
                return (
                  <>
                  <tr key={email.id} onClick={() => openClose(email.id)} className="pointer">
                    <td>
                      {email.id}
                    </td>
                    <td>
                      {moment.unix(email.date_time_sent).format("DD-MM-YYYY HH:mm:ss", { trim: false })}
                    </td>
                    <td>
                      {'  '  + email.subject}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <div className="emailHeader hidden" id={'body_'+email.id}>
                        <EmailContent email={email}/>
                      </div>
                    </td>
                  </tr>
                  </>
                )
              })
            }
          </tbody>
        </table>
        {emails.length > paginationStep ?
            <Pagination
                nrItems={emails.length}
                paginationFrom={paginationFrom}
                setPaginationFrom={setPaginationFrom}
                paginationStep={paginationStep}
            />
            :false}
        </>
        :t("Geen mails verzonden")}
      </>
      :<i className="fas fa-spinner fa-spin"></i>}
    </div>
  )

}

export default Emails;
