import React, { useState, useEffect } from "react";
import t from "../../translate";
import CoachRegistrationData from "./registration_data.js";
import DeleteCoach from "./delete.js";
import CoachImage from "./image.js";
import TalenCoach from "./talen.js";
import ChangePassword from "./change_password.js";
import Interventions from "./interventions.js";
import StudentsAll from "./students_all.js";
import { useSelector, useDispatch } from "react-redux";
import standardAvatar from "../../../images/course/standard/avatar.png";
import apiCall from "../../api";

const CoachDetails = (props) => {

  const [activeTab, setActiveTab] = useState(1);
  const [coach, setCoach] = useState(false);

  const auth = useSelector(state => state.auth);
  const url = useSelector(state => state.url);

  useEffect(() => {
    if (props.coach) {
      props.coach.password = '';
      props.coach.password_check = '';
      setCoach(props.coach)
      setProfileImage(props.coach.profile_pic);
    }
  }, []);

  const [profileImage, setProfileImage] = useState('');

  //////////////////////
  ///Upload image to server
  const uploadImage = (uploadedFiles) => {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_coach_image",
        token: auth.token,
        data: {
          user_id: coach.id,
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error == 0){
            setProfileImage(resp.image);
          }
        }
      });
    });
  }

    //////////////////////
  ///Delete image from server
  const deleteImage = (e, name) => {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_coach_image",
      token: auth.token,
      data:{
        user_id: coach.id
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error == 0) {
          setProfileImage('');
        }
      }
    });
  }

  let inputFileRef = React.createRef();
  const triggerInputFile = () => {
    inputFileRef.current.click();
  }

  return(
    <div className="content">

      <i class="fa fa-times close" onClick={()=>props.hide(props.coach.id)}></i>
      <div className="flex">
        <CoachImage coach={coach}/>
        <div className="right">
        <div className="tabs">
          <div className={"tab" + (activeTab == 1 ? ' active':'')} onClick={()=>setActiveTab(1)}>
            {t("Interventies")}
          </div>
          <div className={"tab" + (activeTab == 5 ? ' active':'')} onClick={()=>setActiveTab(5)}>
            {t("Deelnemers")}
          </div>
          <div className={"tab" + (activeTab == 2 ? ' active':'')} onClick={()=>setActiveTab(2)}>
            {t("Personalia")}
          </div>
          <div className={"tab" + (activeTab == 6 ? ' active':'')} onClick={()=>setActiveTab(6)}>
            {t("Talen")}
          </div>
          <div className={"tab" + (activeTab == 3 ? ' active':'')} onClick={()=>setActiveTab(3)}>
            {t("Wachtwoord")}
          </div>
          {auth.user_id != coach.id?
            <div className={"tab" + (activeTab == 4 ? ' active':'')} onClick={()=>setActiveTab(4)}>
              {t("Verwijderen")}
            </div>
            :false}
        </div>
        <div className="tabContent">
          {activeTab == 1 ?
            <Interventions coach={coach} interventions={props.interventions} state={props.state} setState={props.setState}/>
            :false}
          {activeTab == 2 ?
            <CoachRegistrationData coach={coach} setCoach={setCoach} setState={props.setState} state={props.state} setActiveTab={setActiveTab} />
            :false}
          {activeTab == 3 ?
            <ChangePassword coach={coach}/>
            :false}
          {activeTab == 4 ?
            <DeleteCoach coach={coach} setCoach={setCoach} setState={props.setState} state={props.state} />
            :false}
          {activeTab == 5 ?
            <StudentsAll coach={coach} interventions={props.interventions} paid={false}/>
            :false}
          {activeTab == 6 ?
            <TalenCoach coach={coach} setCoach={setCoach} />
            :false}
        </div>
        </div>
      </div>
      {
          /*
          <div className="align_right">
            <span class="btn btn-secondary" onClick={()=>props.hide(props.coach.id)}>{t("Sluiten")}</span>
          </div>
          */
      }
    </div>
  )
}
export default CoachDetails;
