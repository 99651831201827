const text_45 = {
  intervention: 45,
  description_short: "Leer allerlei lifeskills om je mentale welzijn te versterken.",
  step_2_title: "Dus je wilt je mentale welzijn sterk houden? Je bent aan het juiste adres!",
  description_part1: "LifeHack is een zelfhulpprogramma waarin je diverse lifeskills leert voor allerlei situaties die je tegen zult komen in het leven. Dit programma is gebaseerd op Cognitieve Gedragstherapie en bestaat uit leesmateriaal en interactieve oefeningen. Er komen vier domeinen aan bod: je emotionele welzijn, de studie, je sociale leven en je eigenwaarde. Binnen elk domein zijn meerdere optionele onderwerpen beschikbaar en kun je zelf kiezen welke je wilt doen en welke niet.",
  description_part2: "We raden aan om één domein per week af te ronden. Het programma duurt daarmee ongeveer 4 tot 5 weken.",
  description_part3: "Tijdens het programma krijg je ondersteuning van een online coach (geheel gratis) die tekstuele feedback geeft op de interactieve opdrachten en je algehele voortgang.",
}

export {text_45};
