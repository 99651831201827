import React, { useState, useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import apiCall from "../../api";
import t from '../../translate';
import { nationalitieslist } from '../../utils';
import NotificationBox from "../../alert/notification";
import ReactTooltip from 'react-tooltip';
import thumbsup from '../../../images/thumbsup.png';

const Step5 = ( { registrationTexts, registrationData, setRegistrationData } ) => {


  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const errorMessages = [
    {
      nl: 'Zowel je voor als achternaam zijn verplichte velden.',
      eng: 'Both your first and last name are required fields.',
    },
    {
      nl: 'Gebruikersnaam is een verplicht veld, deze ga je gebruiken om in te loggen op het programma.',
      eng: 'Username is a required field, you will use it to log in to the program.',
    },
    {
      nl: 'De door jouw gekozen gebruikersnaam is helaas al in gebruik, kies een andere.',
      eng: 'The username you have chosen is unfortunately already in use, choose another one.',
    },
    {
      nl: 'Wachtwoord is een verplicht veld, deze ga je gebruiken om in te loggen op het programma.',
      eng: 'Password is a required field, you will use it to log in to the program.',
    },
    {
      nl: 'Het opgegeven wachtwoord en wachtwoord ter controle komen niet overeen.',
      eng: 'The specified password and password for verification do not match.',
    },
    {
      nl: 'Het opgegeven wachtwoord is niet sterk genoeg. Combineer kleine en hoofdletters met cijfers en tekens.',
      eng: 'The specified password is not strong enough. Combine upper and lower case letters with numbers and signs.',
    }
    ,
    {
      nl: 'Geef aan of je je persoonlijke gegevens wilt delen met je coach of dat je anoniem wilt deelnemen.',
      eng: 'Indicate if you want to share your personal data with your coach or if you want to participate anonymously.',
    }
    ,
    {
      nl: 'Geef een geldig telefoonnummer op.',
      eng: 'Please enter a valid phone number.',
    }
  ]

  const [notificationOptions, setNotificationOptions] = useState({
    show: false,
    text: "",
    confirmText: t("Ok"),
  });

  const [accountData, setAccountData] = useState({
    firstname: '',
    insertion: '',
    lastname: '',
    gender: '',
    anonymous: '',
    student_email: '',
    private_email: '',
    age: '',
    nationality: '',
    password: '',
    password_check: '',
    phonenumber: '', 
    university: '',
    faculty: '',
    learnedabout: '',
    learnedaboutOther: ''
  })

  const [nationalities, setNationalities] = useState([]);

  const [showRegistrationFinished, setShowRegistrationFinished] = useState(false);
  const [languageCode, setLanguageCode] = useState('nl')
  const [steps, setSteps] = useState('stap 3 van 3');

  let finishRegistrationActivated = false;
  
  const finishRegistration = () => {
    //dubbelklikken voorkomen
    if (!finishRegistrationActivated) {
      finishRegistrationActivated = true;
    
      apiCall({
        action: "save_registration",
        token: cookies.registrationToken,
        data: {...registrationData, ...accountData}
      }).then(resp => {
        if (resp.save_registration) {
          setShowRegistrationFinished(true);
          removeCookie("registration", { path: '/registration' });
          removeCookie("registrationToken", { path: '/registration' });
        } else {
          setNotificationOptions({
            show: true,
            text: resp.msg,
            confirmText: t("Ok"),
          });
        }
      })
    }
  }

  useEffect(() => {
    if (registrationData.language_id === 1) {
      setLanguageCode('nl')
    } else {
      setLanguageCode('eng')
    }

    if (registrationData.intervention_id === 45 || registrationData.intervention_id === 47) {
      setSteps('stap 4 van 4');
    }
    
    let newAccountData = {...accountData}
    newAccountData.student_email = registrationData.email
    if (typeof registrationData.gender !== 'undefined') {
      newAccountData.gender = registrationData.gender;
    }
    if (typeof registrationData.age !== 'undefined') {
      newAccountData.age = registrationData.age;
    }
    if (typeof registrationData.faculty !== 'undefined') {
      newAccountData.faculty = registrationData.faculty;
    }
    if (typeof registrationData.nationality !== 'undefined') {
      switch (registrationData.nationality) {
        case 'European - Dutch':
          newAccountData.nationality = 0;
          break;
        case 'European - but not Dutch':
          newAccountData.nationality = 1;
          break;
        case 'North-American':
          newAccountData.nationality = 2;
          break;
        case 'South-American':
          newAccountData.nationality = 3;
          break;
        case 'Australian':
          newAccountData.nationality = 4;
          break;
        case 'Asian':
          newAccountData.nationality = 5;
          break;
        case 'African':
          newAccountData.nationality = 6;
      }
    }
    if (typeof registrationData.university !== 'undefined') {
      newAccountData.university = registrationData.university;
      let options = [];
      faculteiten.forEach(f => {
        if (f.name_nl === registrationData.university) {
          if (registrationData.language_id === 1) {
            options =  ["", ...f.faculteiten_nl];
          } else {
            options = ["", ...f.faculteiten_eng];
          }
        }
      })
      setFacultyOptions(options);

    }
    setAccountData(newAccountData)
    setNationalities(nationalitieslist[registrationData.language_id]);
  }, [registrationData])

  const onChange = (e) => {
    let newAccountData = {...accountData}
    newAccountData[e.target.name] = e.target.value
    setAccountData(newAccountData)
  }

  const onChangeUniversity = (e) => {
    //opties van faculty vullen?
    if (e.target.name === 'university') {
      if (e.target.value.length > 0)
      {
        let options = [];
        faculteiten.forEach(f => {
          if (f.name_nl === e.target.value) {
            if (registrationData.language_id === 1) {
              options =  ["", ...f.faculteiten_nl];
            } else {
              options = ["", ...f.faculteiten_eng];
            }
          }
        })
        setFacultyOptions(options);
      }
      else
      {
        setFacultyOptions([]);
      }
    }
    let newAccountData = {...accountData}
    newAccountData.university = e.target.value
    setAccountData(newAccountData)
  }

  const faculteiten = [
    {
      name_nl: 'Vrije Universiteit Amsterdam',
      name_eng: 'Vrije Universiteit Amsterdam',
      faculteiten_nl: ["Bètawetenschappen","Geesteswetenschappen", "Gedrags- en Bewegingswetenschappen", "Religie en Theologie", "Rechtsgeleerdheid", "Sociale Wetenschappen", "Tandheelkunde - ACTA", "Geneeskunde - VUmc School of Medical Sciences", "School of Business and Economics"],
      faculteiten_eng: ["Science","Humanities","Behavioural and Movement Sciences","Religion and Theology","Law","Social Sciences","Dentistry","Medical Sciences","School of Business and Economics"]
    },
    {
      name_nl: 'Universiteit Leiden',
      name_eng: 'Leiden University',
      faculteiten_nl: ["Archeologie","Geesteswetenschappen","Geneeskunde/LUMC","Governance and Global Affairs","Rechtsgeleerdheid","Sociale Wetenschappen","Wiskunde en Natuurwetenschappen"],
      faculteiten_eng: ["Archaeology","Humanities","Medicine","Governance and Global Affairs","Law","Social and Behavioural Sciences","Science"]

    }
    ,
    {
      name_nl: 'Universiteit Utrecht',
      name_eng: 'Utrecht University',
      faculteiten_nl: ["Bètawetenschappen","Diergeneeskunde","Geesteswetenschappen","Geneeskunde","Geowetenschappen","Recht, Economie, Bestuur en Organisatie","Sociale Wetenschappen"],
      faculteiten_eng: ["Science","Veterinary Medicine","Humanities","Medicine","Geosciences","Law, Economics and Governance","Social and Behavioural Sciences"]
    }
    ,
    {
      name_nl: 'De Universiteit van Maastricht',
      name_eng: 'Maastricht University',
      faculteiten_nl: ["Cultuur- en Maatschappijwetenschappen","Health, Medicine and Life Sciences","Science and Engineering","Rechtsgeleerdheid","Psychology and Neuroscience","School of Business and Economics"],
      faculteiten_eng: ["Arts and Social Sciences","Health, Medicine and Life Sciences","Science and Engineering","Law","Psychology and Neuroscience","School of Business and Economics"]
    }
    ,
    {
      name_nl: 'Erasmus Universiteit Rotterdam',
      name_eng: 'Erasmus University Rotterdam',
      faculteiten_nl: ["Erasmus School of Economics", "Erasmus School of Law", "Erasmus School of History, Culture and Communication", "Erasmus School of Social and Behavioural Sciences", "Erasmus School of Philosophy", "Erasmus Medical Centre", "Rotterdam School of Management", "International Institute of Social Studies", "Erasmus School of Health Policy & Management", "Erasmus University College", "Institute for Housing and Urban Development Studies"],
      faculteiten_eng: ["Erasmus School of Economics", "Erasmus School of Law", "Erasmus School of History, Culture and Communication", "Erasmus School of Social and Behavioural Sciences", "Erasmus School of Philosophy", "Erasmus Medical Centre", "Rotterdam School of Management", "International Institute of Social Studies", "Erasmus School of Health Policy & Management", "Erasmus University College", "Institute for Housing and Urban Development Studies"]
    },
    {
      name_nl: 'Inholland Hogeschool',
      name_eng: 'Inholland University of Applied Sciences',
      faculteiten_nl: ["Business, Finance & Law", "Creative Business", "Agri, Food & Life Sciences", "Onderwijs & Innovatie", "Techniek, Ontwerpen en Informatica", "Gezondheid, Sport en Welzijn"],
      faculteiten_eng: ["Business, Finance & Law", "Creative Business", "Agri, Food & Life Sciences", "Education & Innovation", "Engineering, Design and Computing", "Health, Sports & Social Work"]
    },
    {
      name_nl: 'Universiteit van Amsterdam',
      name_eng: 'University of Amsterdam',
      faculteiten_nl: ["Economie en Bedrijfskunde", "Faculteit der Geesteswetenschappen", "Faculteit der Geneeskunde", "Faculteit der Maatschappij en Gedragswetenschappen", "Faculteit der Natuurwetenschappen, Wiskunde en Informatica", "Faculteit der Rechtsgeleerdheid", "Faculteit der Tandheelkunde"],
      faculteiten_eng: ["Economics and Business", "Faculty of Humanity", "Faculty of Medicine", "Faculty of Social and Behavioral Sciences", "Faculty of Science", "Amsterdam Law School", "Faculty of Dentistry"]
    },
    {
      name_nl: 'Avans Hogeschool',
      name_eng: 'Avans University of Applied Sciences',
      faculteiten_nl: ["Academie voor Management en Finance (AMF)", "Academie voor Business en Entrepreneurship (ABE)", "Academie voor Welzijn Educatie en Gezondheid (AWEG)", "Academie voor Technologie en Innovatie x (ATIx)", "Academie voor Duurzaam Gebouwde Omgeving (ADGO)", "Academie voor Management Bestuur en Finance (AMBF)", "Academie voor Waardecreatie en Ondernemerschap (AWO)", "Academie voor Technologie en Design (ATD)", "Academie voor Welzijn en Gezondheid (AWG)", "Avans Creative Innovation (ACI)", "Academie voor Life Sciences en Technologie (ALST)"],
      faculteiten_eng: ["School for Management and Finance (AMF)", "School of Business and Entrepreneurship (ABE)", "School of Social Work, Eduction and Health (AWEG)", "School of Technology and Innovation x (ATIx)", "School for the Sustainable Built Environment (ADGO)", "School of Management, Public Administration and Finance (AMBF)", "School of Value Creation and Entrepeneurship (AWO)", "School of Technology and Design (ATD)", "School of Social Work and Health (AWG)", "Avans Creative Innovation (ACI)", "School of Life Sciences and Technology (ALST)"]
    },
    {
      name_nl: 'Hogeschool Rotterdam',
      name_eng: 'Rotterdam University of Applied Sciences',
      faculteiten_nl: ["Rotterdam Mainport Institute", "Instituut voor Communicatie, Media en Informatietechnologie", "Instituut voor Engineering en Applied Science", "Instituut voor de Gebouwde Omgeving", "Instituut voor Gezondheidszorg", "Rotterdam Business School", "Rotterdam Academy", "Hogeschool Rotterdam Business School", "Instituut voor Social Work", "Instituut voor Lerarenopleidingen", "Willem de Kooning Academie"],
      faculteiten_eng: ["Rotterdam Mainport Institute", "School of Communication, Media and Information Technology", "School of Engineering and Applied Science", "School of Build Environment", "School of Health Care Studies", "Rotterdam Business School", "Rotterdam Academy", "Hogeschool Rotterdam Business School", "School of Social Work", "School of Education (Teacher Training)", "Willem de Kooning Academy (Art)"]
    }
  ]
  const [facultyOptions, setFacultyOptions] = useState([]);



  function setStep(step) {
    let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
    registrationDataTemp.step = step
    setRegistrationData(registrationDataTemp)
  }

  return (
    <div className="step6">
      {
        //aanmelder voor RCT iSleep die ingedeeld is in controlegroep en dus geen coach heeft gekozen, kan niet terug
        //naar de vorige stap
        !(parseInt(registrationData.coachChosen) === 0 && parseInt(registrationData.intervention_id) === 47) ?
          <button type="button" className={showRegistrationFinished ? 'hidden' : 'btn prev'} onClick={() => setStep(4)}>{t("Terug")}</button> : <></>
      }
      <div className="container">
        <div className="step">
          {
            !showRegistrationFinished ?
              <><b>{t(steps)}</b> {t("account aanmaken")}</> 
              :
              <>{t("Aanmelding afgerond")}</>
          }
          
        </div>
        <div className="content">
          {
          !showRegistrationFinished ?
            <>
              <div className={showRegistrationFinished ? 'hidden' : ''}>
                <div className="intro">
                  <h1>{t("Laatste stap: over jou")}</h1>

                  <p>{t("Met deze informatie wordt een account voor je aangemaakt.")}</p>
                </div>
                <form>
                  <div className="form-row align-items-center">
                    <div className="col-auto col-40 form-label-group">

                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        aria-describedby="firstname"
                        placeholder=" "
                        value={accountData.firstname}
                        onChange={onChange}
                      />
                      <label htmlFor="firstname">{t("Voornaam")}</label>
                    </div>
                    <div className="col-auto col-20 form-label-group">
                      <input
                        type="text"
                        className="form-control"
                        id="insertion"
                        name="insertion"
                        aria-describedby="insertion"
                        placeholder=" "
                        value={accountData.insertion}
                        onChange={onChange}
                      />
                      <label htmlFor="insertion">{t("Tussenvoegsel")}</label>
                    </div>
                    <div className="col-auto col-40 form-label-group">
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        aria-describedby="lastname"
                        placeholder=" "
                        value={accountData.lastname}
                        onChange={onChange}
                      />
                      <label htmlFor="lastname">{t("Achternaam")}</label>
                    </div>
                  </div>
                  <div className="form-row align-items-center bigLabel">
                  <div className="col">
                    <input
                      type="radio"
                      className="form-control"
                      id="gender_f"
                      name="gender"
                      aria-describedby="gender"
                      value="F"
                      onChange={onChange}
                      checked={accountData.gender === 'F'}
                    /> <label htmlFor="gender_f">{t("Vrouw")}</label>
                    <input
                      type="radio"
                      className="form-control"
                      id="gender_m"
                      name="gender"
                      aria-describedby="gender"
                      value="M"
                      onChange={onChange}
                      checked={accountData.gender === 'M'}
                      /> <label htmlFor="gender_m">{t("Man")}</label>
                      <input
                      type="radio"
                      className="form-control"
                      id="gender_x"
                      name="gender"
                      aria-describedby="gender"
                      value="X"
                      onChange={onChange}
                      checked={accountData.gender === 'X'}
                      /> <label htmlFor="gender_x">{t("Anders")}</label>
                  </div>
                  <div className="col-50 form-label-group">
                      <input
                        type="text"
                        className="form-control"
                        id="phonenumber"
                        name="phonenumber"
                        aria-describedby="phonenumber"
                        placeholder=" "
                        value={accountData.phonenumber}
                        onChange={onChange}
                      />
                      <label htmlFor="login">{t("Telefoonnummer")}</label>
                    </div>
                </div>
                  <div className="form-row align-items-center ">
                    <div className="col-50 form-label-group">
                      <input
                        type="text"
                        className="form-control"
                        id="student_email"
                        name="student_email"
                        aria-describedby="student_email"
                        placeholder=" "
                        value={accountData.student_email}
                        onChange={onChange}
                        autoCorrect="off" 
                        autoCapitalize="none"
                      />
                      <label htmlFor="student_email">{t("Belangrijk: controleer je studenten-email")}</label>
                    </div>
                    <div className="col-50 form-label-group">
                        <i className="fas fa-info-circle" data-tip={registrationTexts.panel5.privateEmailTip[languageCode]} style={{position: 'absolute', top: '-4px', right: '2px'}}></i>
                        <ReactTooltip place="top" effect="solid" delayShow={200} />
                      <input
                        type="text"
                        className="form-control"
                        id="private_email"
                        name="private_email"
                        aria-describedby="private_email"
                        placeholder=" "
                        value={accountData.private_email}
                        onChange={onChange}
                        autoCorrect="off" 
                        autoCapitalize="none"
                      />
                      <label htmlFor="private_email">{t("Privé email (optioneel)")}</label>
                      
                    </div>
                    
                  </div>
                  <div className="form-row align-items-center" style={{marginTop: '-20px'}}>
                    <div className="col-auto col-50 form-group form-label-group">
                    
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      name="age"
                      aria-describedby="age"
                      value={accountData.age}
                      onChange={onChange}
                      min="16"
                      max="99"
                      step="1"
                      maxLength="2"
                      style={{marginTop: '24px'}}
                    />
                    <label htmlFor="age" style={{marginTop: '-12px'}}>{t("Leeftijd")}</label>
                    </div>
                    <div className="col-50 form-group">
                      <label htmlFor="nationality" style={{marginBottom: '-2px', marginLeft: '11px'}}>{t("Nationaliteit")}</label>
                      <select
                      id="nationality"
                      name="nationality"
                      value={accountData.nationality}
                      onChange={onChange}
                      className="form-control"
                      style={{paddingLeft: '5px'}}
                      >
                        <option value=""></option>
                        {
                          nationalities.map((n, index) => {
                            return (
                            <option key={index} value={index}>{n}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-row align-items-center" style={{marginTop: '-20px'}}>
                  
                    <div className="col-50 form-group">
                      <label htmlFor="university" style={{marginBottom: '-2px', marginLeft: '11px'}}>{t("Universiteit/Hogeschool")}</label>
                      <select
                      id="university"
                      name="university"
                      value={accountData.university}
                      onChange={onChangeUniversity}
                      className="form-control"
                      style={{paddingLeft: '5px'}}
                      >
                        <option value=""></option>
                        {
                          faculteiten.map((f, index) => {
                            return (
                            <option key={index} value={f.name_nl}>{(registrationData.language_id === 1) ? f.name_nl : f.name_eng}</option>
                            )
                          })
                        }
                      </select>
                    </div>

                    <div className="col-50 form-group">
                      <label htmlFor="faculty" style={{marginBottom: '-2px', marginLeft: '11px'}}>{t("Faculteit")}</label>
                      <select
                      id="faculty"
                      name="faculty"
                      value={accountData.faculty}
                      onChange={onChange}
                      className="form-control"
                      style={{paddingLeft: '5px'}}
                      >
                        {facultyOptions.length === 0 ?
                          <option value="">
                            {t("Selecteer eerst een universiteit/hogeschool")}
                          </option>
                          :''}
                        {
                          facultyOptions.map((f, index) => {
                            return (
                              <option key={index} value={f}>{f}</option>
                            )
                          })
                        }
                        {facultyOptions.length !== 0 ?
                          <option value="Ik weet het niet">
                            {t("Ik weet het niet")}
                          </option>
                          :''}
                      </select>
                    </div>
                  </div>
                  <div className="form-group select-group" style={{marginTop: '-20px', marginBottom: '30px'}}>
                    <label htmlFor="learnedabout" style={{marginBottom: '-2px', marginLeft: '11px'}}>{t("Hoe heb je over het MoodLift platform gehoord?")}</label>
                    <select
                    id="learnedabout"
                    name="learnedabout"
                    value={accountData.learnedabout}
                    onChange={onChange}
                    className="form-control"
                    style={{paddingLeft: '5px'}}
                    >
                      <option value=""></option>
                      <option value="Via the MoodLift survey">{t("Via de MoodLift survey")}</option>
                      <option value="Via student counselling">{t("Via studentbegeleiding (bijv. van een studentenpsycholoog, studieadviseur, studentendecaan, mentor of tutor) ")}</option>
                      <option value="Via a course">{t("Via een vak (bijv. tijdens college of een werkgroep)")}</option>
                      <option value="Via a workshop">{t("Via een workshop")}</option>
                      <option value="Via social media">{t("Via social media")}</option>
                      <option value="Via a fellow student">{t("Via een mede student")}</option>
                      <option value="other">{t("Anders")}</option>
                    </select>
                  </div>
                  {
                    accountData.learnedabout === 'other' ?
                      <><br />
                      <div className="form-group form-label-group">
                        <input
                          type="text"
                          className="form-control"
                          id="learnedaboutOther"
                          name="learnedaboutOther"
                          aria-describedby="learnedaboutOther"
                          placeholder={t("")}
                          value={accountData.learnedaboutOther}
                          onChange={onChange}
                        />
                        <label htmlFor="learnedaboutOther">{t("Op welke andere manier heb je over het MoodLift platform gehoord?")}</label>
                      </div></>
                      : <></>
                    }
                  {/* <div className="form-row align-items-center ">
                    <div className="col-50 form-label-group">

                    </div>
                    
                  </div>  */}             
                  <div className="form-row align-items-center">
                    <div className="col-50 form-label-group">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        aria-describedby="password"
                        placeholder=" "
                        value={accountData.password}
                        onChange={onChange}
                        autoCorrect="off" 
                        autoCapitalize="none"
                      />
                      <label htmlFor="password">{t("Wachtwoord")}</label>
                    </div>
                    <div className="col-50 form-label-group">
                      <input
                        type="password"
                        className="form-control"
                        id="password_check"
                        name="password_check"
                        aria-describedby="password_check"
                        placeholder=" "
                        value={accountData.password_check}
                        onChange={onChange}
                        autoCorrect="off" 
                        autoCapitalize="none"
                      />
                      <label htmlFor="password_check">{t("Wachtwoord ter controle")}</label>
                    </div>
                  </div>
                  
                  <div className="form-row align-items-center " style={{marginTop: '-20px'}}>
                    
                    <div className="col bigLabel" style={{width: 'calc(100% - 10px)', marginTop: '23px', border: '1px solid rgba(0, 0, 0, 0.3)',  borderRadius: '10px', height: '85px', marginLeft: '6px', flexBasis: 'auto', flexGrow: 'unset', padding: '10px'}}>
                    <div className="blokker">{t("Wil je dit programma anoniem volgen? (je coach kan dan je naam niet zien)")}</div>
                      <input
                          type="radio"
                          className="form-control"
                          id="anonymous_1"
                          name="anonymous"
                          aria-describedby="anonymous"
                          value="1"
                          onChange={onChange}
                          checked={accountData.anonymous === '1'}
                          /> <label htmlFor="anonymous_1">{t("Ja")}</label>
                      <input
                        type="radio"
                        className="form-control"
                        id="anonymous_0"
                        name="anonymous"
                        aria-describedby="anonymous"
                        value="0"
                        onChange={onChange}
                        checked={accountData.anonymous === '0'}
                      /> <label htmlFor="anonymous_0">{t("Nee")}</label>
                      
                        
                    </div>
                  </div>
                  <div className="button_holder">
                    <button type="button" className="btn btn-primary" onClick={() => finishRegistration()}>{t("Helemaal klaar!")}</button>
                  </div>

                </form>
              </div>
            </> 
            :
            <>
              <div style={{textAlign: 'center'}}>
                  <img src={thumbsup} style={{display: 'inline-block', width: '300px', maxWidth: '90%', marginBottom: '20px'}} />
                  <h1>{t("Gelukt! Je account is aangemaakt, je moet deze alleen nog activeren.")}</h1>
                  {t("Je ontvangt binnen enkele minuten een e-mail met je inloggegevens en een link om je account te activeren. Daarna kan je meteen starten met het programma!")}

              </div>
            </>
          }
        </div>
      </div>
      <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions} />
    </div>
  )

}

export default Step5;
