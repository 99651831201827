const text_47 = {
  intervention: 47,
  description_short: "Overwin je slapeloosheid en leer je biologische klok te herstellen.",
  step_2_title: "",
  description_part1: "",
  description_part2: "",
  description_part3: "",
}

export {text_47};
