import React, { useState, useEffect } from "react";
import t from "../../../../translate";
import { GetDate } from "../../../../helpers/convertTimestamp.js";
import parse from 'html-react-parser';
import SleepDiaryInfo from "../info.js";

const Title = (props) => {

  const weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];

  const getTitle = (activeStep) => {
    if(activeStep == 0){
      return "Slaap opgeven";
    }
    if(activeStep == 1){
      return "Heb je wakker gelegen?";
    }
    if(activeStep == 2){
      return "Heb je nog een dutje gedaan?";
    }
    if(activeStep == 3){
      return "Ben je nog buiten geweest?";
    }
    if(activeStep == 4){
      return "Schermgebruik voor slapen?";
    }
    if(activeStep == 5){
      return "Geef een cijfer";
    }
  }

  const getIntroText = (activeStep) => {
    if(activeStep == 3){
      return "Geef aan wanneer je naar buiten bent geweest op";
    } else if(activeStep == 4){
      return "Dit gaat over de avond van";
    } else if(activeStep == 1){
      return "Geef aan wanneer je wakker hebt gelegen in de nacht van";
    } else if(activeStep == 2){
      return "Geef aan wanneer je een dutje hebt gedaan op";
    } else {
      return "Dit gaat over de nacht van";
    }
  }
  const getExtraText = (activeStep) => {
    if(activeStep == 1){
      return "Geef hier aan wanneer je 's nachts wakker bent geweest. Je ook kunt kiezen of je wel of niet uit bed bent gegaan in de tijd dat je 's nachts wakker bent geweest. Selecteer de vakjes uit het tijdschema door te klikken. Eén vakje staat gelijk aan 15 minuten."
    } else if(activeStep == 2){
      return "Een dutje is de tijd die je overdag slaapt, of je nu in bed ligt of niet. Selecteer de vakjes uit het tijdschema door te klikken. Eén vakje staat gelijk aan 15 minuten.<br/><br/>Heb je geen dutje gedaan? Vul dan niets in en klik op 'Verder'."
    } else if(activeStep == 3){
      return "Geef aan wanneer je buiten het huis bent geweest. Dit zijn alle momenten wanneer je geen dak boven het hoofd hebt gehad. <br/><br/>Ben je niet buiten geweest? Vul dan niets in en klik op 'Verder'."
    } else {
      return ''
    }
  }

  const prevDate = () => {
    props.setActiveDateIndex(props.activeDateIndex + 1)
  }
  const nextDate = () => {
    props.setActiveDateIndex(props.activeDateIndex - 1)
  }

  return (
    <>
      <h1>{t(getTitle(props.activeStep))}</h1>
      <table className="choose_date">
      <tbody>
        <tr>
          <td>
            {props.datesToFill.length > 1 && props.activeStep == 0 ?
              <>
              {props.activeDateIndex < (props.datesToFill.length - props.activeDateIndex) ?
                <i className="fas fa-chevron-circle-left" onClick={()=>prevDate()}></i>
                :<></>}
              </>
              :false}
          </td>
          <td>
            <div className="intro">
              {t(getIntroText(props.activeStep))} <span>
                <b>{t(weekdays[props.dateSleep.getDay()])}</b> ({GetDate(Math.floor(props.dateSleep / 1000), "-")}) {props.activeStep != 4 && props.activeStep != 3 && props.activeStep != 2 ?
                  <>
                    {t("op")}
                  </>
                :false}
                {props.activeStep != 4 && props.activeStep != 3 && props.activeStep != 2 && props.wakeUpDay  ?
                  <>
                  <b> {t(weekdays[props.wakeUpDay.getDay()])}</b> ({GetDate(Math.floor(Date.parse(props.wakeUpDay) / 1000), "-")})
                  </>
                :false}
              </span>
            </div>
          </td>
          <td>
            {props.datesToFill.length > 1 && props.activeStep == 0 ?
              <>
              {props.activeDateIndex > 0 ?
                <i className="fas fa-chevron-circle-right" onClick={()=>nextDate()}></i>
                :<></>}
              </>
              :false}
          </td>
        </tr>
      </tbody>
      </table>
      {getExtraText(props.activeStep) != "" ?
        <div className="extra_text">
          {props.activeStep == 0 ?
            <SleepDiaryInfo/>
            :
            <>
              <b>{t("Uitleg")}:</b> {parse(t(getExtraText(props.activeStep)))}
              {props.activeStep == 1 ?
                <>
                  <br/>
                  <ul>
                    <li>{t("1 klik = Wakker in bed")}</li>
                    <li>{t("2 kliks = Wakker uit bed")}</li>
                    <li>{t("3 kliks = Slapen")}</li>
                  </ul>
                </>
                :false}
            </>
          }

        </div>
      :false}
    </>
  );
};

export default Title;
