import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import t from "../translate";

const ShowPoints = (props) =>{
  const [pointsToShow, setPointsToShow] = useState(false); ///place rewards to state
  const intervention = useSelector(state => state.intervention);

  /////////////////
  ////Check if reward data contains points to show
  useEffect(() => {
    if(typeof props !== "undefined" && typeof props.rewards !== "undefined" && props.rewards !== null && props.rewards.length > 0 && props.showData == false && props.showPoints == false){
      let this_rewards_obj = props.rewards.filter(function (reward) {
        return parseInt(reward.id) === parseInt(intervention.id)
      });
      if(this_rewards_obj.length != 0){
        if(typeof this_rewards_obj[0].points_to_show != "undefined" && this_rewards_obj[0].points_to_show.length > 0){
          setPointsToShow(this_rewards_obj[0].points_to_show[0].points);
          props.setShowPoints(true);

          setTimeout(function(){
            props.closeShownPoints()
            setPointsToShow(false)
          }, 3500)
        }

        /*
        if(this_rewards_obj[0].to_show.length > 0){
          ///if there are rewards to show show first
          props.setShowReward(this_rewards_obj[0].to_show)
        } else {
          ///no rewards to show hide and empty
          props.setShowData(false);
          props.setShowReward([]);
        }
        */
      }
    }
  }, [props.rewards, intervention]);

  return (
    <>
      {pointsToShow ?
        <div className="course_points slideDown">
          <i class="fas fa-plus"></i> {pointsToShow} {t("Punten")}
        </div>
        :
        <></>
      }



    </>
  )
}

export default ShowPoints;
