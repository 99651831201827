import React, {useState, useEffect} from "react";

let saveSettingsTimeout = null;

const BadgeInput = props => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    if(typeof props.value != "undefined"){
      setValue(props.value);
    }
  }, [props.value]);

  function change(action, id, i, times = false){

    setValue(times)

    clearTimeout(saveSettingsTimeout);

    //dispatch(setSavingStatus("not_saved"));

    saveSettingsTimeout = setTimeout(() => {
      props.changeTimes('set', id, i, times);
    }, 250);
    //props.changeTimes('set', item.id, i, e.target.value)
  }

  return(
    <>
      <input type="number" min="0" value={value} onChange={(e)=>change('set', props.id, props.index, e.target.value)}/>
    </>
  )
}

export default BadgeInput
