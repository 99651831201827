import React from 'react';
import AddImageAsBg from "../helpers/addImageAsBg";

const ImageColumns = (props) => {

  return(
    <div>
      {props.gridColumns > 0 && props.images.length > 3 ?
        <div className={"columns grid-"+props.gridColumns+" clearfix"}>
          <div className='column'>
            <AddImageAsBg image={props.images[0].url != '' ? props.images[0].url:''} showMediaLibrary={e => props.showMediaLibraryAndSetChosenImageIndex(0)} index={props.index} deleteImage={e => props.deleteImageAndSetChosenImageIndex(0)} />
          </div>
          <div className='column'>
            <AddImageAsBg image={props.images[1].url != '' ?props.images[1].url:''} showMediaLibrary={e => props.showMediaLibraryAndSetChosenImageIndex(1)} index={props.index} deleteImage={e => props.deleteImageAndSetChosenImageIndex(1)} />
          </div>
          {props.gridColumns > 2?
            <div className='column'>
              <AddImageAsBg image={props.images[2].url != '' ?props.images[2].url:''} showMediaLibrary={e => props.showMediaLibraryAndSetChosenImageIndex(2)} index={props.index} deleteImage={e =>  props.deleteImageAndSetChosenImageIndex(2)} />
            </div>
          :''}
          {props.gridColumns > 3?
            <div className='column'>
              <AddImageAsBg image={props.images[3].url != '' ?props.images[3].url:''} showMediaLibrary={e => props.showMediaLibraryAndSetChosenImageIndex(3)} index={props.index} deleteImage={e =>  props.deleteImageAndSetChosenImageIndex(3)} />
            </div>
          :''}
        </div>
      :''}
    </div>
  )
}

export default ImageColumns;
