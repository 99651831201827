import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setIntervention } from "../../../actions";
import InterventionSettingsList from "./list.js";
import t from "../../translate";
import { getClone } from "../../utils";
import ContentEditable from 'react-contenteditable';

let saveSettingsTimeout = null;

const InterventionSettingsLessons = props => {

  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);

  useEffect(() => { 

    //if (intervention.id > 0 && typeof intervention.settings.selfhelp.alternative_menu_seq !== "undefined" && intervention.settings.selfhelp.alternative_menu_seq === "ssi") {
      //betreft een ssi interventie...
      //Zijn er al teksten gedefinieerd?
      if (intervention.id > 0 && typeof intervention.settings.selfhelp.ssi_texts === "undefined") {
        //nee, dus even aanmaken...
        let interventionC = getClone(intervention);
        interventionC.settings.selfhelp.ssi_texts = {
          start: "",
          intro_modal: "",
          heading_category: "",
          heading_lesson: "",
          finish_program: "",
        }
        saveThemSettings(interventionC.settings);
      }
    //}

  }, [intervention, intervention.settings.selfhelp.alternative_menu_seq]);

  const onChangeAlternativeMenu = (e) => {

      let interventionC = getClone(intervention);
      if (interventionC.settings.selfhelp.alternative_menu !== 'undefined')
      {
        interventionC.settings.selfhelp.alternative_menu = !interventionC.settings.selfhelp.alternative_menu;
      }
      else
      {
        interventionC.settings.selfhelp.alternative_menu = true;
      }
      saveThemSettings(interventionC.settings);
  }

  const onChangeAlternativeMenuSeq = (e) => {

    let interventionC = getClone(intervention);
    if (typeof interventionC.settings.selfhelp.alternative_menu_seq !== "undefined" && interventionC.settings.selfhelp.alternative_menu_seq.toString() === e.target.value) {
      interventionC.settings.selfhelp.alternative_menu_seq = false;
    } else {
      if (e.target.value === "true") {
        interventionC.settings.selfhelp.alternative_menu_seq = true;
      } else {
        interventionC.settings.selfhelp.alternative_menu_seq = e.target.value;
      }
    }
    saveThemSettings(interventionC.settings);
  }

  const onChangeAlternativeMenuFlipcard = (e) => {
    let interventionC = getClone(intervention);
    if (interventionC.settings.selfhelp.alternative_menu_flipcard !== 'undefined') {
      interventionC.settings.selfhelp.alternative_menu_flipcard = !interventionC.settings.selfhelp.alternative_menu_flipcard;
    } else {
      interventionC.settings.selfhelp.alternative_menu_flipcard = true;
    }
    saveThemSettings(interventionC.settings);
  }

  const saveThemSettings = (newSettings) => {
    
    dispatch(
      setIntervention(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      )
    );

    props.setErrorMessage("");
    clearTimeout(saveSettingsTimeout);
    saveSettingsTimeout = setTimeout(() => {
      props.saveSettings(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      );
    }, 1500);
  }

  const onChangeSsiTextStart = (e) => {
    let interventionC = getClone(intervention);
    interventionC.settings.selfhelp.ssi_texts.start = e.target.value;
    saveThemSettings(interventionC.settings);
  }

  const onChangeSsiTextIntroModal = (e) => {
    let interventionC = getClone(intervention);
    interventionC.settings.selfhelp.ssi_texts.intro_modal = e.target.value;
    saveThemSettings(interventionC.settings);
  }

  const onChangeSsiTextHeadingCategory = (e) => {
    let interventionC = getClone(intervention);
    interventionC.settings.selfhelp.ssi_texts.heading_category = e.target.value;
    saveThemSettings(interventionC.settings);
  }

  const onChangeSsiTextHeadingLesson = (e) => {
    let interventionC = getClone(intervention);
    interventionC.settings.selfhelp.ssi_texts.heading_lesson = e.target.value;
    saveThemSettings(interventionC.settings);
  }

  const onChangeSsiTextFinishProgram = (e) => {
    let interventionC = getClone(intervention);
    interventionC.settings.selfhelp.ssi_texts.finish_program = e.target.value;
    saveThemSettings(interventionC.settings);
  }

  return (
    <>
      <div className="question" style={{paddingLeft:"20px"}}>
        <input
          type="checkbox"
          name="alternative_menu"
          id="alternative_menu"
          value="true"
          checked={typeof intervention.settings.selfhelp.alternative_menu !== "undefined" && intervention.settings.selfhelp.alternative_menu === true}
          onChange={onChangeAlternativeMenu}
        />
        <label
          htmlFor="alternative_menu"
        >
          {t("Alternatief menu (met afbeeldingen)")}
        </label>
        {
           intervention.settings.selfhelp.alternative_menu ?
            
            <div style={{paddingLeft: '30px'}}>

                  <div className="question" >
                    <input
                      type="checkbox"
                      name="alternative_menu_flipcard"
                      id="alternative_menu_flipcard"
                      value="true"
                      checked={typeof intervention.settings.selfhelp.alternative_menu_flipcard !== "undefined" && intervention.settings.selfhelp.alternative_menu_flipcard === true}
                      onChange={onChangeAlternativeMenuFlipcard}
                    />
                    <label
                      htmlFor="alternative_menu_flipcard"
                    > {t("Flipcardmenu")} </label><br /><br />
                  </div>
              
              <input
              type="radio"
              name="alternative_menu_seq"
              id="alternative_menu_seq_true"
              value={true}
              checked={typeof intervention.settings.selfhelp.alternative_menu_seq != "undefined" && intervention.settings.selfhelp.alternative_menu_seq === true}
              onClick={onChangeAlternativeMenuSeq}
            />
            <label
              htmlFor="alternative_menu_seq_true"
            >
              {t("Programma moet volgordelijk doorlopen worden")}
            </label>
            <br />
            <input
              type="radio"
              name="alternative_menu_seq"
              id="alternative_menu_seq_ssi"
              value="ssi"
              checked={typeof intervention.settings.selfhelp.alternative_menu_seq != "undefined" && intervention.settings.selfhelp.alternative_menu_seq === "ssi"}
              onClick={onChangeAlternativeMenuSeq}
            />
            <label
              htmlFor="alternative_menu_seq_ssi"
            >
              {t("SSI (Single Session Intervention)")}
            </label>
            {
              intervention.settings.selfhelp.alternative_menu_seq === "ssi" ?
                <div style={{paddingLeft: '34px', paddingTop: '20px'}}>

                  Tekst start blok:
                  <div className="intro">
                  <ContentEditable
                    html={intervention.settings.selfhelp?.ssi_texts?.start}
                    placeholder="Tekst start blok SSI"
                    disabled={false}
                    onChange={onChangeSsiTextStart}
                    className="introTextarea"
                  />
                  </div>
                  Tekst intro modal:
                  <div className="intro">
                  <ContentEditable
                    html={intervention.settings.selfhelp?.ssi_texts?.intro_modal}
                    placeholder="Tekst intro modal SSI"
                    disabled={false}
                    onChange={onChangeSsiTextIntroModal}
                    className="introTextarea"
                  />
                  </div>
                   Tekst heading categorie:
                  <div className="intro">
                  <ContentEditable
                    html={intervention.settings.selfhelp?.ssi_texts?.heading_category}
                    placeholder="Tekst heading keuze categorie SSI"
                    disabled={false}
                    onChange={onChangeSsiTextHeadingCategory}
                    className="introTextarea"
                  />
                  </div>
                   Tekst heading module:
                  <div className="intro">
                  <ContentEditable
                    html={intervention.settings.selfhelp?.ssi_texts?.heading_lesson}
                    placeholder="Tekst intro keuze categorie SSI"
                    disabled={false}
                    onChange={onChangeSsiTextHeadingLesson}
                    className="introTextarea"
                  />
                  </div>
                   Tekst afsluiting programma:
                  <div className="intro">
                  <ContentEditable
                    html={intervention.settings.selfhelp?.ssi_texts?.finish_program}
                    placeholder="Tekst modal afronden programma SSI"
                    disabled={false}
                    onChange={onChangeSsiTextFinishProgram}
                    className="introTextarea"
                  />
                  </div>
                </div> : <></>
            }
            </div>
            : <></>
        }
      </div>
      <br />
      <InterventionSettingsList
        type="lesson"
        nest="true"
        items={props.intervention.settings.selfhelp.lessons}
        intervention={props.intervention}
        setErrorMessage={props.setErrorMessage}
        saveSettings={props.saveSettings}
        placeholderNew={t("Voeg nieuwe les toe")}
        placeholderItem={t("De les titel")}
        itemName={t("les")}
        />
    </>
  )
};

export default InterventionSettingsLessons;
