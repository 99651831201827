const text_39 = {
  intervention: 39,
  description_short: "Stapsgewijs meer grip op je leven en zorgen.",
  step_2_title: "​​Dus je wilt meer grip krijgen op je leven en je zorgen? Je bent aan het juiste adres!",
  description_part1: "Soms overkomt ons zoveel, dat we even niet meer weten hoe nu verder. In dit online programma leer je stap voor stap om je leven weer op de rails te krijgen. Je krijgt nuttige tips en tools om succesvol te kunnen dealen met allerlei uitdagingen. En je krijgt zelfs een persoonlijke e-coach die je ondersteunt tijdens het programma!",
  description_part2: "GetAGrip bestaat uit 5 modules gebaseerd op Cognitieve Gedragstherapie en bevat beeld- en leesmateriaal, en interactieve oefeningen. We raden aan om één of twee modules per week af te ronden. Het programma duurt daarmee ongeveer 3 tot 4 weken.",
  description_part3: "Tijdens het programma krijg je ondersteuning van een online coach (geheel gratis) die tekstuele feedback geeft op de interactieve opdrachten en je algehele voortgang.",
}

export {text_39};
