import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StudentDetailsInfo from "./info.js";
import StudentDetailsChat from "./chat.js";
import StudentDetailsStress from "./stress.js";
import StudentDetailsJournal from "./journal.js";
import StudentDetailsLessons from "./lessons.js";
import StudentDetailsQuestionnaires from "./questionnaires.js";
import StudentDetailsGoals from "./goals.js";
import StudentDetailsLog from "../overview/log.js";
import StudentDetailsEmails from "../overview/emails.js";
import StudentDetailsRegistration from "./registration.js";
import StudentDetailsSleepdiary from "./sleepdiary.js";

const DynamicContent = (props) => {

    var dynamicContent = "";

    switch (props.dynamicContentType) {

        case "chat":
            dynamicContent = <StudentDetailsChat studentId={props.studentId} student={props.user} setUnreadMessage={props.setUnreadMessage} setUnreadMessageMain={props.setUnreadMessageMain} />;
            break;
        case "stress":
            dynamicContent = <StudentDetailsStress studentId={props.studentId} intervention={props.intervention} />;
            break;
        case "journal":
            dynamicContent = <StudentDetailsJournal studentId={props.studentId} studentInterventionID={props.intervention.intervention_id} />;
            break;
        case "lessons":
            dynamicContent = <StudentDetailsLessons studentId={props.studentId} intervention={props.intervention} setUnseenLesson={props.setUnseenLesson} setUnseenLessonMain={props.setUnseenLessonMain} />;
            break;
        case "questionnaires":
            dynamicContent = <StudentDetailsQuestionnaires studentId={props.studentId} intervention={props.intervention} user={props.user} />;
            break;
        case "goals":
            dynamicContent = <StudentDetailsGoals studentId={props.studentId} intervention={props.intervention} />;
            break;
        case "log":
            dynamicContent = <StudentDetailsLog studentId={props.studentId} studentInterventionID={props.intervention.intervention_id} />;
            break;
        case "emails":
            dynamicContent = <StudentDetailsEmails studentId={props.studentId} />;
            break;
        case "sleepdiary":
            dynamicContent = <StudentDetailsSleepdiary studentId={props.studentId} studentInterventionID={props.intervention.intervention_id} />;
            break;
        case "registration":
            dynamicContent = <StudentDetailsRegistration studentId={props.studentId} registration={props.user.registration}
            intervention={props.intervention} coaches={props.coaches}/>;
            break;
        default:
            dynamicContent = <StudentDetailsInfo studentId={props.studentId} user={props.user} />;
            break;
    }

    return (
        <span>
            {(props.studentId === 0) ? <div /> : dynamicContent}
        </span>

    );

}

export default DynamicContent;
