import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import t from "../../translate";
import doneIcon from '../../../images/course/standard/done.jpg';
import lockIcon from '../../../images/course/standard/lock.jpg';


const BaselineMenu = ({ t0Finished }) => {

   const history = useHistory();

   const auth = useSelector(state => state.auth);
   const intervention = useSelector(state => state.intervention);

   function startQuestionnaireT0() {
      history.push('/baseline/questionnaire')
   }

   return (
      <>
         <div className="parent">
            <span>{t("Waar sta je nu?")}</span>
         </div>
         <div className={!t0Finished ? 'active part' : 'part'} onClick={!t0Finished ? ()=>startQuestionnaireT0() : () => {}} >
            <table><tbody><tr><td><span>{t("Vragen")}</span></td><td>{t0Finished ? <img src={doneIcon} style={{width: '20px'}} /> : <></>}</td></tr></tbody></table>
         </div>
         <div className={t0Finished ? 'part' : 'part closed'}>
            <span><table><tbody><tr><td>{t("Terugkoppeling")}</td><td><div>{!t0Finished ? <img src={lockIcon}/> : <></>}</div></td></tr></tbody></table></span>
         </div>
         {
            //todo: samenstellen programma voor vrije programma's x
         }
      </>
   )
}

export default BaselineMenu