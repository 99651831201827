const text_40 = {
  intervention: 40,
  description_short: "Verbeter je sociale leven en je relaties",
  step_2_title: "",
  description_part1: "",
  description_part2: "",
  description_part3: "",
}

export {text_40};
