import React, { useEffect } from "react"
import finishFlag from "../../../images/progressbar/vlaggetjes.png"
import rocket from "../../../images/progressbar/raket.png"
import ReactTooltip from 'react-tooltip'

const LessonsProgressbar = props => {

   function changeTooltipPos(pos) {
      const newPosition = { 
         top: pos.top - 25,
         left: pos.left + 21,
      };
    return newPosition;
   }

   useEffect(() => {
      //default all de popover showen... 
      setTimeout(() => {
         var mouseoverEvent = new Event('mouseenter');
         document.getElementById('triggerTooltipRocket').dispatchEvent(mouseoverEvent);
      }, 1000);

      window.addEventListener('resize', function() {
         //bij resize de tooltip positioneren
         var mouseoverEvent = new Event('mouseenter');
         document.getElementById('triggerTooltipRocket').dispatchEvent(mouseoverEvent);
      })

      window.addEventListener('scroll', function() {
         //bij scroll de tooltip positioneren
         var mouseoverEvent = new Event('mouseleave');
         document.getElementById('triggerTooltipRocket').dispatchEvent(mouseoverEvent);
         var mouseoverEvent = new Event('mouseenter');
         document.getElementById('triggerTooltipRocket').dispatchEvent(mouseoverEvent);
      })

   }, [props.progress])

   return (
      <div className="progress-bar-lessons-desktop">

         <div className="progress" style={{width: 'calc('+props.progress+'% - 30px)'}}></div>
         
         <div className="finishFlag">
            <img src={finishFlag} alt="finish flag" />
         </div>
         
         <div id="triggerTooltipRocket" className="rocket" style={{marginLeft: 'max(-30px, calc('+props.progress+'% - 45px))'}} data-tip={props.popoverText} >
            <img src={rocket} alt="rocket" />
         </div>
         <ReactTooltip place="top" effect="solid" delayShow={200} overridePosition={changeTooltipPos} type="info" className="progress-tooltip" arrowColor="#FDBD59" />
         </div>
   )
}

export default LessonsProgressbar
