import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import apiCall from "../../api";
import t from '../../translate';
import { getText } from './text/';
import Universities from "./universities.js";
import ConfirmBox from "../../alert/confirm";
import logo from '../../../images/logo_moodlift.png';
import flag_en from '../../../images/language-switch-flag-en.png';
import flag_nl from '../../../images/language-switch-flag-nl.png';
import Footer from './footer'
import LoadScreen from '../../loadScreen/index.js';
import { setUiTranslation } from '../../../actions';

const Step1 = ({ registrationTexts, changeLanguage, registrationData, setRegistrationData, landingPage }) => {

  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [interventions, setInterventions] = useState([]);
  const [languageCode, setLanguageCode] = useState('nl')
  const [thirdPartyPrograms, setThirdPartyPrograms] = useState(0)
  const [confirmOptions, setConfirmOptions] = useState({});
  const [toDeleteIndex, setToDeleteIndex] = useState(-1); /// doet niks maar is nodig in ingeladen custom confirm

  //registrationTextsbject gebruikt eng ipv en als engelse code, daarom deze niet zo fraaie workarround
  useEffect(() => {
    if (registrationData.language_id === 1) {
      setLanguageCode('nl')
    } else {
      setLanguageCode('eng')
    }
  }, [registrationData.language_id])

  //vooraf bepalen of er in de gekozen taal thirdparty programma's zijn, zo niet dan hoeft de titel ook niet te worden weergegeven
  useEffect(() => {
    let nrThirdPartyPrograms = 0;
    registrationTexts.panel1.thirdpartiesContent.forEach(program => {
      if (program.title[languageCode].length > 0) {
        nrThirdPartyPrograms++;
      }
    })
    setThirdPartyPrograms(nrThirdPartyPrograms)
  }, [registrationTexts, languageCode])

  //ophalen registreerbare interventies + sortering aanpassen
  useEffect(() => {
    if (interventions.length < 1) {
      apiCall({
        action: "get_registrationable_interventions",
        data: {
          language_id: registrationData.language_id
        }
      }).then(resp => {
        //move SociaLife, id #40 to top of list
        resp.interventions.sort(function(x,y){ return x.id == 40 ? -1 : y.id == 40 ? 1 : 0; });
        //move goodEnough, id #43 to top of list
        resp.interventions.sort(function(x,y){ return x.id == 43 ? -1 : y.id == 43 ? 1 : 0; });
        //move iSleeo, id #47 to top of list
        //resp.interventions.sort(function(x,y){ return x.id == 47 ? -1 : y.id == 47 ? 1 : 0; });  
        //move LifeHack, id #45 to top of list
        resp.interventions.sort(function(x,y){ return x.id == 45 ? -1 : y.id == 45 ? 1 : 0; });
        //move SpiralUp to top
        resp.interventions.sort(function(x,y){ return x.id == 49 ? -1 : y.id == 45 ? 1 : 0; });
        //Move oplossingshop down the list
        /* let indexofOplossingShop = resp.interventions.findIndex(x => x.id == 30);
        if (indexofOplossingShop > -1) {
          let oplossingShop = resp.interventions[indexofOplossingShop];
          resp.interventions.splice(indexofOplossingShop, 1)
          resp.interventions.push(oplossingShop);
        } */
        setInterventions(resp.interventions);
        //rechtstreeks naar landingPage?
        if (typeof landingPage !== "undefined" && landingPage.length > 0) {
          let intervention = resp.interventions.find(intervention => {
            if (intervention.title.toLowerCase().replace(/ /g, '').replace('&amp;', '') === landingPage.toLowerCase()) {
              return intervention
            }
          })
          if (typeof intervention !== "undefined") {
            let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
            registrationDataTemp.intervention_id = intervention.id
            registrationDataTemp.interventionImage = intervention.image
            registrationDataTemp.step = 2
            registrationDataTemp.coaches_available = intervention.coaches_available;
            //qualtrics parameter in url?
            if (typeof location.search !== 'undefined' && location.search.substring(0,4) === '?id=') {
              let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
              registrationDataTemp.qualtrics_id = location.search.substr(4)
            }
            setRegistrationData(registrationDataTemp)
            window.scrollTo(0, 0);
          }
        }     
      });
    }
  }, [])

  const goToStep2 = (intervention_id) => {
    let index = registrationTexts.panel1.popups.findIndex(item => parseInt(item.intervention_id) === parseInt(intervention_id))
    if (index > -1 && registrationTexts.panel1.popups[index].show) {
      let confirmOptionsToSet = {
        show: "true",
        text: registrationTexts.panel1.popups[index].content[languageCode],
        cancelText: t("Annuleer"),
        confirmText: t("OK"),
        confirmAction: () => {
          let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
          registrationDataTemp.intervention_id = intervention_id
          let intervention = interventions.find(item => parseInt(intervention_id) === parseInt(item.id))
          registrationDataTemp.interventionImage = intervention.image
          registrationDataTemp.step = 2
          registrationDataTemp.coaches_available = intervention.coaches_available;
          setRegistrationData(registrationDataTemp)
          window.scrollTo(0, 0);
        }
      }
      setConfirmOptions(confirmOptionsToSet);
    } else {
      let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
      registrationDataTemp.intervention_id = intervention_id
      let intervention = interventions.find(item => parseInt(intervention_id) === parseInt(item.id))
      registrationDataTemp.interventionImage = intervention.image
      registrationDataTemp.step = 2
      registrationDataTemp.coaches_available = intervention.coaches_available;
      setRegistrationData(registrationDataTemp)
      window.scrollTo(0, 0);
    }
  }

  function toggleThirdParty(index) {
    if (document.getElementById('tp_caret_'+index).classList.contains('fa-caret-right')) {
      document.getElementById('tp_caret_'+index).classList.remove('fa-caret-right')
      document.getElementById('tp_caret_'+index).classList.add('fa-caret-down')
      document.getElementById('tp_ld_'+index).classList.add('display-block')
      document.getElementById('tp_lb_'+index).classList.add('display-block')
    } else {
      document.getElementById('tp_caret_'+index).classList.remove('fa-caret-down')
      document.getElementById('tp_caret_'+index).classList.add('fa-caret-right')
      document.getElementById('tp_ld_'+index).classList.remove('display-block')
      document.getElementById('tp_lb_'+index).classList.remove('display-block')
    }
  }

  return (
    <>
      <header>

        <img className="logo" src={logo} />
        <a className="btn login" href="/">{t("Log in")}</a>

        <div className="languageswitchheader" onClick={() => changeLanguage(parseInt(registrationData.language_id) === 1 ? 2 : 1)}>
            <div className={parseInt(registrationData.language_id) === 1 ? 'code active' : 'code pointer'}> NL <img src={flag_nl} style={{height: '20px'}} /> </div>
            <div className={parseInt(registrationData.language_id) === 2 ? 'code active' : 'code pointer'}> EN <img src={flag_en} style={{height: '20px'}} /> </div>
        </div>     
        <div className="container">
            <div className="intro">
              <h1>{registrationTexts.panel1.introTitle[languageCode]}</h1>
              <span dangerouslySetInnerHTML={{ __html: registrationTexts.panel1.introContent[languageCode] }} />
            </div>
        </div>
      </header>
      <div className="step1">
        <div className="container">
          {
          registrationData.qualtrics_id.length < 1 && (registrationTexts.panel1.surveyTitle[languageCode].length > 0 || registrationTexts.panel1.surveyContent[languageCode].length > 0) ?
              <div className="registration_waitinglist">
                <h2>{registrationTexts.panel1.surveyTitle[languageCode]}</h2>
                <p dangerouslySetInnerHTML={{ __html: registrationTexts.panel1.surveyContent[languageCode] }} />
              </div>
              : <></>
          }
          <br />
          <h2 dangerouslySetInnerHTML={{__html: registrationTexts.panel1.programsTitle[languageCode]}} />
          <span dangerouslySetInnerHTML={{__html: registrationTexts.panel1.programsContent[languageCode]}} />

          <br />
          
          <div className="languageswitchheader" onClick={() => changeLanguage(parseInt(registrationData.language_id) === 1 ? 2 : 1)}>
            <div className={parseInt(registrationData.language_id) === 1 ? 'code active' : 'code pointer'}> NL <img src={flag_nl} style={{height: '20px'}} /> </div>
            <div className={parseInt(registrationData.language_id) === 2 ? 'code active' : 'code pointer'}> EN <img src={flag_en} style={{height: '20px'}} /> </div>
            </div>  
          <div className="intervention_holder">
            {
              interventions.length < 1 ?
                <LoadScreen /> : <></>
            }
            {
              interventions.map((intervention, index) => {
                return (
                <div className="intervention pointer" key={index} onClick={() => goToStep2(intervention.id)}>
                  <div className="image" style={{backgroundImage: "url('"+ intervention.image + "')"}}></div>
                  {
                    !intervention.coaches_available ?
                      <div className="tempNotAvailable">{t("Tijdelijk niet beschikbaar")}</div> : <></>
                  }
                  
                  <h2 dangerouslySetInnerHTML={{__html: intervention.title}}></h2>
                  <div className="description">
                    {t(getText(intervention.id, "description_short"))}
                  </div>
                  <div className="btn_holder">
                      <span className="btn red">
                        {t("Lees verder")}
                      </span>
                  </div>
                </div>
                )
              })
            }

            {
              interventions.length % 3 ? 
                <div className="intervention intervention_blank" style={{pointerEvents: "none"}}></div> : <></>
            }
          </div>


          <Universities step={registrationData.step} registrationTexts={registrationTexts} languageCode={languageCode} />

          {
            thirdPartyPrograms > 0 ?
              <>
                <div className="thirdpartyprograms">
                  <br />
                  <h2 dangerouslySetInnerHTML={{__html: registrationTexts.panel1.thirdpartiesTitle[languageCode]}} />
                  {
                    registrationTexts.panel1.thirdpartiesContent.map((tpp, index) => {
                      if (tpp.title[languageCode].length > 0) {
                        return(
                          <div key={index}>
                            <div className="title" onClick={() => toggleThirdParty(index)}>
                              <i className="fa fa-caret-right table-cell" id={'tp_caret_'+index}></i><span className="table-cell marginLeft">{tpp.title[languageCode]}: <span dangerouslySetInnerHTML={{__html: tpp.shortdescription[languageCode]}} /></span>
                            </div>
                            <a href={tpp.url[languageCode]} target="_blank"  id={'tp_ld_'+index} dangerouslySetInnerHTML={{__html: tpp.longdescription[languageCode]}} />
                            <a href={tpp.url[languageCode]} target="_blank" id={'tp_lb_'+index} className="btn btn-primary">{t("Lees meer")}</a>
                          </div>
                         )
                        } else {
                          return;
                        }
                    })
                  }
                </div>
              </>
              : <></>
          }
          
        </div>
        <ConfirmBox confirmOptions={confirmOptions} setConfirmOptions={setConfirmOptions}  setToDeleteIndex={setToDeleteIndex}/>
      </div>
      <Footer language={registrationData.language_id} changeLanguage={changeLanguage} />
    </>
  )

}

export default Step1;
