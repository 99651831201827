import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import t from "../translate";
import LogOut from "./logout";
import LanguageSwitch from "./languageswitch";
import $ from "jquery";

const MenuCoach = props => {
  let history = useHistory();

  const auth = useSelector(state => state.auth);
  let location = useLocation();
  const intervention = useSelector(state => state.intervention);
  const [supervisorFor, setSupervisorFor] = useState([]);

  useEffect(() => {
    //Van welke interventies is deze coach supervisor?
    let localSupervisorFor = [];
    auth.rights.interventions.forEach(function (interv, index) {
      if (interv.isSupervisor)
      {
        localSupervisorFor.push(interv.id);
      }
    });
    setSupervisorFor(localSupervisorFor);
  }, [])

  const handleClick = (event, redirectTo) => {
    $("#menu_left").animate(
      {
        marginRight: "-405px"
      },
      600
    );
    $('body').removeClass('menuIsVisible');
    $("#nav-icon-wrapper, #menu_left").removeClass("open");
    $(".overlay").fadeOut();
    history.push(redirectTo);
  };

  return (
    <div>
        {auth.rights.interventions.length > 1 ?
            <div className="pointer" onClick={event => handleClick(event, "/")} >
                <i className="fas fa-home"></i>
                <div className="menu-left-link">{t("Studenten")}</div>
            </div>
            : <></>
        }
        {supervisorFor.length > 0 ?
            <div className="pointer" onClick={event => handleClick(event, "/intervention/coaches/")} >
                <i className="fas fa-hands-helping color_orange"></i>
                <div className="menu-left-link">{t("Coaches")}</div>
            </div>
            : <></>
        }
        <LogOut />
        <LanguageSwitch />
    </div>
  );
};

export default MenuCoach;
