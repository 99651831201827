import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../api";
import $ from "jquery";
import "popper.js/dist/popper"; //als package geinstalleerd
import "bootstrap/dist/js/bootstrap"; //als package geinstalleerd
import { getClone } from "../utils";
import SortObjectArray from "../helpers/sortObjectArray.js";
import LeftMenu from "../leftmenu";
import Modal from "../modal";
import Editcoach from "./edituser.js";
import t from "../translate";
import { setIntervention } from "../../actions";
import { useHistory } from "react-router-dom";
import LoadScreen from "../loadScreen";
import NavBar from "../navBar";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const InterventionCoaches = (props) => {
  let history = useHistory();

  let { intervention_id } = useParams();

  //functie die lijst opmaakt met users
  const ListItems = (props) => {
    const viewUser = (user) => {
      props.setStateCallback(user, t("Wijzigen coach"));
      $("#user_edit").modal("toggle");
    };

    let coaches = state.users;

    let searchForLowerCase = searchFor.toLowerCase();
    for (let i = 0; i < coaches.length; i++) {
      let name =
        coaches[i].firstname +
        " " +
        coaches[i].insertion +
        " " +
        coaches[i].lastname;
      if (
        !name.toLowerCase().includes(searchForLowerCase) &&
        !coaches[i].login.toLowerCase().includes(searchForLowerCase) &&
        !coaches[i].email.toLowerCase().includes(searchForLowerCase) &&
        !coaches[i].id.toLowerCase().includes(searchForLowerCase)
      ) {
        coaches[i].hide = "true";
      } else {
        coaches[i].hide = "false";
      }
    }

    if (sort != "") {
      coaches.sort(SortObjectArray(sort, sortDirection));
    }

    return (
      <tbody>
        {coaches.map((coach) => {
          let superVisorName = "";
          let superVisorObject = props.state.users.find((u) => {
            return u.id === coach.hasSupervisor;
          });
          if (superVisorObject) {
            superVisorName =
              superVisorObject.firstname +
              " " +
              superVisorObject.insertion +
              " " +
              superVisorObject.lastname;
          }
          return (
            <tr
              key={coach.id}
              className={
                "pointer rowHover" + (coach.hide == "true" ? " hide" : "")
              }
              onClick={() => viewUser(coach)}
            >
              <td>{coach.id}</td>
              <td>{coach.login}</td>
              <td>
                {coach.firstname} {coach.insertion} {coach.lastname}{" "}
                {coach.isSupervisor ? "(" + t("Supervisor") + ")" : ""}
              </td>
              <td>{superVisorName}</td>
              <td>
                {coach.type} {coach.isSupervisor ? "/ " + t("supervisor") : ""}
              </td>
              <td>{coach.email}</td>
              <td>{coach.phone}</td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  //deze functie wordt als property doorgegeven aan modal en vandaar naar editUser
  //en aangeroepen na een geslaagde saveUser
  const closeModal = (msg, intervention_id) => {
    $("#user_edit").modal("toggle");
    setMessage(msg);
    getUsers();
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }

  const dispatch = useDispatch();
  const [supervisorFor, setSupervisorFor] = useState([]);
  const [state, setState] = useState({
    users: [],
    modalState: {
      name: "user_edit",
      label: "user_edit_label",
      title: "",
      component: Editcoach,
      btnValue: t("Opslaan"),
      componentData: {
        coaches: [], //coaches van deze org maar niet gekoppeld aan interventie
        supervisorFor: [], //coach is supervisor voor deze interventies
        user: {
          id: 0,
          firstname: "",
          insertion: "",
          lastname: "",
          email: "",
          phone: "",
          organisation_id: 0,
          type: "",
          login: "",
          password: "",
          password_check: "",
          rights: {
            max_students: 0,
            bio: "",
            languages: [],
            bioTranslations: [],
            interventions: [],
          },
          preferences: {},
          isSupervisor: false,
          hasSupervisor: 0,
          hasCoaches: false, //hangen er coaches onder deze supervisor?
          hasStudents: false, //hangen er students onder deze coach
          profile_pic: "",
        },
        closeModal: closeModal,
      },
    },
  });

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const auth = useSelector((state) => state.auth);
  const intervention = useSelector((state) => state.intervention);
  const uiTranslation = useSelector((state) => state.uiTranslation);
  const [coachesLoading, setCoachesLoading] = useState(true);

  const getUsers = () => {
    setCoachesLoading(true);
    //api aanroepen
    apiCall({
      action: "get_coaches",
      token: auth.token,
      data: {},
    }).then((resp) => {
      const newState = getClone(state);
      newState.users = resp.users;
      newState.modalState.componentData.coaches = resp.non_users;
      newState.modalState.componentData.supervisorFor = supervisorFor;
      setState(newState);
      setCoachesLoading(false);
    });
  };

  const [interventions, setInterventions] = useState([]);

  //onFirstRenderOnly to prevent infinite render loop
  useEffect(() => {
    //bepalen voor welke interventies deze coach supervisor is
    let localSupervisorFor = [];
    auth.rights.interventions.forEach(function (interv, index) {
      if (interv.isSupervisor) {
        localSupervisorFor.push(parseInt(interv.id));
      }
    });
    setSupervisorFor(localSupervisorFor);
  }, [auth]);

  useEffect(() => {
    if (supervisorFor.length > 0) {
      getUsers();
    }
  }, [supervisorFor]);

  //deze functie wordt aangeroepen bij aanklikken van een user in de list (ListItems)
  const setStateCallback = (user, title) => {
    let newState = getClone(state);
    newState.modalState.componentData.user = user;
    newState.modalState.title = title;
    newState.modalState.componentData.user.hasCoaches = false;
    if (user.isSupervisor) {
      //hangen er coaches onder deze supervisor?
      state.users.map((coach) => {
        if (coach.rights.interventions !== undefined) {
          coach.rights.interventions.map((int) => {
            if (int.id === intervention.id && int.hasSupervisor === user.id) {
              newState.modalState.componentData.user.hasCoaches = true;
            }
          });
        }
      });
    }
    setState(newState);
  };

  const addUser = () => {
    setStateCallback(
      {
        id: 0,
        firstname: "",
        insertion: "",
        lastname: "",
        email: "",
        phone: "",
        organisation_id: intervention.organisation_id,
        type: "coach",
        login: "",
        password: "",
        password_check: "",
        rights: {
          max_students: 0,
          bio: "",
          languages: [],
          bioTranslations: [],
          interventions: [],
        },
        preferences: {},
        isSupervisor: false,
        hasSupervisor: 0,
      },
      t("Toevoegen coach")
    );
    $("#user_edit").modal("toggle");
    $("#firstname").focus();
  };

  const searchView = () => {
    setOpenSearch(true);
  };

  const [openSearch, setOpenSearch] = useState(false);
  const [searchFor, setSearchFor] = useState("");
  const [sort, setSort] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  function changeSort(type) {
    setSortDirection(sortDirection == "asc" ? "desc" : "asc");
    setSort(type);
  }

  return (
    <>
      <NavBar />
      <div
        className={
          "coachInterface coaches" +
          (openSearch ? " openSearch" : "") +
          " " +
          auth.userType
        }
      >
        {/* <nav className="navbar navbar-expand-lg navbar-light">

          {auth.userType == 'admin' ?
            <h2>
              <span className="pointer" onClick={()=>history.push("/intervention/edit/" + intervention.id + "/general/")} dangerouslySetInnerHTML={{__html: intervention.title+' '}}></span>
            </h2>
          :
            <h2 className='select'>
              <select value={intervention.id} onChange={(e) => changeIntervention(e.target.value)}>
                {interventions.map((int, index) => (
                  <option value={int.id} key={index}>
                    {int.title}
                  </option>
                ))}
              </select>
              <span dangerouslySetInnerHTML={{__html: intervention.title+' '}}></span>
            </h2>
          }
        <h2 className="noPadding">
          &gt; coaches
        </h2>
      </nav> */}
        <LeftMenu />
        <div className="list">
          <table className="theIntervention">
            <tbody>
              <tr>
                <td>
                  <h1>
                    {t("Coaches")}{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: intervention.title + " ",
                      }}
                    ></span>
                  </h1>
                </td>
                <td className="options">
                  <button
                    className="btn btn-primary btn-sm btn-trans"
                    onClick={addUser}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  <i
                    className={
                      "fas fa-search pointer" + (openSearch ? " hide" : " ")
                    }
                    onClick={() => searchView()}
                  ></i>
                  <div className="search">
                    <input
                      type="text"
                      value={searchFor}
                      placeholder={t("Zoek coach")}
                      onChange={(e) => setSearchFor(e.target.value)}
                    />
                    <i
                      className="far fa-times-circle pointer"
                      onClick={() => setOpenSearch(false)}
                    ></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="holder">
            <div className="users">
              <table>
                <thead>
                  <tr>
                    <th className="pointer" onClick={() => changeSort("id")}>
                      #
                    </th>
                    <th className="pointer" onClick={() => changeSort("login")}>
                      {t("Gebruikersnaam")}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => changeSort("firstname")}
                    >
                      {t("Naam")}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => changeSort("isSupervisor")}
                    >
                      {t("Supervisor")}
                    </th>
                    <th>{t("Type")}</th>
                    <th className="pointer" onClick={() => changeSort("email")}>
                      {t("Email")}
                    </th>
                    <th className="pointer" onClick={() => changeSort("phone")}>
                      {t("Telefoon")}
                    </th>
                  </tr>
                </thead>
                {coachesLoading ? (
                  <LoadScreen />
                ) : (
                  <ListItems
                    state={state}
                    setStateCallback={setStateCallback}
                  />
                )}
              </table>
            </div>
          </div>
        </div>

        <div
          className={message.length < 1 ? "hidden" : "alert alert-success"}
          role="alert"
        >
          {message}
        </div>
        <div
          className={errorMessage.length < 1 ? "hidden" : "alert alert-danger"}
          role="alert"
        >
          {errorMessage}
        </div>

        <Modal {...state.modalState} />
      </div>
    </>
  );
};

export default InterventionCoaches;
