import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import apiCall from "../api";
import t from "../translate";
import ConfirmBox from "../alert/confirm";
import { validateEmail } from "../utils";

const Edituser = forwardRef((props, ref) => {
  const [state, setState] = useState({
    selectedCoach: 0,
    user: {
      id: 0,
      firstname: "",
      insertion: "",
      lastname: "",
      email: "",
      phone: "",
      organisation_id: 0,
      type: "",
      login: "",
      password: "",
      password_check: "",
      hasCoaches: false,
      profile_pic: '',
      rights: {
        interventions: []
      },
      preferences: {},
      removeUser: false
    },
    coaches: [],
    interventions: [],
    types: [],
    showPassword: false
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [languages, setLanguages] = useState([]);

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const url = useSelector(state => state.url);

  //talen laden
  useEffect(() => {
    apiCall({
      action: "get_languages",
      token: auth.token,
      data: {}
    }).then(resp => {
      setLanguages(resp.languages);
    })
  }, [])

  //laden state met data uit props
  useEffect(() => {
    const newState = {...state};
    newState.user = props.user;
    newState.user.password = "";
    newState.user.password_check = "";
    newState.coaches = props.coaches;
    newState.interventions = props.interventions;
    newState.types = ["coach"];
    if (newState.user.id == 0) {
      newState.showPassword = true;
    } else {
      newState.showPassword = false;
    }
    newState.user.removeUser = false;
    setState(newState);
    if (newState.user.profile_pic) {
      setProfileImage(url + "/api/?a=get_profile_pic_coach&user_id=" + newState.user.id + "&" + new Date().getTime());
    } else {
      setProfileImage('')
    }
    setErrorMessage("");
  }, [props.user]);

  //////////////////////
  ///Upload image to server
  const uploadImage = (uploadedFiles) => {
    let promises = [];
    let files = [];
    Array.from(uploadedFiles).forEach(uploadFile => {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onload = e => {
          let file = {};
          file.type = uploadFile.type;
          file.name = 'profile_pic.' + uploadFile.name.split('.').pop();
          file.size = uploadFile.size;
          file.content = e.target.result;
          files.push(file);
          resolve(true);
        };
      });
      promises.push(filePromise);
    });

    Promise.all(promises).then(fileContents => {
      let apiObj = {
        action: "upload_coach_image",
        token: auth.token,
        data: {
          user_id: state.user.id,
          files: files
        }
      };

      apiCall(apiObj).then(resp => {
        if (resp) {
          if(resp.error == 0){
            setProfileImage(url + "/api/?a=get_profile_pic_coach&user_id=" + state.user.id + "&" + new Date().getTime());
          }
        }
      });
    });
  }

    //////////////////////
  ///Delete image from server
  const deleteImage = (e, name) => {
    e.stopPropagation();

    let apiMsg = {
      action: "delete_coach_image",
      token: auth.token,
      data:{
        user_id: state.user.id
      }
    };

    apiCall(apiMsg).then(resp => {
      if (false !== resp) {
        if (resp.error == 0) {
          setProfileImage('');
        }
      }
    });
  }

  let inputFileRef = React.createRef();
  const triggerInputFile = () => {
    inputFileRef.current.click();
  }

  const onChange = e => {

    const newState = {...state};
    if (e.target.name.substr(0, 7) === 'isCoach') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      if (typeof newState.user.rights.interventions !== 'undefined') {
        let intervention_key = -1;
        for(let i=0;i < newState.user.rights.interventions.length;i++) {
          if (parseInt(newState.user.rights.interventions[i].id) === intervention_id) {
            intervention_key = i;
            break;
          }
        }
        if (intervention_key > -1) { //remove
          //zijn er studenten gekoppeld, dan kan de coaching niet uitgezet worden
          if (newState.user.rights.interventions[intervention_key].hasStudents) {
            setErrorMessage(t("Er zijn voor dit programma nog studenten gekoppeld aan deze coach, u kunt deze daarom niet verwijderen."))
            return false;
          } else {
            //is deze coach soms supervisor, en is het de enige of hangen er no coaches onder?
            if (newState.user.rights.interventions[intervention_key].isSupervisor) {
              //hasCoaches???
              let hasCoaches = false;
              let otherSupervisors = false;
              state.coaches.map(coach => {
                if (typeof coach.rights !== 'undefined' && typeof coach.rights.interventions !== 'undefined') {
                  coach.rights.interventions.map(interv => {
                    if (parseInt(interv.id) === parseInt(intervention_id)) {
                      if (interv.isSupervisor) {
                        otherSupervisors = true;
                      } else {
                        if (parseInt(interv.hasSupervisor) === parseInt(newState.user.id)) {
                          hasCoaches = true
                        }
                      }
                    }
                  })
                }
              })
              if (hasCoaches) {
                setErrorMessage(t("Er zijn nog coaches gekoppeld aan deze supervisor, u kunt deze daarom niet verwijderen."))
                return false;
              } else if (!otherSupervisors) {
                setErrorMessage(t("Deze coach is de enige supervisor voor dit programma, u kunt deze daarom niet verwijderen."))
                return false;
              } else {
                //wel supervisor, maar er hangen geen coaches onder en is ook niet de enige supervisor
                newState.user.rights.interventions.splice(intervention_key, 1)
              }
            } else {
              //geen supervisor en geen gekoppelde studenten...
              newState.user.rights.interventions.splice(intervention_key, 1)
            }
          }
        } else { //add
          newState.user.rights.interventions.push({
            id: intervention_id,
            isSupervisor: false,
            hasSupervisor: 0,
            /* max_students: 0,
            bio: '',
            bioTranslations: [],
            languages: [], */
            hasStudents: false
          })
        }
      } else {
        newState.user.rights.interventions = [{
          id: intervention_id,
          isSupervisor: false,
          hasSupervisor: 0,
          /* max_students: 0,
          bio: '',
          bioTranslations: [],
          languages: [], */
          hasStudents: false
        }]
      }
    } else  if (e.target.name.substr(0, 12) === 'isSupervisor') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      for(let i=0;i < newState.user.rights.interventions.length;i++) {
        if (parseInt(newState.user.rights.interventions[i].id) === intervention_id) {
          if (newState.user.rights.interventions[i].isSupervisor) {
            //hasCoaches???
            let hasCoaches = false;
            let otherSupervisors = false;
            state.coaches.map(coach => {
              if (typeof coach.rights !== 'undefined' && typeof coach.rights.interventions !== 'undefined') {
                coach.rights.interventions.map(interv => {
                  if (parseInt(interv.id) === parseInt(intervention_id)) {
                    if (interv.isSupervisor) {
                      otherSupervisors = true;
                    } else {
                      if (parseInt(interv.hasSupervisor) === parseInt(newState.user.id)) {
                        hasCoaches = true
                      }
                    }
                  }
                })
              }
            })
            if (hasCoaches) {
              setErrorMessage(t("Er zijn nog coaches gekoppeld aan deze supervisor, u kunt deze daarom niet verwijderen."))
              return false;
            } else if (!otherSupervisors) {
              setErrorMessage(t("Deze coach is de enige supervisor voor dit programma, u kunt deze daarom niet verwijderen."))
              return false;
            } else {
              //wel supervisor, maar er hangen geen coaches onder en is ook niet de enige supervisor
              newState.user.rights.interventions[i].isSupervisor = false;
              newState.user.rights.interventions[i].hasSupervisor = 0;
            }
          } else {
            newState.user.rights.interventions[i].isSupervisor = true;
            newState.user.rights.interventions[i].hasSupervisor = 0;
          }
          break;
        }
      }
    } else if (e.target.name.substr(0,13) === 'hasSupervisor') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      for(let i=0;i < newState.user.rights.interventions.length;i++) {
        if (parseInt(newState.user.rights.interventions[i].id) === intervention_id) {
          newState.user.rights.interventions[i].hasSupervisor = e.target.value
          break;
        }
      }
    } /* else if (e.target.name.substr(0, 11) === 'maxStudents') {
      let intervention_id = parseInt(e.target.getAttribute('int_id'));
      for(let i=0;i < newState.user.rights.interventions.length;i++) {
        if (parseInt(newState.user.rights.interventions[i].id) === intervention_id) {
          newState.user.rights.interventions[i].max_students = e.target.value
          break;
        }
      }
    } */ else if (e.target.name.substr(0, 4) === 'lang') {
      /* let intervention_id = parseInt(e.target.getAttribute('int_id')); */
      let language_code = e.target.getAttribute('lang_code');
      /* for(let i=0;i < newState.user.rights.interventions.length;i++) {
        if (parseInt(newState.user.rights.interventions[i].id) === intervention_id) { */
          if(newState.user.rights.languages.includes(language_code)) {
            let indexOfLanguage = newState.user.rights.languages.indexOf(language_code);
            newState.user.rights.languages.splice(indexOfLanguage, 1);
          } else {
            newState.user.rights.languages.push(language_code);
          }
          /* break;
        }
      }  */
    } else if(e.target.name.substr(0, 3) === 'bio' && e.target.name.length > 3) {
      let language_code = e.target.getAttribute('lang_code');
      if (typeof newState.user.rights.bioTranslations.find(bio => bio.code === language_code) !== 'undefined') {
        newState.user.rights.bioTranslations.find(bio => bio.code === language_code).content = e.target.value
      } else {
        newState.user.rights.bioTranslations.push({
          code: language_code,
          content: e.target.value
        })
      }
    } else if(e.target.name === 'max_students') {
      newState.user.rights.max_students = e.target.value;
    } else if(e.target.name === 'bio') {
      newState.user.rights.bio = e.target.value;
    } else {
      newState.user[e.target.name] = e.target.value;
    }
    setState(newState);
    setErrorMessage("");
  };

  const toggleShowPassword = e => {
    const newState = {...state};
    if (newState.showPassword) {
      newState.showPassword = false;
    } else {
      newState.showPassword = true;
    }
    setState(newState);
  }

  const toggleRemoveUser = e => {
    const newState = {...state};

    //zijn er nog studenten gekoppeld aan deze coach?
    if (newState.user.hasStudents) {
      setErrorMessage(t("Er zijn nog studenten gekoppeld aan deze coach, u kunt deze daarom niet verwijderen."))
      return false;
    }

    //is deze coach de enige supervisor van een programma of hangen er coaches onder deze supervisor?
    if (typeof newState.user.rights !== "undefined" && typeof newState.user.rights.interventions !== "undefined") {
      let hasCoaches = false;
      let onlySupervisor = false;
      newState.user.rights.interventions.map(int => {
        if (int.isSupervisor) {
          let otherSupervisors = false;
          state.coaches.map(coach => {
            if (typeof coach.rights !== 'undefined' && typeof coach.rights.interventions !== 'undefined') {
              coach.rights.interventions.map(interv => {
                if (parseInt(interv.id) === parseInt(int.id)) {
                  if (interv.isSupervisor) {
                    otherSupervisors = true;
                  } else {
                    if (parseInt(interv.hasSupervisor) === parseInt(newState.user.id)) {
                      hasCoaches = true
                    }
                  }
                }
              })
            }
          })
          if (!otherSupervisors) {
            onlySupervisor = true;
          }
        }
      })

      if (hasCoaches) {
        setErrorMessage(t("Er zijn nog coaches gekoppeld aan deze supervisor, u kunt deze daarom niet verwijderen."))
        return false;
      } else if (onlySupervisor) {
        setErrorMessage(t("Deze coach is de enige supervisor voor een van de programma's, u kunt deze daarom niet verwijderen."))
        return false;
      }

    }

    if (newState.user.removeUser) {
      newState.user.removeUser = false;
    } else {
      newState.user.removeUser = true;
    }
    setState(newState);
  }

  const [confirmOptions, setConfirmOptions] = useState({});
  const [toDeleteIndex, setToDeleteIndex] = useState(-1); /// doet niks maar is nodig in ingeladen custom confirm
  const [deleteCoach, setDeleteCoach] = useState(false);

  function deleteConfirm() {
    let confirmOptionsToSet = {
      show: "true",
      text: "<h4>"+t("Weet u zeker dat u deze coach wilt verwijderen?")+"</h4>",
      cancelText: t("Annuleer"),
      confirmText: t("Verwijder"),
      confirmAction: () => doDeleteCoach()
    };
    //setToDeleteIndex(-1);
    setConfirmOptions(confirmOptionsToSet);
  }

  function doDeleteCoach(){

    if (state.user.removeUser && state.user.rights.interventions.length > 1) {
      let intervention_index = 0;
      //zo ja, dan alleen coach loskoppelen van deze interventie
      state.user.rights.interventions.map((interv, index) => {
        if (intervention.id == interv.id) {
          intervention_index = index;
        }
      });
      state.user.rights.interventions.splice(intervention_index, 1);
      state.user.removeUser = false;
    }

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: state.user
      }
    }).then(resp => {
      if (resp.save_user) {
        state.selectedCoach = 0;
        props.closeModal(resp.msg, intervention.id);
      } else {
        setErrorMessage(resp.msg);
      }
    });
  }


  //deze functie wordt aangeroepen door button in component Modal -> ref functie koppelt
  //componenten aan elkaar
  useImperativeHandle(ref, () => ({
    submitHandler() {
      let formisok = true;
      //verwijderen?
      if (parseInt(state.user.id) !== 0 && state.user.removeUser) {
        /// show custom confirm
        if (deleteConfirm() != 'true') {
          //props.closeModal("");
          return;
        }
      } else {
        //check of alle velden zijn ingevuld
        if (state.user.firstname.trim() === "") {
          setErrorMessage(t("Voornaam is verplicht"));
          formisok = false;
        } else if (state.user.lastname.trim() === "") {
          setErrorMessage(t("Achternaam is verplicht"));
          formisok = false;
        } else if (state.user.email.trim() === "") {
          setErrorMessage(t("E-mail is verplicht"));
          formisok = false;
        } else if (!validateEmail(state.user.email)) {
            setErrorMessage(t("E-mail is niet geldig"));
            formisok = false;
        } else if (state.user.login.trim() === "") {
          setErrorMessage(t("Gebruikersnaam is verplicht"));
          formisok = false;
        } else if (typeof state.user.rights.max_students === "undefined" || state.user.rights.max_students === "" || state.user.rights.max_students < 0) {
          setErrorMessage(t("Maximaal aantal studenten is verplicht"));
          formisok = false;
        } else if (state.user.rights.languages.length === 0) {
          setErrorMessage(t("Geef aan in welke talen de coach les kan geven"));
          formisok = false;
        } else if (state.user.rights.languages.find(lang => lang === "nl") && state.user.rights.bio.trim().length < 1) {
          setErrorMessage(t("Geef een korte Nederlandse biografie op"));
          formisok = false;
        } else {
          for(let i=0;i < state.user.rights.languages.length;i++) {
            if (state.user.rights.languages[i] !== 'nl') {
              if (typeof state.user.rights.bioTranslations[state.user.rights.languages[i]] === "undefined") {
                let bio = state.user.rights.bioTranslations.find(lang => lang.code === state.user.rights.languages[i]);
                if (typeof bio === "undefined" || bio.content.length < 1) {
                  setErrorMessage(t("Geef een korte biografie op in de gekozen taal ("+state.user.rights.languages[i]+")"));
                  formisok = false;
                  break;
                }
              }
            }
          }
          if (formisok) {
            if (state.showPassword && state.user.password.trim() === "") {
              setErrorMessage(t("Wachtwoord is verplicht"));
              formisok = false;
            } else if (state.showPassword && state.user.password !== state.user.password_check) {
              setErrorMessage(t("Wachtwoorden komen niet overeen"));
              formisok = false;
            }
            state.user.rights.interventions.map(interv => {

              if (state.interventions.find(int => parseInt(int.id) === parseInt(interv.id)) !== undefined) {
                if (!interv.isSupervisor && parseInt(interv.hasSupervisor) === 0) {
                  let program = state.interventions.find(int => parseInt(int.id) === parseInt(interv.id)).title;
                  setErrorMessage(t("Geef voor programma ")+program+(" aan onder welke supervisor deze coach valt!"));
                  formisok = false;
                }
              }
            })
          }
        }
      }
      if (formisok) {
        apiCall({
          action: "save_user",
          token: auth.token,
          data: {
            user: state.user
          }
        }).then(resp => {
          if (resp.save_user) {
            state.selectedCoach = 0;
            props.closeModal(resp.msg, intervention.id);
          } else {
            setErrorMessage(resp.msg);
          }
        })
      }
    }
  }));

  return (
    <div>
      {
        errorMessage.length > 0 ?
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-circle"></i> &nbsp;
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </div> : <></>
      }
      <form>
          <div className={state.user.id === 0 ? "hidden" : "form-row align-items-center"} style={{marginBottom: '20px'}}>
            <div className='image_holder' style={{ backgroundImage: "url(" + profileImage + ")" }}>
              <input
                type="file"
                onChange={e => uploadImage(e.target.files)}
                name="file"
                ref={inputFileRef}
                multiple={false}
              />
              <div className="options">
                <span className="btn handle">
                  <i
                    className="fas fa-camera-retro"
                    onClick={triggerInputFile}
                  ></i>
                </span>

                <span className="btn delete">
                  <i
                    className="fas fa-trash"
                    onClick={e => deleteImage(e, profileImage)}
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col-auto">
              <label htmlFor="firstname">{t("Voornaam")}</label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="firstname"
                aria-describedby="firstname"
                placeholder=""
                value={state.user.firstname}
                onChange={onChange}
              />
            </div>
            <div className="col-auto col-sm">
              <label htmlFor="insertion">{t("Tussenvoegsel")}</label>
              <input
                type="text"
                className="form-control"
                id="insertion"
                name="insertion"
                aria-describedby="insertion"
                placeholder=""
                value={state.user.insertion}
                onChange={onChange}
              />
            </div>
            <div className="col-auto">
              <label htmlFor="lastname">{t("Achternaam")}</label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                name="lastname"
                aria-describedby="lastname"
                placeholder=""
                value={state.user.lastname}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col">
              <label htmlFor="email">{t("E-mail")}</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="email"
                placeholder=""
                value={state.user.email}
                onChange={onChange}
              />
            </div>
            <div className="col">
              <label htmlFor="phone">{t("Telefoonnummer")}</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                aria-describedby="phone"
                placeholder=""
                value={state.user.phone}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="login">{t("Gebruikersnaam")}</label>
            <input
              type="text"
              className="form-control"
              id="login"
              name="login"
              aria-describedby="login"
              placeholder=""
              value={state.user.login}
              onChange={onChange}
            />
          </div>
          <div>
          <div>
            <label htmlFor="max_students">{t("Begeleidt max. aantal deelnemers")}</label>
            <input type="number" min="0" max="999" step="1" className="form-control" name="max_students" id="max_students" value={state.user.rights.max_students} onChange={e => onChange(e)} />
          </div>
          <label htmlFor="type">{t("Talen")}</label><br />
          {
            languages.map((lang, index) => {
              let checked = false;
              if (state.user.rights.languages.indexOf(lang.code) > -1) {
                checked = true;              }
              return (
                <div key={index}>
                  <input className="form-check-input" type="checkbox" name={'lang_'+lang.id} id={'lang_'+lang.id} lang_code={lang.code} onChange={e => onChange(e)} checked={checked} /> <label className="form-check-label" htmlFor={'lang_'+lang.id}> {lang.language}</label><br />
                </div>
              )
            })
          }
          {
            typeof state.user.rights.languages !== "undefined" && state.user.rights.languages.find(lang => lang === 'nl') ?
              <>
                <label htmlFor="type">{t("Bio Nederlands")}</label><br />
                <textarea className="form-control" name="bio" id="bio" value={state.user.rights.bio} onChange={e => onChange(e)} />
              </> : <></>
          }
          {
            languages.map((lang, index) => {
              if (lang.code !== 'nl' && typeof state.user.rights.languages !== "undefined" && state.user.rights.languages.find(language => language === lang.code)) {
                let content = '';
                if (typeof state.user.rights.bioTranslations !== "undefined") {
                  let bioTranslation = state.user.rights.bioTranslations.find(bio => bio.code === lang.code);
                  if (typeof bioTranslation !== "undefined") {
                    content = bioTranslation.content;
                  }
                }
              return (
                <div key={index}>
                  <label htmlFor="type">{t("Bio") + ' ' + lang.language}</label><br />
                  <textarea className="form-control" name={'bio_'+lang.code} id={'bio_'+lang.code} lang_code={lang.code} value={content} onChange={e => onChange(e)} />
                  <br />
                </div>
              )
            }})
          }

          <label>{t("Coached deelnemers in de programma's")}</label>
            {
              state.interventions.map(int => {

                let isCoach = state.user.rights.interventions.find(interv => parseInt(interv.id) === parseInt(int.id));
                let isSupervisor = false;
                let hasSupervisor = 0;
                /* let maxStudents = 0;
                let bio = '';
                let bioTranslations = [];
                let languages = []; */
                if (typeof isCoach !== 'undefined') {
                  isSupervisor = isCoach.isSupervisor;
                  hasSupervisor = isCoach.hasSupervisor;
                  /* maxStudents = isCoach.max_students;
                  bio = isCoach.bio;
                  bioTranslations = isCoach.bioTranslations;
                  languages = isCoach.languages;
                  if (typeof languages === 'undefined') {
                    languages = []
                  } */
                }

                return (
                  <div key={'int'+int.id}>
                    <input className="form-check-input" type="checkbox" name={'isCoach'+int.id} id={'isCoach'+int.id} int_id={int.id} onChange={e => onChange(e)} checked={(typeof isCoach !== 'undefined') ? true : false} />
                    <label className="form-check-label" htmlFor={'isCoach'+int.id} dangerouslySetInnerHTML={{__html: int.title}}></label>
                    {
                    typeof isCoach !== 'undefined' ?
                      <div style={{marginLeft: '20px',backgroundColor:'#efefef',padding:'10px'}}>
                        <input className="form-check-input" type="checkbox" name={'isSupervisor'+int.id} id={'isSupervisor'+int.id} int_id={int.id} onChange={e => onChange(e)} checked={isSupervisor} /> <label className="form-check-label" htmlFor={'isSupervisor'+int.id}> {t('Coach is supervisor van dit programma')}</label><br />
                        {
                          !isSupervisor ?
                            <>
                              <label htmlFor="type">{t("Coach valt onder supervisor")}</label>
                              <select
                                id={'hasSupervisor'+int.id}
                                name={'hasSupervisor'+int.id}
                                int_id={int.id}
                                value={hasSupervisor}
                                onChange={onChange}
                                className="form-control"
                              >
                                <option value="0"> --- {t("Selecteer een supervisor")} --- </option>
                                {state.coaches.map(coach => {
                                  //bepalen of deze coach supervisor is voor dit programma
                                  let coachIsSupervisor = false
                                  coach.rights.interventions.map(interv => {
                                    if (parseInt(interv.id) === parseInt(int.id) && interv.isSupervisor) {
                                      coachIsSupervisor = true;
                                    }
                                  })
                                  if (coachIsSupervisor) {
                                    return (
                                      <option value={coach.id} key={'int'+int.id+'coach'+coach.id}>
                                        {coach.firstname} {coach.insertion}{" "}
                                        {coach.lastname}
                                      </option>
                                    )
                                  }
                                })}
                              </select>
                            </> : <></>
                        }
                        {/* <div>
                          <label htmlFor="login">{t("Begeleidt max. aantal deelnemers")}</label>
                          <input type="number" min="0" max="999" step="1" className="form-control" name={'maxStudents'+int.id} id={'maxStudents'+int.id} int_id={int.id} value={maxStudents} onChange={e => onChange(e)} />
                        </div>
                        {
                          (typeof int.settings !== 'undefined' && typeof int.settings.languages !== 'undefined') ?
                            <>
                              <label htmlFor="type">{t("Talen")}</label><br />
                                 {
                                  int.settings.languages.map(lang => {
                                    return (
                                      <div key={'lang'+lang.id+'int'+int.id}>
                                        <input className="form-check-input" type="checkbox" name={'lang'+lang.id+'int'+int.id} id={'lang'+lang.id+'int'+int.id} int_id={int.id} lang_code={lang.code} onChange={e => onChange(e)} checked={languages.includes(lang.code)} />
                                        <label className="form-check-label" htmlFor={'lang'+lang.id+'int'+int.id}> {lang.language}</label><br />
                                        {
                                          languages.includes(lang.code) ?
                                            <>
                                              <label htmlFor="login">{t("Bio")}</label>
                                              <textarea className="form-control" name={'bio'+lang.id+'int'+int.id} id={'bio'+lang.id+'int'+int.id} int_id={int.id} lang_code={lang.code} value={lang.code === 'nl' ? bio : typeof bioTranslations.find(bio => bio.code === lang.code) !== 'undefined' ? bioTranslations.find(bio => bio.code === lang.code).content : '' } onChange={onChange} style={{height: '100px'}}></textarea>
                                            </> : <></>
                                        }
                                      </div>
                                    )
                                  })
                                }
                            </> : <></>
                        }  */}
                      </div> : <></>
                    }
                    <br />

                  </div>
                )
              })
            }
          </div>
          <br /><br />
          <div className={state.user.id != 0 ? "form-check" : "hidden"}>
            <input
              className="form-check-input"
              type="checkbox"
              id="showPassword"
              onChange={toggleShowPassword}
              checked={state.showPassword}
            />
            <label className="form-check-label" htmlFor="showPassword">
              {t("Wachtwoord wijzigen")}
            </label>
            <br />
            <br />
          </div>
          <div
            className={
              state.showPassword ? "form-row align-items-center" : "hidden"
            }
          >
            <div className="col">
              <label htmlFor="login">{t("Wachtwoord")}</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                aria-describedby="password"
                placeholder=""
                value={state.user.password}
                onChange={onChange}
              />
            </div>
            <div className="col">
              <label htmlFor="login">{t("Wachtwoord ter controle")}</label>
              <input
                type="password"
                className="form-control"
                id="password_check"
                name="password_check"
                aria-describedby="password_check"
                placeholder=""
                value={state.user.password_check}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            className={
              auth.user_id === props.user.id || props.user.id === 0
                ? "hidden"
                : "form-check"
            }
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="remove"
              name="remove"
              onChange={toggleRemoveUser}
              checked={state.user.removeUser}
            />
            <label className="form-check-label" htmlFor="remove">
              {t("Verwijder gebruiker")}
            </label>
          </div>
        {/* </div> */}
      </form>

      <ConfirmBox confirmOptions={confirmOptions} setConfirmOptions={setConfirmOptions} setToDeleteIndex={setToDeleteIndex}/>
    </div>
  );
});

export default Edituser;
