import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setIntervention } from "../../../actions";
import t from "../../translate";
import { getClone } from "../../utils";

let saveSettingsTimeout = null;

const InterventionSettingsProgressbar = props => {

  const dispatch = useDispatch();

  const intervention = useSelector(state => state.intervention);

  const onChange = e => {
    let interventionC = getClone(intervention);
    if (e.target.checked) {
      interventionC.settings.selfhelp.include_progressbar = 1;
    } else {
      interventionC.settings.selfhelp.include_progressbar = 0;
    }
    saveThemSettings(interventionC.settings);
  };

  const onChangePopoverText = e => {
   let interventionC = getClone(intervention)
   if (typeof interventionC.settings.selfhelp.progressbar_popover_text === "undefined") {
    interventionC.settings.selfhelp.progressbar_popover_text = {};
   }
   interventionC.settings.selfhelp.progressbar_popover_text[e.target.name] = e.target.value
   saveThemSettings(interventionC.settings)
  }

  const saveThemSettings = (newSettings) => {
    
    dispatch(
      setIntervention(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      )
    );

    props.setErrorMessage("");
    clearTimeout(saveSettingsTimeout);
    saveSettingsTimeout = setTimeout(() => {
      props.saveSettings(
        intervention.id,
        intervention.organisation_id,
        intervention.title,
        newSettings
      );
    }, 1500);
  }

  return (
    <div className="form-group">
        <br />
      <h5>{t("Progressbar")}</h5>
      <div className="question">
        <input
            type="checkbox"
            name="include_progressbar"
            id="include_progressbar"
            value="1"
            checked={
              intervention.settings.selfhelp.include_progressbar === 1
            }
            onChange={onChange}
          />
        <label
            className="question"
            htmlFor="include_progressbar"
          >
          {t("progressbar op overzichtspagina tonen")}
        </label>
      </div>
      {
         intervention.settings.selfhelp.include_progressbar === 1 ?  
            <>
              <div className="question">
                <div style={{width: '100%', marginBottom: '10px'}}>
                  <input type="text" style={{width: '100%'}} placeholder={t("Ballontekst nog niet gestart")} id={"progressbar_popover_0"} name={"progressbar_popover_0"} onChange={(e) => onChangePopoverText(e) } value={typeof intervention.settings.selfhelp.progressbar_popover_text !== "undefined" && typeof intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_0"] !== "undefined" ? intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_0"] : ''} />
                </div>
              </div>
              {
                 intervention.settings.selfhelp.lessons.map((lesson, index) => {
                    if (parseInt(lesson.parent_id) === 0) {
                       return (
                          <div key={index} className="question">
                            <div style={{width: '100%', marginBottom: '10px'}}>
                              <input type="text" style={{width: '100%'}} placeholder={t("Ballontekst module: ") + lesson.title} id={"progressbar_popover_" + lesson.id} name={"progressbar_popover_" + lesson.id} onChange={(e) => onChangePopoverText(e) } value={typeof intervention.settings.selfhelp.progressbar_popover_text !== "undefined" &&typeof intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_" + lesson.id] !== "undefined" ? intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_" + lesson.id] : ''} />
                            </div>
                          </div>
                       )
                    }
                 })
              }
              <div className="question">
                <div style={{width: '100%', marginBottom: '10px'}}>
                  <input type="text" style={{width: '100%'}} placeholder={t("Ballontekst programma afgerond")} id={"progressbar_popover_00"} name={"progressbar_popover_00"} onChange={(e) => onChangePopoverText(e) } value={typeof intervention.settings.selfhelp.progressbar_popover_text !== "undefined" && typeof intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_00"] !== "undefined" ? intervention.settings.selfhelp.progressbar_popover_text["progressbar_popover_00"] : ''} />
                </div>
              </div>
            </> : <></>
      }
    </div>
  );
};

export default InterventionSettingsProgressbar;
