import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import flag_en from '../../images/language-switch-flag-en.png';
import flag_nl from '../../images/language-switch-flag-nl.png';
import { setUiTranslation } from '../../actions';
import apiCall from '../api';
const Header = () => {

  const dispatch = useDispatch();

  const language_id = useSelector(state => state.uiTranslation.language_id);
  //const [language, setLanguage] = useState(language_id);

  const changeLanguage = (lang_id) => {
    if (lang_id !== language_id)
    {
      //setLanguage(lang_id);
      if (parseInt(lang_id) === 1)
      {
        dispatch(setUiTranslation(1,[]));
      }
      else
      {
        //getTranslation
        apiCall({
          action: "get_ui_translation_strings_by_language",
          data: {
            language_id: lang_id
          }
        }).then(resp => {
          dispatch(setUiTranslation(lang_id, resp.translation));
        });
      }
    }
  }

  return (
    <header>
      <div className="languageswitchheader" onClick={() => changeLanguage(parseInt(language_id) === 1 ? 2 : 1)}>
       <div className={parseInt(language_id) === 1 ? 'code active' : 'code pointer'}> NL <img src={flag_nl} style={{height: '20px'}} /> </div>
       <div className={parseInt(language_id) === 2 ? 'code active' : 'code pointer'}> EN <img src={flag_en} style={{height: '20px'}} /> </div>
      </div>  

      {/* <div style={{textAlign: 'center', marginTop: '60px'}}>
         <img style={{height: '80px'}} src={logo} />
      </div> */}
    </header>
  )
}

export default Header;
