import React, { useEffect, useState } from "react";
import t from "../../translate";
import { useSelector } from "react-redux";
import apiCall from "../../api";
import { getClone } from "../../utils";


const  StudentInterventions = props => {

  const auth = useSelector(state => state.auth);

  const [student, setStudent] = useState([]);
  const [coachesPerIntervention, setCoachesPerIntervention] = useState([]);
  const [coachesOfIntervention, setCoachesOfIntervention] = useState(false);
  const [chosenInterventionID, setChosenInterventionId] = useState(false);
  const [chosenCoach, setChosenCoach] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if(props.student){
      setChosenInterventionId(props.student.intervention_id)
      setStudent(props.student)
      setChosenCoach(props.student.hasCoach)
    }

  },[])
  useEffect(() => {
    if(props.coaches){
      let tempCoachesPerIntervention = []
      let toPush = {}
      let exist = -1
      for(let i = 0 ; i < props.coaches.length ; i++){
        for(let ii = 0 ; ii < props.coaches[i].interventions.length ; ii++){
          exist = -1
          for(let iii = 0 ; iii < tempCoachesPerIntervention.length ; iii++){
            if(tempCoachesPerIntervention[iii].id == props.coaches[i].interventions[ii].id){
              exist = iii
            }
          }
          if(exist === -1) {
          //  tempCoachesPerIntervention.push({id:1, coaches:[props.coaches[i]], firstname:props.coaches[i].firstname})
            tempCoachesPerIntervention.push({id:parseInt(props.coaches[i].interventions[ii].id), coaches:[props.coaches[i]]})
          } else {
            tempCoachesPerIntervention[exist].coaches.push(props.coaches[i])
          }
        }
      }
      setCoachesPerIntervention(tempCoachesPerIntervention);

      let thisInterventionCoaches = tempCoachesPerIntervention.filter(function(item, i) {
          return item.id === parseInt(props.student.intervention_id)
      })[0];
      let thisIndexInterventionCoaches = tempCoachesPerIntervention.indexOf(thisInterventionCoaches);

      if(thisIndexInterventionCoaches !== -1){
        setCoachesOfIntervention(tempCoachesPerIntervention[thisIndexInterventionCoaches].coaches)
      } else {
        setCoachesOfIntervention([])
      }
    }
  },[props])

  function changeIntervention(intervention_id) {
      /*
    const newStudent = getClone(student)
    newRights = {}
    newRights.interventions = [];
    newRights.interventions.push({id:intervention_id})
    newStudent.rights = newRights;
    setStudent(newStudent)
    setChosenInterventionId(intervention_id)
    */
  }


  const getInterventionTitle = (index) => {
    var this_intervention = props.interventions.find(int => parseInt(int.id) === parseInt(index));
    if(this_intervention){
      return this_intervention.title;
    }
  };

  function changeCoach(coach_id) {
    const newStudent = getClone(student)
    newStudent.rights.interventions.map((interv, index) => {
      if (parseInt(interv.id) === parseInt(chosenInterventionID)) {
        newStudent.rights.interventions[index].hasCoach = coach_id;
      }
    })
    newStudent.hasCoach = coach_id;
    setChosenCoach(coach_id)
    setStudent(newStudent)
  }

  function saveChanges(){

    apiCall({
      action: "save_user",
      token: auth.token,
      data: {
        user: student
      }
    }).then(resp => {
      if (resp.save_user) {
        if(props.setStudents){
          const studentsN = [...props.students];

          for(let i = 0 ; i < studentsN.length ; i++){
            if (studentsN[i].id === student.id){
              studentsN[i] = student;
              break;
            }
          }
          props.setStudents(studentsN)
        }

        setMessage(t("Gegevens zijn gewijzigd"))
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else {
        props.setErrorMessage(resp.msg);
        setTimeout(() => {
          props.setErrorMessage("");
        }, 5000);
      }
    });


  }


    return (
        <div className="interventions">
        <div className={(message != "" ? 'saved':'')}>
          <table>
          <thead>
            <tr>
              <th>
                <b>{t("Interventie")}</b>
              </th>
              <th>
                <b>{t("Coach")}</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {false ?
                  <select value={chosenInterventionID} onChange={(e)=>changeIntervention(e.target.value)}>
                    <option value="">{t("Selecteer een interventie")}</option>
                    {props.interventions.map((intervention, index) => {
                      return (
                        <option key={index} value={intervention.id}>{intervention.title}</option>
                      )
                    })}
                  </select>
                  :
                  <span className="select_dummy">{getInterventionTitle(chosenInterventionID)}</span>
                }
              </td>
              <td>

                  <>
                    {coachesOfIntervention ?
                      <select onChange={(e)=>changeCoach(e.target.value)} value={chosenCoach}>
                          <option value="">Selecteer een coach</option>
                          {coachesOfIntervention.map((coach, index) => {
                            return (
                              <option key={index} value={coach.id}>{coach.login}</option>
                            )
                          })}
                        </select>
                      :
                      <i className="fas fa-spinner fa-spin" ></i>
                    }

                  </>

               </td>
             </tr>
           </tbody>
           </table>
        </div>
      <div className="align_right">
        {message ?
          <span className="btn btn-primary btn-success">
            {message} <i className="fa fa-check"></i>
          </span>
          :
          <span className="btn btn-primary" onClick={()=>saveChanges()}>{t("Opslaan")}</span>
        }
      </div>
    </div>
    )
}

export default StudentInterventions;
